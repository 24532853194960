import axios from 'axios'
import {getUserToken} from '../data/dataApi';
const serverurl = '/assets/data/server.json';
export const getRootPath = async () => {
    const response = await Promise.all([
      fetch(serverurl)
      ]);
      const responseData = await response[0].json();

      return responseData.server;
    }

// const RootPath = 'http://192.168.1.7/sources/newinventory/omni2inventory/api';
// const RootPath = 'https://channel.rooc.egeroo.com';
const Get = async(path:String,params:any=null,headers:any=null) => {
   
    const RootPath=await getRootPath();
    // console.log("headers",headers);
    const promise = new Promise((resolve,reject)=>{
        axios.get(RootPath+'/'+path,{params,headers:headers})
        .then((result)=>{
            // console.log(result);
            resolve(result.data);
        },(err)=>{
            
            // console.log("GET error",err);
            reject(err);
            
        })
    });
    return promise;
}

const Post = async(path:String,data:any=null,headers:any=null) => {
    const RootPath=await getRootPath();
    const promise = new Promise((resolve,reject)=>{
        axios.post(RootPath+'/'+path,data,{headers:headers})
        .then((result)=>{
            
            console.log(result);
            resolve(result.data);
        },(err)=>{
            // console.log("post error",err.response);
            console.log("post error",err);

            reject(err);
        })
    });
    return promise;
}


const Put = async(path:String,id:String,data:any=null,headers:any=null) => {
    const RootPath=await getRootPath();
    const promise = new Promise((resolve,reject)=>{
        axios.put(RootPath+'/'+path+'/'+id,data,{headers:headers})
        .then((result)=>{
            
            // console.log(result);
            resolve(result.data);
        },(err)=>{
            console.log("post error",err);
            reject(err);
        })
    });
    return promise;
}

const Delete = async(path:String,id:String,headers:any=null) => {
    const RootPath=await getRootPath();
    const promise = new Promise((resolve,reject)=>{
        axios.delete(RootPath+'/'+path+'/'+id,{headers:headers})
        .then((result)=>{
            
            // console.log(result);
            resolve(result.data);
        },(err)=>{
            console.log("post error",err.response);
            reject(err);
        })
    });
    return promise;
}

const postLogin = async(data:any) =>  {
    
    return await Post('auth/login',data);
}

const getUserme = async () =>  {
    const usertoken:any = await getUserToken();
    
    console.log("usertoken",usertoken);
    
    let headers:any = {'Content-Type': 'application/json'};
    if(usertoken){
        if(usertoken.token){
            const token = usertoken.token;
            headers = {'Authorization':'Bearer '+token,'Content-Type': 'application/json'};
        }
    }
        
    return await Get('user/me',null,headers);
    
}

const view = async (path:string,params?:any) =>  {
    const usertoken:any = await getUserToken();
    
    // console.log("usertoken",usertoken);
    
    let headers:any = {'Content-Type': 'application/json'};
    if(usertoken){
        if(usertoken.token){
            const token = usertoken.token;
            headers = {'Authorization':'Bearer '+token,'Content-Type': 'application/json'};
        }
    }
        
    return await Get(path,params,headers);
    
}

const load = async (path:string,id:string) =>  {
    const usertoken:any = await getUserToken();
    
    // console.log("usertoken",usertoken);
    
    let headers:any = {'Content-Type': 'application/json'};
    if(usertoken){
        if(usertoken.token){
            const token = usertoken.token;
            headers = {'Authorization':'Bearer '+token,'Content-Type': 'application/json'};
        }
    }
   

    return await Get(path+'/'+id,null,headers);
    
}

const insert = async (path:string,data:any) =>  {
    const usertoken:any = await getUserToken();
    
    // console.log("usertoken",usertoken);
    
    let headers:any = {'Content-Type': 'application/json'};
    if(usertoken){
        if(usertoken.token){
            const token = usertoken.token;
            headers = {'Authorization':'Bearer '+token,'Content-Type': 'application/json'};
        }
    }
   

    return await Post(path,data,headers);
    
}

const update = async (path:string,id:string,data:any) =>  {
    const usertoken:any = await getUserToken();
    
    // console.log("usertoken",usertoken);
    
    let headers:any = {'Content-Type': 'application/json'};
    if(usertoken){
        if(usertoken.token){
            const token = usertoken.token;
            headers = {'Authorization':'Bearer '+token,'Content-Type': 'application/json'};
        }
    }
   

    return await Put(path,id,data,headers);
    
}


const remove = async (path:string,id:string) =>  {
    const usertoken:any = await getUserToken();
    
    // console.log("usertoken",usertoken);
    
    let headers:any = {'Content-Type': 'application/json'};
    if(usertoken){
        if(usertoken.token){
            const token = usertoken.token;
            headers = {'Authorization':'Bearer '+token,'Content-Type': 'application/json'};
        }
    }
   

    return await Delete(path,id,headers);
    
}

const API = {
    postLogin,
    getUserme,
    view,
    load,
    insert,
    update,
    remove
}

export default API;