import { getPlatforms, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonLoading, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { connect } from '../../data/connect';
import React, { useEffect,  useState } from 'react';
import { eyeOffOutline, eyeOutline, printOutline } from 'ionicons/icons';
import {PDFViewer , Page, Text, View, Document, Image, StyleSheet ,Font, PDFDownloadLink} from '@react-pdf/renderer';
import {Table,TableHeader,TableCell,TableBody,DataTableCell}from '@david.kucsai/react-pdf-table';
// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// import pdfMake from 'pdfmake/build/pdfmake';
// import vfsFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
import dateFormat from 'dateformat';
import {
    PDFDocument,
    PDFText,
    PDFTable,
    PDFTableRow,
    PDFTableColumn,
    PDFColumns,
    PDFColumn
  } from 'react-pdfmake';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import API from '../../services';

  interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
  
};

interface DispatchProps {
    
}
type ReceivablePrintProps = OwnProps & StateProps & DispatchProps;
Font.register({
    family: 'Oswald',
    // src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
    src: 'assets/font/Oswald.ttf'
  });
const ReceivablePrint: React.FC<ReceivablePrintProps> = ({location,match}) => {
    const [isloading,setIsloading] = useState(false);
    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [data,setData] = useState<any>();
    const [bank,setBank] = useState<any>();
    const [receivable,setReceivable] = useState<any>();
    const [payment,setPayment] = useState<any>();
    const [transaction,setTransaction] = useState<any>();

    const [details,setDetails] = useState<any>([]);
    const [inventory,setInventory] = useState<any>();
    const [partner,setPartner] = useState<any>();
    const [currency,setCurrency] = useState<any>();
    const [printdata,setPrintdata] = useState<any>();
    const [showPrint, setShowPrint] = useState(false);
    const [errors,setErrors] = useState(
        []
    );
    useEffect(() => {
        if(location){
            if(location.pathname === "/receivable/"+match.params.id+"/print"){
           
                
                
                    loaddata();
                
                
            }
        }
       
      }, [location,match.params.id]);

      const loaddata=async()=>{
        setShowPrint(false);
        setShowLoading(true);
        
        var result:any = await API.load("receivable/printtransaction",match.params.id).catch(e=>errorhandler(e));
     
        if(result){
            setToastMessage("load complete");
           
            setData(result["receivable"]);
            
            setPartner(result["partner"]);
            setInventory(result["inventory"]);
            setCurrency(result["currency"]);
            setBank(result["bank"]);
            setPayment(result["payment"]);
            setTransaction(result["transaction"]);
            var printdata = await DocumentPrint(result);
            setPrintdata(printdata);
        }
       
        setShowCompleteToast(true);
        setShowLoading(false);
        setShowPrint(true);
      }

      const styles2 = StyleSheet.create({
        em:{
            // fontStyle: 'bold'
        }, 
        table: {
            // width: '100%',
            borderWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            marginVertical: 12,
            fontFamily: 'Oswald',
            fontSize:10,
            marginTop:0,
            marginLeft:10,
            marginRight:10,
            padding: 10
        },
        tableRow:{
            display: 'flex',
            flexDirection: 'row',
        },
        header:{
            fontWeight:'bold',
            backgroundColor:'lightgrey'
        },
        cell: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            flexWrap: 'wrap',
            // height:25,
            padding:2,
        },
       
    }) ;
      const styles = StyleSheet.create({
        page: {
            fontFamily: 'Oswald',
            fontSize:12,
        //   margin:10,
        //   padding: 10,
        },
        header:{
            marginLeft:10,
            marginRight:10,
            marginTop:10,

            padding: 10,
            flexDirection: 'row'
            
        },
        image:{
            height:65,
            width:65

        },
        headerimage:{
            fontFamily: 'Oswald',
            fontSize:10,
            flexGrow: 1
        },
        headerinventory:{
            fontFamily: 'Oswald',
            fontSize:10,
            flexGrow: 2
        },
        headercustomer:{
            margin:10,
            fontFamily: 'Oswald',
            fontSize:10,
            // textAlign:"right",
            flexGrow: 2
        },
        viewtable:{
            fontFamily: 'Oswald',
            fontSize:10,
            marginTop:0,
            marginLeft:10,
            marginRight:10,
            padding: 10
            // flexGrow: 1
        },
        tableheadercell:{
            padding:2,
            fontSize:10,
            backgroundColor:"lightgrey"
        },
        tabledatacell:{
            padding:2,
            fontSize:8,
        },
        summary:{
            // marginLeft:10,
            padding: 10,
            fontSize:10,
            flexDirection: 'row'
        },
        summaryleft:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey"

        },
        summaryright:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey"

        },
        ttd:{
            // marginLeft:10,
            padding: 10,
            fontSize:10,
            flexDirection: 'row',
            

        },
        ttdleft:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey",justifyContent:'center'
        },
        ttdright:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey",justifyContent:'center'

        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
          
        }
      });
      
      const errorhandler = (err:any) =>{
        setToastMessage(err.message);
        if(err.response){
            
        }
        else{
            console.log("errorhandler",err.message);
        }
      }

      function numberWithCommas(x:any) {
     
        if(x!==undefined){
           
            return new Intl.NumberFormat().format(x.toString().replace(/,/g, ''));
           
        }
        else{
            return 0;
        }
    }
    const opendoc =(blob:any) =>{
        var fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL);
    }

    const TableTTD = () =>{
        return (
            <View style={[styles2.table,{margin:0}]}>
                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                    
                </View>

            </View>
        )
    }

    
    const TablePayment = (result:any) =>{
        const data =result["sale"];
        const details =result["details"];
        const customer =result["customer"];
        const inventory = result["inventory"];
        const currency = result["currency"];
        const bank = result["bank"];
        const payment = result["payment"];
        return(
        <>
        {
        payment.map((p:any,i:any)=>{
            return (
            <View key = {i} style={styles2.table}>
                <View style={[styles2.tableRow]}>
                    <View style={[styles2.header,styles2.cell
                        , {width:'25%',borderWidth:1,textAlign:'left'}]}>
                        <Text>Tanggal</Text>
                    </View>
                    <View style={[styles2.cell
                        , {width:'25%',borderWidth:1,textAlign:'left'}]}>
                        <Text>{dateFormat(p.PaymentDate,'yyyy-mm-dd')}</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'25%',borderWidth:1,textAlign:'right'}]}>
                        <Text>Receivable Invoice</Text>
                    </View>
                    <View style={[styles2.cell
                        , {width:'25%',borderWidth:1,textAlign:'right'}]}>
                        <Text>{p.PaymentCode}</Text>
                    </View>
                </View>
                {p.details.length>0 &&
                <>
                <View style={[styles2.tableRow,  styles2.header]}>
                    <View style={[styles2.header,styles2.cell
                        , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                        <Text>Method</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'70%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Account</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                        <Text>Total</Text>
                    </View>
                </View>
                {
                p.details.map((d:any,di:any)=>{
                    return(
                        <View key={di} style={[styles2.tableRow]}>
                            <View style={[styles2.cell
                                , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                                <Text>{d.PaymentMethod}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'70%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{d.cashflow.Name}/{d.cashflow.AccountNumber}/{d.cashflow.AccountName}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text>{d.curr.Symbol} {numberWithCommas(d.Debit)}</Text>
                            </View>
                        </View>
                    )
                })
                }
                </>
                }
                {p.receivables.length>0 &&
                <>
                <View style={[styles2.tableRow,  styles2.header]}>
                    <View style={[styles2.header,styles2.cell
                        , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                        <Text>Method</Text>
                    </View>
                    
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Transaction No</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Cheque / Giro No</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'15%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Due Date</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'15%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Status</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                        <Text>Total</Text>
                    </View>
                </View>
                {
                p.receivables.map((r:any,ri:any)=>{
                    return(
                        <View key={ri} style={[styles2.tableRow]}>
                            <View style={[styles2.cell
                                , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                                <Text>{r.PaymentMethod}</Text>
                            </View>
                            
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{r.TransactionNumber}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{r.ChequeNo}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'15%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{dateFormat(r.WithDrawalDate,'yyyy-mm-dd')}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'15%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{r.Status==='WAIT_PAYMENT'?r.PaymentMethod==='CREDIT'?r.Status:'WAIT_CLEARANCE':r.Status}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text>{r.curr.Symbol} {numberWithCommas(r.Amount)}</Text>
                            </View>
                        </View>
                    )
                })
                }
                </>
                }
            </View>
            )
        })
    }
        </>
        )
    }

    const TableBank =(result:any)=>{
        const data =result["receivable"];
        const partner =result["partner"];
        const inventory = result["inventory"];
        const currency = result["currency"];
        const bank = result["bank"];
        const payment = result["payment"];
        const transaction = result["transaction"];

        var maxcol = 3;
        var col = 0;
        var result1 = [];
         
        for (let index = 0; index < bank.length; index++ ) {     
            console.log(index);       
            var child:any = [];
            for(let i=0;i<maxcol && index< bank.length;i++){
                var c = <View key={index} style={[styles2.cell
                    , {width:'31%',borderWidth:1,marginBottom:'1%',marginLeft:'1%',marginRight:'1%',textAlign:'left'}]}>
                        <View style={[styles2.tableRow]}>
                            <View style={[styles2.cell
                                , {width:'30%',textAlign:'left'}]}>
                               <Text>{"Bank"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'2%',textAlign:'left'}]}>
                               <Text>{":"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'70%',textAlign:'left'}]}>
                               <Text>{bank[index].Name}</Text>
                            </View>
                        </View>
                        <View style={[styles2.tableRow]}>
                            <View style={[styles2.cell
                                , {width:'30%',textAlign:'left'}]}>
                               <Text>{"AccountNo"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'2%',textAlign:'left'}]}>
                               <Text>{":"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'70%',textAlign:'left'}]}>
                               <Text>{bank[index].AccountNumber}</Text>
                            </View>
                        </View>
                        <View style={[styles2.tableRow]}>
                            <View style={[styles2.cell
                                , {width:'30%',textAlign:'left'}]}>
                               <Text>{"Name"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'2%',textAlign:'left'}]}>
                               <Text>{":"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'70%',textAlign:'left'}]}>
                               <Text>{bank[index].AccountName}</Text>
                            </View>
                        </View>
                </View>;

                child.push(c);
                if(i<maxcol-1){
                 index++;
                 console.log(index);
                }
                console.log(index);
                
            }
            var parent = <View key={index} style={[styles2.tableRow,{alignItems:'flex-start'}]}>  
                        
                        {child}
                        </View>;
            result1.push(parent);
        }
        
        return (
            <View style={[styles2.table,{margin:0}]}> 
            {/* <Text>Payment Method :</Text> */}
            {
                result1
            
            }
            </View>
        )
    }
    const TableHeader = (result:any) => {
        const data =result["receivable"];
        const partner =result["partner"];
        const inventory = result["inventory"];
        const currency = result["currency"];
        const bank = result["bank"];
        const payment = result["payment"];
        const transaction = result["transaction"];

        return (
            <View style={[styles2.table,{margin:0}]}>
                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                    <View style={[styles2.cell
                        , {width:'15%',borderWidth:0,textAlign:'left'}]}>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',textAlign:'left'}]}>
                                    <Image
                                        style={styles.image}
                                        src="assets/img/appicon.png"
                                    />
                                </View>
                            </View>
                            
                    </View>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'left'}]}>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',textAlign:'left'}]}>
                                   <Text style={{fontSize:16}}>{inventory.Title}</Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',textAlign:'left'}]}>
                                   <Text>{inventory.Address}</Text>
                                </View>
                            </View>
                    </View>
                    <View style={[styles2.cell
                        , {width:'35%',borderWidth:1,textAlign:'right'}]}>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',textAlign:'center',fontSize:16}]}>
                                   <Text>{data.PaymentMethod==="CREDIT"?"RECEIVABLE INVOICE":data.PaymentMethod+" "+data.ChequeNo}</Text>
                                </View>
                                
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                   <Text>{"No"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                   <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                   <Text>{data.TransactionNumber}</Text>
                                </View>
                            </View>
                            {data.ReceivableType ==="SALE"&&
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                    <Text>{"Sale No"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                    <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                    <Text>{transaction.SaleCode}</Text>
                                </View>
                            </View>
                            }
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                   <Text>{"Tanggal"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                   <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                   <Text>{dateFormat(data.TransactionDate,'dd mmmm yyyy')}</Text>
                                </View>
                            </View>
                           
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                   <Text>{"Kepada"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                   <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                   <Text>{partner.Name}</Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                   <Text>{"Alamat"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                   <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                   <Text>{partner.Address}</Text>
                                </View>
                            </View>
                    </View>
                </View>
            </View>

        )
    }
    const TableSummary = (result:any) => {
        const data =result["receivable"];
        const partner =result["partner"];
        const inventory = result["inventory"];
        const currency = result["currency"];
        const bank = result["bank"];
        const payment = result["payment"];
        const transaction = result["transaction"];

        return (
            <View style={[styles2.table,{marginTop:-20}]}>
                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>

                    <View style={[styles2.cell
                        , {width:'66.6%',borderWidth:0,textAlign:'left'}]}>
                            <View style={[styles2.tableRow,styles2.header]}>
                                <View style={[styles2.cell
                                    , {width:'100%',borderTopWidth:1,borderLeftWidth:1,borderRightWidth:1,textAlign:'left'}]}>
                                    <Text>Note : </Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',borderWidth:1,textAlign:'left',height:40}]}>
                                        <Text>{data.Remarks}</Text>
                                    </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',borderWidth:1,textAlign:'center'}]}>
                                    <Text>Terbilang : {toword(numberWithCommas(data.LeftPayment))} {currency.Name}</Text>
                                </View>
                                
                            </View>
                    </View>
                    <View style={[styles2.cell
                        , {width:'33.3%',borderWidth:0}]}>
                            <View style={[styles2.tableRow]}>
                            
                            <View style={[styles2.header,styles2.cell
                                , {width:'50%',borderTopWidth:1,borderLeftWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{"Jatuh Tempo"}</Text>
                            </View>
                
                            <View style={[styles2.cell
                                , {width:'50%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text>{dateFormat(data.WithDrawalDate,'dd mmmm yyyy')}</Text>
                            </View>
                        </View>
                        <View style={[styles2.tableRow]}>
                            
                            <View style={[styles2.header,styles2.cell
                                , {width:'50%',borderLeftWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{"("+currency.Symbol+") "+"Total"}</Text>
                            </View>
                
                            <View style={[styles2.cell
                                , {width:'50%',borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text>{numberWithCommas(data.Amount)}</Text>
                            </View>
                        </View>
                        <View style={[styles2.tableRow]}>
                            
                            <View style={[styles2.header,styles2.cell
                                , {width:'50%',borderLeftWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{"("+currency.Symbol+") "+"Terbayar"}</Text>
                            </View>
                        
                            <View style={[styles2.cell
                                , {width:'50%',borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text>{numberWithCommas(parseFloat(data.Amount)-parseFloat(data.LeftPayment))}</Text>
                            </View>
                        </View>
                        <View style={[styles2.tableRow]}>
                            
                            <View style={[styles2.header,styles2.cell
                                , {width:'50%',borderLeftWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{"("+currency.Symbol+") "+"Sisa"}</Text>
                            </View>
                        
                            <View style={[styles2.cell
                                , {width:'50%',borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text>{numberWithCommas(data.LeftPayment)}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                
            </View>
        )
    }
    const TableItems = (result:any) => {
        const data =result["receivable"];
        const partner =result["partner"];
        const inventory = result["inventory"];
        const currency = result["currency"];
        const bank = result["bank"];
        const payment = result["payment"];
        const transaction = result["transaction"];
        const details:any = [];

        const columnwidth = '16.66%';
        return (
        <View style={styles2.table}>
            <View style={[styles2.tableRow,  styles2.header]}>
                <View style={[styles2.header,styles2.cell
                    , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                    <Text>Kode</Text>
                </View>
                <View style={[styles2.header,styles2.cell
                    , {width:'30%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                    <Text>Barang</Text>
                </View>
                <View style={[styles2.header,styles2.cell
                    , {width:'10%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                    <Text>{"("+currency.Symbol+") "+"Qty"}</Text>
                </View>
                <View style={[styles2.header,styles2.cell
                    , {width:columnwidth,borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                    <Text>{"("+currency.Symbol+") "+"Harga Satuan"}</Text>
                </View>
                <View style={[styles2.header,styles2.cell
                    , {width:columnwidth,borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                    <Text>{"(%) Discount"}</Text>
                </View>
                {/* <View style={[styles2.header,styles2.cell
                    , {width:'10.66%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                    <Text>{"("+currency.Symbol+") "+"Discount"}</Text>
                </View> */}
                <View style={[styles2.header,styles2.cell
                    , {width:columnwidth,borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                    <Text>{"("+currency.Symbol+") "+"Jumlah"}</Text>
                </View>
            </View>

            {details.map((row:any, ind:any) =>
                <View key={ind} style={[styles2.tableRow,  styles2.row]}>
                    
                        <View style={[styles2.cell
                            , {width:'10%',borderLeftWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                           
                                <Text>{row.ItemCode}</Text> 
                           
                        </View>
                        <View style={[styles2.cell
                            , {width:'30%',borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                           
                           <Text>{row.ItemName}</Text> 
                      
                        </View>
                        <View style={[styles2.cell
                            , {width:'10%',borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                           
                           <Text>{numberWithCommas(row.Quantity)+" "+row.QuantityCode}</Text> 
                      
                        </View>
                        <View style={[styles2.cell
                            , {width:columnwidth,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                           
                           <Text>{numberWithCommas(row.ItemSellPrice)}</Text> 
                      
                        </View>
                        <View style={[styles2.cell
                            , {width:columnwidth,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                           
                           <Text>{numberWithCommas(row.DiscountPercent)}%</Text> 
                      
                        </View>
                        {/* <View style={[styles2.cell
                            , {width:'10.66%',borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                           
                           <Text>{numberWithCommas(row.Discount)}</Text> 
                      
                        </View> */}
                        <View style={[styles2.cell
                            , {width:columnwidth,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                           
                           <Text>{numberWithCommas(row.TotalPrice)}</Text> 
                      
                        </View>
                        
                </View>
            )}

            
        </View>
    )
    }

    const toword =(value:any)=>{
        var s = value;
        var th = ['','Ribu','Juta', 'Milyar','Triliun'];
        var dg = ['Nol','Satu','Dua','Tiga','Empat', 'Lima','Enam','Tujuh','Delapan','Sembilan']; var tn = ['Sepuluh','Sebelas','Dua Belas','Tiga Belas', 'Empat Belas','Lima Belas','Enam Belas', 'Tujuh Belas','Delapan Belas','Sembilan Belas']; var tw = ['Dua Puluh','Tiga Puluh','Empat Puluh','Lima Puluh', 'Enam Puluh','Tujuh Puluh','Delapan Puluh','Sembilan Puluh'];
        s = s.toString();
        s = s.replace(/[\, ]/g,''); 
        if (s != parseFloat(s)) 
            return 'not a number'; 
            var x = s.indexOf('.'); 
            if (x == -1) x = s.length; 
            if (x > 15) return 'too big'; 
            var n = s.split(''); 
            var str = ''; 
            var sk = 0; 
            for (var i=0; i < x; i++) {
                if ((x-i)%3==2) {
                    if (n[i] == '1') {
                        str += tn[Number(n[i+1])] + ' '; i++; 
                        sk=1;
                    } else if (n[i]!=0) {
                        str += tw[n[i]-2] + ' ';
                        sk=1;
                    }
                } else if (n[i]!=0) {
                    str += dg[n[i]] +' '; if ((x-i)%3==0) str += 'Ratus ';sk=1;
                } if ((x-i)%3==1) {
                    if (sk) str += th[(x-i-1)/3] + ' ';
                    sk=0;
                }
            } 
            if (x != s.length) {
                var y = s.length; str += 'point '; 
                for (var i:number=parseInt(x)+1; i<y; i++) str += dg[n[i]] +' ';
            } 
            return str.replace(/\s+/g,' ').replace("Satu Ratus","Seratus").replace("Satu Ribu","Seribu").replace("Satu Puluh","Sepuluh");
        }
    

    const DocumentPrint = (result:any)=>(
            
        <Document>
        <Page size="A4" style={styles.page}>
            {TableHeader(result)}
            {TableSummary(result)}
            {TablePayment(result)}
            {TableBank(result)}

<View style={[{textAlign:'right',margin:10,fontWeight:'thin',fontSize:8}]}>
    <Text>PrintDate: {dateFormat(Date.now(),"yyyy-mm-dd HH:MM:ss")}</Text>
</View>
        </Page>
        </Document>
    )
    return (
        
        <>
         {/* <IonButton title={"print sale"} onClick={() => makePdf() }>
              <IonIcon icon={printOutline}></IonIcon>
            </IonButton> */}
<IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/receivable/"+match.params.id}></IonBackButton>
          </IonButtons>
          <IonTitle>{"PRINT RECEIVABLE"}</IonTitle>
          <IonButtons slot="end">
          
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        {/* <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher> */}

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
 {//!getPlatforms().includes("desktop")&&
    //      !showLoading&&data&&details.length>0&&inventory&&customer&&currency&&
    // <PDFDownloadLink document={MyDoc()} fileName="somename.pdf">
    //   {({ blob, url, loading, error }) => {
    //       console.log(blob);
    //       console.log(url);
    //       console.log(error);
         
    //       return (loading ? 'Loading document...' : <IonButton onClick={()=>opendoc(blob)}>Download now!</IonButton>)
    // }}
    // </PDFDownloadLink>
        }
        {//getPlatforms().includes("desktop")&&
        showPrint&&data&&

        <>
         <PDFDownloadLink document={printdata} fileName="saleinvoice.pdf">
             {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <IonButton>Download now!</IonButton>)}
         </PDFDownloadLink>
        <PDFViewer width={"100%"} height={"100%"}>
            {printdata}
        </PDFViewer>
        </>
}
      </IonContent>
      
      
    </IonPage>

        </>

    );
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state, OwnProps) => ({
     
    }),
    mapDispatchToProps: {
      
    },
    component: withRouter(ReceivablePrint)
  })