import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonSelect, IonSelectOption, IonDatetime } from '@ionic/react';

import { connect } from '../../data/connect';
import './Form.scss';
import API from '../../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { eyeOutline, informationCircleOutline, options, save as saveicon, saveOutline } from 'ionicons/icons';
import { getOption } from '../../data/form/template';
import Selectfield from './Selectfield';
import Passwordfield from './Passwordfield';
import NumericField from './NumericField';
import DateInput from '../../components/ActiveForm/DateInput';

interface OwnProps {
      mode:any;
      data: any;
      template:any[];
      errors:any[];
      dataChanged: (data:any) => void;
  }

interface StateProps {
  
};

interface DispatchProps {
    
}

type FormProps = OwnProps & StateProps & DispatchProps;

const Form: React.FC<FormProps> = ({mode,data,errors,template,dataChanged}) => {
  
    useEffect(() => {

        //    loadOptiondata();
        // eslint-disable-next-line
      }, []);


    const setData = (data:any) =>{
        dataChanged(data);
    }
    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
        if(data[name]!==value){
            setData({
                ...data,
                [name]: value
            });
        }
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        console.log("checked",checked);
        console.log("data checked",data);
        console.log("data same",data[name]!==checked);

        if(data[name]!==checked){
            setData({
                ...data,
                [name]: checked
            });
        }
        
    };

  
    const getErrorMessage=(field:string)=>{
      return (
        <div key={"error"+field} className="validation-errors"> 
            {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
              <div key={field} className="error-message"> 
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                {filterederror.message}
                
              </div>
              
            ))}
        </div>
          
        );
    }

    const generateField=()=>{
        var result : any[] = [];

        if(data&&template){
            if(mode==="update"&&data.id===0)
            {

            }
            else{
                Object.keys(template).map((key:any, value) => {
                    let readonly:boolean = false;
                    let disabled:boolean = false;
                    if(template[key].readonly!==undefined){
                        readonly=template[key].readonly;
                        
                    }
                    if(template[key].disabled!==undefined){
                        disabled=template[key].disabled;
                        
                    }
                    var type:any = template[key].type;
                    // var view:any = template[key].view;
                    // var create:any = template[key].create;
                    // var edit:any = template[key].edit;
                    
                    // if((mode==="new"&&create)||(mode==="update"&&edit)){
                    if(type==="string"){
                        result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <IonInput disabled={disabled} readonly={readonly} name={key} value={data[key]} placeholder={"Please Input "+template[key].name} onIonChange={e=>handleChange(e,key)}/>   
                        </IonItem>
                        );
                        
                    }
                    else if(type==="password"){
                        result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <Passwordfield disabled={disabled} readonly ={readonly} name={key} value={data[key]} dataChanged={e=>handleChange(e,key)}/>
                            {/* <IonInput name={key} type={"password"} value={data[key]} placeholder={"Please Input "+template[key].name} onIonChange={e=>handleChange(e,key)}/>  
                            <IonButtons slot="end">
                                <IonButton>
                                <IonIcon slot="icon-only" icon={eyeOutline}></IonIcon>
                                </IonButton>
                            </IonButtons> */}
                            
                        </IonItem>
                        );
                        
                    }
                    else if(type==="email"){
                        result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <IonInput disabled={disabled} readonly={readonly} name={key} type={"email"} value={data[key]} placeholder={"Please Input "+template[key].name} onIonChange={e=>handleChange(e,key)}/>   
                        </IonItem>
                        );
                        
                    }
                    else if(type==="date"){
                        result.push(
                            <IonItem key = {key} detail={false} >
                                <IonLabel position="floating" color="primary">
                                {template[key].name}
                                </IonLabel>
                                <DateInput disabled={disabled} readonly={readonly} displayFormat="DD MMM YYYY" placeholder="Select Date" value={data[key]} onIonChange={e=>handleChange(e,key)}></DateInput>
                                </IonItem>
                        );
                    }
                    else if(type==="boolean"){
                        result.push(
                            <IonItem key = {key} detail={false}  >
                                <IonLabel position="fixed" color="primary">
                                {template[key].name}
                                </IonLabel>
                                <IonToggle  disabled={readonly||disabled} name={key} checked={data[key]} onIonChange={e=>handleChangeChecked(e,key)}/>

                            </IonItem>
    
                            );
                    }
                    else if(type==="number"){
                        result.push(
                            <IonItem key = {key} detail={false}  >
                                <IonLabel position="floating" color="primary">
                                {template[key].name}
                                </IonLabel>
                                <NumericField disabled={disabled} readonly={readonly} type="tel" name={key} value={data[key]} placeholder={"Please Input "+template[key].name} onIonChange={e=>handleChange(e,key)}/>   
                            </IonItem>
                            );
                    }
                    else if(type==="description"){
                        result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <IonTextarea disabled={disabled} readonly={readonly} name={key} value={data[key]} placeholder={"Please Input "+template[key].name} onIonChange={e=>handleChange(e,key)}/>  
                        </IonItem>
                        );
                    }
                    else if(type==="option"){
                        var option = template[key].option;
                        var param = option.param;
                        var api = option.api;
                        var optionchanged = false;
                        Object.keys(data).map((key:any)=>{
                            // console.log(api);
                            // console.log(key);
                            // console.log(data[key]);
                            if(api){
                                if(api.includes(":"+key)){
                                    api = api.replace(":"+key,data[key]);
                                    optionchanged = true;
                                }
                            }
                            if(param){
                                param.map((p:any)=>{
                                    if(param[p].includes(":"+key)){
    
                                        param[p]= param[p].replace(":"+key,data[key]);
                                        optionchanged = true;
                                    }
                                })
                            }
                        });

                        // console.log("before",option);
                        if(optionchanged){
                            // console.log("notsame");
                            option = {...option,param:param,api:api}
                        }
                        // console.log("after",option);
                        // console.log(data);
                        // console.log(key);
                        // console.log("value",data[key]);
                        result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <Selectfield disabled={readonly||disabled} name={template[key].name} value={data[key]} option={option} dataChanged={e=>handleChange(e,key)}/>
                           
                        </IonItem>
                        );
                    }
                    else{
                        result.push(
                            <IonItem key = {key} detail={false} >
                                <IonLabel position="floating" color="primary">
                                {template[key].name}
                                </IonLabel>
                                <IonInput disabled={disabled} readonly={readonly} name={key} value={data[key]} placeholder={"Please Input "+template[key].name} onIonChange={e=>handleChange(e,key)}/>   

                            </IonItem>
                            );
                    }

                    result.push(getErrorMessage(key));
                    // }
                
                });
            }
        }
        return result;
    }
        
    return (
 
        <IonList>
        {generateField()}
        </IonList>

    );

 
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
   
  }),
  mapDispatchToProps: {
    
  },
  component: (Form)
})