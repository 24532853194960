import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal } from '@ionic/react';

import { connect } from '../../data/connect';
import './UserList.scss';
import API from '../../services';
import { add, filter, options, search } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';

interface OwnProps extends RouteProps{
  
 };

interface StateProps {
  mode: 'ios' | 'md';
};

interface DispatchProps {
 };

interface UserListProps extends OwnProps, StateProps, DispatchProps { };

const UserList: React.FC<UserListProps> = ({mode,location}) => {
    const apipath="user";
  const [isleave,setIsLeave] = useState(false);
  const [datas,setDatas] = useState([]);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(true);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);
  const [usefilter,setUseFilter] = useState(false);
  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Address:''
          ,Description:''
          ,Active:true
      }
  );
  const [buttons,setButtons] = useState<any>(getTemplate(apipath+'_button'));
  const [fieldtemplate,setFieldTemplate] = useState<any>(getTemplate(apipath+"_view"));
  const [sort,setSort] = useState<any>(getTemplate(apipath+'_sort'));
  const [filters,setFilters] = useState<any>(getTemplate(apipath+'_filter'));

  const ios = mode === 'ios';
  useEffect(() => {

      if(location){
        if(location.pathname === "/"+apipath){
          loaddatas();
        }
      }
      
    // eslint-disable-next-line
  }, [location,filters,sort]);

  const submitfilter=(pfilters:any[],psort:any)=>{
    setFilters(pfilters);
    setSort(psort);
    setShowFilterModal(false);

  }
  const cancelfilter=()=>{
    
    setShowFilterModal(false);

  }
  const resetfilter=()=>{
    
    setFilters(getTemplate(apipath));
    setSort(getTemplate(apipath+'_sort'));
    setShowFilterModal(false);

  }
  const apifilterParameter=()=>{
    var result:any = {};

    if(filters)
    {
      Object.keys(filters).map((key:any,value:any) => {
        if(filters[key].active && filters[key].value !== ""){
          var param = "filter["+key+"]["+filters[key].operand+"]";
          var pvalue = filters[key].value;
          if(filters[key].type==="boolean"){
            pvalue = pvalue?1:0;
          }
          result[param]=pvalue;
        }
      });
    }

    if(sort){
      if(sort.active){
        var param = "sort";
        var pvalue = sort.field;
        if(sort.direction==="desc"){
          pvalue = "-"+pvalue;
        }
        result[param]=pvalue;
      }
    }

    return result;
  }
  const  loaddatas=async()=>{
    setShowLoading(true);
    const param:any = apifilterParameter();
    const datas:any = await API.view(apipath,param).catch(e=>errorhandler(e));

    if(datas){
      setDatas(datas);
    }

    ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        console.log("errorhandler response",err.response);
        
    }
    else{
        console.log("errorhandler",err.message);
    }
  }
  
  const haveFilter=():boolean=>{
    if(filters){
      var find = Object.keys(filters).find((f:any)=>{
        
          return filters[f].active === true;
      });
      if(find){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }
  return (
    <IonPage id={apipath+"-list"}>
      <IonHeader translucent={true}>
        <IonToolbar>
        {!showSearchbar &&
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          }

          {!showSearchbar &&
          <IonTitle>{apipath.toLocaleUpperCase()}</IonTitle>
          }

          {showSearchbar &&
            <IonSearchbar showCancelButton="always" placeholder="Search"
             //onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
              >

              </IonSearchbar>
          }
          <IonButtons slot="end">
            {/* {!ios && !showSearchbar &&
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            } */}
            {!showSearchbar &&
              <IonButton color={haveFilter()?"primary":""} onClick={() => setShowFilterModal(true)}>
                {mode === 'ios' ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
              </IonButton>
            }
            {!showSearchbar &&
              <IonButton title={"Add User"}routerLink={"/"+apipath+"/new"}>
                {mode === 'ios' ? 'Add' : <IonIcon icon={add} slot="icon-only" />}
              </IonButton>
            }
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{apipath.toLocaleUpperCase()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddatas}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

          <IonGrid fixed>
            <IonRow>
              {datas.map((data:any) => (
                <IonCol size="12" size-md="6" key={data.id}>
                  {/* <UserItem
                    key={User.id}
                    User={User}
                    
                  /> */}
                  <Card key={data.id} id={data.id} data={data} name={apipath} template={fieldtemplate} buttons={buttons}></Card>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
      </IonContent>
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <Filter
          fields={filterfields}
          filters={filters}
          sort={sort}
          submit={submitfilter}
          cancel={cancelfilter}
          reset={resetfilter}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
  },
  component: React.memo(UserList)
});