import React, { useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal } from '@ionic/react';

import { connect } from '../../data/connect';
import './InventoryList.scss';
import API from '../../services';
import { add, options } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { setCurrentPath } from '../../data/sessions/sessions.actions';
import * as selectors from '../../data/selectors';
import { RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';

interface OwnProps extends RouteProps{
  
 };

interface StateProps {
  // inventories: any [];
  mode: 'ios' | 'md';
  currentpath?: any;
};

interface DispatchProps {
  setCurrentPath: typeof setCurrentPath;
 };

interface InventoryListProps extends OwnProps, StateProps, DispatchProps { };

const InventoryList: React.FC<InventoryListProps> = ({mode,location,currentpath,setCurrentPath}) => {
  const apipath="inventory";
  const [isleave,setIsLeave] = useState(false);
  const [inventories,setInventories] = useState([]);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(true);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);
  const [usefilter,setUseFilter] = useState(false);
  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Address:''
          ,Description:''
          ,Active:true
      }
  );
  const [buttons,setButtons] = useState<any>(getTemplate(apipath+'_button'));
  const [fieldtemplate,setFieldTemplate] = useState<any>(getTemplate('inventory_view'));
  const [sort,setSort] = useState<any>(getTemplate('inventory_sort'));
  const [filters,setFilters] = useState<any>(getTemplate('inventory_filter'));
//   const [fieldtemplate,setFieldTemplate] = useState<any>(
//     {  Name:{name:"Name",type:"string",view:true,create:true,edit:true} 
//         ,Address:{name:"Address",type:"description",view:true,create:true,edit:true}
//         ,Description:{name:"Address",type:"description",view:true,create:true,edit:true}
//         ,Active:{name:"Active",type:"boolean",view:true,create:true,edit:true}
//     }
// );

  // const [sort,setSort] = useState<any>(
  //   {   field:'Name'
  //       ,direction:'asc'
  //       ,active:false
  //   }
  // );
  // const [filters,setFilters] = useState<any[]>([]);
  const ios = mode === 'ios';
  useEffect(() => {

    // console.log("location",location);
      // console.log("curentpath",currentpath);
      if(location){
        if(location.pathname === "/inventory"){
          // console.log("loadinventory");
          loadinventories();
        }
      }
      
    // eslint-disable-next-line
  }, [location,filters,sort]);

  const submitfilter=(pfilters:any[],psort:any)=>{
    // console.log("submit filters",pfilters);
    // console.log("submit sort",psort);
    setFilters(pfilters);
    setSort(psort);
    setShowFilterModal(false);
    // loadinventories();

  }
  const cancelfilter=()=>{
    
    setShowFilterModal(false);

  }
  const resetfilter=()=>{
    
    setFilters(getTemplate('inventory_filter'));
    setSort(getTemplate('inventory_sort'));
    setShowFilterModal(false);

  }
  const apifilterParameter=()=>{
    var result:any = {};

    if(filters)
    {
      Object.keys(filters).map((key:any,value:any) => {
        if(filters[key].active && filters[key].value !== ""){
          var param = "filter["+key+"]["+filters[key].operand+"]";
          var pvalue = filters[key].value;
          if(filters[key].type==="boolean"){
            pvalue = pvalue?1:0;
          }
          result[param]=pvalue;
        }
      });
    }

    if(sort){
      if(sort.active){
        var param = "sort";
        var pvalue = sort.field;
        if(sort.direction==="desc"){
          pvalue = "-"+pvalue;
        }
        result[param]=pvalue;
      }
    }

    return result;
  }
  const  loadinventories=async()=>{
    setShowLoading(true);
    const param:any = apifilterParameter();
    // console.log("param",param);
    const inventories:any = await API.view('inventory',param).catch(e=>errorhandler(e));

    if(inventories){
      setInventories(inventories);
    }

    ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        console.log("errorhandler response",err.response);
        
    }
    else{
        console.log("errorhandler",err.message);
    }
  }

  const haveFilter=():boolean=>{
    if(filters){
      var find = Object.keys(filters).find((f:any)=>{
        
          return filters[f].active === true;
      });
      if(find){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }

  return (
    <IonPage id="inventory-list">
      <IonHeader translucent={true}>
        <IonToolbar>
        {!showSearchbar &&
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          }

          {!showSearchbar &&
          <IonTitle>Inventory</IonTitle>
          }

          {showSearchbar &&
            <IonSearchbar showCancelButton="always" placeholder="Search"
             //onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
              >

              </IonSearchbar>
          }
          <IonButtons slot="end">
            {/* {!ios && !showSearchbar &&
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            } */}
            {!showSearchbar &&
              <IonButton color={haveFilter()?"primary":""} onClick={() => setShowFilterModal(true)}>
                {mode === 'ios' ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
              </IonButton>
            }
            {!showSearchbar &&
              <IonButton title={"Add Inventory"} routerLink={`/inventory/new`}>
                {mode === 'ios' ? 'Add' : <IonIcon icon={add} slot="icon-only" />}
              </IonButton>
            }
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Inventory</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loadinventories}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

          <IonGrid fixed>
            <IonRow>
              {inventories.map((inventory:any) => (
                <IonCol size="12" size-md="6" key={inventory.id}>
                 
                  <Card  key={inventory.id} id={inventory.id} data={inventory} name={'inventory'} template={fieldtemplate} buttons={buttons}></Card>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
      </IonContent>
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="inventory-list-filter"
      >
        <Filter
          fields={filterfields}
          filters={filters}
          sort={sort}
          submit={submitfilter}
          cancel={cancelfilter}
          reset={resetfilter}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
    ,currentpath:selectors.getCurrentPath(state)
  }),
  mapDispatchToProps: {
    setCurrentPath
  },
  component: React.memo(InventoryList)
});