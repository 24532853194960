import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard } from '@ionic/react';

import { connect } from '../../data/connect';
import './ItemGroupForm.scss';
import API from '../../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { informationCircleOutline, save as saveicon, saveOutline } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
 
};

interface DispatchProps {

}

type ItemGroupFormProps = OwnProps & StateProps & DispatchProps;

const ItemGroupForm: React.FC<ItemGroupFormProps> = ({location,match}) => {
  const ionRouterContext = useContext(IonRouterContext);

    const apipath = 'item';
    var initdata =  {   
      id:0
        ,Name:''
        ,Active:false
    }
    const [errors,setErrors] = useState(
        []
    );
    const [data,setData] = useState<any>(initdata);
    const [ischanged,setIschanged]=useState(false);

    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        setData({
            ...data,
            [name]: checked
        });
        // setChecked(checked);
    };

    useEffect(() => {
      if(location){
        if(location.pathname === "/itemgroup/"+match.params.id){
         
          if(match.params.id!=="new"){
            loaddata();
          }
          else{
            setData(initdata);
          }
        }
      }
        // loaddata();
        // eslint-disable-next-line
      }, [location,match.params.id]);
    
    const loaddata=async()=>{
      setShowLoading(true);
      var result:any = await API.load('item/loadgroup',match.params.id).catch(e=>errorhandler(e));
      if(result){
          setToastMessage("load complete");
          setData(result);
          // setChecked(result.Active);
      }
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              var error:any = err.response.data;
              var errordata:any = [];
              if(error.itemgroup){
                  try{
                    var erroritemgroup = error.itemgroup.map((e:any)=>{
                        var result = e;
                        switch( e.field){
                            case 'Code':
                                result = {...e,field:"GroupCode"}
                                break;
                            case 'Name':
                                    result = {...e,field:"GroupName"}
                                    break;
                            default:
                                break;
                        }
                        console.log(result);

                        return result;
                        
                    })
                    console.log(erroritemgroup);
                    if(erroritemgroup){
                        errordata= [...errordata,...erroritemgroup];

                    }
                }catch(ex){
                    console.log(ex);
                }
              }
              if(error.item){
                  try{
                    var erroritem = error.item.map((e:any)=>{
                        var result = e;
                        switch( e.field){
                            case 'Code':
                                result = {...e,field:"ItemCode"}
                                break;
                            case 'Name':
                                    result = {...e,field:"ItemName"}
                                    break;
                            default:
                                break;
                        }
                        console.log(result);
                        return result;
                        
                    })
                    console.log(erroritem);
                    if(erroritem){
                        errordata= [...errordata,...erroritem];
                    }
                }catch(ex){
                    console.log(ex);
                }
              }

              console.log(errordata);
              setErrors(errordata);
            //   setErrors(err.response.data);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }

    const save = async ()=>{
      
      setShowLoading(true);
      setErrors([]);
   
      let result:any=null;
      if(match.params.id==="new"){
        var param:any ={
                        "itemgroup":{
                            "Code":data.GroupCode,
                            "Name":data.GroupName,
                            "QuantityGroupID":data.QuantityGroupID,
                            "Active":data.Active
                        },
                        "item":{
                            "Code":data.ItemCode,
                            "Name":data.ItemName,
                            "QuantityCode":data.QuantityCode,
                            "StockPrice":data.StockPrice,
                            "SellPrice":data.SellPrice,
                            "DiscountPercent":data.DiscountPercent,
                            "Currency":data.Currency,
                            "Remarks":data.Remarks,
                            "Active":data.Active
                    
                        }};

        result = await API.insert('item/creategroup',param).catch(e=>errorhandler(e));
      }
      else{
         result = await API.update('item/updategroup',match.params.id,data).catch(e=>errorhandler(e));
      }
      if(result){
          setToastMessage("save complete");
          setData(result);
          ionRouterContext.push('/'+apipath);
      }
      setShowCompleteToast(true);
      setShowLoading(false);

    }

    const dataChanged=(data:any)=>{
      setData(data);
      setIschanged(true);
    }

    function getErrorMessage(field:string) {
      return (
        <div className="validation-errors"> 
            {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
              <div key={field} className="error-message"> 
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                {filterederror.message}
                
              </div>
              
            ))}
        </div>
          
        );
    }
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/"+apipath}></IonBackButton>
          </IonButtons>
          <IonTitle>{match.params.id==="new"?"NEW GROUP":"UPDATE GROUP"}</IonTitle>
          <IonButtons slot="end">
            <IonButton disabled={!ischanged} title={"save"} onClick={() => save() }>
              <IonIcon slot="icon-only" icon={saveicon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
         <IonCard className="field-card">
           
        <Form mode={match.params.id==="new"?"new":"update"}
              data={data}
              errors={errors}
              template={getTemplate("itemgroup"+(match.params.id==="new"?"_create":"_update"))}
              dataChanged={dataChanged}
        />
         </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: withRouter(ItemGroupForm)
})