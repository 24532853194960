import { getConfig, IonBackButton, IonButton, IonButtons, IonCard, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { save, saveOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import { connect } from '../../data/connect';
import { setCurrentPath } from '../../data/sessions/sessions.actions';
import API from '../../services';

interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
    // inventories: any [];
    mode: 'ios' | 'md';
};
  
interface DispatchProps {

};

interface CashflowInventoryAccessFormProps extends OwnProps, StateProps, DispatchProps { };

const CashflowInventoryAccessForm: React.FC<CashflowInventoryAccessFormProps> = ({match,mode,location}) => {
    const masterapipath = 'cashflow';
    const listapipath = 'inventory';
    const [data, setdata] = useState<any>([]);
    const [ischanged,setIschanged]=useState(false);

    const [currentdata, setcurrentdata] = useState<any>([]);
    const [maindata, setMaindata] = useState<any>({});
    const [listdata, setListdata] = useState<any>([]);
    const [actiondata, setActiondata] = useState<any>([]);
    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

    useEffect(() => {
      if(location){
        if(location.pathname === "/cashflowinventory/"+match.params.id){
          loaddata();
        }
      }
       
          // loaddata();
          // eslint-disable-next-line
        }, [location,match.params.id]);
      
      const loaddata=async()=>{
        setShowLoading(true);
        var main:any = await API.load(masterapipath,match.params.id).catch(e=>errorhandler(e));
        if(main){
            setMaindata(main);
        }
        var list:any = await API.view(listapipath).catch(e=>errorhandler(e));
        if(list){
            setListdata(list);
        }
        var access:any = await API.view(masterapipath+"/"+listapipath+"/"+match.params.id).catch(e=>errorhandler(e));
        if(access){
            setdata(access);
        
            var cd:any= await list.map((l:any) => { 
            var found = access.find( (a:any) =>{
                return a.InventoryID === l.id;
            });
            var selected = false;
            
            if(found){
                selected = true;
            }
            return {...l,selected:selected};
            });

            setcurrentdata(cd);
        }
        setShowCompleteToast(true);
        setShowLoading(false);
      }

      

      
    const errorhandler = (err:any) =>{
        setToastMessage(err.message);
        if(err.response){
            if(err.response.status==422){
                // setErrors(err.response.data);
                console.log("errorhandler",err.response.data);
            }
        }
        else{
            console.log("errorhandler",err.message);
        }
      }
  
      const selecteditem = (item: any) => {
        var items =  currentdata.map((i:any)=>{
          if(i.id===item.id){
            return {...i,selected:!i.selected}
          }
          else{
            return i;
          }
          
        })
        setcurrentdata(items);
        setIschanged(true);
      };
      const save = async ()=>{
        
        setShowLoading(true);
        console.log(currentdata);

        var cd = currentdata.map((i:any)=>{
          var result = {InventoryID:i.id,action:""};
          var found = data.find((f:any)=>{
              return f.InventoryID === i.id;
          });

          if(i.selected){
              if(!found){
                result = {InventoryID:i.id,action:"assigned"}
              }
          }
          else{
            if(found){
              result = {InventoryID:i.id,action:"unassigned"}
            }
          }
            
          return result;
        })
        .filter((f:any)=>{
          console.log(f);
          return f.action !=="";
        });

        if(cd){
           var r = await API.insert("cashflow/inventoryaccess/"+match.params.id,cd).catch(e=>errorhandler(e));
          if(r){
            loaddata();
          }
        }
        // let result:any=null;
        // if(match.params.id==="new"){
        //   result = await API.insert(apipath+"/register",data).catch(e=>errorhandler(e));
        // }
        // else{
        //    result = await API.update(apipath+"/edit",match.params.id,data).catch(e=>errorhandler(e));
        // }
        // if(result){
        //     setToastMessage("save complete");
        //     setData(result);
        //     ionRouterContext.push('/'+apipath);
        // }
        setShowCompleteToast(true);
        setShowLoading(false);
  
      }
    return (
    <IonPage id="inventoryAccess-form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/cashflow"></IonBackButton>
          </IonButtons>
          <IonTitle>{maindata.Name+" Inventory Access"}</IonTitle>
          <IonButtons slot="end">
            <IonButton disabled={!ischanged} title={"save"} onClick={() => save() }>
              <IonIcon slot="icon-only" icon={saveOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
         <IonCard className="field-card">
         <IonList lines={ mode==='ios' ? 'inset' : 'full'}>
          
          {currentdata.map((key:any, index:any) => (
            <IonItem key={key.id}>
             
              <IonLabel>{key.Name}</IonLabel>
              {/* <IonLabel>{key.Active?"Active":"InActive"}</IonLabel> */}
              {/* <IonLabel>{key.Name}</IonLabel> */}
              <IonCheckbox
                onClick={() => selecteditem(key)}
                checked={key.selected}
                color="primary"
                // value={track}
              ></IonCheckbox>
            </IonItem>
          ))}
        </IonList>
        {/* <Form mode={match.params.id==="new"?"new":"update"}
              data={data}
              errors={errors}
              template={getTemplate(apipath)}
              dataChanged={setData}
        /> */}
         </IonCard>
      </IonContent>
    </IonPage>
    );
};

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
      mode: getConfig()!.get('mode')
    }),
    mapDispatchToProps: {
    },
    component: React.memo(CashflowInventoryAccessForm)
  });

