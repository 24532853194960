import { IonButton, IonButtons, IonIcon, IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import { connect } from '../../data/connect';
import React, { useEffect,  useState } from 'react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';


interface OwnProps {
    name:any,
    value:any,
    readonly?:any,
    disabled?:any,
    required?:any
    dataChanged: (data:any) => void;
    
}
interface StateProps {
  
};

interface DispatchProps {
    
}
type PasswordfieldProps = OwnProps & StateProps & DispatchProps;

const Passwordfield: React.FC<PasswordfieldProps> = ({name,value,readonly,disabled,required,dataChanged}) => {
    const [isshow,setIsshow] = useState(false);
    const [isloading,setIsloading] = useState(false);
    useEffect(() => {

       
      }, []);


    

    return (
        
        <>
        {!isloading&&
        <>
        <IonInput required ={required} disabled = {disabled} readonly = {readonly} name={name} type={isshow?"text":"password"} value={value} placeholder={"password"} onIonChange={e=>dataChanged(e)}/>  
        <IonButtons slot="end" onClick={()=>setIsshow(!isshow)}>
            <IonButton>
            <IonIcon slot="icon-only" icon={isshow?eyeOffOutline:eyeOutline}></IonIcon>
            </IonButton>
        </IonButtons>
        </>

        }
        </>

    );
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state, OwnProps) => ({
     
    }),
    mapDispatchToProps: {
      
    },
    component: (Passwordfield)
  })