import React, { useState ,useEffect, useContext} from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonList, IonItem, IonAlert, IonRouterContext } from '@ionic/react';
import './Account.scss';
import { setUsername, setUserdata } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';

interface OwnProps extends RouteComponentProps { }

interface StateProps {
  username?: string;
  userdata?: any;
}

interface DispatchProps {
  setUsername: typeof setUsername;
}

interface AccountProps extends OwnProps, StateProps, DispatchProps { }

const Account: React.FC<AccountProps> = ({ setUsername, username , userdata,location}) => {
  const ionRouterContext = useContext(IonRouterContext);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // console.log("userdata : ",userdata);
    // console.log("username : ",username);
    // eslint-disable-next-line
    console.log("load account");
  }, [location]);

  const clicked = (text: string) => {
    console.log(`Clicked ${text}`);
  }

  const changepassword = ()=>{
    ionRouterContext.push('/changemypassword');
  }
  return (
    
    <IonPage id="account-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {username &&
          (<div className="ion-padding-top ion-text-center">
            <img src="https://www.gravatar.com/avatar?d=mm&s=140" alt="avatar" />
            <h2>{ username }</h2>
            <IonList inset>
              {/* <IonItem onClick={() => clicked('Update Picture')}>Update Picture</IonItem> */}
              {/* <IonItem onClick={() => setShowAlert(true)}>Change Username</IonItem> */}
              <IonItem>{username}</IonItem>
              <IonItem onClick={() => changepassword()}>Change Password</IonItem>
              {/* <IonItem routerLink="/support" routerDirection="none">Support</IonItem> */}
              <IonItem routerLink="/logout" routerDirection="none">Logout</IonItem>
            </IonList>
          </div>)
        }
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        header="Change Username"
        buttons={[
          'Cancel',
          {
            text: 'Ok',
            handler: (data) => {
              setUsername(data.username);
            }
          }
        ]}
        inputs={[
          {
            type: 'text',
            name: 'username',
            value: username,
            placeholder: 'username'
          }
        ]}
        onDidDismiss={() => setShowAlert(false)}
      />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  
  mapStateToProps: (state) => ({
    username: state.user.username,
    userdata: state.user.userdata
  }),
  mapDispatchToProps: {
    setUsername
  },
  component: Account
})