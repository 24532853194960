import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal, IonBackButton, IonList, IonItem, IonLabel, IonCheckbox, IonInput, IonCard, IonCardHeader, IonCardContent, IonDatetime, IonTextarea } from '@ionic/react';

import { connect } from '../../data/connect';
import './ViewPartnerForm.scss';
import API from '../../services';
import { add, arrowBack, checkbox, filter, options, search } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';
import MasterDetailList from '../../components/ActiveForm/MasterDetailList';
import NumberFormat from 'react-number-format';
import DateInput from '../../components/ActiveForm/DateInput';

interface OwnProps{
    close?:()=>void;
    id:any;
    type?:any
 };

interface StateProps {
  mode: 'ios' | 'md';
};

interface DispatchProps {
 };

interface ViewPartnerFormProps extends OwnProps, StateProps, DispatchProps { };

const ViewPartnerForm: React.FC<ViewPartnerFormProps> = ({mode,type,id,close}) => {
    const apipath="partner";

  const [data,setData] = useState<any>([]);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);

  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Description:''
          ,Active:true
      }
  );

  const [sort,setSort] = useState<any>(getTemplate(apipath+'_sort'));
  const [filters,setFilters] = useState<any>(getTemplate(apipath+'_filter'));

  const ios = mode === 'ios';
  useEffect(() => {

    
      loaddatas();
    // eslint-disable-next-line
  }, []);



 


const  loaddatas=async()=>{
    setShowLoading(true);
   
    const data:any = await API.load(apipath,id).catch(e=>errorhandler(e));
    
   
    if(data){
       
        setData(data);
        setToastMessage("load data complete");
    }
    
    
    ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        console.log("errorhandler response",err.response);
        
    }
    else{
        console.log("errorhandler",err.message);
    }
  }
  return (
    <IonPage id={apipath+"-list"}>
      <IonHeader translucent={true}>
        <IonToolbar>
        <IonButtons slot="end">
            {close?
            <IonButton onClick={()=>close()}>
            {mode === 'ios' ? 'close' : 'close'}
            </IonButton>
            :""}
         
        </IonButtons>

      
          {!showSearchbar &&
          <IonTitle> {"VIEW " + (type!==undefined?type.toLocaleUpperCase():apipath.toLocaleUpperCase())}
          </IonTitle>
          }

          {showSearchbar &&
            <IonSearchbar showCancelButton="always" placeholder="Search"
              onIonCancel={() => setShowSearchbar(false)}
              >

              </IonSearchbar>
          }
          <IonButtons slot="end">
            
           
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{apipath.toLocaleUpperCase()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddatas}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

            <IonGrid>
            
            <IonList lines={ mode==='ios' ? 'inset' : 'full'}>
          
            
                <IonCard className="field-card">
                    

                    <IonCardContent>
                    <IonList lines="none">
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Code"}
                        </IonLabel>
                        <IonInput value={data.Code} placeholder={"Please Input "+"Code"} readonly/>   
                    </IonItem>
                   
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Name"}
                        </IonLabel>
                        <IonInput value={data.Name} placeholder={"Please Input "+"Name"} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Location Code"}
                        </IonLabel>
                        <IonInput value={data.LocationCode} placeholder={"Please Input "+"Location Code"} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Location"}
                        </IonLabel>
                        <IonInput value={data.Location} placeholder={"Please Input "+"Location"} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Address"}
                        </IonLabel>
                        <IonTextarea value={data.Address} placeholder={"Please Input "+"Address"} readonly/>   
                    </IonItem>
                   
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Email"}
                        </IonLabel>
                        <IonInput value={data.Email} type={"email"} placeholder={"Please Input "+"Email"} readonly/>   
                    </IonItem>
                    
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"DOB"}
                        </IonLabel>
                        <DateInput displayFormat="DD MMM YYYY" placeholder="Select DOB" value={data.DOB} readonly></DateInput>

                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Home Phone"}
                        </IonLabel>
                        <IonInput value={data.HomePhone} placeholder={"Please Input "+"Home Phone"} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Office Phone"}
                        </IonLabel>
                        <IonInput value={data.OfficePhone} placeholder={"Please Input "+"Office Phone"} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Mobile Phone"}
                        </IonLabel>
                        <IonInput value={data.MobilePhone} placeholder={"Please Input "+"Mobile Phone"} readonly/>   
                    </IonItem>
                  
                    </IonList>
                    </IonCardContent>
                </IonCard>

            </IonList>
           
          </IonGrid>
         
      </IonContent>
      
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
  },
  component: React.memo(ViewPartnerForm)
});