import React, { useEffect, useContext } from 'react';
import { IonRouterContext } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { connect } from '../data/connect';
import { setIsLoggedIn, setUsername } from '../data/user/user.actions';

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface StateProps {

}

interface RedirectToLoginProps extends OwnProps,  StateProps, DispatchProps { }
// interface RedirectToLoginProps {
//   setIsLoggedIn: Function;
//   setUsername: Function;
// }

const RedirectToLogin: React.FC<RedirectToLoginProps> = ({history, setIsLoggedIn, setUsername }) => {
  console.log("redirect to login");
  const ionRouterContext = useContext(IonRouterContext);
  useEffect(() => {
    setIsLoggedIn(false);
    setUsername(undefined);

    history.replace('/login');
    
    // ionRouterContext.push('/login',"root","replace");
    // ionRouterContext.push('/login');
    // window.location.reload();
  }, []);
  return null;
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,

  },
  mapStateToProps: (state) => ({

  }),
  component: RedirectToLogin
})
