import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard, IonDatetime, IonCardHeader, IonCardContent, IonModal, IonSelect, IonSelectOption, IonAlert, IonItemDivider, useIonViewWillEnter, useIonViewDidEnter, useIonViewWillLeave, useIonViewDidLeave } from '@ionic/react';

import { connect } from '../../data/connect';
import './SaleForm.scss';
import API from '../../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { add, arrowRedo, cartOutline, cash, closeCircle, eyeOutline, grid, informationCircleOutline, list, pencil, printOutline, remove, save as saveicon, saveOutline, search, send, trashBin } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
import Selectfield from '../../components/ActiveForm/Selectfield';
import SearchItemForm from '../Stockopname/SearchItemForm';
import NumberFormat from 'react-number-format';
import SearchPartnerForm from '../Partner/SearchPartnerForm';
import ViewPartnerForm from '../Partner/ViewPartnerForm';
import AddPartnerForm from '../Partner/AddPartnerForm';
import EditPartnerForm from '../Partner/EditPartnerForm';
import NumericField from '../../components/ActiveForm/NumericField';
import SalePrintButtons from './SalePrintButtons';
import DateInput from '../../components/ActiveForm/DateInput';

interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
 
};

interface DispatchProps {

}

type SaleFormProps = OwnProps & StateProps & DispatchProps;

const SaleForm: React.FC<SaleFormProps> = ({location,match}) => {
  const ionRouterContext = useContext(IonRouterContext);
  var today = new Date();

  const initdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    const apipath = 'sale';
    var initdata =  {   
      id:0
        ,Name:''
        ,TotalItemPrice:0
        ,TotalItemDiscount:0
        ,TotalItemBonus:0
        ,TotalSale:0
        ,Discount:0
        ,TotalDelivery:0
        ,PPN:0
        ,TotalPayment:0
        ,Active:false
        ,Status:"NEW"
        ,SaleDate:initdate
    }
    const [errors,setErrors] = useState(
        []
    );

    const [errordetails,setErrordetails] = useState<any>(
        []
    );
    const [ischanged,setIschanged]=useState(false);
    const [data,setData] = useState<any>();
    const [details,setDetails] = useState<any>([]);
    const [currencylist,setCurrencylist] = useState<any>([]);
    const [currency,setCurrency] = useState<any>([]);
    const [isReadonly, setIsReadOnly] = useState(false);

    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showSearchItemModal, setShowSearchItemModal] = useState(false);
    const [showSearchParnerModal, setShowSearchPartnerModal] = useState(false);
    const [showViewParnerModal, setShowViewPartnerModal] = useState(false);
    const [showAddParnerModal, setShowAddPartnerModal] = useState(false);
    const [showEditParnerModal, setShowEditPartnerModal] = useState(false);

    const [myInventory, setMyInventory] = useState<any>([]);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeader, setAlertHeader] = useState("Warning");
    const [alertMessage, setAlertMessage] = useState("Warning");
    const [alertButtons, setAlertButtons] = useState<any>(['Ok']);

    const pageRef = useRef<HTMLElement>(null);

    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
    const handleDetailChange = (e:any,key:any,name:string)=>{
        const { value } = e.detail;
        var change = details;
        // console.log(name);
        // console.log(change[key]);
        if(change[key].isBonus){
            change[key]={...change[key],[name]:value,Discount:0,TotalPrice:0};
        }
        else{
            if(name ==="Quantity"){
                var total = (parseFloat(value.toString().replace(/,/g, ''))*(parseFloat(change[key].ItemSellPrice.toString().replace(/,/g, ''))-parseFloat(change[key].Discount.toString().replace(/,/g, ''))));

                change[key]={...change[key],[name]:value,TotalPrice:total.toString()};
            }
            else if(name ==="ItemSellPrice"){
                var discount = parseFloat(value.toString().replace(/,/g, '')) * parseFloat(change[key].DiscountPercent.replace(/,/g, ''))/100;
                discount = parseFloat(parseFloat(discount.toString()).toFixed(2));
                var total = (parseFloat(change[key].Quantity.toString().replace(/,/g, ''))*(parseFloat(value.toString().replace(/,/g, ''))-parseFloat(discount.toString().replace(/,/g, ''))));

                change[key]={...change[key],[name]:value,TotalPrice:total,Discount:discount};
            }
            else if(name ==="Discount"){
                var total = (parseFloat(change[key].Quantity.toString().replace(/,/g, ''))*(parseFloat(change[key].ItemSellPrice.toString().replace(/,/g, ''))-parseFloat(value.toString().replace(/,/g, ''))));

                change[key]={...change[key],[name]:value,TotalPrice:total};
            }
            else if(name ==="DiscountPercent"){
                var discount = parseFloat(change[key].ItemSellPrice.toString().replace(/,/g, '')) * parseFloat(value.toString().replace(/,/g, ''))/100;
                discount = parseFloat(parseFloat(discount.toString()).toFixed(2));

                var total = (parseFloat(change[key].Quantity.toString().replace(/,/g, ''))*(parseFloat(change[key].ItemSellPrice.toString().replace(/,/g, ''))-parseFloat(discount.toString().replace(/,/g, ''))));

                change[key]={...change[key],[name]:value,Discount:discount,TotalPrice:total};
            }
            else{
                change[key]={...change[key],[name]:value};
            }
        }
        // console.log(change);
        setDetails(change);
       
        calculateTotal(change);
        
    }

    const calculateTotal=(itemdetails:any)=>{
        if(itemdetails!==undefined&&itemdetails.length>0){
            const TotalItemPrice = itemdetails.map((c:any) => c.TotalPrice).reduce((a:any, b:any) => parseFloat(a.toString().replace(/,/g, '')) + parseFloat(b.toString().replace(/,/g, '')));
            const TotalItemDiscount = itemdetails.map((c:any) => c.isBonus?0:c.Discount).reduce((a:any, b:any) => parseFloat(a.toString().replace(/,/g, '')) + parseFloat(b.toString().replace(/,/g, '')));

            const TotalItemBonus = itemdetails.map((c:any) => c.isBonus?c.TotalPrice:0).reduce((a:any, b:any) => parseFloat(a.toString().replace(/,/g, '')) + parseFloat(b.toString().replace(/,/g, '')));
            
            const TotalSale = TotalItemPrice ;
            console.log("TotalSale",TotalSale);
            console.log("data.Discount",data.Discount);
            console.log("data.TotalDelivery",data.TotalDelivery);
            const TotalPayment = parseFloat(TotalSale.toString().replace(/,/g, '')) - parseFloat(data.Discount.toString().replace(/,/g, '')) + parseFloat(data.TotalDelivery.toString().replace(/,/g, ''))+ parseFloat(data.PPN.toString().replace(/,/g, ''));
            console.log("TotalPayment",TotalPayment);
            
            setData({...data,TotalItemPrice:TotalItemPrice,TotalItemDiscount:TotalItemDiscount,TotalItemBonus:TotalItemBonus,TotalSale:TotalSale,TotalPayment:TotalPayment});
        }
    }

    
    const handleDetailChangeChecked =(e:any,key:any,name:string) => {
        const { checked } = e.detail;
        var change = details;

        if(change[key][name]!==checked){
            if(name==="isBonus"){
                if(checked){
                    var total = change[key].TotalPrice=parseFloat(change[key].Quantity.toString().replace(/,/g, ''))*(parseFloat(change[key].ItemSellPrice.toString().replace(/,/g, ''))-parseFloat(change[key].Discount.toString().replace(/,/g, '')));

                    change[key]={...change[key],[name]:checked,TotalPrice:total};
                }
                else{
                change[key]={...change[key],[name]:checked,TotalPrice:0};
                }
            }
            else{
                change[key]={...change[key],[name]:checked};
            }

            setDetails(change);
            calculateTotal(change);

        }
        setIschanged(true);
        // setChecked(checked);
    };
    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
        //console.log("before",data,name,value);

            if(name==="Currency"){
                var selectedcurrency = currencylist.find((f:any)=>{return f.Currency == value});
                setCurrency(selectedcurrency);
                setData({
                    ...data,
                    [name]: value
                    ,ExchangeRate:selectedcurrency.Kurs
                });
            }
        
            else if(name=="Discount"){
                const TotalPayment = parseFloat(data.TotalSale.toString().replace(/,/g, '')) - parseFloat(value.toString().replace(/,/g, '')) + parseFloat(data.TotalDelivery.toString().replace(/,/g, ''))+ parseFloat(data.PPN.toString().replace(/,/g, ''));
                //console.log("TotalPayment",TotalPayment);
                setData({
                    ...data,
                    [name]: parseFloat(value.toString().replace(/,/g, ''))
                    ,TotalPayment:TotalPayment
                });
            }
            else if(name =="PPN"){
                const TotalPayment = parseFloat(data.TotalSale.toString().replace(/,/g, '')) - parseFloat(data.Discount.toString().replace(/,/g, '')) + parseFloat(value.toString().replace(/,/g, ''))+ parseFloat(data.TotalDelivery.toString().replace(/,/g, ''));
                //console.log("TotalPayment",TotalPayment);

                setData({
                    ...data,
                    [name]: parseFloat(value.toString().replace(/,/g, ''))
                    ,TotalPayment:TotalPayment
                });
            }
            else if(name =="TotalDelivery"){
                const TotalPayment = parseFloat(data.TotalSale.toString().replace(/,/g, '')) - parseFloat(data.Discount.toString().replace(/,/g, '')) + parseFloat(value.toString().replace(/,/g, ''))+ parseFloat(data.PPN.toString().replace(/,/g, ''));
                //console.log("TotalPayment",TotalPayment);

                setData({
                    ...data,
                    [name]: parseFloat(value.toString().replace(/,/g, ''))
                    ,TotalPayment:TotalPayment
                });
            }
            else if(["TotalDelivery","Discount","PPN"].includes(name)){
                setData({
                    ...data,
                    [name]: parseFloat(value.toString().replace(/,/g, ''))
                });
            }
            else{
                
            setData({
                ...data,
                [name]: value
            });

            }
            setIschanged(true);
            //console.log("after",data,name,value);

        
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        if(data[name]!==checked){
            setData({
                ...data,
                [name]: checked
            });
        }
        setIschanged(true);
        // setChecked(checked);
    };

    useEffect(() => {
        if(location){
            if(location.pathname === "/sale/"+match.params.id){
           
                
                if(match.params.id!=="new"){
                    loaddata();
                }
                else{
                    // setData(initdata);
                    loadnew();
                }
            }
        }
        // loaddata();
        // eslint-disable-next-line
      }, [location,match.params.id]);
    
    const openSearchPartnerModal=async()=>{
    

        setShowSearchPartnerModal(true);
           
    
    }

    const openViewPartnerModal=()=>{
    
        if(data.CustomerID){
        
        setShowViewPartnerModal(true);
        }

    }
    const cancelSearchPartnerModal=()=>{
    
        
        setShowSearchPartnerModal(false);
       

    }
    const submitSearchPartnerModal=(partner:any)=>{
    
        setData({...data,CustomerID:partner.id,CustomerName:partner.Name});
        setShowSearchPartnerModal(false);
        setIschanged(true);
    
    }
    const openAddPartnerModal=()=>{
    
        
        setShowAddPartnerModal(true);
       

    }
    const submitAddPartnerModal=(partner:any)=>{
        setData({...data,CustomerID:partner.id,CustomerName:partner.Name});
        setShowAddPartnerModal(false);
        setIschanged(true);
    }

    const openEditPartnerModal=()=>{
    
        if(data.CustomerID){
        
        setShowEditPartnerModal(true);
        }

    }
    const cancelEditPartnerModal=()=>{
    
        
        setShowEditPartnerModal(false);
       

    }
    const submitEditPartnerModal=(partner:any)=>{
    
        setData({...data,CustomerID:partner.id,CustomerName:partner.Name});
        setShowEditPartnerModal(false);
        setIschanged(true);
    
    }
      const openSearchItemModal=()=>{

        if(data.Currency!==undefined){
            setShowSearchItemModal(true);
        }
        else{
            setAlertButtons(['Ok']);
            setAlertHeader("Warning");
            setAlertMessage("Please Input Currency");
            setShowAlert(true);
        }
    
      }
      const cancelSearchItemModal=()=>{
    
        setShowSearchItemModal(false);
    
      }

      const submitSearchItemModal=(items:any)=>{
        
        var newinput = items.map((item:any)=>{
            var discount = 0;
            discount = parseFloat(item.SellPrice)*parseFloat(item.DiscountPercent)/100;
            return {
               
                ItemID:item.id,
                ItemName:item.Name,
                ItemCode:item.Code,
                Quantity:item.Quantity,
                QuantityCode:item.QuantityCode,
                Currency:data.Currency,
                ItemSellPrice:item.SellPrice,
                ItemBuyPrice:item.StockPrice,
                Discount:discount,
                DiscountPercent:item.DiscountPercent,
                UseDiscountPercent:true,
                isBonus:false,
                TotalPrice:parseFloat(item.Quantity)*(parseFloat(item.SellPrice)-discount),
                Status:"ACTIVE",
            }
        })

        
        changeItemDetails(newinput);

        setShowSearchItemModal(false);
        setIschanged(true);
      }

      const changeItemDetails=(inputs:any)=>{
        if(details.length>0){
            var currentdetails = details;
  
            inputs= inputs.map((d:any)=>{
                var find = currentdetails.findIndex((f:any)=>{
                    
                    return  f.ItemID === d.ItemID;
                });
                // console.log(find);

                // console.log(find!==-1);
                
                if(find!==-1){
                    // console.log("currentdetails[find]",currentdetails[find]);
                    var quantity = parseFloat(currentdetails[find].Quantity.toString().replace(/,/g, '')) + parseFloat(d.Quantity.toString().replace(/,/g, ''));
                    currentdetails[find].Quantity=quantity;
                    currentdetails[find].TotalPrice=parseFloat(quantity.toString().replace(/,/g, ''))*(parseFloat(currentdetails[find].ItemSellPrice.toString().replace(/,/g, ''))-parseFloat(currentdetails[find].Discount.toString().replace(/,/g, '')));
                }
                else{
                    
                    currentdetails.push(d);
                }
                return d;
            });

            setDetails(currentdetails);
            calculateTotal(currentdetails);

        }
        else{
            setDetails(inputs);
            calculateTotal(inputs);

        }
        setIschanged(true);
      }
      const loadinventory = async()=>{
        var inventories:any = await API.view("user/myinventory").catch(e=>errorhandler(e));
       
        if(inventories){
            setMyInventory(inventories);
        }
      }
      const loadcurrency = async()=>{
        var currencylist:any = await API.view("currency").catch(e=>errorhandler(e));
       
        if(currencylist){
           await setCurrencylist(currencylist);
        }
      }
    const loadnew=async()=>{
        setShowLoading(true);
        await loadinventory();
        // await loadcurrency();
        var currencylist:any = await API.view("currency").catch(e=>errorhandler(e));
       
        if(currencylist){
           await setCurrencylist(currencylist);
        }
        var findbasic = await currencylist.find((c:any)=>{
            return c.isBasicCurrency;
         });
         if(findbasic){
             setData({...initdata,Currency:findbasic.Currency,ExchangeRate:1});
             setCurrency(findbasic);
 
         }
         else{
            setData(initdata);
         }
        setShowCompleteToast(true);
      setShowLoading(false);
    }
    const loaddata=async()=>{
      setShowLoading(true);
      var result:any = await API.load("sale/loadtransaction",match.params.id).catch(e=>errorhandler(e));
    //   var inventories:any = await API.view("user/myinventory").catch(e=>errorhandler(e));
        await loadinventory();
        await loadcurrency();
      if(result){
          setToastMessage("load complete");
         
            maploaddata(result);
          // setChecked(result.Active);
      }
      else{

      }
    //   if(inventories){
    //       setMyInventory(inventories);
    //   }
    if(ionRefresherRef.current ){
        ionRefresherRef.current!.complete();
        }
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const maploaddata=(result:any)=>{

        // result["sale"]
        if(result["sale"]["Status"]!=="NEW"){
            setIsReadOnly(true);
        }
        setData(result["sale"]);
        setDetails(result["details"]);
    }
    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              setErrors(err.response.data["sale"]);
              setErrordetails(err.response.data["details"]);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }
    const removeitem=(item:any)=>{
        // console.log(item);
        var items = details.filter((d:any)=>{
            return d.ItemID!==item.ItemID;
        })

        setDetails(items);
        calculateTotal(items);
        setIschanged(true);
    }
    const cancelsale = async ()=>{
        setAlertButtons([
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                
                }
            },
            {
              text: 'Yes',
              handler: () => {
                //   console.log(data,value);
               cancel();
                
              }
            }
          ]);
        setAlertHeader("Warning");
        setAlertMessage("do you want to cancel this sale?");
        setShowAlert(true);
     

    }
    const cancel = async()=>{
        setShowLoading(true);
     
      let result:any=null;
      
        result = await API.load(apipath+"/cancel",match.params.id).catch(e=>errorhandler(e));
      setShowCompleteToast(true);
      setShowLoading(false);
      setIschanged(false);
      if(result){
          loaddata();
      }
      setShowLoading(false);
    }
    const payment = async ()=>{
        if(ischanged){
            setAlertButtons([
                {
                  text: 'Ok',
                  role: 'cancel',
                  handler: () => {
                    
                    }
                }
              ]);
            setAlertHeader("Warning");
            setAlertMessage("sale ischanged, please save first");
            setShowAlert(true);
        }
        else{
            if(match.params.id!="new"){
                if(data.Status=="NEW"){
                    ionRouterContext.push('/'+apipath+"/"+match.params.id+"/payment");
                }
                else{
                    ionRouterContext.push('/'+apipath+"/"+match.params.id+"/paymentlist");

                }
            }
        }
    }
    const delivery = async ()=>{
        if(match.params.id!="new"){
            if(data.Status=="WAIT_DELIVERY"){
                ionRouterContext.push('/'+apipath+"/"+match.params.id+"/delivery");
            }
            else{
                ionRouterContext.push('/'+apipath+"/"+match.params.id+"/deliverylist");
            }
        }
    }
    const print = ()=>{

        if(ischanged){
            setAlertButtons([
                {
                  text: 'Ok',
                  role: 'cancel',
                  handler: () => {
                    
                    }
                }
              ]);
            setAlertHeader("Warning");
            setAlertMessage("sale ischanged, please save first");
            setShowAlert(true);
        }
        else{
        ionRouterContext.push('/'+apipath+"/"+match.params.id+"/print");
        }

    }
    const save = async ()=>{
      
      setShowLoading(true);
      setErrors([]);
      setErrors([]);
    //   console.log("sale",data);
      let result:any=null;
      if(match.params.id==="new"){
        result = await API.insert(apipath+"/createtransaction",{"sale":data,"details":details}).catch(e=>errorhandler(e));
      }
      else{
         result = await API.update(apipath+"/updatetransaction",data.id,{"sale":data,"details":details}).catch(e=>errorhandler(e));
      }
      if(result){
          setToastMessage("save complete");
          setData(result["sale"]);
          setDetails(result["details"]);
          setErrors([]);
          setIschanged(false);
          if(match.params.id==="new"){
            ionRouterContext.push('/'+apipath+"/"+result["sale"]["id"]+"/payment");

          }
          else{
            ionRouterContext.push('/'+apipath+"/"+result["sale"]["id"]);
          }      

      }
      setShowCompleteToast(true);
      setShowLoading(false);

    }
    function numberWithCommas(x:any) {
        // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // return x.toLocaleString('en-US', {maximumFractionDigits:2}) 
        if(x!==undefined){
            return x;
            // return new Intl.NumberFormat().format(x.toString().replace(/,/g, ''));
            // return x.toLocaleString('en-US', {maximumFractionDigits:2}) 
            // var parts=x.toString().split(".");
            // return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
        }
        else{
            return 0;
        }
    }
    function getDetailErrorMessage(field:string,index:any) {
        if(errordetails.length>0){
            // console.log("errordetails",errordetails);
            // console.log("index",index);

            // console.log("errordetails[index]",errordetails[index]);
            if(!Array.isArray(errordetails[index])){
                return ;
            }
            else{
            return (
                <div className="validation-errors"> 
                    {errordetails[index].filter((error:any) => error.field === field).map((filterederror:any) => (
                    <div key={field} className="error-message"> 
                    <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                        {filterederror.message}
                        
                    </div>
                    
                    ))}
                </div>
                
                );
            }
        }
    }
    function getErrorMessage(field:string) {
        if(!Array.isArray(errors)){
            return ;
        }
        else{
        return (
            <div className="validation-errors"> 
                {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
                <div key={field} className="error-message"> 
                <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                    {filterederror.message}
                    
                </div>
                
                ))}
            </div>
            
            );
        }
    }
    const generateOption=()=>{
        var result:any = [];
        // console.log(myInventory);
        myInventory.forEach((od:any) => {
            
            result.push( <IonSelectOption key={"Inventory"+od.InventoryID} value={od.InventoryID}>{od.Name}</IonSelectOption>);

        });
        

        return result;
    }
    function getDetailsempty(){
        return (
            <IonList>
            <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Code"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Item"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Quantity"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    
                    
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Price"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Discount(%)"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Discount"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    {/* <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Free"}
                            </IonLabel>
                        </IonItem>
                    </IonCol> */}
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Total Price"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonList>
        );
    }

    function getDetail(detail:any,key:any){
        // console.log(detail);
        return (
        <IonList key = {"list"+key} >
            <IonRow>
                <IonCol>
                    <IonItem detail={false} >
                        <IonLabel position="floating" color="primary">
                        {"Code"}
                        </IonLabel>
                        
                        <IonInput size={20}readonly={true} name={"ItemCode"} value={detail["ItemCode"]} />   
                          
                    </IonItem>
                    {getDetailErrorMessage("ItemID",key)}
                   
                </IonCol>
                <IonCol size="25">
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Item"}
                        </IonLabel>
                        <IonInput  readonly={true} name={"ItemName"} value={detail["ItemName"]} />   

                    </IonItem>
                    
                </IonCol>
                
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Quantity"+"("+detail["QuantityCode"]+")"}
                        </IonLabel>
                        <NumericField type={"tel"} readonly = {isReadonly} name={"Quantity"} value={detail["Quantity"]} placeholder={"Please Input "+"Quantity"} onIonChange={e => handleDetailChange(e,key,"Quantity")} />   
                        
                    </IonItem>
                    {getDetailErrorMessage("Quantity",key)}

                </IonCol>
              
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Price"+"("+detail["Currency"]+")"}
                        </IonLabel>
                        <NumericField type={"tel"} readonly = {isReadonly}  name={"ItemSellPrice"} value={numberWithCommas(detail["ItemSellPrice"])} placeholder={"Please Input "+"Price"} onIonChange={e => handleDetailChange(e,key,"ItemSellPrice")} />   
                        
                    </IonItem>
                    {getDetailErrorMessage("ItemSellPrice",key)}

                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Discount"+"(%)"}
                        </IonLabel>
                        <NumericField noformat={true}  readonly = {isReadonly} min={0.00} max={100} type={"tel"}  name={"DiscountPercent"} value={detail["DiscountPercent"]} placeholder={"Please Input "+"DiscountPercent"} onIonChange={e => handleDetailChange(e,key,"DiscountPercent")} />   
                        
                    </IonItem>
                    {getDetailErrorMessage("DiscountPercent",key)}

                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Discount"+"("+detail["Currency"]+")"}
                        </IonLabel>
                        <NumericField disabled = {true} type={"tel"}  name={"Discount"} value={detail["Discount"]} placeholder={"Please Input "+"Discount"} onIonChange={e => handleDetailChange(e,key,"Discount")} />   
                        
                    </IonItem>
                    {getDetailErrorMessage("Discount",key)}

                </IonCol>
                {/* <IonCol>
                    <IonItem>
                        <IonCheckbox disabled = {isReadonly} name={"isBonus"} checked={detail["isBonus"]} onIonChange={e=>handleChangeChecked(e,key)}/>

                        <IonLabel position="fixed" color="primary">
                        {"Free"}
                        </IonLabel>                        
                    </IonItem>
                    {getDetailErrorMessage("isBonus",key)}

                </IonCol> */}
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Total"+"("+detail["Currency"]+")"}
                        </IonLabel>
                        <NumericField type={"tel"} readonly={true} name={"TotalPrice"} value={numberWithCommas(detail["TotalPrice"])} placeholder={"Please Input "+"Total"} />   
                        
                    </IonItem>
                    {getDetailErrorMessage("TotalPrice",key)}

                </IonCol>
                <IonCol>
                    {/* <IonItem> */}
                    {!isReadonly&&
                        <IonButtons>
                            <IonButton color="primary" onClick={() => removeitem(detail) }>
                            <IonIcon slot="icon-only" icon={trashBin}></IonIcon>
                            </IonButton>  
                        </IonButtons>
                    }
                    {/* </IonItem> */}
                </IonCol>
            </IonRow>
            <IonItemDivider color="primary"></IonItemDivider>
        </IonList>
        );
    }
    // console.log(data);
    // console.log(showSearchParnerModal);
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/"+apipath}></IonBackButton>
          </IonButtons>
          <IonTitle>{match.params.id==="new"?"NEW SALE":"SALE"}</IonTitle>
          <IonButtons slot="end">
          {data&&match.params.id!=="new"&&
            <IonButton title={"Payment sale"} onClick={() => payment() }>
              <IonIcon icon={cash}></IonIcon>
            </IonButton>
            }
            
            {data&&match.params.id!=="new"&&data.Status!=="NEW"&&
            <IonButton title={"Delivery sale"}  onClick={() => delivery() }>
              <IonIcon  icon={cartOutline}></IonIcon>
            </IonButton>
            }
            {data&&match.params.id!=="new"&&data.Status==="NEW"&&
            <IonButton title={"cancel sale"} onClick={() => cancelsale() }>
              <IonIcon icon={closeCircle}></IonIcon>
            </IonButton>
            }
            {data&&match.params.id!=="new"&&
             <IonButton title={"print sale"} onClick={()=>print()}>
             <IonIcon  icon={printOutline}></IonIcon>
           </IonButton>
           }
            {data&&data.Status==="NEW"&&
            <IonButton title={"save"} disabled={!ischanged} onClick={() => save() }>
              <IonIcon  icon={saveicon}></IonIcon>
            </IonButton>
            }
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
         <IonCard className="field-card">
           {data&&data!==undefined&&!showLoading&&
           <IonGrid>
               <IonRow>
               <IonCol>
                    <IonItem key = {"SaleDate"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Date"}
                        </IonLabel>
                        <DateInput readonly = {isReadonly} displayFormat="DD MMM YYYY" placeholder="Select Date" value={data["SaleDate"]} onIonChange={e=>handleChange(e,"SaleDate")}></DateInput>
                    </IonItem>
                    {getErrorMessage("SaleDate")}
                </IonCol>
                <IonCol>
                    <IonItem key = {"SaleCode"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Code"}
                        </IonLabel>
                        <IonInput readonly = {isReadonly} name={"SaleCode"} value={data["SaleCode"]} placeholder={"Please Input "+"Code"} onIonChange={e=>handleChange(e,"SaleCode")}/>   
                    </IonItem>
                    {getErrorMessage("SaleCode")}
                </IonCol>
                
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Inventory"}
                        </IonLabel>
                        {/* <IonInput readonly={true} name={"InventoryID"} value={detail["InventoryID"]} />    */}
                        
                        {myInventory.length>0&&
                        <Selectfield disabled={isReadonly} name={"InventoryID"} value={data["InventoryID"]} option={{datas:myInventory,label:"Name",value:"id"}} dataChanged={e=>handleChange(e,"InventoryID")}/>
                        }
                        {/* <IonSelect key={"InventoryID"} value={data["InventoryID"]}  placeholder={"Please Select Inventory"} onIonChange={e => handleChange(e,"InventoryID")}>
                            {generateOption()}
                                            
                        </IonSelect> */}
                    </IonItem>
                    {getErrorMessage("InventoryID")}

                </IonCol>
                <IonCol>
                    <IonItem key = {"Status"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Status"}
                        </IonLabel>
                        <IonInput name={"Status"} readonly value={data["Status"]}/>   
                    </IonItem>
                    {getErrorMessage("Status")}
                </IonCol>
            </IonRow>
            <IonRow>
               <IonCol>
                    <IonItem key = {"CustomerID"} detail={false} disabled={false}>
                        <IonLabel position="fixed" color="primary">
                        {"Customer"}
                        </IonLabel>
                        <IonInput name={"CustomerName"} value={data["CustomerName"]} readonly={true}/>   
                        {!isReadonly&&
                        <IonButton color="primary" onClick={() => openSearchPartnerModal()}>
                                <IonIcon icon={search} />
                                
                        </IonButton>
                        }
                        {data.CustomerID&&
                        <IonButton color="primary" onClick={() => openViewPartnerModal()}>
                                <IonIcon icon={eyeOutline} />
                                
                            </IonButton>
                            }
                        {data.CustomerID&&
                        <IonButton color="primary" onClick={() => openEditPartnerModal()}>
                                <IonIcon icon={pencil} />
                                
                        </IonButton>
                        }
                        {!isReadonly&&
                        <IonButton color="primary" onClick={() => openAddPartnerModal()}>
                                <IonIcon icon={add} />
                                
                        </IonButton>
                        }
                    </IonItem>
                    {getErrorMessage("CustomerID")}
                    </IonCol>
               
                </IonRow>
                <IonRow>
               <IonCol>
                    <IonItem key = {"Currency"} detail={false} disabled={false}>
                        <IonLabel position="fixed" color="primary">
                        {"Currency"}
                        </IonLabel>
                        <Selectfield disabled = {isReadonly} name={"Currency"} value={data["Currency"]} option={{datas:currencylist,label:"Name",value:"Currency"}} dataChanged={e=>handleChange(e,"Currency")}/>

                    </IonItem>
                    {getErrorMessage("Currency")}
                    </IonCol>
               
                </IonRow>
           <IonRow>
                <IonCard >
                    <IonList>
                        <IonRow>
                        {/* <IonCol>
                            <IonItem key = {"itemdetail"} detail={false} disabled={false}>
                                <IonLabel position="fixed" color="primary">
                                {"Item Detail"}
                                </IonLabel>
                            </IonItem>
                        </IonCol> */}
                        <IonCol>
                        {/* <IonButtons > */}
                        <IonItem>
                        <IonLabel color="primary">
                           ITEMS
                        </IonLabel>
                        {!isReadonly&&
                            <IonButton onClick={() => openSearchItemModal()}>
                                <IonIcon icon={search} />
                                Find Item
                            </IonButton>
                            }
                            {/* </IonButtons> */}
                        </IonItem>
                        </IonCol>
                        
                        </IonRow>
                    </IonList>
                    <IonCardContent>
                    {details.length==0? 
                        getDetailsempty()
                            :
                        Object.keys(details).map((key:any)=>{
                            // var detail = details[key];
                            // console.log(detail);
                            return getDetail(details[key],key);
                        })
                    }
                    </IonCardContent>
                </IonCard>
           </IonRow>
          
           <IonList>
           <IonRow>
                <IonCol>
                    <IonItem key = {"TotalSale"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            Total Sale
                        </IonLabel>
                        <NumericField type={"tel"} readonly={true} name={"TotalSale"} value={numberWithCommas(data["TotalSale"])}  />   
                        
                    </IonItem>
                    {getErrorMessage("TotalSale")}
                </IonCol>
                
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem key = {"Discount"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            Discount
                        </IonLabel>
                        <NumericField type={"tel"} readonly = {isReadonly} name={"Discount"} value={numberWithCommas(data["Discount"])} onIonChange={e=>handleChange(e,"Discount")}  />   
                        
                    </IonItem>
                    {getErrorMessage("Discount")}
                </IonCol>
                
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem key = {"PPN"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            PPN
                        </IonLabel>
                        <NumericField type={"tel"} readonly = {isReadonly} name={"PPN"} value={numberWithCommas(data["PPN"])} onIonChange={e=>handleChange(e,"PPN")}  />   
                        
                    </IonItem>
                    {getErrorMessage("PPN")}
                </IonCol>
                
            </IonRow>
            {/* <IonRow>
                <IonCol>
                    <IonItem key = {"TotalDelivery"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            Delivery Cost
                        </IonLabel>
                        <NumericField type={"tel"} readonly = {isReadonly} name={"TotalDelivery"} value={numberWithCommas(data["TotalDelivery"])} onIonChange={e=>handleChange(e,"TotalDelivery")}  />   
                        
                    </IonItem>
                    {getErrorMessage("TotalDelivery")}
                </IonCol>
                
            </IonRow> */}
            <IonRow>
                <IonCol>
                    <IonItem key = {"TotalPayment"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            Total Invoice
                        </IonLabel>
                        <NumericField type={"tel"} readonly={true} name={"TotalPayment"} value={numberWithCommas(data["TotalPayment"])} onIonChange={e=>handleChange(e,"TotalPayment")}  />   
                        
                    </IonItem>
                    {getErrorMessage("TotalPayment")}
                </IonCol>
                {data&&data.Status!=="NEW"&&
                    <IonCol>
                    <IonItem key = {"TotalDebit"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            Total Debit
                        </IonLabel>
                        <NumericField type={"tel"} readonly={true} name={"TotalDebit"} value={numberWithCommas(data["TotalDebit"])} onIonChange={e=>handleChange(e,"TotalDebit")}  />   
                        
                    </IonItem>
                    {getErrorMessage("TotalDebit")}
                </IonCol>
                }
                {data&&data.Status!=="NEW"&&
                    <IonCol>
                    <IonItem key = {"TotalCredit"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            Total Credit
                        </IonLabel>
                        <NumericField type={"tel"} readonly={true} name={"TotalCredit"} value={numberWithCommas(data["TotalCredit"])} onIonChange={e=>handleChange(e,"TotalCredit")}  />   
                        
                    </IonItem>
                    {getErrorMessage("TotalCredit")}
                </IonCol>
                }
                {data&&data.Status!=="NEW"&&
                    <IonCol>
                    <IonItem key = {"LeftPayment"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            Left Payment
                        </IonLabel>
                        <NumericField type={"tel"} readonly={true} name={"LeftPayment"} value={numberWithCommas(data["LeftPayment"])} onIonChange={e=>handleChange(e,"LeftPayment")}  />   
                        
                    </IonItem>
                    {getErrorMessage("LeftPayment")}
                </IonCol>
                }
            </IonRow>
           </IonList>
           <IonList>
            <IonRow>
               <IonCol>
                    <IonItem key = {"Remarks"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Remarks"}
                        </IonLabel>
                        <IonTextarea readonly = {isReadonly} name={"Remarks"} value={data["Remarks"]} placeholder={"Please Input "+"Remarks"} onIonChange={e=>handleChange(e,"Remarks")}/>   
                    </IonItem>
                    {getErrorMessage("Remarks")}

                </IonCol>
                </IonRow>
           </IonList>
           </IonGrid>
        }
        {/* <Form mode={match.params.id==="new"?"new":"update"}
              data={data}
              errors={errors}
              template={getTemplate(apipath+(match.params.id==="new"?"_create":"_update"))}
              dataChanged={setData}
        /> */}
         </IonCard>
      </IonContent>
      <IonModal
        isOpen={showSearchItemModal}
        onDidDismiss={() => setShowSearchItemModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <SearchItemForm
          cancel={cancelSearchItemModal}
          submit={submitSearchItemModal}
        />
      </IonModal>
      <IonModal
        isOpen={showSearchParnerModal}
        onDidDismiss={() => setShowSearchPartnerModal(false)}
        swipeToClose={true}
        // presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <SearchPartnerForm
          cancel={cancelSearchPartnerModal}
          submit={submitSearchPartnerModal}
          type={"customer"}
        />
      </IonModal>
{data&&
      <IonModal
        isOpen={showViewParnerModal}
        onDidDismiss={() => setShowViewPartnerModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <ViewPartnerForm
          close={() => setShowViewPartnerModal(false)}
          id={data.CustomerID}
          type={"customer"}

        />
      </IonModal>
}
      <IonModal
        isOpen={showAddParnerModal}
        onDidDismiss={() => setShowAddPartnerModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <AddPartnerForm
          close={() => setShowAddPartnerModal(false)}
          submit={submitAddPartnerModal}
          type={"customer"}
        />
      </IonModal>
{data&&
      <IonModal
        isOpen={showEditParnerModal}
        onDidDismiss={() => setShowEditPartnerModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <EditPartnerForm
          close={() => setShowEditPartnerModal(false)}
          submit={submitEditPartnerModal}
          type={"customer"}
          id={data.CustomerID}
        />
      </IonModal>
}      
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={alertHeader}
          message={alertMessage}
          buttons={alertButtons}
        />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: withRouter(SaleForm)
})