import React, { useState, useEffect, useContext } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText,useIonViewWillEnter, IonRouterContext, IonLoading, IonToast } from '@ionic/react';
import './Login.scss';
import { setIsLoggedIn, setUsername ,setUserdata, setUsertoken} from '../data/user/user.actions';
import { setMenuEnabled } from '../data/sessions/sessions.actions';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import API from '../services';
import Passwordfield from '../components/ActiveForm/Passwordfield';

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  setMenuEnabled: typeof setMenuEnabled;
  setUserdata: typeof setUserdata;
  setUsertoken: typeof setUsertoken;
}

interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface LoginProps extends OwnProps,  StateProps, DispatchProps { }

const Login: React.FC<LoginProps> = ({isAuthenticated,setIsLoggedIn, history,location, setUsername: setUsernameAction,setUserdata: setUserdataAction,setUsertoken: setUsertokenAction,setMenuEnabled}) => {
  const ionRouterContext = useContext(IonRouterContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    //setIsLoggedIn(false);
    // setMenuEnabled(false);
    if(location){
      if(location.pathname==="/login"){
            console.log("login will enter");

        setIsLoggedIn(false);
        setMenuEnabled(false);
        setFormSubmitted(false);
        setUsername('');
        setPassword('');
      }
    }
  }, [location]);
  // useIonViewWillEnter(() => {
  //   console.log("login will enter");
  //   setIsLoggedIn(false);
  //   setMenuEnabled(false);
  //   setFormSubmitted(false);
  //   setUsername('');
  //   setPassword('');
  // });
  const errorhandler = (err:any) =>{
    
    if(err.response){
        // console.log("errorhandler response",err.response);
        setToastMessage(err.response.statusText);
    }
    else{
        setToastMessage(err.message);
        // console.log("errorhandler",err.message);
    }
  }
  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowLoading(true);
    setFormSubmitted(true);
    if(!username) {
      setUsernameError(true);
    }
    if(!password) {
      setPasswordError(true);
    }

    if(username && password) {
      try{
      const result:any = await API.postLogin({"username":username,"password":password}).catch(e=>errorhandler(e));
      console.log("result",result);

      
      await setUsernameAction(username);
      
      await setUsertokenAction(result.data);

      const userdata = await API.getUserme();
     
      await setUserdataAction(userdata);
      await setIsLoggedIn(true);
      await setMenuEnabled(true);
      setShowCompleteToast(true);
      setShowLoading(false);
        // console.log("push");
      // ionRouterContext.push('/');
      history.replace('/');
      // window.location.replace('/');
      // history.push('/', {replaceurl:true});
      }catch(ex){
        console.log("error",ex);
        await setIsLoggedIn(false);
        await setMenuEnabled(false);
      }

    }
    setShowCompleteToast(true);
    setShowLoading(false);
  };
// console.log("login render");
  return (
    
    <IonPage id="login-page">
{!isAuthenticated&&
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <div className="login-logo">
          <img src="assets/img/appicon.svg" alt="Omni Inventory" />
        </div>

        <form onSubmit={login} className="loginform">
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">Username</IonLabel>
              <IonInput name="username" type="text" placeholder={"username"} value={username} spellCheck={false} autocapitalize="off" onIonChange={e => setUsername(e.detail.value!)}
                required>
              </IonInput>
            </IonItem>

            {formSubmitted && usernameError && <IonText color="danger">
              <p className="ion-padding-start">
                Username is required
              </p>
            </IonText>}

            <IonItem>
              <IonLabel position="stacked" color="primary">Password</IonLabel>
              <Passwordfield name="password" required  value={password} dataChanged={e => setPassword(e.detail.value!)}/>
              
            </IonItem>

            {formSubmitted && passwordError && <IonText color="danger">
              <p className="ion-padding-start">
                Password is required
              </p>
            </IonText>}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">Login</IonButton>
            </IonCol>
            {/* <IonCol>
              <IonButton routerLink="/signup" color="light" expand="block">Signup</IonButton>
            </IonCol> */}
          </IonRow>
        </form>
        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
      </IonContent>
}
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,
    setMenuEnabled,
    setUserdata,
    setUsertoken
  },
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled
  }),
  component: Login
})