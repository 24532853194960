import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard, IonDatetime, IonCardHeader, IonCardContent, IonModal, IonSelect, IonSelectOption, IonAlert, IonItemDivider, useIonViewWillEnter, useIonViewDidEnter, useIonViewWillLeave, useIonViewDidLeave } from '@ionic/react';

import { connect } from '../../data/connect';
import './SaleReport.scss';
import API from '../../services';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router';
import { add, arrowRedo, cartOutline, cash, closeCircle, eyeOutline, grid, informationCircleOutline, list, newspaperOutline, pencil, printOutline, remove, save as saveicon, saveOutline, search, send, trashBin } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
import Selectfield from '../../components/ActiveForm/Selectfield';
import SearchItemForm from '../Stockopname/SearchItemForm';
import NumberFormat from 'react-number-format';
import SearchPartnerForm from '../Partner/SearchPartnerForm';
import ViewPartnerForm from '../Partner/ViewPartnerForm';
import AddPartnerForm from '../Partner/AddPartnerForm';
import EditPartnerForm from '../Partner/EditPartnerForm';
import NumericField from '../../components/ActiveForm/NumericField';
import ReactHTMLTableToExcel from '../../components/react-html-table-to-excel/src/ReactHTMLTableToExcel';
import ReactExport from "../../components/react-export-excel/dist";
import DateInput from '../../components/ActiveForm/DateInput';
// import ReactExport from "../../components/react-data-export/dist"
// import ExcelFile from '../../components/react-export-excel/src/ExcelPlugin/components/ExcelFile';
// import ExcelSheet from '../../components/react-export-excel/src/ExcelPlugin/elements/ExcelSheet';
interface OwnProps extends RouteProps {}

interface StateProps {
 
};

interface DispatchProps {

}

type SaleReportProps = OwnProps & StateProps & DispatchProps;

const SaleReport: React.FC<SaleReportProps> = ({location}) => {
  const ionRouterContext = useContext(IonRouterContext);
  var today = new Date();

  const initdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const apipath = 'sale';
    const [params,setParams] = useState({
        DateFrom:initdate
        ,DateTo:initdate
        ,InventoryID:0
    })
    const [errors,setErrors] = useState(
        []
    );

    const [errordetails,setErrordetails] = useState<any>(
        []
    );
    const [ischanged,setIschanged]=useState(false);
    const [data,setData] = useState<any>();
    const [salesummary,setSalesummary] = useState<any>([]);
    const [saleitemsummary,setSaleitemsummary] = useState<any>([]);

    const [details,setDetails] = useState<any>([]);
    const [currencylist,setCurrencylist] = useState<any>([]);
    const [currency,setCurrency] = useState<any>([]);
    const [isReadonly, setIsReadOnly] = useState(false);

    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showSearchItemModal, setShowSearchItemModal] = useState(false);
    const [showSearchParnerModal, setShowSearchPartnerModal] = useState(false);
    const [showViewParnerModal, setShowViewPartnerModal] = useState(false);
    const [showAddParnerModal, setShowAddPartnerModal] = useState(false);
    const [showEditParnerModal, setShowEditPartnerModal] = useState(false);

    const [myInventory, setMyInventory] = useState<any>([]);
    const [currentInventory,setCurrentInventory] = useState<any>();
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeader, setAlertHeader] = useState("Warning");
    const [alertMessage, setAlertMessage] = useState("Warning");
    const [alertButtons, setAlertButtons] = useState<any>(['Ok']);
    const [selectdate,setSelectdate]= useState('today');
    const [iscustomdate,setIscustomdate]=useState(false);
    const pageRef = useRef<HTMLElement>(null);
    const [filename,setFilename] = useState("");
    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
        
            if(name==="date"){
                if(selectdate!==value){
                loaddate(value);
                }
            }
            
            else{
                setParams({
                    ...params,
                    [name]: value
                });
            }

            if(name ==="InventoryID"){
                const inventory = myInventory.find((f:any)=>f.id === value);
                setCurrentInventory(inventory);
            }            
            setData([]);
            setSaleitemsummary([]);
            setSalesummary([]);
            setIschanged(true);
            
        
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        if(data[name]!==checked){
            setData({
                ...data,
                [name]: checked
            });
        }
        setIschanged(true);
        // setChecked(checked);
    };

    useEffect(() => {
        if(location){
            if(location.pathname === "/report/"+"sale"){
           
                loadinventory();
                
            }
        }
        // loaddata();
        // eslint-disable-next-line
      }, [location]);
    

      const loaddate=(code:any)=>{
        var date = new Date();
        var datefrom = new Date(date.getFullYear(), date.getMonth(), 1);
        var dateto = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
       
        switch(code){
            case 'today':
                 datefrom = date;
                 dateto = date;
                 setIscustomdate(false);

                break;
            case 'yesterday':
                
                 datefrom = new Date(date.getFullYear(), date.getMonth(),date.getDate()-1);
                 dateto = new Date(date.getFullYear(), date.getMonth(),date.getDate()-1);
                 setIscustomdate(false);

                break;
            case 'this week':

                 datefrom = new Date(date.setDate(diff));
                 dateto = new Date(datefrom.getFullYear(), datefrom.getMonth(),datefrom.getDate()+7);
                 setIscustomdate(false);

                break;
           
            case 'this month':
                datefrom = new Date(date.getFullYear(), date.getMonth(), 1);
                dateto = new Date(date.getFullYear(), date.getMonth()+1,0);
                setIscustomdate(false);

                break;
           
            case 'custom':
                setIscustomdate(true);
                break;
            default:
                break;

        }

       
        const datefromstring = datefrom.getFullYear() + '-' + (datefrom.getMonth() + 1) + '-' + datefrom.getDate();
        const datetostring = dateto.getFullYear() + '-' + (dateto.getMonth() + 1) + '-' + dateto.getDate();
        
        setSelectdate(code);
        setParams({...params
            ,DateFrom:datefromstring
            ,DateTo:datetostring});
        
      }
    const loaddata=async()=>{
      setShowLoading(true);
      var result:any = await API.insert("report/sale",null).catch(e=>errorhandler(e));
    
      if(result){
          setToastMessage("load complete");
       
      }
      else{

      }
   
    if(ionRefresherRef.current ){
        ionRefresherRef.current!.complete();
        }
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const loadinventory = async()=>{
        var inventories:any = await API.view("user/myinventory").catch(e=>errorhandler(e));
       
        if(inventories&&inventories.length>0){
            
            inventories.sort((a:any,b:any)=>a.Name>b.Name?1:-1);
            inventories.unshift({id:0,Name:"All Inventory",Address:"All Inventory",Description:"All Inventory",Active:true});
            setMyInventory(inventories);
            setParams({...params,InventoryID:inventories[0].id});
            setCurrentInventory(inventories[0]);
        }
      }

      const generatefilename = async(params:any) =>{
          var filename = "report";
          switch(selectdate){
            case "today":
            case "yesterday":
                filename = "_"+currentInventory.Name +"_("+params.DateFrom+")";
                break;
            default:
                filename = "_"+currentInventory.Name +"_("+params.DateFrom+")_("+params.DateTo+")";
                break;
            }

            setFilename(filename);
      }
      const generatereport = async()=>{
        setShowLoading(true);

        var lparams = params;
        var datef = new Date(lparams.DateFrom);
        var datefrom = datef;
        const datefromstring = datefrom.getFullYear() + '-' + (datefrom.getMonth() + 1) + '-' + datefrom.getDate();
        
        var datet = new Date(lparams.DateTo);
        var dateto = datet;
        const datetostring = dateto.getFullYear() + '-' + (dateto.getMonth() + 1) + '-' + dateto.getDate();

        lparams={...lparams,DateFrom:datefromstring,DateTo:datetostring}        

        await setParams(lparams);
        await generatefilename(lparams);
        var data:any = await API.insert("report/sale",lparams).catch(e=>errorhandler(e));
        
        if(data&&data.length>0){
            var salesummary:any = []
            if( data.length==1){
                data.map((a:any)=>{
                    var result = [];
                    result[a.Status] = {TotalSale:parseFloat(a.TotalSale)
                        ,TotalCount:1
                        ,TotalDelivery:parseFloat(a.TotalDelivery)
                        ,TotalDiscount:parseFloat(a.Discount)
                        ,TotalPayment:parseFloat(a.TotalPayment)};
                    salesummary=result;
                })
            }
            else{
            
                salesummary= data.reduce((a:any, b:any) => {
                    var result = [];
                    
                    if(a["id"]){
                        result[a.Status] = {TotalSale:parseFloat(a.TotalSale)
                            ,TotalCount:1
                            ,TotalDelivery:parseFloat(a.TotalDelivery)
                            ,TotalDiscount:parseFloat(a.Discount)
                            ,TotalPayment:parseFloat(a.TotalPayment)};
                            

                    }
                    else{
                        result=a;
                    }
                    
                    if (!result[b.Status]) {
                        result[b.Status] = {TotalSale:parseFloat(b.TotalSale)
                                        ,TotalCount:1
                                        ,TotalDelivery:parseFloat(b.TotalDelivery)
                                        ,TotalDiscount:parseFloat(b.Discount)
                                        ,TotalPayment:parseFloat(b.TotalPayment)};
                    }
                    else{
                        
                        result[b.Status] = {TotalSale:parseFloat(b.TotalSale) +  parseFloat(result[b.Status].TotalSale)
                            ,TotalCount:1+ result[b.Status].TotalCount
                            ,TotalDelivery:parseFloat(b.TotalDelivery)+ parseFloat(result[b.Status].TotalDelivery)
                            ,TotalDiscount:parseFloat(b.Discount)+ parseFloat(result[b.Status].TotalDiscount)
                            ,TotalPayment:parseFloat(b.TotalPayment)+ parseFloat(result[b.Status].TotalPayment)};
                    }
                    
                    return result;
                });
        }


            var sale_items_summary:any =[];
            data.map((d:any)=>{
                var details = d.details;
                details.map((dt:any)=>{
                    var NEW = 0;
                    var WAIT_DELIVERY = 0;
                    var WAIT_PAYMENT = 0;
                    var COMPLETE = 0;
                    var CANCELED = 0;

                    var NEW_PRICE = 0;
                    var WAIT_DELIVERY_PRICE = 0;
                    var WAIT_PAYMENT_PRICE = 0;
                    var COMPLETE_PRICE = 0;
                    var CANCELED_PRICE = 0;
                    if(sale_items_summary[dt.ItemID]){
                        
                        NEW = parseFloat(sale_items_summary[dt.ItemID].NEW);
                        NEW_PRICE =  parseFloat(sale_items_summary[dt.ItemID].NEW_PRICE);
                    
                        WAIT_DELIVERY = parseFloat(sale_items_summary[dt.ItemID].WAIT_DELIVERY);
                        WAIT_DELIVERY_PRICE =  parseFloat(sale_items_summary[dt.ItemID].WAIT_DELIVERY_PRICE);
                    
                        WAIT_PAYMENT = parseFloat(sale_items_summary[dt.ItemID].WAIT_PAYMENT);
                        WAIT_PAYMENT_PRICE =  parseFloat(sale_items_summary[dt.ItemID].WAIT_PAYMENT_PRICE);
                    
                        COMPLETE = parseFloat(sale_items_summary[dt.ItemID].COMPLETE);
                        COMPLETE_PRICE =  parseFloat(sale_items_summary[dt.ItemID].COMPLETE_PRICE);
                    
                        CANCELED = parseFloat(sale_items_summary[dt.ItemID].CANCELED);
                        CANCELED_PRICE =  parseFloat(sale_items_summary[dt.ItemID].CANCELED_PRICE);
                    

                        if(d.Status ==="NEW"){
                            NEW +=parseFloat(dt.Quantity);
                            NEW_PRICE +=parseFloat(dt.TotalPrice);
                        }
                        else if(d.Status ==="WAIT_DELIVERY"){
                            WAIT_DELIVERY +=parseFloat(dt.Quantity);
                            WAIT_DELIVERY_PRICE +=parseFloat(dt.TotalPrice);
                        }
                        else if(d.Status ==="WAIT_PAYMENT"){
                            WAIT_PAYMENT +=parseFloat(dt.Quantity);
                            WAIT_PAYMENT_PRICE +=parseFloat(dt.TotalPrice);
                        }
                        else if(d.Status ==="COMPLETE"){
                            COMPLETE +=parseFloat(dt.Quantity);
                            COMPLETE_PRICE +=parseFloat(dt.TotalPrice);
                        }
                        else if(d.Status ==="CANCELED"){
                            CANCELED +=parseFloat(dt.Quantity);
                            CANCELED_PRICE +=parseFloat(dt.TotalPrice);
                        }
                    }
                    else{
                        if(d.Status ==="NEW"){
                            NEW = parseFloat(dt.Quantity);
                            NEW_PRICE = parseFloat(dt.TotalPrice);
                        }
                        else if(d.Status ==="WAIT_DELIVERY"){
                            WAIT_DELIVERY = parseFloat(dt.Quantity);
                            WAIT_DELIVERY_PRICE = parseFloat(dt.TotalPrice);
                        }
                        else if(d.Status ==="WAIT_PAYMENT"){
                            WAIT_PAYMENT = parseFloat(dt.Quantity);
                            WAIT_PAYMENT_PRICE = parseFloat(dt.TotalPrice);
                        }
                        else if(d.Status ==="COMPLETE"){
                            COMPLETE = parseFloat(dt.Quantity);
                            COMPLETE_PRICE =  parseFloat(dt.TotalPrice);
                        }
                        else if(d.Status ==="CANCELED"){
                            CANCELED = parseFloat(dt.Quantity);
                            CANCELED_PRICE =  parseFloat(dt.TotalPrice);
                        }
                    }

                    sale_items_summary[dt.ItemID]={
                        ...dt.item
                        ,NEW:NEW
                        ,WAIT_PAYMENT:WAIT_PAYMENT
                        ,WAIT_DELIVERY:WAIT_DELIVERY
                        ,COMPLETE:COMPLETE
                        ,CANCELED:CANCELED
                        ,NEW_PRICE:NEW_PRICE
                        ,WAIT_PAYMENT_PRICE:WAIT_PAYMENT_PRICE
                        ,WAIT_DELIVERY_PRICE:WAIT_DELIVERY_PRICE
                        ,COMPLETE_PRICE:COMPLETE_PRICE
                        ,CANCELED_PRICE:CANCELED_PRICE
                    }
                }) 
            })
            setSaleitemsummary(sale_items_summary);
            setSalesummary(salesummary);
            setData(data);
        }
        else{
            setSaleitemsummary([]);
            setSalesummary([]);
            setData([]);
            setAlertMessage("no data");
            setShowAlert(true);
        }
        setShowLoading(false);
      }
    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              setErrors(err.response.data["sale"]);
              setErrordetails(err.response.data["details"]);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }
    
    function getErrorMessage(field:string) {
        if(!Array.isArray(errors)){
            return ;
        }
        else{
        return (
            <div className="validation-errors"> 
                {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
                <div key={field} className="error-message"> 
                <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                    {filterederror.message}
                    
                </div>
                
                ))}
            </div>
            
            );
        }
    }
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{"SALE REPORT"}</IonTitle>
          <IonButtons slot="end">
          
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        {/* <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher> */}

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

        <IonCard className="field-card">
        
        <IonCardContent>
        <IonList lines="none">
         <IonItem>
            
            <IonLabel position="fixed" color="primary">Date</IonLabel>
            <Selectfield name={"date"} value={selectdate} 
                    option={{datas:[
                                    {id:'today',Name:'Today'}
                                    ,{id:'yesterday',Name:'Yesterday'}
                                    ,{id:'this week',Name:'This Week'}
                                    ,{id:'this month',Name:'This Month'}
                                    ,{id:'custom',Name:'Custom'}
                                    ]
                                ,label:"Name",value:"id"}} 
                    dataChanged={e=>handleChange(e,"date")}/>
                    
        </IonItem>
        {iscustomdate&&
            <IonItem>
                <IonLabel position="fixed" color="primary">
                Date From
                </IonLabel>
                <DateInput disabled={!iscustomdate} displayFormat="DD MMM YYYY" placeholder="Select Date" value={params.DateFrom} onIonChange={e => handleChange(e,"DateFrom")}/>
            </IonItem>
        }
        {iscustomdate&&
            <IonItem>
                <IonLabel position="fixed" color="primary">
                Date To
                </IonLabel>
                <DateInput  displayFormat="DD MMM YYYY" placeholder="Select Date" value={params.DateTo} onIonChange={e => handleChange(e,"DateTo")}/>
            </IonItem>
        }

        <IonItem>
            
            <IonLabel position="fixed" color="primary">Inventory</IonLabel>
            
            {myInventory.length>0&&
                    <Selectfield name={"InventoryID"} value={params["InventoryID"]} option={{datas:myInventory,label:"Name",value:"id"}} dataChanged={e=>handleChange(e,"InventoryID")}/>
                    }
        </IonItem>
        <IonItem>
        <IonButton title={"Generate Report"} onClick={() => generatereport() }>
              <IonIcon icon={newspaperOutline}></IonIcon> Generate
            </IonButton>
        </IonItem>
        </IonList>
{data&&data.length>0&&
<IonCardContent>
        <div className = "xlstable">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="report-sale"
                    filename={"sale_"+filename}
                    sheet={"sale_"+filename}
                    buttonText="download"/>
                <table id="report-sale">
                <tr>
                        <th>Report</th>
                        <th>Inventory</th>
                        <th>DateFrom</th>
                        <th>DateTo</th>
                        
                        
                </tr>
                    <tr>
                        <td>{"SALE"}</td>
                        <td>{currentInventory.Name}</td>
                        <td>{params.DateFrom}</td>
                        <td>{params.DateTo}</td>
                        
                        
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        
                        
                    </tr>
                    
                    <tr>
                        <th className="topfixed">SaleDate</th>
                        <th className="topfixed">SaleCode</th>
                        <th className="topfixed">Status</th>
                        <th className="topfixed">CustomerName</th>
                        <th className="topfixed">TotalSale</th>
                        <th className="topfixed">Discount</th>
                        <th className="topfixed">DeliveryCost</th>
                        <th className="topfixed"> TotalInvoice</th>
                        <th className="topfixed">Remarks</th>
                    </tr>
                    {data.map((element:any) => {
                        return <tr>
                            <td>{element.SaleDate}</td>
                            <td>{element.SaleCode}</td>
                            <td>{element.Status}</td>
                            <td>{element.CustomerName}</td>
                            <td>{element.TotalSale}</td>
                            <td>{element.Discount}</td>
                            <td>{element.TotalDelivery}</td>
                            <td>{element.TotalPayment}</td>
                            <td>{element.Remarks}</td>
                            </tr>
                    })}                        
                    <tr><th>Summary</th></tr>
                   <tr>
                            <th>Status</th>
                            <th>TotalCount</th>
                            <th>TotalSale</th>
                            <th>TotalDiscount</th>
                            <th>TotalDelivery</th>
                            <th>TotalInvoice</th>
                           
                        </tr>

                        {Object.keys(salesummary)
                            .sort((a,b)=>{
                                var alevel=99;
                                var blevel=99;
                                switch(a){
                                    case "NEW":alevel = 1;
                                    break;
                                    case "WAIT_DELIVERY":alevel = 2;
                                    break;
                                    case "WAIT_PAYMENT":alevel = 3;
                                    break;
                                    case "COMPLETE":alevel = 4;
                                    break;
                                    case "CANCELED":alevel = 5;
                                    break;
                                }
                                switch(b){
                                    case "NEW":blevel = 1;
                                    break;
                                    case "WAIT_DELIVERY":blevel = 2;
                                    break;
                                    case "WAIT_PAYMENT":blevel = 3;
                                    break;
                                    case "COMPLETE":blevel = 4;
                                    break;
                                    case "CANCELED":blevel = 5;
                                    break;
                                }

                                return alevel>blevel?1:-1;
                            })
                            .map((key) => {
                            const element = salesummary[key];
                            return <tr>
                                <th>{key}</th>
                                <td>{element.TotalCount}</td>
                                <td>{element.TotalSale}</td>
                                <td>{element.TotalDiscount}</td>
                                <td>{element.TotalDelivery}</td>
                                <td>{element.TotalPayment}</td>
                                
                                </tr>
                        })}
                        <tr>
                        <td></td>
                        <td></td>
                        </tr>
                        <tr>
                        <th>GenerateDate</th>
                        <td>{new Date().toString()}</td>
                        </tr>
                </table>
                
            </div>
            </IonCardContent>
}
{data&&data.length>0&&
            <IonCardContent>
            <div className = "xlstable">
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="report-sale-item"
                        filename={"sale-item_"+filename}
                        sheet={"sale-item_"+filename}
                        buttonText="download"/>
                    <table id="report-sale-item">
                        <tr>
                            <th>Report</th>
                            <th>Inventory</th>
                            <th>DateFrom</th>
                            <th>DateTo</th>
                            
                        </tr>
                        <tr>
                            <td>{"SALEITEM"}</td>
                            <td>{currentInventory.Name}</td>
                            <td>{params.DateFrom}</td>
                            <td>{params.DateTo}</td>
                            
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                            
                        </tr>
                        <tr className="topfixed">
                            <th className="topfixed">SaleDate</th>
                            <th className="topfixed">SaleCode</th>
                            <th className="topfixed">Status</th>
                            <th className="topfixed">ItemCode</th>
                            <th className="topfixed">ItemName</th>
                            <th className="topfixed">Quantity</th>
                            <th className="topfixed">QuantityCode</th>
                            <th className="topfixed">Price</th>
                            <th className="topfixed">Discount</th>
                            <th className="topfixed">Total</th>
                        </tr>
                        {data.map((element:any) => {
                            var result = [];
                            var details = element.details;
                            var ItemName = details;
                            result.push(
                                <tr>
                                <td>{element.SaleDate}</td>
                                <td>{element.SaleCode}</td>
                                <td>{element.Status}</td>
                                <td>{details[0]?details[0].ItemCode:""}</td>
                                <td>{details[0]?details[0].ItemName:""}</td>
                                <td>{details[0]?details[0].Quantity:""}</td>
                                <td>{details[0]?details[0].QuantityCode:""}</td>
                                <td>{details[0]?details[0].ItemSellPrice:""}</td>
                                <td>{details[0]?details[0].Discount:""}</td>
                                <td>{details[0]?details[0].TotalPrice:""}</td>

                                </tr>
                            )
                                Object.keys(details).map((d:any)=>{
                                    if(d>0)
                                    {
                                        result.push(
                                            <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{details[d]?details[d].ItemCode:""}</td>
                                            <td>{details[d]?details[d].ItemName:""}</td>
                                            <td>{details[d]?details[d].Quantity:""}</td>
                                            <td>{details[d]?details[d].QuantityCode:""}</td>
                                            <td>{details[d]?details[d].ItemSellPrice:""}</td>
                                            <td>{details[d]?details[d].Discount:""}</td>
                                            <td>{details[d]?details[d].TotalPrice:""}</td>

                                            </tr>
                                        )
                                    }
                                    return d;
                                })
                            return result;
                        })}
                        <tr><th>Summary</th></tr>
                        <tr>
                            <th>ItemCode</th>
                            <th>ItemName</th>
                            <th>Qty</th>
                            <th></th>
                            <th></th>
                            <th>COUNT</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>PRICE</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>NEW</th>
                            <th>WAIT_DELIVERY</th>
                            <th>WAIT_PAYMENT</th>
                            <th>COMPLETE</th>
                            <th>CANCELED</th>
                            <th>NEW</th>
                            <th>WAIT_DELIVERY</th>
                            <th>WAIT_PAYMENT</th>
                            <th>COMPLETE</th>
                            <th>CANCELED</th>
                        </tr>
                        {
                            
                        saleitemsummary&&saleitemsummary.length>0&&
                            Object.keys(saleitemsummary).map((i:any)=>{
                                return(
                                <tr>
                                    <td>{saleitemsummary[i].Code}</td>
                                    <td>{saleitemsummary[i].Name}</td>
                                    <td>{saleitemsummary[i].QuantityCode}</td>
                                    <td>{saleitemsummary[i].NEW}</td>
                                    <td>{saleitemsummary[i].WAIT_DELIVERY}</td>
                                    <td>{saleitemsummary[i].WAIT_PAYMENT}</td>
                                    <td>{saleitemsummary[i].COMPLETE}</td>
                                    <td>{saleitemsummary[i].CANCELED}</td>
                                    <td>{saleitemsummary[i].NEW_PRICE}</td>
                                    <td>{saleitemsummary[i].WAIT_DELIVERY_PRICE}</td>
                                    <td>{saleitemsummary[i].WAIT_PAYMENT_PRICE}</td>
                                    <td>{saleitemsummary[i].COMPLETE_PRICE}</td>
                                    <td>{saleitemsummary[i].CANCELED_PRICE}</td>
                                </tr>
                                )
                            })
                        }
                        <tr>
                        <td></td>
                        <td></td>
                        </tr>
                       <tr>
                        <th>GenerateDate</th>
                        <td>{new Date().toString()}</td>
                        </tr>
                    </table>
                    
                </div>
                </IonCardContent>
}

        </IonCardContent>
      </IonCard>
         
      </IonContent>
      
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={alertHeader}
          message={alertMessage}
          buttons={alertButtons}
        />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: React.memo(SaleReport)
})