import React, { FormEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal, IonBackButton, IonList, IonItem, IonLabel, IonCheckbox, IonInput, IonCard, IonCardHeader, IonCardContent, IonInfiniteScroll, IonInfiniteScrollContent, IonTextarea } from '@ionic/react';

import { connect } from '../../data/connect';
import './SearchItemForm.scss';
import API from '../../services';
import { add, arrowBack, checkbox, filter, options, search } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';
import MasterDetailList from '../../components/ActiveForm/MasterDetailList';
import NumberFormat from 'react-number-format';
import NumericField from '../../components/ActiveForm/NumericField';

interface OwnProps{
    submit: (items:any[]) => void;
    cancel:()=>void;

 };

interface StateProps {
  mode: 'ios' | 'md';
};

interface DispatchProps {
 };

interface SearchItemFormProps extends OwnProps, StateProps, DispatchProps { };

const SearchItemForm: React.FC<SearchItemFormProps> = ({mode,cancel,submit}) => {
    const apipath="item";
    const masterapipath = "itemgroup";
  const [isleave,setIsLeave] = useState(false);
  const [masters,setMasters] = useState([]);
  const [datas,setDatas] = useState<any>([]);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);
  const [usefilter,setUseFilter] = useState(false);
  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Description:''
          ,Active:true
      }
  );
  const [buttons,setButtons] = useState<any>(getTemplate(apipath+'_button'));
  const [fieldtemplate,setFieldTemplate] = useState<any>(getTemplate(apipath+"_view"));
  const [masterbuttons,setMasterButtons] = useState<any>(getTemplate(masterapipath+'_button'));
  const [masterfieldtemplate,setMasterFieldTemplate] = useState<any>(getTemplate(masterapipath+"_view"));
  const [searchText,setSearchText]= useState("");
  const [disableInfiniteScroll, setDisableInfiniteScroll] = 
        useState<boolean>(false);
  const [sort,setSort] = useState<any>(getTemplate(apipath+'_sort'));
  const [filters,setFilters] = useState<any>(getTemplate(apipath+'_filter'));
  const [page, setPage] =  useState(1);
  const ios = mode === 'ios';
  useEffect(() => {

    
      loaddatas();
    // eslint-disable-next-line
  }, [filters,sort]);
  const searchNext=async($event: CustomEvent<void>) =>{
    await loadnextdatas();

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }
  const submitdata=()=>{
    var selecteddata = datas.filter((d:any)=>{
        return d.selected;
    });

    // console.log(selecteddata);
    submit(selecteddata);

  }

  const selecteditem = (item: any) => {
    var items:any =  datas.map((i:any)=>{
      if(i.id===item.id){
        return {...i,selected:!i.selected}
      }
      else{
        return i;
      }
      
    })
    setDatas(items);
  };
  const handleChangeNumber = (e:any,item:any,name:any) => {
    const { value } = e;
    console.log(e);
    var items:any =  datas.map((i:any)=>{
        if(i.id===item.id){
          return {...i,Quantity:value}
        }
        else{
          return i;
        }
        
      })
      setDatas(items);
  };
  const handleChange = (e:any,item:any,name:any) => {
    const { value } = e.detail;
    var items:any =  datas.map((i:any)=>{
        if(i.id===item.id){
          if(name==="Quantity"){
            return {...i,Quantity:value}
          }
          else{
            return i;
          }
        }
        else{
          return i;
        }
        
      })
      setDatas(items);
  };

  const submitfilter=(pfilters:any[],psort:any)=>{
    setFilters(pfilters);
    setSort(psort);
    setShowFilterModal(false);

  }
  const cancelfilter=()=>{
    
    setShowFilterModal(false);

  }
  const resetfilter=()=>{
    
    setFilters(getTemplate(apipath));
    setSort(getTemplate(apipath+'_sort'));
    setShowFilterModal(false);

  }
  const apifilterParameter=()=>{
    var result:any = {};

    if(filters)
    {
      Object.keys(filters).map((key:any,value:any) => {
        if(filters[key].active && filters[key].value !== ""){
          var param = "filter["+key+"]["+filters[key].operand+"]";
          var pvalue = filters[key].value;
          if(filters[key].type==="boolean"){
            pvalue = pvalue?1:0;
          }
          result[param]=pvalue;
        }
      });
    }

    if(sort){
      if(sort.active){
        var param = "sort";
        var pvalue = sort.field;
        if(sort.direction==="desc"){
          pvalue = "-"+pvalue;
        }
        result[param]=pvalue;
      }
    }

    return result;
  }
  const loadnextdatas=async()=>{
    loaddatas(page+1);
    setPage(page+1);
  }

const  loaddatas=async(page?:any)=>{
    setShowLoading(true);
    const param:any = apifilterParameter();
    const nextdatas:any = await API.view(apipath,{...param,"expand":"group",page:page?page:1}).catch(e=>errorhandler(e));
    
    var nmasters:any = [];
    var ndatas:any=[];
    if(page>1){
        ndatas =await nextdatas.filter((d:any)=>{
            return datas.find((f:any)=>{
                    return f.id===d.id
                  })?false:true;
            
        }).map((d:any)=>{
            d={...d,Quantity:1};
            return d;
        });
        setDatas([...datas,...ndatas]);
        
        setToastMessage("load data complete");
    }
    else{
        ndatas =await nextdatas.map((d:any)=>{
            d={...d,Quantity:1};
            return d;
        })
        // console.log(datas,nmasters);
        setMasters(nmasters);
        setDatas(ndatas);
        setToastMessage("load data complete");
    }
    
    if(ionRefresherRef.current){
    ionRefresherRef.current!.complete();
    }
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  const handleSubmitSearch=(e:FormEvent)=>{
    e.preventDefault();

    setFilters({...filters,Name:{name:"Name",type:"string",operand:"like",value:searchText,active:true} })
    loaddatas();
  }
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        console.log("errorhandler response",err.response);
        
    }
    else{
        console.log("errorhandler",err.message);
    }
  }
  const haveFilter=():boolean=>{
    if(filters){
      var find = Object.keys(filters).find((f:any)=>{
        
          return filters[f].active === true;
      });
      if(find){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }
  return (
    <IonPage id={apipath+"-list"}>
      <IonHeader translucent={true}>
        <IonToolbar>
        <IonButtons slot="start">
            
            <IonButton onClick={()=>cancel()}>
            {mode === 'ios' ? 'cancel' : <IonIcon icon={arrowBack} slot="icon-only" />}
            </IonButton>
         
        </IonButtons>

      
          {!showSearchbar &&
          <IonTitle>{apipath.toLocaleUpperCase()}</IonTitle>
          }

          {showSearchbar &&
          <form onSubmit={e => handleSubmitSearch(e)}>
            <IonSearchbar showCancelButton="always" placeholder="Search" cancelButtonIcon="close"
             onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
              >
               
              </IonSearchbar>
            </form>
          }
          
          <IonButtons slot="end">
            { !showSearchbar &&
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            }
            
            <IonButton color={haveFilter()?"primary":""} onClick={() => setShowFilterModal(true)}>
              {mode === 'ios' ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
            </IonButton>
            
          
                
                <IonButton color="primary" onClick={() => submitdata()}>
                {mode === 'ios' ? 'submit' : 'submit'}
                </IonButton>
            
           
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{apipath.toLocaleUpperCase()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddatas}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

            <IonGrid>
            
            <IonList lines={ mode==='ios' ? 'inset' : 'full'}>
          
            {datas.map((key:any, index:any) => (
                // <IonItem key={key.id}>
                
                <IonCard className="field-card">
                    <IonCardHeader>
                        <IonItem  className="field-item" onClick={() => selecteditem(key)}>
                        
                        <IonLabel color="primary">
                        {key.Code}
                        </IonLabel>
                        
                            <IonButtons slot="end">
                                <IonCheckbox
                                onClick={() => selecteditem(key)}
                                checked={key.selected}
                                color="primary"
                                // value={track}
                                />
                            </IonButtons>
                        </IonItem>
                    </IonCardHeader>

                    <IonCardContent>
                    <IonList lines="none">
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Item Name"}
                        </IonLabel>
                        <IonInput value={key.Name} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Quantity ("+key.QuantityCode+")"}
                        </IonLabel>
                        <NumericField type='tel' name={"Quantity"} value={key.Quantity} 
                                placeholder={"please input quantity"} 
                                onIonChange={e=>handleChange(e,key,"Quantity")}
                                min={"0"}
                                inputmode="tel"
                                
                            />  

                        {/* <IonLabel color="primary">
                            {key.QuantityCode}
                        </IonLabel> */}
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Price"}
                        </IonLabel>
                        <NumericField type='tel' name={"SellPrice"} value={key.SellPrice} 
                                placeholder={"please input quantity"} 
                                onIonChange={e=>handleChange(e,key,"SellPrice")}
                                min={"0"}
                                inputmode="tel"
                                readonly
                            />  
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Discount(%)"}
                        </IonLabel>
                        <NumericField type='tel' name={"DiscountPercent"} value={key.DiscountPercent} 
                                placeholder={"please input discount percent"} 
                                onIonChange={e=>handleChange(e,key,"DiscountPercent")}
                                min={"0"}
                                inputmode="tel"
                                readonly
                            />  
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Remarks"}
                        </IonLabel>
                        <IonTextarea value={key.Remarks} readonly/>   
                    </IonItem>
                    </IonList>
                    </IonCardContent>
                </IonCard>

                // <IonRow key={key.id}>
                //     <IonCol size="10">
                //         <IonRow>
                //         <IonCol>
                //             <IonItem>
                //             <IonLabel position="fixed" >{key.Code +" "+key.name}</IonLabel>
                //             </IonItem>
                //         </IonCol>
                //         {/* <IonCol>
                //             <IonItem>
                //             <IonLabel position="fixed" >{key.Name}</IonLabel>
                //             </IonItem>
                //         </IonCol> */}
                //         <IonCol>
                //             <IonItem>
                //             <IonInput type='number' name={key.id} value={key.Quantity} 
                //                 placeholder={"Quantity"} 
                //                 onIonChange={e=>handleChange(e,key,"Quantity")}
                                
                //             />   
                //             </IonItem>
                //         </IonCol>
                //         <IonCol>
                //             <IonItem>
                //             <IonLabel position="fixed" >{key.QuantityCode}</IonLabel>
                //             </IonItem>
                //         </IonCol>
                //         </IonRow>
                //     </IonCol>
                //     <IonCol>
                //         <IonItem>
                //         <IonCheckbox
                //             onClick={() => selecteditem(key)}
                //             checked={key.selected}
                //             color="primary"
                //             // value={track}
                            
                //         ></IonCheckbox>
                //         </IonItem>
                //     </IonCol>
                // </IonRow>
                // </IonItem>
            ))}
            </IonList>
                {/* {masters.map((master:any)=>{
                      return  <MasterDetailList detailkey={"ItemGroupID"} key={master.id} mastername={masterapipath} master={master} mastertemplate={masterfieldtemplate} masterbuttons={masterbuttons}
                                           name={apipath} datas={datas} template={fieldtemplate} buttons={buttons}/>
                })}
               */}
           
          </IonGrid>
          <IonInfiniteScroll threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
                loadingText="Loading more ...">
            </IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <Filter
          fields={filterfields}
          filters={filters}
          sort={sort}
          submit={submitfilter}
          cancel={cancelfilter}
          reset={resetfilter}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
  },
  component: React.memo(SearchItemForm)
});