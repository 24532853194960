import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonLoading, IonRouterOutlet, IonSelectPopover, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MainTabs from './pages/MainTabs';
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import { loadConfData } from './data/sessions/sessions.actions';
import { setIsLoggedIn, setUsername, loadUserData } from './data/user/user.actions';
import Account from './pages/Account';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Support from './pages/Support';
import Tutorial from './pages/Tutorial';
import HomeOrTutorial from './components/HomeOrTutorial';
import { Schedule } from "./models/Schedule";
import RedirectToLogin from './components/RedirectToLogin';
import PrivateRoute from './components/PrivateRoute';
import InventoryList from './pages/Inventory/InventoryList';
import InventoryForm from './pages/Inventory/InventoryForm';
import UserList from './pages/User/UserList';
import UserForm from './pages/User/UserForm';
import UserInventoryAccessForm from './pages/User/UserInventoryAccessForm';
import InventoryUserAccessForm from './pages/Inventory/InventoryUserAccessForm';
import CashflowList from './pages/Cashflow/CashflowList';
import CashflowForm from './pages/Cashflow/CashflowForm';
import CashflowInventoryAccessForm from './pages/Cashflow/CashflowInventoryAccessForm';
import InventoryCashFlowAccessForm from './pages/Inventory/InventoryCashFlowAccessForm';
import PartnerList from './pages/Partner/PartnerList';
import PartnerForm from './pages/Partner/PartnerForm';
import ItemList from './pages/Item/ItemList';
import ItemForm from './pages/Item/ItemForm';
import QuantityList from './pages/Quantity/QuantityList';
import QuantityForm from './pages/Quantity/QuantityForm';
import GQuantityForm from './pages/Quantity/GQuantityForm';
import QuantityGroupForm from './pages/Quantity/QuantityGroupForm';
import GItemForm from './pages/Item/GItemForm';
import ItemGroupForm from './pages/Item/ItemGroupForm';
import StockopnameList from './pages/Stockopname/StockopnameList';
import StockopnameForm from './pages/Stockopname/StockopnameForm';
import SaleList from './pages/Sale/SaleList';
import SaleForm from './pages/Sale/SaleForm';
import SalePayment from './pages/Sale/SalePayment';
import SaleDelivery from './pages/Sale/SaleDelivery';
import CashflowUserAccessForm from './pages/Cashflow/CashflowUserAccessForm';
import UserCashflowAccessForm from './pages/User/UserCashflowAccessForm';
import PaymentList from './pages/Payment/PaymentList';
import PaymentForm from './pages/Payment/PaymentForm';
import ReceivableList from './pages/Receivable/ReceivableList';
import ReceivableForm from './pages/Receivable/ReceivableForm';
import SalePaymentList from './pages/Sale/SalePaymentList';
import SaleDeliveryList from './pages/Sale/SaleDeliveryList';
import SalePrint from './pages/Sale/SalePrint';

import ReceivablePayment from './pages/Receivable/ReceivablePayment';
import ReceivablePaymentList from './pages/Receivable/ReceivablePaymentList';
import ReceivableListCustom from './pages/Receivable/ReceivableListCustom';
import SaleReport from './pages/Report/SaleReport';
import StockReport from './pages/Report/StockReport';
import ReceivableReport from './pages/Report/ReceivableReport';
import CashflowReport from './pages/Report/CashflowReport';
import StockopnamePrint from './pages/Stockopname/StockopnamePrint';
import PaymentPrint from './pages/Payment/PaymentPrint';
import ChangePassword from './pages/User/ChangePassword';
import ChangeMyPassword from './pages/User/ChangeMyPassword';
import SaleItemMonthlyReport from './pages/Report/SaleItemMonthlyReport';
import SaleCustomerMonthlyReport from './pages/Report/SaleCustomerMonthlyReport';
import SaleCustomerDetailReport from './pages/Report/SaleCustomerDetailReport';
import ReceivablePrint from './pages/Receivable/ReceivablePrint';

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  schedule: Schedule;
  isAuthenticated: boolean;
}

interface DispatchProps {
  loadConfData: typeof loadConfData;
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ darkMode, schedule, isAuthenticated, setIsLoggedIn, setUsername, loadConfData, loadUserData }) => {

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    // console.log("useEffect");
    // console.log("isauthenticated",isAuthenticated);
    loadUserData();
    loadConfData();
    setTimeout(() => {
      //  console.log("isauthenticated",isAuthenticated);
        setShowLoading(false);
    }, 1000);
   
    // eslint-disable-next-line
  }, []);

  
  return (
    
      
        <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>

        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        
        {showLoading?"": isAuthenticated ? (
        <IonReactRouter>
        <IonSplitPane contentId="main">
          
          <Menu />
          
          <IonRouterOutlet id="main">
            {/*
            We use IonRoute here to keep the tabs state intact,
            which makes transitions between tabs and non tab pages smooth
            */}
             <PrivateRoute path="/tabs" render={() => <MainTabs />} />
            <PrivateRoute path="/account" component={Account} />
            {/* <Route path="/login" component={Login} /> */}
            <PrivateRoute path="/signup" component={Signup} />
            <PrivateRoute path="/support" component={Support} />
            <PrivateRoute path="/tutorial" component={Tutorial} />
            <Route path="/logout" component={RedirectToLogin}/>

            <PrivateRoute path="/inventory"  component={InventoryList} exact={true} />
            <PrivateRoute path="/inventory/:id"  component={InventoryForm} />
            <PrivateRoute path="/inventoryuser/:id"  component={InventoryUserAccessForm} />
            <PrivateRoute path="/inventorycashflow/:id"  component={InventoryCashFlowAccessForm} />
            <PrivateRoute path="/user"  component={UserList} exact={true} />
            <PrivateRoute path="/userinventory/:id"  component={UserInventoryAccessForm} />
            <PrivateRoute path="/usercashflow/:id"  component={UserCashflowAccessForm} />
            <PrivateRoute path="/user/:id"  component={UserForm} />
            <PrivateRoute path="/changepassword/:id"  component={ChangePassword} />
            <PrivateRoute path="/changemypassword"  component={ChangeMyPassword} />
            <PrivateRoute path="/cashflow"  component={CashflowList} exact={true} />
            <PrivateRoute path="/cashflowinventory/:id"  component={CashflowInventoryAccessForm} />
            <PrivateRoute path="/cashflowuser/:id"  component={CashflowUserAccessForm} />
            <PrivateRoute path="/cashflow/:id"  component={CashflowForm} />

            <PrivateRoute path="/partner"  component={PartnerList} exact={true} />
            <PrivateRoute path="/partner/:id"  component={PartnerForm} />
            <PrivateRoute path="/itemgroup/:id"  component={ItemGroupForm} exact={true}/>
            <PrivateRoute path="/itemgroup/:id/add"  component={GItemForm} />
            <PrivateRoute path="/item"  component={ItemList} exact={true} />
            <PrivateRoute path="/item/:id"  component={ItemForm} />
            <PrivateRoute path="/quantitygroup/:id"  component={QuantityGroupForm} exact={true}/>
            <PrivateRoute path="/quantitygroup/:id/add"  component={GQuantityForm} />
            <PrivateRoute path="/quantity"  component={QuantityList} exact={true} />
            <PrivateRoute path="/quantity/:id"  component={QuantityForm} exact={true}/>

            <PrivateRoute path="/stockopname"  component={StockopnameList} exact={true} />
            <PrivateRoute path="/stockopname/:id"  component={StockopnameForm}  exact={true} />
            <PrivateRoute path="/stockopname/:id/print"  component={StockopnamePrint}  exact={true} />

            <PrivateRoute path="/payment"  component={PaymentList} exact={true} />
            <PrivateRoute path="/payment/:id"  component={PaymentForm}  exact={true} />
            <PrivateRoute path="/payment/:id/print"  component={PaymentPrint}  exact={true} />
            <PrivateRoute path="/receivable"  component={ReceivableList} exact={true} />
            <PrivateRoute path="/receivablecredit"  exact={true} render={(e) => <ReceivableListCustom {...e} PaymentMethod={"CREDIT"} />} />
            <PrivateRoute path="/receivablegiro"  exact={true} render={(e) => <ReceivableListCustom {...e} PaymentMethod={"GIRO"} />} />
            <PrivateRoute path="/receivablecheque"  exact={true} render={(e) => <ReceivableListCustom {...e} PaymentMethod={"CHEQUE"} />} />
            <PrivateRoute path="/receivable/:id"  component={ReceivableForm}  exact={true}/>
            <PrivateRoute path="/receivable/:id/payment"  component={ReceivablePayment} exact={true} />
            <PrivateRoute path="/receivable/:id/paymentlist"  component={ReceivablePaymentList} exact={true} />
            <PrivateRoute path="/receivable/:id/print"  component={ReceivablePrint} exact={true} />


            <PrivateRoute path="/sale"  component={SaleList} exact={true} />
            <PrivateRoute path="/sale/:id"  component={SaleForm} exact={true}/>
            <PrivateRoute path="/sale/:id/refresh"  component={SaleForm} exact={true}/>
            <PrivateRoute path="/sale/:id/payment"  component={SalePayment} exact={true} />
            <PrivateRoute path="/sale/:id/paymentlist"  component={SalePaymentList} exact={true} />
            <PrivateRoute path="/sale/:id/delivery"  component={SaleDelivery} exact={true} />
            <PrivateRoute path="/sale/:id/deliverylist"  component={SaleDeliveryList} exact={true} />
            <PrivateRoute path="/sale/:id/print"  component={SalePrint} exact={true} />

            <PrivateRoute path="/report/sale"  component={SaleReport} exact={true} />
            <PrivateRoute path="/report/stock"  component={StockReport} exact={true} />
            <PrivateRoute path="/report/receivable"  component={ReceivableReport} exact={true} />
            <PrivateRoute path="/report/cashflow"  component={CashflowReport} exact={true} />
            <PrivateRoute path="/report/saleitemmonthly"  component={SaleItemMonthlyReport} exact={true} />
            <PrivateRoute path="/report/salecustomermonthly"  component={SaleCustomerMonthlyReport} exact={true} />
            <PrivateRoute path="/report/salecustomerdetail"  component={SaleCustomerDetailReport} exact={true} />

           
         
            {/* <Route path="/logout" render={() => {
              return <RedirectToLogin
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
              />;
            }} /> */}

            {/* <Route path='/' exact render={() => <Redirect to={this.props.isAuthenticated ? '/dashboard' : '/login'}/>}/> */}

            <PrivateRoute path="/" exact render={() => <Redirect to={'/account'}/>}/>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
      ) : (
        <IonReactRouter>
          <Route path="/login" component={Login} />
          
          <Redirect from="/" to="/login" exact />
        </IonReactRouter>
      )}
          
        </IonApp>
      
  )
}

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    schedule: state.data.schedule,
    isAuthenticated : state.user.isLoggedin
  }),
  mapDispatchToProps: { loadConfData, loadUserData, setIsLoggedIn, setUsername },
  component: IonicApp
});
