import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal, IonBackButton, IonList, IonItem, IonLabel, IonCheckbox, IonInput, IonCard, IonCardHeader, IonCardContent, IonTextarea, IonDatetime } from '@ionic/react';

import { connect } from '../../data/connect';
import './AddPartnerForm.scss';
import API from '../../services';
import { add, arrowBack, checkbox, filter, informationCircleOutline, options, search } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';
import MasterDetailList from '../../components/ActiveForm/MasterDetailList';
import NumberFormat from 'react-number-format';
import DateInput from '../../components/ActiveForm/DateInput';

interface OwnProps{
    submit:(data:any)=>void;
    type?:any;
    close?:()=>void;
 };

interface StateProps {
  mode: 'ios' | 'md';
};

interface DispatchProps {
 };

interface AddPartnerFormProps extends OwnProps, StateProps, DispatchProps { };

const AddPartnerForm: React.FC<AddPartnerFormProps> = ({mode,type,submit,close}) => {

    const apipath = 'partner';
    var initdata =  {   
      id:0
        ,Name:''
        ,Active:false
    }
    const [errors,setErrors] = useState(
        []
    );
    const [data,setData] = useState<any>(initdata);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);

  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Description:''
          ,Active:true
          ,isCustomer:true
      }
  );

  const [sort,setSort] = useState<any>(getTemplate(apipath+'_sort'));
  const [filters,setFilters] = useState<any>(getTemplate(apipath+'_filter'));

  const ios = mode === 'ios';
  useEffect(() => {


      loaddatas();
    // eslint-disable-next-line
  }, [type]);

const  loaddatas=async()=>{
    setShowLoading(true);
   
    if(type){
        switch(type){
            case 'customer': setData({...data,isCustomer:true,Active:true,isSupplier:false});
            break;
            case 'supplier': setData({...data,isSupplier:true,Active:true,isCustomer:false});
            break;
            default:
                break;
        }
    }
    ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  const handleChange = (e:any,name:string) => {
    const { value } = e.detail;
    if(data[name]!==value){
        setData({
            ...data,
            [name]: value
        });
    }
};
  const save = async ()=>{
      
    setShowLoading(true);
    let result:any=null;
    
      result = await API.insert(apipath,data).catch(e=>errorhandler(e));
   
    if(result){
        setToastMessage("save complete");
        setData(result);
         setShowCompleteToast(true);
        setShowLoading(false);
        submit(result);
    }
   
  }
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        if(err.response.status==422){
            setErrors(err.response.data);
        }
    }
    else{
        console.log("errorhandler",err.message);
    }
    setShowCompleteToast(true);
    setShowLoading(false);
  }
  function getErrorMessage(field:string) {
    return (
      <div className="validation-errors"> 
          {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
            <div key={field} className="error-message"> 
            <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
              {filterederror.message}
              
            </div>
            
          ))}
      </div>
        
      );
  }
  return (
    <IonPage id={apipath+"-list"}>
      <IonHeader translucent={true}>
        <IonToolbar>
        <IonButtons slot={"start"} >
            {close?
            <IonButton color="primary" onClick={()=>close()}>
            {mode === 'ios' ? 'cancel' : 'cancel'}
            </IonButton>
            :""}
         
        </IonButtons>
        

      
          {!showSearchbar &&
          <IonTitle>{"NEW " + (type!==undefined?type.toLocaleUpperCase():apipath.toLocaleUpperCase())}
          </IonTitle>
          }

          {showSearchbar &&
            <IonSearchbar showCancelButton="always" placeholder="Search"
              onIonCancel={() => setShowSearchbar(false)}
              >

              </IonSearchbar>
          }
         <IonButtons   slot="end">
            {
            <IonButton color="primary" onClick={()=>save()}>
            {mode === 'ios' ? 'submit' : 'submit'}
            </IonButton>
            }
         
        </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{apipath.toLocaleUpperCase()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddatas}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

            <IonGrid>
            
            <IonList lines={ mode==='ios' ? 'inset' : 'full'}>

                <IonCard className="field-card">
                    <IonCardContent>
                    <IonList lines="none">
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Code"}
                        </IonLabel>
                        <IonInput value={data.Code} placeholder={"Please Input "+"Code"} onIonChange={e=>handleChange(e,"Code")}/>   
                    </IonItem>
                    {getErrorMessage("Code")}

                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Name"}
                        </IonLabel>
                        <IonInput value={data.Name} placeholder={"Please Input "+"Name"} onIonChange={e=>handleChange(e,"Name")}/>   
                    </IonItem>
                    {getErrorMessage("Name")}
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Location Code"}
                        </IonLabel>
                        <IonInput value={data.LocationCode} placeholder={"Please Input "+"Location Code"} onIonChange={e=>handleChange(e,"LocationCode")}/>   
                    </IonItem>
                    {getErrorMessage("LocationCode")}
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Location"}
                        </IonLabel>
                        <IonInput value={data.Location} placeholder={"Please Input "+"Location"} onIonChange={e=>handleChange(e,"Location")}/>   
                    </IonItem>
                    {getErrorMessage("Location")}
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Address"}
                        </IonLabel>
                        <IonTextarea value={data.Address} placeholder={"Please Input "+"Address"} onIonChange={e=>handleChange(e,"Address")}/>   
                    </IonItem>
                    {getErrorMessage("Address")}
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Email"}
                        </IonLabel>
                        <IonInput value={data.Email} type={"email"} placeholder={"Please Input "+"Email"} onIonChange={e=>handleChange(e,"Email")}/>   
                    </IonItem>
                    {getErrorMessage("Email")}
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"DOB"}
                        </IonLabel>
                        <DateInput displayFormat="DD MMM YYYY" placeholder="Select DOB" value={data.DOB} onIonChange={e=>handleChange(e,"DOB")}></DateInput>

                    </IonItem>
                    {getErrorMessage("DOB")}
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Home Phone"}
                        </IonLabel>
                        <IonInput value={data.HomePhone} placeholder={"Please Input "+"Home Phone"} onIonChange={e=>handleChange(e,"HomePhone")}/>   
                    </IonItem>
                    {getErrorMessage("HomePhone")}
                    
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Office Phone"}
                        </IonLabel>
                        <IonInput value={data.OfficePhone} placeholder={"Please Input "+"Office Phone"} onIonChange={e=>handleChange(e,"OfficePhone")}/>   
                    </IonItem>
                    {getErrorMessage("OfficePhone")}
                    
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Mobile Phone"}
                        </IonLabel>
                        <IonInput value={data.MobilePhone} placeholder={"Please Input "+"Mobile Phone"} onIonChange={e=>handleChange(e,"MobilePhone")}/>   
                    </IonItem>
                    {getErrorMessage("MobilePhone")}
                    
                    </IonList>
                    </IonCardContent>
                </IonCard>

            </IonList>
           
          </IonGrid>
         
      </IonContent>
      
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
  },
  component: React.memo(AddPartnerForm)
});