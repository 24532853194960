import { IonButton, IonButtons, IonDatetime, IonIcon, IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import { connect } from '../../data/connect';
import React, { useEffect,  useState } from 'react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';


interface OwnProps {
    displayFormat?:any,
    
    value:any,
    placeholder?:any,
    readonly?:any,
    disabled?:any,
    type?:any,
    min?:any,
    max?:any,
    inputmode?:any
    onIonChange?: (data:any) => void;
    
}
interface StateProps {
  
};

interface DispatchProps {
    
}
type DateInputProps = OwnProps & StateProps & DispatchProps;

const DateInput: React.FC<DateInputProps> = ({inputmode,displayFormat,min,max,value,readonly,disabled,placeholder,type,onIonChange}) => {
    const [isloading,setIsloading] = useState(false);
    const [localmax,setLocalmax] = useState(max);
    useEffect(() => {
        if(max===undefined){
          var maxyear = (new Date().getFullYear())+5;
          setLocalmax(maxyear);
        }
       
      }, []);


      const dataChanged=(e:any)=>{
        var data = e;
        
        if(onIonChange){
            onIonChange(data);
        }

      }
     

    return (
        
        <>
        {!isloading&&
        <>
        <IonDatetime disabled = {disabled} 
                    readonly = {readonly} 
                    displayFormat={displayFormat}
                    pickerFormat ={displayFormat}
                    value={value} 
                    placeholder={placeholder} 
                    min={min}
                    max={localmax}
                    inputMode={inputmode}
                    onIonChange={e=>dataChanged(e)}/>  
        
        </>

        }
        </>

    );
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state, OwnProps) => ({
     
    }),
    mapDispatchToProps: {
      
    },
    component: (DateInput)
  })