import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal, useIonViewWillEnter, IonRouterContext, IonBackButton } from '@ionic/react';

import { connect } from '../../data/connect';
import './ReceivablePaymentList.scss';
import API from '../../services';
import { add, arrowBack, filter, options, search } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { RouteComponentProps, RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';
import MasterDetailList from '../../components/ActiveForm/MasterDetailList';
interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}


interface StateProps {
  mode: 'ios' | 'md';
};

interface DispatchProps {
 };

interface ReceivablePaymentListProps extends OwnProps, StateProps, DispatchProps { };

const ReceivablePaymentList: React.FC<ReceivablePaymentListProps> = ({match,mode,location}) => {
    const ionRouterContext = useContext(IonRouterContext);

    const apipath="payment";
    const masterapipath = "payment";
    const sbuttons = [
        {icon:"eyeOutline",route:"/payment/:id",label:"view"},
        {icon:"printOutline",route:"/payment/:id/print",label:"print"},
    ]
  const [isleave,setIsLeave] = useState(false);
  const [masters,setMasters] = useState([]);
  const [receivable,setReceivable] = useState<any>();
  const [datas,setDatas] = useState([]);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(true);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);
  const [usefilter,setUseFilter] = useState(false);
  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Description:''
          ,Active:true
      }
  );
  const [buttons,setButtons] = useState<any>(sbuttons);
  const [fieldtemplate,setFieldTemplate] = useState<any>(getTemplate(apipath+"_view"));
  const [masterbuttons,setMasterButtons] = useState<any>(getTemplate(masterapipath+'_button'));
  const [masterfieldtemplate,setMasterFieldTemplate] = useState<any>(getTemplate(masterapipath+"_view"));
  
  const [sort,setSort] = useState<any>(getTemplate(apipath+'_sort'));
  const [filters,setFilters] = useState<any>(getTemplate(apipath+'_filter'));

  const ios = mode === 'ios';
  useEffect(() => {

      if(location){
        if(location.pathname === "/receivable/"+match.params.id+"/paymentlist"){
          loaddatas();
        }
      }
      
    // eslint-disable-next-line
  }, [location,filters,sort]);
//   useIonViewWillEnter(async () => {
//     console.log("useIonViewWillEnter");
//     loaddatas();
//   });
  const submitfilter=(pfilters:any[],psort:any)=>{
    setFilters(pfilters);
    setSort(psort);
    setShowFilterModal(false);

  }
  const cancelfilter=()=>{
    
    setShowFilterModal(false);

  }
  const resetfilter=()=>{
    
    setFilters(getTemplate(apipath));
    setSort(getTemplate(apipath+'_sort'));
    setShowFilterModal(false);

  }
  const apifilterParameter=()=>{
    var result:any = {};

    if(filters)
    {
      Object.keys(filters).map((key:any,value:any) => {
        if(filters[key].active && filters[key].value !== ""){
          var param = "filter["+key+"]["+filters[key].operand+"]";
          var pvalue = filters[key].value;
          if(filters[key].type==="boolean"){
            pvalue = pvalue?1:0;
          }
          result[param]=pvalue;
        }
      });
    }

    if(sort){
      if(sort.active){
        var param = "sort";
        var pvalue = sort.field;
        if(sort.direction==="desc"){
          pvalue = "-"+pvalue;
        }
        result[param]=pvalue;
      }
    }

    return result;
  }

  const back=()=>{
    ionRouterContext.push('/receivable/'+match.params.id);

    }
//   const  loaddatas=async()=>{
//     setShowLoading(true);
//     const param:any = apifilterParameter();
//     const datas:any = await API.view(apipath,param).catch(e=>errorhandler(e));

//     if(datas){
//       setDatas(datas);
//     }

//     ionRefresherRef.current!.complete();
//       setShowCompleteToast(true);
//       setShowLoading(false);
    
//   }
  
//   const errorhandler = (err:any) =>{
//     setToastMessage(err.message);
//     if(err.response){
//         console.log("errorhandler response",err.response);
        
//     }
//     else{
//         console.log("errorhandler",err.message);
//     }
//   }

const  loaddatas=async()=>{
    setShowLoading(true);
    const param:any = apifilterParameter();    
    var receivable:any = await API.load('receivable',match.params.id).catch(e=>errorhandler(e));

    const ndatas:any = await API.view('receivable/loadpayment/'+match.params.id).catch(e=>errorhandler(e));
  if(receivable){

        setReceivable(receivable);

      if(ndatas){

          setDatas(ndatas);
          
          setToastMessage("load data complete");
      }
    }
    
    if(ionRefresherRef.current ){
    ionRefresherRef.current!.complete();
    }
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        console.log("errorhandler response",err.response);
        
    }
    else{
        console.log("errorhandler",err.message);
    }
  }
  return (
    <IonPage id={apipath+"-list"}>
      <IonHeader translucent={true}>
        <IonToolbar>
        <IonButtons slot="start">
            <IonBackButton defaultHref={"/receivable/"+match.params.id}></IonBackButton>
            {/* <IonButton title="Back to Sale" onClick={() => back() }>
              <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
            </IonButton> */}
          </IonButtons>

          {!showSearchbar &&
          <IonTitle>{receivable?receivable.PaymentMethod==="CREDIT"?"PAYMENT HISTORY":"CLEARANCE HISTORY":"HISTORY"}</IonTitle>
          }

          {showSearchbar &&
            <IonSearchbar showCancelButton="always" placeholder="Search"
             //onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
              >

              </IonSearchbar>
          }
          <IonButtons slot="end">
 
           
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{apipath.toLocaleUpperCase()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddatas}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

            {/* <IonGrid>
            
                {masters.map((master:any)=>{
                      return  <MasterDetailList detailkey={"SaleID"} key={master.id} mastername={masterapipath} master={master} mastertemplate={masterfieldtemplate} masterbuttons={masterbuttons}
                                           name={apipath} datas={datas} template={fieldtemplate} buttons={buttons}/>
                })}
              
           
          </IonGrid> */}
          
          <IonGrid>
            <IonRow>
              {datas.map((data:any) => (
                <IonCol size="12" size-md="6" key={data.id}>
                
                  <Card key={data.id} id={data.id} data={data} name={apipath} template={fieldtemplate} buttons={buttons}></Card>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
      </IonContent>
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <Filter
          fields={filterfields}
          filters={filters}
          sort={sort}
          submit={submitfilter}
          cancel={cancelfilter}
          reset={resetfilter}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
  },
  component: React.memo(ReceivablePaymentList)
});