import React from 'react';

import { IonCard, IonCardHeader, IonItem, IonLabel, IonAvatar, IonCardContent, IonList, IonButtons, IonButton, IonInput, IonToggle, IonTextarea, IonIcon, IonCol, IonRow } from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import { iconMap } from '../IconMap';
import NumericField from './NumericField';


interface FieldProps {
 datas : any[];
 name: string;
 template: any[];
 buttons:any[];
 detailkey:any;
 master : any;
 mastername: string;
 mastertemplate: any[];
 masterbuttons:any[];
}

const MasterDetailList: React.FC<FieldProps> = ({detailkey,master,mastername,mastertemplate,masterbuttons, datas,name,template,buttons }) => {

  
    const generatebuttons=(buttons:any,id:any)=>{
      var result:any= [];
      Object.keys(buttons).map((key:any, value) => {
        var route:any = buttons[key].route;
        var icon:any = buttons[key].icon;
        var label:any = buttons[key].label;
        result.push(
                <IonButton title={label} key={"button"+key} routerLink={route.replace(":id",id)}>
                  <IonIcon icon={iconMap[icon]} slot="icon-only" />
                  
                </IonButton>
          );
      });

      return result;
    }
    const generatefields=(data:any,template:any)=>{
        var result:any= [];
        if(data&&template){
   
          Object.keys(template).map((key:any, value) => {
              var type:any = template[key].type;
              // result.push(
                
              //       <IonCol key={"detail"+key}>
              //       <IonItem key = {key}>
              //           <IonLabel position="floating" color="primary">
              //           {template[key].name}
              //           </IonLabel>
              //           <IonInput name={key} value={data[key]} readonly/>  
              //       </IonItem>
              //       </IonCol>
                
              //   );
                // if(type==="string"){
                //     result.push(
                //     <IonItem key = {key} detail={false} >
                //         <IonLabel key = {key} >
                //         {data[key]}
                //         </IonLabel>
                //     </IonItem>
                //     );
                // }
                // else if(type==="boolean"){
                //     result.push(
                //         <IonItem key = {key} detail={false} >
                //             <IonLabel position="floating" color="primary">
                //             {template[key].name}
                //             </IonLabel>
                //             <IonInput name={key} value={data[key]?"True":"False"} readonly/>   
                //         </IonItem>

                //         );
                // }
                 if(type==="number"){
                  result.push(
                    <IonItem key = {key} detail={false}  >
                        <IonLabel position="floating" color="primary">
                        {template[key].name}
                        </IonLabel>
                        <NumericField readonly={true} type="tel" name={key} value={data[key]} placeholder={"Please Input "+template[key].name}/>   
                    </IonItem>
                    );
                }
                // else if(type==="description"){
                //     result.push(
                //     <IonItem key = {key} detail={false} >
                //         <IonLabel position="floating" color="primary">
                //         {template[key].name}
                //         </IonLabel>
                //         <IonTextarea name={key} value={data[key]} readonly/>  
                //     </IonItem>
                //     );
                // }
                else{
                    result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <IonInput name={key} value={data[key]} readonly/>  
                        </IonItem>
                        );
                        
                }
                // }
            },()=>{
              
            })
            
            
          }
          return result;
        }
        
        const generatemasterfields=(data:any,template:any)=>{
            var result:any= [];
            if(data&&template){
              Object.keys(template).map((key:any, value) => {
                  var type:any = template[key].type;
                  result.push(
                    <IonItem key = {"master"+key} detail={false} >
                        <IonLabel position="floating" color="primary">
                        {template[key].name}
                        </IonLabel>
                        <IonInput name={key} value={data[key]} readonly/>  
                    </IonItem>
                    );
                    // if(type==="string"){
                    //     result.push(
                    //     <IonItem key = {key} detail={false} >
                    //         <IonLabel>
                    //         {data[key]}
                    //         </IonLabel>
                    //     </IonItem>
                    //     );
                    // }
                    // else if(type==="boolean"){
                    //     result.push(
                    //         <IonItem key = {key} detail={false} >
                    //             <IonLabel>
                    //             {data[key]?"True":"False"}
                    //             </IonLabel>
                    //         </IonItem>
    
                    //         );
                    // }
                    // else if(type==="number"){
                    //     result.push(
                    //         <IonItem key = {key} detail={false} >
                    //             <IonLabel position="floating" color="primary">
                    //             {template[key].name}
                    //             </IonLabel>
                    //             <IonInput type="number" name={key} value={data[key]} readonly/>   
                    //         </IonItem>
                    //         );
                    // }
                    // else if(type==="description"){
                    //     result.push(
                    //     <IonItem key = {key} detail={false} >
                    //         <IonLabel position="floating" color="primary">
                    //         {template[key].name}
                    //         </IonLabel>
                    //         <IonTextarea name={key} value={data[key]} readonly/>  
                    //     </IonItem>
                    //     );
                    // }
                    // else{
                    //     result.push(
                    //         <IonItem key = {key} detail={false} >
                    //             <IonLabel>
                    //             {data[key]}
                    //             </IonLabel>
                    //         </IonItem>
                    //         );
                    // }
                    // }
                },()=>{
                  
                })
        
                  
              }
              return result;
            }
  return (
    <>
      <IonCard className="field-card">
        <IonCardHeader>
          <IonItem  className="field-item">
                {generatemasterfields(master,mastertemplate)}
            <IonButtons slot="end">
                {generatebuttons(masterbuttons,master.id)}
            </IonButtons>
            
          </IonItem>
        </IonCardHeader>

        <IonCardContent>
          
            {datas.filter((data)=>data[detailkey]===master.id)
                    .map((data) => 
                    
                        <IonRow key={"detail"+data.id}>
                            <IonCol size="11">
                            <IonRow>
                            {generatefields(data,template)}
                            </IonRow>
                            </IonCol>
                            <IonCol size="1">
                                {generatebuttons(buttons,data.id)}
                            </IonCol>
                        </IonRow>
                    
                )}

        </IonCardContent>
      </IonCard>
    </>
  );
};

export default MasterDetailList;