import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal, IonBackButton, IonList, IonItem, IonLabel, IonCheckbox, IonInput, IonCard, IonCardHeader, IonCardContent, IonDatetime, IonTextarea } from '@ionic/react';

import { connect } from '../../data/connect';
import './CreditSelectForm.scss';
import API from '../../services';
import { add, arrowBack, checkbox, checkmarkCircleOutline, filter, informationCircleOutline, options, search } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';
import MasterDetailList from '../../components/ActiveForm/MasterDetailList';
import NumberFormat from 'react-number-format';
import Selectfield from '../../components/ActiveForm/Selectfield';
import DateInput from '../../components/ActiveForm/DateInput';

interface OwnProps{
    submit: (items:any[]) => void;
    cancel:()=>void;
    currency:any;
    amount:any;
 };

interface StateProps {
  mode: 'ios' | 'md';
};

interface DispatchProps {
 };

interface CreditSelectFormProps extends OwnProps, StateProps, DispatchProps { };

const CreditSelectForm: React.FC<CreditSelectFormProps> = ({mode,currency,amount,cancel,submit}) => {
    const apipath="cashflow";
    const masterapipath = "cashflow";
    const initdata = {
        PaymentMethod:"CREDIT"
        ,Currency:currency
        ,Amount:amount
        //,WithDrawalDate:Date.now()
        ,ChequeNo:""
        ,Remarks:""
    };
  const [isleave,setIsLeave] = useState(false);
  const [masters,setMasters] = useState([]);
  const [errors,setErrors] = useState<any>(
        []
    );
  const [data,setData] = useState<any>(initdata);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);
  const [usefilter,setUseFilter] = useState(false);
  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Description:''
          ,Active:true
      }
  );
  const [buttons,setButtons] = useState<any>(getTemplate(apipath+'_button'));
  const [fieldtemplate,setFieldTemplate] = useState<any>(getTemplate(apipath+"_view"));
  const [masterbuttons,setMasterButtons] = useState<any>(getTemplate(masterapipath+'_button'));
  const [masterfieldtemplate,setMasterFieldTemplate] = useState<any>(getTemplate(masterapipath+"_view"));
  
  const [sort,setSort] = useState<any>(getTemplate(apipath+'_sort'));
  const [filters,setFilters] = useState<any>(getTemplate(apipath+'_filter'));

  const ios = mode === 'ios';

  const PaymentMethods = [{id:"CREDIT",Name:"CREDIT"},{id:"GIRO",Name:"GIRO"},{id:"CHEQUE",Name:"CHEQUE"}];

  useEffect(() => {

    
    //   loaddatas();
    // eslint-disable-next-line
  }, [filters,sort]);

  const submitdata=()=>{
    // console.log(data);
    if(validate()){
     submit(data);
    }

  }

  const validate=()=>{
    // console.log(data);
    var errors = [];
    var result = true;
    switch(data.PaymentMethod){
        case "CREDIT": break;
        case "GIRO": if(data.ChequeNo.trim()===""){
                            errors.push({field:"ChequeNo",message:"Please Input Giro Number"});
                            result = false;
                        }
                    break;
        case "CHEQUE": if(data.ChequeNo.trim()===""){
                            errors.push({field:"ChequeNo",message:"Please Input Giro Number"});
                            result = false;
                        }
                    break;
        default: break;
        }
    
        if(!data.WithDrawalDate){
            errors.push({field:"WithDrawalDate",message:"Please Input WithDrawalDate/PayDate"});
                            result = false;
        }
        if(data.Amount===0){
            errors.push({field:"Amount",message:"Please Input Amount"});
                            result = false;
        }
        if(!data.PaymentMethod){
            errors.push({field:"PaymentMethod",message:"Please Input Payment Method"});
                            result = false;
        }
        setErrors(errors);
    
    return result;

  }
  const handleChange = (e:any,name:string) => {
    const { value } = e.detail;
    setData({
        ...data,
        [name]: value
    });
};

  const submitfilter=(pfilters:any[],psort:any)=>{
    setFilters(pfilters);
    setSort(psort);
    setShowFilterModal(false);

  }
  const cancelfilter=()=>{
    
    setShowFilterModal(false);

  }
  const resetfilter=()=>{
    
    setFilters(getTemplate(apipath));
    setSort(getTemplate(apipath+'_sort'));
    setShowFilterModal(false);

  }
  const apifilterParameter=()=>{
    var result:any = {};

    if(filters)
    {
      Object.keys(filters).map((key:any,value:any) => {
        if(filters[key].active && filters[key].value !== ""){
          var param = "filter["+key+"]["+filters[key].operand+"]";
          var pvalue = filters[key].value;
          if(filters[key].type==="boolean"){
            pvalue = pvalue?1:0;
          }
          result[param]=pvalue;
        }
      });
    }

    if(sort){
      if(sort.active){
        var param = "sort";
        var pvalue = sort.field;
        if(sort.direction==="desc"){
          pvalue = "-"+pvalue;
        }
        result[param]=pvalue;
      }
    }

    return result;
  }

  function getErrorMessage(field:string) {
    if(!Array.isArray(errors)){
        return ;
    }
    else{
    return (
        <div className="validation-errors"> 
            {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
            <div key={field} className="error-message"> 
            <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                {filterederror.message}
                
            </div>
            
            ))}
        </div>
        
        );
    }
}
const  loaddatas=async()=>{
    setShowLoading(true);
    
    ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        console.log("errorhandler response",err.response);
        
    }
    else{
        console.log("errorhandler",err.message);
    }
  }
  console.log(data);
  return (
    <IonPage id={apipath+"-list"}>
      <IonHeader translucent={true}>
        <IonToolbar>
        <IonButtons slot="start">
            
            <IonButton onClick={()=>cancel()}>
            {mode === 'ios' ? 'cancel' : <IonIcon icon={arrowBack} slot="icon-only" />}
            </IonButton>
         
        </IonButtons>

      
          {!showSearchbar &&
          <IonTitle>{"SELECT CREDIT"}</IonTitle>
          }

          {showSearchbar &&
            <IonSearchbar showCancelButton="always" placeholder="Search"
              onIonCancel={() => setShowSearchbar(false)}
              >

              </IonSearchbar>
          }
          <IonButtons slot="end">
            
            {!showSearchbar &&
            
                <IonButton onClick={()=>submitdata()}>
                {mode === 'ios' ? 'submit' : <IonIcon icon={checkmarkCircleOutline} slot="icon-only" />}
                </IonButton>
            }
            
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{apipath.toLocaleUpperCase()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddatas}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

            <IonGrid>
            
            <IonList lines={ mode==='ios' ? 'inset' : 'full'}>
          
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Payment Method"}
                        </IonLabel>
                        {/* <IonInput readonly={true} name={"InventoryID"} value={detail["InventoryID"]} />    */}
                        
                        
                        <Selectfield name={"PaymentMethod"} value={data["PaymentMethod"]} option={{datas:PaymentMethods,label:"Name",value:"id"}} dataChanged={e=>handleChange(e,"PaymentMethod")}/>
                        
                        {/* <IonSelect key={"InventoryID"} value={data["InventoryID"]}  placeholder={"Please Select Inventory"} onIonChange={e => handleChange(e,"InventoryID")}>
                            {generateOption()}
                                            
                        </IonSelect> */}
                    </IonItem>
                    {getErrorMessage("InventoryID")}

                </IonCol>
              
            </IonRow>
            <IonRow>
                
                <IonCol>
                    <IonItem key = {"Currency"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Currency"}
                        </IonLabel>
                        <IonInput name={"Currency"} readonly value={data["Currency"]}/>   
                    </IonItem>
                    {getErrorMessage("Currency")}
                </IonCol>

                <IonCol>
                    <IonItem key = {"Amount"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Amount"}
                        </IonLabel>
                        <IonInput type="number" inputMode="tel" name={"Amount"}  value={data["Amount"]} onIonChange={e=>handleChange(e,"Amount")}/>   
                    </IonItem>
                    {getErrorMessage("Amount")}
                </IonCol>
            </IonRow>
            <IonRow>
                
                <IonCol>
                    <IonItem key = {"WithDrawalDate"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {data["PaymentMethod"]==="CREDIT"?"Pay Date":"Withdrawal Date"}
                        </IonLabel>
                        <DateInput displayFormat="DD MMM YYYY" placeholder="Select Date" value={data["WithDrawalDate"]} onIonChange={e=>handleChange(e,"WithDrawalDate")}></DateInput>
                    </IonItem>
                    {getErrorMessage("WithDrawalDate")}
                </IonCol>

                
            </IonRow>
            <IonRow>
                
                <IonCol>
                    <IonItem key = {"ChequeNo"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Giro / ChequeNo"}
                        </IonLabel>
                        <IonInput name={"ChequeNo"} value={data["ChequeNo"]} onIonChange={e=>handleChange(e,"ChequeNo")}/>   
                    </IonItem>
                    {getErrorMessage("ChequeNo")}
                </IonCol>

                
            </IonRow>
            <IonRow>
                
            <IonCol>
                    <IonItem key = {"Remarks"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Remarks"}
                        </IonLabel>
                        <IonTextarea name={"Remarks"} value={data["Remarks"]} placeholder={"Please Input "+"Remarks"} onIonChange={e=>handleChange(e,"Remarks")}/>   
                    </IonItem>
                    {getErrorMessage("Remarks")}

                </IonCol>

                
            </IonRow>
            </IonList>
           
          </IonGrid>
         
      </IonContent>
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <Filter
          fields={filterfields}
          filters={filters}
          sort={sort}
          submit={submitfilter}
          cancel={cancelfilter}
          reset={resetfilter}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
  },
  component: React.memo(CreditSelectForm)
});