import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard, IonDatetime, IonCardHeader, IonCardContent, IonModal, IonSelect, IonSelectOption, IonAlert, IonItemDivider, useIonViewWillEnter, useIonViewDidEnter, useIonViewWillLeave, useIonViewDidLeave } from '@ionic/react';

import { connect } from '../../data/connect';
import './SaleItemMonthlyReport.scss';
import API from '../../services';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router';
import { add, arrowRedo, cartOutline, cash, closeCircle, eyeOutline, grid, informationCircleOutline, list, newspaperOutline, pencil, printOutline, remove, save as saveicon, saveOutline, search, send, trashBin } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
import Selectfield from '../../components/ActiveForm/Selectfield';
import SearchItemForm from '../Stockopname/SearchItemForm';
import NumberFormat from 'react-number-format';
import SearchPartnerForm from '../Partner/SearchPartnerForm';
import ViewPartnerForm from '../Partner/ViewPartnerForm';
import AddPartnerForm from '../Partner/AddPartnerForm';
import EditPartnerForm from '../Partner/EditPartnerForm';
import NumericField from '../../components/ActiveForm/NumericField';
import ReactHTMLTableToExcel from '../../components/react-html-table-to-excel/src/ReactHTMLTableToExcel';
import ReactExport from "../../components/react-export-excel/dist";
import DateInput from '../../components/ActiveForm/DateInput';
// import ReactExport from "../../components/react-data-export/dist"
// import ExcelFile from '../../components/react-export-excel/src/ExcelPlugin/components/ExcelFile';
// import ExcelSheet from '../../components/react-export-excel/src/ExcelPlugin/elements/ExcelSheet';
interface OwnProps extends RouteProps {}

interface StateProps {
 
};

interface DispatchProps {

}

type SaleItemMonthlyReportProps = OwnProps & StateProps & DispatchProps;

const SaleItemMonthlyReport: React.FC<SaleItemMonthlyReportProps> = ({location}) => {
  const ionRouterContext = useContext(IonRouterContext);
  var today = new Date();

  const initdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const apipath = 'sale';
    const [params,setParams] = useState({
        DateFrom:initdate
        ,DateTo:initdate
        ,InventoryID:0
        ,ItemGroupID:0
        ,ItemID:0


    })
    const [errors,setErrors] = useState(
        []
    );

    const [errordetails,setErrordetails] = useState<any>(
        []
    );
    const [ischanged,setIschanged]=useState(false);
    const [data,setData] = useState<any>([]);
    const [salesummary,setSalesummary] = useState<any>([]);
    const [saleitemsummary,setSaleitemsummary] = useState<any>([]);

    const [details,setDetails] = useState<any>([]);
    const [currencylist,setCurrencylist] = useState<any>([]);
    const [currency,setCurrency] = useState<any>([]);
    const [isReadonly, setIsReadOnly] = useState(false);

    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showSearchItemModal, setShowSearchItemModal] = useState(false);
    const [showSearchParnerModal, setShowSearchPartnerModal] = useState(false);
    const [showViewParnerModal, setShowViewPartnerModal] = useState(false);
    const [showAddParnerModal, setShowAddPartnerModal] = useState(false);
    const [showEditParnerModal, setShowEditPartnerModal] = useState(false);

    const [myInventory, setMyInventory] = useState<any>([]);
    const [itemgrouplist, setItemgrouplist] = useState<any>([]);
    const [itemlist, setItemlist] = useState<any>([]);
    const [montharray, setMontharray] = useState<any>([]);
    const [totalreport,setTotalreport] = useState<any>({});

    const [currentInventory,setCurrentInventory] = useState<any>();
    const [selecteditem,setSelecteditem] = useState<any>();
    const [selecteditemgroup,setSelecteditemgroup] = useState<any>();
    const [datefrom,setDatefrom] = useState<any>(initdate);
    const [dateto,setDateto] = useState<any>(initdate);

    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeader, setAlertHeader] = useState("Warning");
    const [alertMessage, setAlertMessage] = useState("Warning");
    const [alertButtons, setAlertButtons] = useState<any>(['Ok']);
    const [selectdate,setSelectdate]= useState('today');
    const [iscustomdate,setIscustomdate]=useState(true);
    const pageRef = useRef<HTMLElement>(null);
    const [filename,setFilename] = useState("");
    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
            if(name==="date"){
                if(selectdate!==value){
                loaddate(value);
                }
            }
            
            else{
                if(name==="ItemGroupID"&&value!==0){
                    setParams({
                        ...params,
                        [name]: value,
                        ItemID:0
                    });
                    const item = itemlist.find((f:any)=>f.id === 0);
                    setSelecteditem(item);
                }
                else if(name==="DateFrom"){
                    var date = new Date(value);
                    var datefrom = new Date(date.getFullYear(), date.getMonth(), 1);
                    const datefromstring = datefrom.getFullYear() + '-' + (datefrom.getMonth() + 1) + '-' + datefrom.getDate();
        
                    setParams({
                        ...params,
                        DateFrom: datefromstring
                    });

                    setDatefrom(value);
                }
                else if(name ==="DateTo"){
                    var date = new Date(value);
                    var dateto = new Date(date.getFullYear(), date.getMonth()+1,0);
                    const datetostring = dateto.getFullYear() + '-' + (dateto.getMonth() + 1) + '-' + dateto.getDate();
        
                    setParams({
                        ...params,
                        [name]: datetostring
                    });

                    setDateto(value);
                }
                else{
                    setParams({
                        ...params,
                        [name]: value
                    });
                }
            }
            
            if(name ==="InventoryID"){
                const inventory = myInventory.find((f:any)=>f.id === value);

                setCurrentInventory(inventory);
            }
            else if(name ==="ItemGroupID"){
                const itemgroup = itemgrouplist.find((f:any)=>f.id === value);
                setSelecteditemgroup(itemgroup);
            }
            else if(name ==="ItemID"){
                const item = itemlist.find((f:any)=>f.id === value);
                setSelecteditem(item);
            }
            setData([]);
            setIschanged(true);
            
        
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        if(data[name]!==checked){
            setData({
                ...data,
                [name]: checked
            });
        }
        setIschanged(true);
        // setChecked(checked);
    };

    useEffect(() => {
        if(location){
            if(location.pathname === "/report/"+"saleitemmonthly"){
                setData([]);
                loadinventory();
                loaditemgroup();
                loaditem();

            }
        }
        // loaddata();
        // eslint-disable-next-line
      }, [location]);
    

      const loaddate=(code:any)=>{
        var date = new Date();
        var datefrom = new Date(date.getFullYear(), date.getMonth(), 1);
        var dateto = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
       
        switch(code){
            case 'today':
                 datefrom = date;
                 dateto = date;
                 setIscustomdate(false);

                break;
            case 'yesterday':
                
                 datefrom = new Date(date.getFullYear(), date.getMonth(),date.getDate()-1);
                 dateto = new Date(date.getFullYear(), date.getMonth(),date.getDate()-1);
                 setIscustomdate(false);

                break;
            case 'this week':

                 datefrom = new Date(date.setDate(diff));
                 dateto = new Date(datefrom.getFullYear(), datefrom.getMonth(),datefrom.getDate()+7);
                 setIscustomdate(false);

                break;
           
            case 'this month':
                datefrom = new Date(date.getFullYear(), date.getMonth(), 1);
                dateto = new Date(date.getFullYear(), date.getMonth()+1,0);
                setIscustomdate(false);

                break;
            case 'monthly':
                    setIscustomdate(true);
                    break;
            case 'custom':
                setIscustomdate(true);
                break;
            default:
                break;

        }

       
        const datefromstring = datefrom.getFullYear() + '-' + (datefrom.getMonth() + 1) + '-' + datefrom.getDate();
        const datetostring = dateto.getFullYear() + '-' + (dateto.getMonth() + 1) + '-' + dateto.getDate();
        
        setSelectdate(code);
        setParams({...params
            ,DateFrom:datefromstring
            ,DateTo:datetostring});
        
      }
    const loaddata=async()=>{
      setShowLoading(true);
      var result:any = await API.insert("report/sale",null).catch(e=>errorhandler(e));
    
      if(result){
          setToastMessage("load complete");
       
      }
      else{

      }
   
    if(ionRefresherRef.current ){
        ionRefresherRef.current!.complete();
        }
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const loadinventory = async()=>{
        var inventories:any = await API.view("user/myinventory").catch(e=>errorhandler(e));
       
        if(inventories&&inventories.length>0){
            inventories = inventories.sort((a:any,b:any)=>a.Name>b.Name?1:-1);
            inventories.unshift({id:0,Name:"All Inventory",Address:"All Inventory",Description:"All Inventory",Active:true});
            setMyInventory(inventories);
            setParams({...params,InventoryID:inventories[0].id});
            setCurrentInventory(inventories[0]);
        }
      }
      const loaditemgroup = async()=>{
        var itemgrouplist:any = await API.view("report/listitemgroup").catch(e=>errorhandler(e));
       
        if(itemgrouplist&&itemgrouplist.length>0){            
            itemgrouplist= itemgrouplist.sort((a:any,b:any)=>{
                
                    return a.Name > b.Name ? 1 : -1;
                
            });
            itemgrouplist.unshift({id:0,Name:"All Group",Address:"All Group",Description:"All Group",Active:true});
            setItemgrouplist(itemgrouplist);
            setParams({...params,ItemGroupID:itemgrouplist[0].id});
            setSelecteditemgroup(itemgrouplist[0]);
        }
      }

      const loaditem = async()=>{
        var itemlist:any = await API.view("report/listitem").catch(e=>errorhandler(e));
       
        if(itemlist&&itemlist.length>0){
            itemlist = itemlist.sort((a:any,b:any)=>a.Name>b.Name?1:-1);
            setItemlist(itemlist);
            itemlist.unshift({id:0,Name:"All Item",Address:"All Item",Description:"All Item",Active:true});
            setParams({...params,ItemID:itemlist[0].id});
            setSelecteditem(itemlist[0]);
        }
      }
      const generatefilename = async(params:any) =>{
          var filename = "reportsaleitemmonthly";   
          
          filename = "_"+currentInventory.Name+"_"+selecteditemgroup.Name+"_"+selecteditem.Name +"_("+params.DateFrom+")_("+params.DateTo+")";

            setFilename(filename);
      }

      const getMonthsArray=(datefrom:any,dateto:any)=>{
          var montharray:any = [];
          
          var datef = new Date(datefrom);
          var datet = new Date(dateto);
          var nextdate = datef;
          while(nextdate<=dateto){
            montharray.push(nextdate.getFullYear() + '-' + (nextdate.getMonth() + 1) );
            nextdate = new Date(nextdate.getFullYear(), nextdate.getMonth()+1,1);
          }

          return montharray;
      }
      const generatereport = async()=>{
        setShowLoading(true);

        var lparams = params;
        var datef = new Date(lparams.DateFrom);
        var datefrom = new Date(datef.getFullYear(), datef.getMonth(), 1);
        const datefromstring = datefrom.getFullYear() + '-' + (datefrom.getMonth() + 1) + '-' + datefrom.getDate();
        
        var datet = new Date(lparams.DateTo);
        var dateto = new Date(datet.getFullYear(), datet.getMonth()+1,0);
        const datetostring = dateto.getFullYear() + '-' + (dateto.getMonth() + 1) + '-' + dateto.getDate();

        var montharray = getMonthsArray(datef,datet);
        setMontharray(montharray);
        lparams={...lparams,DateFrom:datefromstring,DateTo:datetostring}        

        await setParams(lparams);
        await generatefilename(lparams);
        var data:any = await API.insert("report/saleitemmonthly",lparams).catch(e=>errorhandler(e));
        
        if(data&&data.length>0){
            var totalreport:any = {};
            const report = itemlist
            .filter((f:any)=>{
                if(f.id===0){
                    return false;
                }
                else{
                    return selecteditemgroup.id===0?
                            selecteditem.id===0?
                                true
                                :f.id===selecteditem.id
                            :selecteditem.id===0?
                            f.ItemGroupID===selecteditemgroup.id
                            :f.id===selecteditem.id;
                }
            })
            .map((item:any)=>{
                var result:any = [];

                var group = itemgrouplist.find((f:any)=>f.id === item.ItemGroupID);

                result["ItemID"]=item.id;
                result["ItemName"] = item.Name;
                result["ItemGroupID"] = item.ItemGroupID;
                result["ItemGroupName"] = group.Name;
                var total = 0;
                var count = 0;
                var mv= montharray.map((month:any)=>{
                    var m:any = {};
                    
                    var v = data.find((d:any)=>{
                       
                        return month===d.saleyear+"-"+d.salemonth && item.id.toString()===d.ItemID.toString();
                    })
                   
                    if(v){
                        m[month]=parseFloat(v.TotalPrice);
                    }
                    else{
                        m[month]=0;
                    } 
                    if(totalreport[month]!==undefined){
                        totalreport[month]+=m[month];
                       
                    }
                    else{
                        
                        totalreport[month]=m[month];
                        
                    }
                    total +=m[month];
                    if(totalreport["total"]!==undefined){
                        totalreport["total"]+=m[month];
                    }
                    else{
                        totalreport["total"]=m[month];
                    }
                    count ++;
                    result = {...result,[month]:m[month]}
                    return m;
                })

                result = {...result,Total:total,Avg:parseFloat(total.toString())/parseFloat(count.toString())}
                return result;
            })
            .sort((a:any,b:any)=>{
                    if (a.ItemGroupName === b.ItemGroupName) {
                    // Price is only important when cities are the same
                    return a.ItemName > b.ItemName ? 1: -1;
                    }
                    return a.ItemGroupName > b.ItemGroupName ? 1 : -1;
                
            });

            totalreport["avg"]=parseFloat(totalreport["total"].toString())/parseFloat(montharray.length.toString());
            setTotalreport(totalreport);
            setData(report);

           
        }
        else{
            setSaleitemsummary([]);
            setSalesummary([]);
            setData([]);
            setAlertMessage("no data");
            setShowAlert(true);
        }
        setShowLoading(false);
      }
    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              setErrors(err.response.data["sale"]);
              setErrordetails(err.response.data["details"]);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }
    
    function getErrorMessage(field:string) {
        if(!Array.isArray(errors)){
            return ;
        }
        else{
        return (
            <div className="validation-errors"> 
                {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
                <div key={field} className="error-message"> 
                <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                    {filterederror.message}
                    
                </div>
                
                ))}
            </div>
            
            );
        }
    }
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{"REPORT SALE ITEM MONTHLY"}</IonTitle>
          <IonButtons slot="end">
          
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        {/* <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher> */}

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

        <IonCard className="field-card">
        
        <IonCardContent>
        <IonList lines="none">
         
            <IonItem>
                <IonLabel position="fixed" color="primary">
                From
                </IonLabel>
                <DateInput displayFormat="MMM YYYY" placeholder="Select Date" value={datefrom} onIonChange={e => handleChange(e,"DateFrom")}/>
            </IonItem>

            <IonItem>
                <IonLabel position="fixed" color="primary">
                To
                </IonLabel>
                <DateInput  displayFormat="MMM YYYY" placeholder="Select Date" value={dateto} onIonChange={e => handleChange(e,"DateTo")}/>
            </IonItem>


        <IonItem>
            
            <IonLabel position="fixed" color="primary">Inventory</IonLabel>
            
            {myInventory.length>0&&
                    <Selectfield name={"InventoryID"} value={params["InventoryID"]} option={{datas:myInventory,label:"Name",value:"id"}} dataChanged={e=>handleChange(e,"InventoryID")}/>
                    }
        </IonItem>
        <IonItem>
            
            <IonLabel position="fixed" color="primary">Group</IonLabel>
            
            {itemgrouplist.length>0&&
                    <Selectfield name={"ItemGroupID"} value={params["ItemGroupID"]} option={{datas:itemgrouplist,label:"Name",value:"id"}} dataChanged={e=>handleChange(e,"ItemGroupID")}/>
                    }
        </IonItem>
        <IonItem>
            
            <IonLabel position="fixed" color="primary">Item</IonLabel>
            
            {itemlist.length>0&&
                    <Selectfield name={"ItemID"} value={params["ItemID"]} option={{datas:itemlist.filter((item:any) => params["ItemGroupID"]===0||item.id===0?true: item.ItemGroupID === params["ItemGroupID"]),label:"Name",value:"id"}} dataChanged={e=>handleChange(e,"ItemID")}/>
                    }
        </IonItem>
        <IonItem>
        <IonButton title={"Generate Report"} onClick={() => generatereport() }>
              <IonIcon icon={newspaperOutline}></IonIcon> Generate
            </IonButton>
        </IonItem>
        </IonList>
{data&&data.length>0&&
<IonCardContent>
        <div className = "xlstable">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="report-sale"
                    filename={"sale_"+filename}
                    sheet={"sale_"+filename}
                    buttonText="download"/>
                <table id="report-sale">
                <tr>
                        <th>Report</th>
                        <th>DateFrom</th>
                        <th>DateTo</th>
                        <th>Inventory</th>
                        <th>Group</th>
                        <th>Item</th>

                </tr>
             
                    <tr>
                        <td>{"SALE ITEM MONTHLY"}</td>
                        <td>{params.DateFrom}</td>
                        <td>{params.DateTo}</td>
                        <td>{currentInventory.Name}</td>
                        <td>{selecteditemgroup.Name}</td>                        
                        <td>{selecteditem.Name}</td>

                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>
                    <tr>
                        <th className="topfixed">Group</th>
                        <th className="topfixed">Item</th>
                        {montharray.map((month:any)=>{

                            return(
                                <th className="topfixed">{month}</th>
                            )
                        })}
                        <th className="topfixed">Total</th>
                        <th className="topfixed">Avg</th>

                    </tr>
                    
                    
                    {data.map((d:any,i:any) => {
                        return (<tr>
                             {
                            i==0||d.ItemGroupName != data[i-1].ItemGroupName?
                            <>
                            <td>{d.ItemGroupName}</td>
                            
                            </>
                            :
                            <>
                            <td></td>
                            
                            </>
                            }
                            
                            <td>{d.ItemName}</td>
                            {montharray.map((month:any)=>{

                                return(
                                    <td>{d[month]}</td>
                                )
                            })}

                            <td>{d.Total}</td>
                            <td>{d.Avg}</td>
                            </tr>)
                    })}     
                        <tr>
                            <th></th>
                            <th>Total</th>
                            {montharray.map((month:any)=>{

                                return(
                                    <th>{totalreport[month]}</th>
                                )
                            })}

                            <th>{totalreport["total"]}</th>
                            <th>{totalreport["avg"]}</th>
                            </tr>               
                        <tr>
                        <td></td>
                        <td></td>
                        </tr>
                        <tr>
                        <th>GenerateDate</th>
                        <td>{new Date().toString()}</td>
                        </tr>
                </table>
                
            </div>
            </IonCardContent>
}

        </IonCardContent>
      </IonCard>
         
      </IonContent>
      
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={alertHeader}
          message={alertMessage}
          buttons={alertButtons}
        />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: React.memo(SaleItemMonthlyReport)
})