import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard } from '@ionic/react';

import { connect } from '../../data/connect';
import './ReceivableForm.scss';
import API from '../../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { cartOutline, cashOutline, informationCircleOutline, newspaperOutline, printOutline, save as saveicon, saveOutline } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
 
};

interface DispatchProps {

}

type ReceivableFormProps = OwnProps & StateProps & DispatchProps;

const ReceivableForm: React.FC<ReceivableFormProps> = ({location,match}) => {
  const ionRouterContext = useContext(IonRouterContext);

    const apipath = 'receivable';
    var initdata =  {   
      id:0
        ,Name:''
        ,Active:false
    }
    const [errors,setErrors] = useState(
        []
    );
    const [data,setData] = useState<any>(initdata);

    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Complete");
    const [showLoading, setShowLoading] = useState(false);
    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        setData({
            ...data,
            [name]: checked
        });
        // setChecked(checked);
    };

    useEffect(() => {
        if(location){
            if(location.pathname === "/"+apipath+"/"+match.params.id){
                if(match.params.id!=="new"){
                    loaddata();
                }
                else{
                    setData(initdata);
                }
            }
        }
        // loaddata();
        // eslint-disable-next-line
      }, [location,match.params.id]);
    
    const loaddata=async()=>{
      setShowLoading(true);
      var result:any = await API.load(apipath,match.params.id).catch(e=>errorhandler(e));
      if(result){
          setToastMessage("load complete");
          setData(result);
          // setChecked(result.Active);
      }
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              setErrors(err.response.data);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }
    const payment = async ()=>{
      
        ionRouterContext.push('/receivable/'+match.params.id+'/payment');
        
    }
    const salehistory = async ()=>{
        if(data.ReceivableType ==="SALE"){
        ionRouterContext.push('/sale/'+data.TransactionID);
        }
        
    }
    const paymenthistory = async ()=>{
        
        ionRouterContext.push('/receivable/'+match.params.id+'/paymentlist');
        
    }
    const receivableprint = async()=>{
      ionRouterContext.push('/receivable/'+match.params.id+'/print');

    }
    const save = async ()=>{
      
      setShowLoading(true);
      setErrors([]);

      let result:any=null;
      if(match.params.id==="new"){
          console.log("data",data);
        result = await API.insert(apipath,data).catch(e=>errorhandler(e));
      }
      else{
         result = await API.update(apipath,match.params.id,data).catch(e=>errorhandler(e));
      }
      if(result){
          setToastMessage("save complete");
          setData(result);
          ionRouterContext.push('/'+apipath);
      }
      setShowCompleteToast(true);
      setShowLoading(false);

    }

    function getErrorMessage(field:string) {
      return (
        <div className="validation-errors"> 
            {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
              <div key={field} className="error-message"> 
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                {filterederror.message}
                
              </div>
              
            ))}
        </div>
          
        );
    }
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/"+apipath}></IonBackButton>
          </IonButtons>
          <IonTitle>{match.params.id==="new"?"NEW":data.PaymentMethod}</IonTitle>
          <IonButtons slot="end">
          <IonButton title="print" onClick={() => receivableprint() }>
              <IonIcon slot="icon-only" icon={printOutline}></IonIcon>
              
            </IonButton>
          <IonButton title="sale history" onClick={() => salehistory() }>
              <IonIcon slot="icon-only" icon={cartOutline}></IonIcon>
              
            </IonButton>
            <IonButton title={data.PaymentMethod==="CREDIT"?"payment history":"clearance history"} onClick={() => paymenthistory() }>
              <IonIcon slot="icon-only" icon={newspaperOutline}></IonIcon>
              
            </IonButton>
            {data.Status==="WAIT_PAYMENT"&&
            <IonButton title={data.PaymentMethod==="CREDIT"?"pay":"clearance"} onClick={() => payment() }>
              <IonIcon slot="icon-only" icon={cashOutline}></IonIcon>
              
            </IonButton>
            }
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
         <IonCard className="field-card">
           
        <Form mode={match.params.id==="new"?"new":"update"}
              data={data}
              errors={errors}
              template={getTemplate(apipath+(match.params.id==="new"?"_create":"_update"))}
              dataChanged={setData}
        />
         </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: withRouter(ReceivableForm)
})