import { IonButton, IonButtons, IonIcon, IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import { connect } from '../../data/connect';
import React, { useEffect,  useState } from 'react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';


interface OwnProps {
    name:any,
    value:any,
    placeholder?:any,
    readonly?:any,
    disabled?:any,
    type?:any,
    min?:any,
    max?:any,
    inputmode?:any
    onIonChange?: (data:any) => void;
    noformat?:any
}
interface StateProps {
  
};

interface DispatchProps {
    
}
type NumericFieldProps = OwnProps & StateProps & DispatchProps;

const NumericField: React.FC<NumericFieldProps> = ({noformat,inputmode,min,max,name,value,readonly,disabled,placeholder,type,onIonChange}) => {
    const [isloading,setIsloading] = useState(false);
    
    useEffect(() => {

       
      }, []);


      const dataChanged=(e:any)=>{
        var data = e;
        // console.log("datachange",data);
        if(noformat===true){
                         
            // console.log("noformat",data.detail.value);
            
        }
        else{
        data.detail.value = parseFloat(data.detail.value.toString().replace(/,/g, ''));
        // console.log(data.detail.value);
        data.detail.value = data.detail.value===""||data.detail.value==="NaN"?0:data.detail.value;
        // console.log("data.detail.value",data.detail.value);
        }
        if(onIonChange){
            onIonChange(data);
        }

      }
      function numberWithCommas(x:any) {
        // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // return x.toLocaleString('en-US', {maximumFractionDigits:2}) 
        var result:any = 0;
        try{
            if(x!==undefined){
                if(noformat===true){
                    result = x;   
                    result =  new Intl.NumberFormat('en-GB',{minimumFractionDigits:1}).format(x.toString().replace(/,/g, ''));
                    result = result===""||result==="NaN"?0:result;

                    // console.log("noformat",result);

                }
                else{
                // return x;
                // console.log(x);
                // result = x.toLocaleString('en-US', {maximumFractionDigits:2});
                // console.log(x);
                // var n = x.indexOf(".");
                // var l = x.length-1;
                
                result =  new Intl.NumberFormat('en-GB',{minimumFractionDigits:0}).format(x.toString().replace(/,/g, ''));
                // console.log("compare",n>-1&&n===l);
                // if(n>-1&&n===l){
                //     console.log("before",result);
                //     // result = result +".0";
                //     console.log("after",result);
                // }
                result = result===""||result==="NaN"?0:result;
                
                // return x.toLocaleString('en-US', {maximumFractionDigits:2}) 
                // var parts=x.toString().split(".");
                // return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
            
                }
            }
            else{
                result = 0;
            }
        }
        catch(e){
            result = 0;
        }
        // console.log("numberwithcoma",result);
        return result ;
    }

    return (
        
        <>
        {!isloading&&
        <>
        <IonInput disabled = {disabled} 
                    readonly = {readonly} 
                    name={name} 
                    type={type?type:"tel"} 
                    value={numberWithCommas(value)} 
                    placeholder={placeholder} 
                    min={min}
                    max={max}
                    inputMode={inputmode}
                    onIonChange={e=>dataChanged(e)}                    
                   
                    onKeyPress={(e:any)=>{
                       
                        const pattern = /[0-9.]/; // without ., for integer only
                        let inputChar = String.fromCharCode(e.which ? e.which : e.keyCode);
                    
                        if (!pattern.test(inputChar)) {
                          // invalid character, prevent input
                          e.preventDefault();
                          return false;
                        }
                        return true;
                    }}
                    />  
        </>

        }
        </>

    );
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state, OwnProps) => ({
     
    }),
    mapDispatchToProps: {
      
    },
    component: (NumericField)
  })