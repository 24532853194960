import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonLoading, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { connect } from '../../data/connect';
import React, { useEffect,  useState } from 'react';
import { eyeOffOutline, eyeOutline, printOutline, settingsOutline } from 'ionicons/icons';
import {PDFDownloadLink,PDFViewer , Page, Text, View, Document, Image, StyleSheet ,Font} from '@react-pdf/renderer';
import {Table,TableHeader,TableCell,TableBody,DataTableCell}from '@david.kucsai/react-pdf-table';
// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// import pdfMake from 'pdfmake/build/pdfmake';
// import vfsFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
import dateFormat from 'dateformat';
import {
    PDFDocument,
    PDFText,
    PDFTable,
    PDFTableRow,
    PDFTableColumn,
    PDFColumns,
    PDFColumn
  } from 'react-pdfmake';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import API from '../../services';
import { title } from 'process';

  interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
  
};

interface DispatchProps {
    
}
type PaymentPrintProps = OwnProps & StateProps & DispatchProps;
Font.register({
            family: 'Oswald',
            // src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
            src: 'assets/font/Oswald.ttf'
        });
const PaymentPrint: React.FC<PaymentPrintProps> = ({location,match}) => {
    const [isloading,setIsloading] = useState(false);
    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showPrint, setShowPrint] = useState(false);
    const [printdata,setPrintdata] = useState<any>();
    const [data,setData] = useState<any>();
    const [details,setDetails] = useState<any>([]);
    const [credits,setCredits] = useState<any>([]);
    const [sale,setSale] = useState<any>();
    const [transaction,setTransaction] = useState<any>();
    const [title,setTitle]=useState<any>();
    const [inventory,setInventory] = useState<any>();
    const [partner,setPartner] = useState<any>();
    const [currency,setCurrency] = useState<any>();
    const [errors,setErrors] = useState(
        []
    );

                    
    useEffect(() => {
        
        if(location){
            
            if(location.pathname === "/payment/"+match.params.id+"/print"){
               
                
                loaddata();
                
                
            }
        }
       
      }, [location,match.params.id]);

      const loaddata=async()=>{        
          setShowPrint(false);

        setShowLoading(true);
        var result:any = await API.load("payment/printtransaction",match.params.id).catch(e=>errorhandler(e));
     
        if(result){
            setToastMessage("load complete");
           
            
            await setCredits(result["credits"]);
            await setPartner(result["partner"]);
            await setInventory(result["inventory"]);
            await setTitle(result["title"]);
            await setTransaction(result["transaction"]);
            await setData(result["payment"]);
            await setDetails(result["details"]);

            var printdata = await DocumentPrint(result);
            setPrintdata(printdata);
        }
       
        setShowCompleteToast(true);
        setShowLoading(false);
        setShowPrint(true);

      }
     
      const styles2 = StyleSheet.create({
        em:{
            // fontStyle: 'bold'
        }, 
        table: {
            // width: '100%',
            borderWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            marginVertical: 12,
            fontFamily: 'Oswald',
            fontSize:10,
            marginTop:0,
            marginLeft:10,
            marginRight:10,
            padding: 10
        },
        tableRow:{
            display: 'flex',
            flexDirection: 'row',
        },
        header:{
            fontWeight:'bold',
            backgroundColor:'lightgrey'
        },
        cell: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            flexWrap: 'wrap',
            // height:25,
            padding:2,
        },
       
    }) ;
      const styles = StyleSheet.create({
        page: {
            fontFamily: 'Oswald',
            fontSize:12,
        //   margin:10,
        //   padding: 10,
        },
        header:{
            marginLeft:10,
            marginRight:10,
            marginTop:10,

            padding: 10,
            flexDirection: 'row'
            
        },
        image:{
            height:65,
            width:65

        },
        headerimage:{
            fontFamily: 'Oswald',
            fontSize:10,
            flexGrow: 1
        },
        headerinventory:{
            fontFamily: 'Oswald',
            fontSize:10,
            flexGrow: 1
        },
        headercustomer:{
            margin:10,
            fontFamily: 'Oswald',
            fontSize:10,
            // textAlign:"right",
            flexGrow: 1
        },
        viewtable:{
            // fontFamily: 'Oswald',
            // fontSize:10,
            marginTop:0,
            marginLeft:10,
            marginRight:10,
            padding: 10
            // flexGrow: 1
        },
        tableheadercell:{
            padding:2,
            fontSize:10,
            backgroundColor:"lightgrey"
        },
        tabledatacell:{
            padding:2,
            fontSize:8,
        },
        summary:{
            // marginLeft:10,
            padding: 10,
            fontSize:10,
            flexDirection: 'row'
        },
        summaryleft:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey"

        },
        summaryright:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey"

        },
        ttd:{
            // marginLeft:10,
            padding: 10,
            fontSize:10,
            flexDirection: 'row',
            

        },
        ttdleft:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey",justifyContent:'center'
        },
        ttdright:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey",justifyContent:'center'

        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
          
        }
      });
      
    const errorhandler = (err:any) =>{
        setToastMessage(err.message);
        if(err.response){
            
        }
        else{
            console.log("errorhandler",err.message);
        }
    }

    function numberWithCommas(x:any) {
     
        if(x!==undefined){
           
            return new Intl.NumberFormat().format(x.toString().replace(/,/g, ''));
           
        }
        else{
            return 0;
        }
    }
    const TableHeaderPayment = (result:any) => {
        const credits=result["credits"];
        const partner=result["partner"];
        const inventory=result["inventory"];
        const title=result["title"];
        const transaction=result["transaction"];
        const data=result["payment"];
        const details=result["details"];
        return (
            <View style={[styles2.table,{margin:0}]}>
                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                    <View style={[styles2.cell
                        , {width:'15%',borderWidth:0,textAlign:'left'}]}>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',textAlign:'left'}]}>
                                    <Image
                                        style={styles.image}
                                        src="assets/img/appicon.png"
                                    />
                                </View>
                            </View>
                            
                    </View>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'left'}]}>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',textAlign:'left'}]}>
                                   <Text style={{fontSize:16}}>{inventory.Title}</Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',textAlign:'left'}]}>
                                   <Text>{inventory.Address}</Text>
                                </View>
                            </View>
                    </View>
                    <View style={[styles2.cell
                        , {width:'35%',borderWidth:1,textAlign:'right'}]}>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',textAlign:'center',fontSize:16}]}>
                                   <Text>{"PAYMENT INVOICE"}</Text>
                                </View>
                                
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                   <Text>{"No"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                   <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                   <Text>{data.PaymentCode}</Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                   <Text>{"Tanggal"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                   <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                   <Text>{dateFormat(data.PaymentDate,'dd mmmm yyyy')}</Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                   <Text>{"Kepada"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                   <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                   <Text>{partner.Name}</Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                   <Text>{"Alamat"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                   <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                   <Text>{partner.Address}</Text>
                                </View>
                            </View>
                    </View>
                </View>
            </View>

        )
    }

    const TablePayment = (result:any) =>{
        
        const credits=result["credits"];
        const partner=result["partner"];
        const inventory=result["inventory"];
        const title=result["title"];
        const transaction=result["transaction"];
        const data=result["payment"];
        const details=result["details"];
        return(
        <>
      
            <View style={styles2.table}>
                <View style={[styles2.tableRow]}>
                    <View style={[styles2.header,styles2.cell
                        , {width:'25%',borderWidth:1,textAlign:'left'}]}>
                        <Text>{title.label1}</Text>
                    </View>
                    <View style={[styles2.cell
                        , {width:'25%',borderWidth:1,textAlign:'left'}]}>
                        <Text>{title.value1}</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'25%',borderWidth:1,textAlign:'right'}]}>
                        <Text>{title.label2}</Text>
                    </View>
                    <View style={[styles2.cell
                        , {width:'25%',borderWidth:1,textAlign:'right'}]}>
                        <Text>{title.value2}</Text>
                    </View>
                </View>
                {details.length>0 &&
                <>
                <View style={[styles2.tableRow,  styles2.header]}>
                    <View style={[styles2.header,styles2.cell
                        , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                        <Text>Method</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'70%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Account</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                        <Text>Total</Text>
                    </View>
                </View>
                {
                details.map((d:any,di:any)=>{
                    return(
                        <View key={di} style={[styles2.tableRow]}>
                            <View style={[styles2.cell
                                , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                                <Text>{d.PaymentMethod}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'70%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{d.cashflow.Name}/{d.cashflow.AccountNumber}/{d.cashflow.AccountName}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text>{d.curr.Symbol} {numberWithCommas(d.Debit)}</Text>
                            </View>
                        </View>
                    )
                })
                }
                </>
                }
                {credits.length>0 &&
                <>
                <View style={[styles2.tableRow,  styles2.header]}>
                    <View style={[styles2.header,styles2.cell
                        , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                        <Text>Method</Text>
                    </View>
                    
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Transaction No</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Cheque / Giro No</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'15%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Due Date</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'15%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Status</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                        <Text>Total</Text>
                    </View>
                </View>
                {
                credits.map((r:any,ri:any)=>{
                    return(
                        <View key={ri} style={[styles2.tableRow]}>
                            <View style={[styles2.cell
                                , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                                <Text>{r.PaymentMethod}</Text>
                            </View>
                            
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{r.TransactionNumber}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{r.ChequeNo}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'15%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{dateFormat(r.WithDrawalDate,'yyyy-mm-dd')}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'15%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{r.Status==='WAIT_PAYMENT'?r.PaymentMethod==='CREDIT'?r.Status:'WAIT_CLEARANCE':r.Status}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text>{r.curr.Symbol} {numberWithCommas(r.Amount)}</Text>
                            </View>
                        </View>
                    )
                })
                }
                </>
                }
            </View>
            
        </>
        )
    }
    const TableSummary = (result:any) => {
        const credits=result["credits"];
        const partner=result["partner"];
        const inventory=result["inventory"];
        const title=result["title"];
        const transaction=result["transaction"];
        const data=result["payment"];
        const details=result["details"];
        return (
            <View style={[styles2.table,{marginTop:-20}]}>
                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>

                    <View style={[styles2.cell
                        , {width:'100%',borderWidth:0,textAlign:'left'}]}>
                            <View style={[styles2.tableRow,styles2.header]}>
                                <View style={[styles2.cell
                                    , {width:'100%',borderTopWidth:1,borderLeftWidth:1,borderRightWidth:1,textAlign:'left'}]}>
                                    <Text>Note : </Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',borderWidth:1,textAlign:'left',height:40}]}>
                                        <Text>{data.Remarks}</Text>
                                    </View>
                            </View>
                            
                    </View>
                    
                </View>
                
            </View>
        )
    }
    const TableFooterPayment = (result:any) => {
        const credits=result["credits"];
        const partner=result["partner"];
        const inventory=result["inventory"];
        const title=result["title"];
        const transaction=result["transaction"];
        const data=result["payment"];
        const details=result["details"];
        return (
            <View style={[styles2.table,{margin:0,fontSize:12}]}>
                <View style={[styles2.tableRow,{alignItems:'flex-start',marginBottom:30}]}>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'center'}]}>
                            <Text>Tanda Terima</Text>
                            
                    </View>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'center'}]}>
                            <Text>Hormat Kami</Text>
                            
                    </View>
                    
                </View>
                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'center'}]}>
                            <Text>(                              )</Text>
                            
                    </View>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'center'}]}>
                            <Text>(                              )</Text>
                            
                    </View>
                    
                </View>
            </View>

        )
    }

    const DocumentPrint = (result:any) =>{
        
        return (
        <Document>
            <Page size="A4" style={styles.page}>
                {TableHeaderPayment(result)}
                {TablePayment(result)}
                {TableSummary(result)}
                {TableFooterPayment(result)}
                
                    <View style={[{textAlign:'right',margin:10,fontWeight:'thin',fontSize:8}]}>
                        <Text>PrintDate: {dateFormat(Date.now(),"yyyy-mm-dd HH:MM:ss")}</Text>
                    </View>
            </Page>
        </Document>
        )
    }
    return (
        
        <>
         {/* <IonButton title={"print payment"} onClick={() => makePdf() }>
              <IonIcon icon={printOutline}></IonIcon>
            </IonButton> */}
<IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/payment/"+match.params.id}></IonBackButton>
          </IonButtons>
          <IonTitle>{"PRINT PAYMENT"}</IonTitle>
          <IonButtons slot="end">
          
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        {/* <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher> */}

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

        {showPrint&&data&&details.length>0&&
        <>
         <PDFDownloadLink document={printdata} fileName="paymentinvoice.pdf">
             {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <IonButton>Download now!</IonButton>)}
         </PDFDownloadLink>
        <PDFViewer width={"100%"} height={"100%"} >
            {printdata}
        </PDFViewer>
        </>
}
      </IonContent>
      
      
    </IonPage>

        </>

    );
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state, OwnProps) => ({
     
    }),
    mapDispatchToProps: {
      
    },
    component: withRouter(PaymentPrint)
  })