import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard, IonDatetime, IonCardHeader, IonCardContent, IonModal, IonSelect, IonSelectOption, IonAlert, IonItemDivider, useIonViewWillEnter } from '@ionic/react';

import { connect } from '../../data/connect';
import './ReceivablePayment.scss';
import API from '../../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { add, arrowBack, cash, eyeOutline, grid, informationCircleOutline, list, pencil, remove, save as saveicon, saveOutline, search, trashBin } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
import Selectfield from '../../components/ActiveForm/Selectfield';
import SearchItemForm from '../Stockopname/SearchItemForm';
import NumberFormat from 'react-number-format';
import SearchPartnerForm from '../Partner/SearchPartnerForm';
import ViewPartnerForm from '../Partner/ViewPartnerForm';
import AddPartnerForm from '../Partner/AddPartnerForm';
import EditPartnerForm from '../Partner/EditPartnerForm';
import DebitSelectForm from '../Cashflow/DebitSelectForm';
import CreditSelectForm from '../Cashflow/CreditSelectForm';
import NumericField from '../../components/ActiveForm/NumericField';
import DateInput from '../../components/ActiveForm/DateInput';

interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
 
};

interface DispatchProps {

}

type ReceivablePaymentProps = OwnProps & StateProps & DispatchProps;

const ReceivablePayment: React.FC<ReceivablePaymentProps> = ({location,match}) => {
  const ionRouterContext = useContext(IonRouterContext);
  var today = new Date();

  const initdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    const apipath = 'receivable';
    var initdata =  {   
      id:0
        ,Name:''
        ,TotalItemPrice:0
        ,TotalItemDiscount:0
        ,TotalItemBonus:0
        ,TotalRECEIVABLE:0
        ,Discount:0
        ,TotalDelivery:0
        ,TotalPayment:0
        ,Active:false
        ,Status:"NEW"
    }
    const [errors,setErrors] = useState(
        []
    );

    const [errordetails,setErrordetails] = useState<any>(
        []
    );

    const [errorcredits,setErrorcredits] = useState<any>(
        []
    );
    const [master,setMaster] = useState<any>();
    const [data,setData] = useState<any>();
    const [details,setDetails] = useState<any>([]);
    const [debits,setDebits] = useState<any>([]);
    const [credits,setCredits] = useState<any>([]);

    const [currencylist,setCurrencylist] = useState<any>([]);
    const [currency,setCurrency] = useState<any>([]);
    const [paymentlist,setPaymentlist] = useState<any>([]);
    const [haspayment,setHaspayment] = useState(false);
    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showSearchItemModal, setShowSearchItemModal] = useState(false);
    const [showSearchParnerModal, setShowSearchPartnerModal] = useState(false);
    const [showViewParnerModal, setShowViewPartnerModal] = useState(false);
    const [showAddParnerModal, setShowAddPartnerModal] = useState(false);
    const [showEditParnerModal, setShowEditPartnerModal] = useState(false);
    const [showDebitModal, setShowDebitModal] = useState(false);
    const [showCreditModal, setShowCreditModal] = useState(false);

    const [myInventory, setMyInventory] = useState<any>([]);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeader, setAlertHeader] = useState("Warning");
    const [alertMessage, setAlertMessage] = useState("Warning");
    const [alertButtons, setAlertButtons] = useState<any>(['Ok']);

    const pageRef = useRef<HTMLElement>(null);

    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
    const handleDebitDetailChange = (e:any,key:any,name:string)=>{
        const { value } = e.detail;
        var change = debits;
        // console.log(name);
        // console.log(change[key]);
        change[key]={...change[key],[name]:value};
        // console.log(change);
        setDebits(change);
       if(name==="Debit"){
        calculateTotalDebit(change);
       }
    }

    const calculateTotalDebit=(itemdetails:any)=>{
        if(itemdetails!==undefined&&itemdetails.length>0){
            const TotalDebit = itemdetails.map((c:any) => c.Debit).reduce((a:any, b:any) => parseFloat(a.toString()) + parseFloat(b.toString()));
            const TotalCredit = credits.length>0?
                                credits.map((c:any) => c.Amount).reduce((a:any, b:any) => parseFloat(a.toString()) + parseFloat(b.toString()))
                                :0;
            const LeftPayment = data.LeftPayment - TotalDebit;
            setData({...data,TotalDebit:TotalDebit,TotalCredit:TotalCredit});
        }
    }

    const handleCreditDetailChange = (e:any,key:any,name:string)=>{
        const { value } = e.detail;
        var change = credits;
        // console.log(name);
        // console.log(change[key]);
        change[key]={...change[key],[name]:value};
        // console.log(change);
        setCredits(change);
       
        if(name==="Amount"){
            calculateTotalCredit(change);
        }
    }

    const calculateTotalCredit=(itemdetails:any)=>{
        if(itemdetails!==undefined&&itemdetails.length>0){
            const TotalDebit = debits.length>0?
                                    debits.map((c:any) => c.Debit).reduce((a:any, b:any) => parseFloat(a.toString()) + parseFloat(b.toString()))
                                    :0;

            const TotalCredit = itemdetails.map((c:any) => c.Amount).reduce((a:any, b:any) => parseFloat(a.toString()) + parseFloat(b.toString()));
            const LeftPayment = data.LeftPayment - TotalDebit;
            setData({...data,TotalDebit:TotalDebit,TotalCredit:TotalCredit});
        }
    }
    
    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
          
            setData({
                ...data,
                [name]: value
            });

    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        if(data[name]!==checked){
            setData({
                ...data,
                [name]: checked
            });
        }
        // setChecked(checked);
    };

    useEffect(() => {
    //   if(match.params.id!=="new"){
          
    //     loaddata();
    //   }
    console.log(location);
      if(location){
        if(location.pathname === "/"+apipath+"/"+match.params.id+"/payment"){
            loaddata();
          }
        }
        // loaddata();
        // eslint-disable-next-line
      }, [location,match.params.id]);
    //   useIonViewWillEnter(async () => {
    //     console.log("useIonViewWillEnter");
    //     if(match.params.id!=="new"){
    //         loaddata();
    //       }
    //   });
    const openSearchPartnerModal=()=>{
    
        
        setShowSearchPartnerModal(true);
           
    
    }

    const openViewPartnerModal=()=>{
    
        if(data.PartnerID){
        
        setShowViewPartnerModal(true);
        }

    }
    const cancelSearchPartnerModal=()=>{
    
        
        setShowSearchPartnerModal(false);
       

    }
    const submitSearchPartnerModal=(partner:any)=>{
    
        setData({...data,CustomerID:partner.id,CustomerName:partner.Name});
        setShowSearchPartnerModal(false);
           
    
    }

    const openDebitModal=()=>{
    
        if(parseFloat(data.TotalPayment.toString().replace(",","")) 
                > 
            (
                parseFloat(data.TotalDebit.toString().replace(",",""))
                +parseFloat(data.TotalCredit.toString().replace(",",""))
                )
            ){
           setShowDebitModal(true);
        }
        else{
            setAlertButtons(['Ok']);
            setAlertHeader("Warning");
            setAlertMessage("Total Invoice < TotalDebit + TotalCredit");
            setShowAlert(true);
        }
        

    }
    const cancelDebitModal=()=>{
    
        
        setShowDebitModal(false);
       

    }
    const submitDebitModal=(debit:any)=>{
    
        var Amount = data.LeftPayment-data.TotalDebit-data.TotalCredit;
        var d:any = {
            CashFlowName:debit.Name
            ,CashFlowAccountID:debit.id
            ,PaymentMethod:debit.CashFlowType==="Cash"?"CASH":"TRANSFER"
            ,InventoryID:data.InventoryID
            ,PartnerID:data.PartnerID
            ,PaymentType:'RECEIVABLE'
            ,TransactionID:data.TransactionID
            ,Debit:Amount
            ,Credit:0
            ,Currency:data.Currency
            ,Status:'PROCESS'
        };
        var datas = debits;
        datas.push(d);
        setDebits(datas);
        calculateTotalDebit(datas);
        setShowDebitModal(false);
           
    
    }

    const openCreditModal=()=>{
    
        if(parseFloat(data.TotalPayment.toString().replace(",","")) 
                > 
            (
                parseFloat(data.TotalDebit.toString().replace(",",""))
                +parseFloat(data.TotalCredit.toString().replace(",",""))
                )
            ){
        setShowCreditModal(true);
        }
        else{
            setAlertButtons(['Ok']);
            setAlertHeader("Warning");
            setAlertMessage("Total Invoice < TotalDebit + TotalCredit");
            setShowAlert(true);
        }

    }
    const cancelCreditModal=()=>{
    
        
            setShowCreditModal(false);
        

    }
    const submitCreditModal=(credit:any)=>{
    
        var Amount = data.LeftPayment-data.TotalDebit-data.TotalCredit;
        var c:any = {
            ...credit
            ,InventoryID:data.InventoryID
            ,PartnerID:data.PartnerID
            ,PaymentType:'RECEIVABLE'
            ,TransactionID:data.TransactionID
            ,Amount:credit.Amount
            ,Currecy:data.Currency
            ,Status:'PROCESS'
        };
        var datas = credits;
        datas.push(c);
        setCredits(datas);
        calculateTotalCredit(datas);

        setShowCreditModal(false);
           
    
    }
    const openAddPartnerModal=()=>{
    
        
        setShowAddPartnerModal(true);
       

    }
    const submitAddPartnerModal=(partner:any)=>{
        setData({...data,CustomerID:partner.id,CustomerName:partner.Name});
        setShowAddPartnerModal(false);
       
    }

    const openEditPartnerModal=()=>{
    
        if(data.CustomerID){
        
        setShowEditPartnerModal(true);
        }

    }
    const cancelEditPartnerModal=()=>{
    
        
        setShowEditPartnerModal(false);
       

    }
    const submitEditPartnerModal=(partner:any)=>{
    
        setData({...data,CustomerID:partner.id,CustomerName:partner.Name});
        setShowEditPartnerModal(false);
           
    
    }
      const openSearchItemModal=()=>{

        if(data.Currency!==undefined){
            setShowSearchItemModal(true);
        }
        else{
            setAlertButtons(['Ok']);
            setAlertHeader("Warning");
            setAlertMessage("Please Input Currency");
            setShowAlert(true);
        }
    
      }
      const cancelSearchItemModal=()=>{
    
        setShowSearchItemModal(false);
    
      }

      
      const loadinventory = async()=>{
        var inventories:any = await API.view("user/myinventory").catch(e=>errorhandler(e));
       
        if(inventories){
            setMyInventory(inventories);
        }
      }
      const loadcurrency = async()=>{
        var currencylist:any = await API.view("currency").catch(e=>errorhandler(e));
       
        if(currencylist){
           await setCurrencylist(currencylist);
        }
      }
    
    const loaddata=async()=>{
        console.log("loaddata");
      setShowLoading(true);
      var result:any = await API.load("receivable",match.params.id).catch(e=>errorhandler(e));
        await loadinventory();
        await loadcurrency();
      if(result){
          setToastMessage("load complete");
         
            maploaddata(result);
          
      }
   
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const maploaddata=(result:any)=>{

        // result["RECEIVABLE"]
        const master = result;

        setMaster(master);
        setData({PartnerName:master["PartnerName"]
        ,TotalPayment:master["Amount"]
        ,LeftPayment:master["LeftPayment"]
        ,TransactionID:master["id"]
        ,InventoryID:master["InventoryID"]
        ,PartnerID:master["PartnerID"]
        ,PaymentType:"RECEIVABLE"
        ,Debit:0
        ,Credit:0
        ,TotalDebit:0
        ,TotalCredit:0
        ,Currency:master["Currency"]
        ,Status:"PROCESS"
        ,PaymentDate:initdate});
        //var result:any = await API.load("RECEIVABLE",match.params.id).catch(e=>errorhandler(e));
        
    }
    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              setErrors(err.response.data["payment"]);
              setErrordetails(err.response.data["debit"]);
              setErrorcredits(err.response.data["credit"]);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }
    const removeitemcredit=(item:any,key:any)=>{
        
        var items = credits.filter((value:any,index:any)=>{
            
            return index.toString()!==key.toString();
        })
        console.log("credits",items);
        setCredits(items);
        calculateTotalCredit(items);
    }
    const removeitemdebit=(item:any,key:any)=>{
        // console.log(item);
        var items = debits.filter((value:any,index:any)=>{
            return index.toString()!==key.toString();
        })

        setDebits(items);
        calculateTotalDebit(items);
    }
    const payment = async ()=>{
        ionRouterContext.push('/'+apipath+"/"+match.params.id+"/payment");
    }
    const delivery = async ()=>{
        ionRouterContext.push('/'+apipath+"/"+match.params.id+"/delivery");
    }
    const back=()=>{
        ionRouterContext.push('/'+apipath+"/"+match.params.id,"root");

    }
    const save = async ()=>{
      
      setShowLoading(true);
      setErrors([]);
      setErrordetails([]);
      setErrorcredits([]);
      let result:any=null;
      result = await API.insert("payment/createpayment/"+match.params.id,{"payment":data,"debit":debits,"credit":credits}).catch(e=>errorhandler(e));

      if(result){
          setToastMessage("save complete");
          setData(result["payment"]);
          setDebits(result["debit"]);
          setCredits(result["credit"]);
          ionRouterContext.push('/'+apipath+"/"+match.params.id,"root");
      }
      setShowCompleteToast(true);
      setShowLoading(false);

    }
    function numberWithCommas(x:any) {
        // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // return x.toLocaleString('en-US', {maximumFractionDigits:2}) 
        if(x!==undefined){
            return x;
            // return new Intl.NumberFormat().format(x.toString().replace(",",""));
            // return x.toLocaleString('en-US', {maximumFractionDigits:2}) 
            // var parts=x.toString().split(".");
            // return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
        }
        else{
            return 0;
        }
    }
    function getDetailErrorMessage(field:string,index:any) {
        if(errordetails!=undefined&&errordetails.length>0){
            console.log("errordetails",errordetails);
            console.log("index",index);

            console.log("errordetails[index]",errordetails[index]);
            if(!Array.isArray(errordetails[index])){
                return ;
            }
            else{
            return (
                <div className="validation-errors"> 
                    {errordetails[index].filter((error:any) => error.field === field).map((filterederror:any) => (
                    <div key={field} className="error-message"> 
                    <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                        {filterederror.message}
                        
                    </div>
                    
                    ))}
                </div>
                
                );
            }
        }
    }

    function getCreditsErrorMessage(field:string,index:any) {
        if(errorcredits!=undefined&&errorcredits.length>0){
            console.log("errordetails",errorcredits);
            console.log("index",index);

            console.log("errorcredits[index]",errorcredits[index]);
            if(!Array.isArray(errorcredits[index])){
                return ;
            }
            else{
            return (
                <div className="validation-errors"> 
                    {errorcredits[index].filter((error:any) => error.field === field).map((filterederror:any) => (
                    <div key={field} className="error-message"> 
                    <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                        {filterederror.message}
                        
                    </div>
                    
                    ))}
                </div>
                
                );
            }
        }
    }
    function getErrorMessage(field:string) {
        if(!Array.isArray(errors)){
            return ;
        }
        else{
        return (
            <div className="validation-errors"> 
                {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
                <div key={field} className="error-message"> 
                <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                    {filterederror.message}
                    
                </div>
                
                ))}
            </div>
            
            );
        }
    }
    const generateOption=()=>{
        var result:any = [];
        // console.log(myInventory);
        myInventory.forEach((od:any) => {
            
            result.push( <IonSelectOption key={"Inventory"+od.InventoryID} value={od.InventoryID}>{od.Name}</IonSelectOption>);

        });
        

        return result;
    }
    function getDebitsempty(){
        return (
            <IonList>
            <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Method"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Account"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Currency"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    
                    
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Amount"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    
                </IonRow>
            </IonList>
        );
    }

    function getDebits(detail:any,key:any){
        // console.log(detail);
        return (
        <IonList key = {"list"+key} >
            <IonRow>
                <IonCol>
                    <IonItem detail={false} >
                        <IonLabel position="floating" color="primary">
                        {"Method"}
                        </IonLabel>
                        
                        <IonInput readonly={true} name={"PaymentMethod"} value={detail["PaymentMethod"]} />   
                          
                    </IonItem>
                    {getDetailErrorMessage("PaymentMethod",key)}
                   
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Account"}
                        </IonLabel>
                        <IonInput readonly={true} name={"CashFlowName"} value={detail["CashFlowName"]} />   

                    </IonItem>
                    {getDetailErrorMessage("CashFlowAccountID",key)}
                   
                </IonCol>
                
              
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Amount"+"("+detail["Currency"]+")"}
                        </IonLabel>
                        <NumericField type={"tel"} readonly={false} name={"Debit"} value={detail["Debit"]} placeholder={"Please Input "+"Amount"}
                         onIonChange={e => handleDebitDetailChange(e,key,"Debit")} />   
                        
                    </IonItem>
                    {getDetailErrorMessage("Debit",key)}

                </IonCol>
                <IonCol>
                    {/* <IonItem> */}
                        <IonButtons>
                            <IonButton color="primary" onClick={() => removeitemdebit(detail,key) }>
                            <IonIcon slot="icon-only" icon={trashBin}></IonIcon>
                            </IonButton>  
                        </IonButtons>
                    {/* </IonItem> */}
                </IonCol>
                
            </IonRow>
            <IonItemDivider color="primary"></IonItemDivider>
        </IonList>
        );
    }

    function getCreditsempty(){
        return (
            <IonList>
            <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Method"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Amount"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"WithDrawalDate"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    
                    
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"ChequeNo/GiroNo"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Remarks"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                   
                </IonRow>
            </IonList>
        );
    }

    function getCredits(detail:any,key:any){
        console.log(detail,key);
        return (
        <IonList key = {"list"+key} >
            <IonRow>
                <IonCol>
                    <IonItem detail={false} >
                        <IonLabel position="floating" color="danger">
                        {"Method"}
                        </IonLabel>
                        
                        <IonInput readonly={true} name={"PaymentMethod"} value={detail["PaymentMethod"]} />   
                          
                    </IonItem>
                    {getCreditsErrorMessage("PaymentMethod",key)}
                   
                </IonCol>
                
                
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="danger">
                        {"Amount"+"("+detail["Currency"]+")"}
                        </IonLabel>
                        <NumericField type={"tel"} readonly={false} name={"Amount"} value={detail["Amount"]} placeholder={"Please Input "+"Amount"} 
                            onIonChange={e => handleCreditDetailChange(e,key,"Amount")} />   
                        
                    </IonItem>
                    {getCreditsErrorMessage("Amount",key)}

                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="danger">
                        {data["PaymentMethod"]==="CREDIT"?"Pay Date":"Withdrawal Date"}

                        </IonLabel>
                <DateInput displayFormat="DD MMM YYYY" placeholder="Select Date" value={detail["WithDrawalDate"]} 
                onIonChange={e=>handleChange(e,"WithDrawalDate")}></DateInput>
                </IonItem>
                    {getCreditsErrorMessage("WithDrawalDate",key)}

                </IonCol>
                <IonCol>
                    <IonItem detail={false} >
                        <IonLabel position="floating" color="danger">
                        {"ChequeNo/GiroNo"}
                        </IonLabel>
                        
                        <IonInput name={"ChequeNo"} value={detail["ChequeNo"]} 
                        onIonChange={e => handleCreditDetailChange(e,key,"ChequeNo")}/>   
                          
                    </IonItem>
                    {getCreditsErrorMessage("ChequeNo",key)}
                   
                </IonCol>
                <IonCol>
                    <IonItem detail={false} >
                        <IonLabel position="floating" color="danger">
                        {"Remarks"}
                        </IonLabel>
                        
                        <IonInput name={"Remarks"} value={detail["Remarks"]} 
                        onIonChange={e => handleCreditDetailChange(e,key,"Remarks")}/>   
                          
                    </IonItem>
                    {getCreditsErrorMessage("Remarks",key)}
                   
                </IonCol>
                <IonCol>
                    {/* <IonItem> */}
                        <IonButtons>
                            <IonButton color="danger" onClick={() => removeitemcredit(detail,key) }>
                            <IonIcon slot="icon-only" icon={trashBin}></IonIcon>
                            </IonButton>  
                        </IonButtons>
                    {/* </IonItem> */}
                </IonCol>
            </IonRow>
            <IonItemDivider color="danger"></IonItemDivider>
        </IonList>
        );
    }
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/"+apipath}></IonBackButton>
            {/* <IonButton title="Back to RECEIVABLE" onClick={() => back() }>
              <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
            </IonButton> */}
          </IonButtons>
          <IonTitle>{"PAYMENT"}</IonTitle>
          <IonButtons slot="end">
          
            <IonButton title="Process Payment" onClick={() => save() }>
              <IonIcon slot="icon-only" icon={saveicon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
         <IonCard className="field-card">
           {data!==undefined&&
           <IonGrid>
               <IonRow>
               <IonCol>
                    <IonItem key = {"PaymentDate"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Payment Date"}
                        </IonLabel>
                        <DateInput displayFormat="DD MMM YYYY" placeholder="Select Date" value={data["PaymentDate"]} onIonChange={e=>handleChange(e,"PaymentDate")}></DateInput>
                    </IonItem>
                    {getErrorMessage("PaymentDate")}
                </IonCol>
                <IonCol>
                    <IonItem key = {"PaymentCode"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Invoice Code"}
                        </IonLabel>
                        <IonInput name={"PaymentCode"} value={data["PaymentCode"]} placeholder={"Please Input "+"Code"} onIonChange={e=>handleChange(e,"PaymentCode")}/>   
                    </IonItem>
                    {getErrorMessage("PaymentCode")}
                </IonCol>
                
            </IonRow>

            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Inventory"}
                        </IonLabel>
                        {/* <IonInput readonly={true} name={"InventoryID"} value={detail["InventoryID"]} />    */}
                        
                        {myInventory.length>0&&
                        <Selectfield disabled name={"InventoryID"} value={data["InventoryID"]} option={{datas:myInventory,label:"Name",value:"id"}} dataChanged={e=>handleChange(e,"InventoryID")}/>
                        }
                        {/* <IonSelect key={"InventoryID"} value={data["InventoryID"]}  placeholder={"Please Select Inventory"} onIonChange={e => handleChange(e,"InventoryID")}>
                            {generateOption()}
                                            
                        </IonSelect> */}
                    </IonItem>
                    {getErrorMessage("InventoryID")}

                </IonCol>
                <IonCol>
                    <IonItem key = {"Status"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Status"}
                        </IonLabel>
                        <IonInput name={"Status"} readonly value={data["Status"]}/>   
                    </IonItem>
                    {getErrorMessage("Status")}
                </IonCol>
            </IonRow>
            <IonRow>
               <IonCol>
                    <IonItem key = {"PartnerID"} detail={false} disabled={false}>
                        <IonLabel position="fixed" color="primary">
                        {"Partner"}
                        </IonLabel>
                        <IonInput disabled name={"PartnerName"} value={data["PartnerName"]} readonly={true}/>   
                        {/* <IonButton color="primary" onClick={() => openSearchPartnerModal()}>
                                <IonIcon icon={search} />
                                
                        </IonButton> */}
                        {data.PartnerID&&
                        <IonButton title="view" color="primary" onClick={() => openViewPartnerModal()}>
                                <IonIcon icon={eyeOutline} />
                                
                            </IonButton>
                            }
                        {data.PartnerID&&
                        <IonButton color="primary" onClick={() => openEditPartnerModal()}>
                                <IonIcon icon={pencil} />
                                
                        </IonButton>
                        }
                        {/* <IonButton color="primary" onClick={() => openAddPartnerModal()}>
                                <IonIcon icon={add} />
                                
                        </IonButton> */}
                    </IonItem>
                    {getErrorMessage("PartnerID")}
                    </IonCol>
               
                </IonRow>
                <IonRow>
                    <IonCol>
                    <IonItem key = {"Currency"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Currency"}
                        </IonLabel>
                        <Selectfield disabled={true} name={"Currency"} value={data["Currency"]} option={{datas:currencylist,label:"Name",value:"Currency"}} dataChanged={e=>handleChange(e,"Currency")}/>

                    </IonItem>
                    {getErrorMessage("Currency")}
                    </IonCol>
                    <IonCol>
                        <IonItem key = {"TotalPayment"} detail={false} disabled={false}>
                            <IonLabel position="floating" color="primary">
                               Total Invoice
                            </IonLabel>
                            <NumericField disabled type={"tel"} readonly={true} name={"TotalPayment"} value={data["TotalPayment"]}  />   
                            
                        </IonItem>
                        {getErrorMessage("TotalPayment")}
                    </IonCol>
                    <IonCol>
                        <IonItem key = {"LeftPayment"} detail={false} disabled={false}>
                            <IonLabel position="floating" color="primary">
                               Left Payment
                            </IonLabel>
                            <NumericField disabled type={"tel"} readonly={true} name={"LeftPayment"} value={data["LeftPayment"]}  />   
                            
                        </IonItem>
                        {getErrorMessage("LeftPayment")}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                        <IonLabel  color="primary">
                        {"Payment Method"}
                        </IonLabel>
                            <IonButton color="primary" onClick={() => openDebitModal()}>
                                <IonIcon icon={add} />
                                Debit
                            </IonButton>
                            {/* <IonButton color="danger" onClick={() => openCreditModal()}>
                                <IonIcon icon={add} />
                                Credit
                            </IonButton> */}
                        </IonItem>
                        {getErrorMessage("PaymentMethod")}
                    </IonCol>
                </IonRow>
                {debits.length>0?
                <IonRow>
                <IonCard >
                    <IonList>
                        <IonRow>
                       
                        <IonCol>
                        <IonItem color="primary">
                        <IonLabel>
                           Debit ({data.Currency})
                        </IonLabel>
                        <NumericField type={"tel"} readonly={true} name={"TotalDebit"} value={data["TotalDebit"]} 
                            />   
                        </IonItem>
                        </IonCol>
                        
                        </IonRow>
                    </IonList>
                    <IonCardContent>
                    {debits.length==0? 
                        getDebitsempty()
                            :
                        Object.keys(debits).map((key:any)=>{
                            // var detail = details[key];
                            // console.log(detail);
                            return getDebits(debits[key],key);
                        })
                    }
                    </IonCardContent>
                </IonCard>
                </IonRow>
                :""}
                {credits.length>0?
                <IonRow>
                <IonCard >
                    <IonList>
                        <IonRow>
                       
                        <IonCol>
                        <IonItem color="danger">
                        <IonLabel>
                           Credit ({data.Currency})
                        </IonLabel>
                        <NumericField type={"tel"} readonly={true} name={"TotalCredit"} value={data["TotalCredit"]} 
                            /> 
                        </IonItem>
                        </IonCol>
                        
                        </IonRow>
                    </IonList>
                    <IonCardContent>
                    {credits.length==0? 
                        getCreditsempty()
                            :
                        Object.keys(credits).map((key:any)=>{
                            // var detail = details[key];
                            // console.log(detail);
                            return getCredits(credits[key],key);
                        })
                    }
                    </IonCardContent>
                </IonCard>
           </IonRow>
          :""}
           <IonList>
            <IonRow>
               <IonCol>
                    <IonItem key = {"Remarks"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Remarks"}
                        </IonLabel>
                        <IonTextarea name={"Remarks"} value={data["Remarks"]} placeholder={"Please Input "+"Remarks"} onIonChange={e=>handleChange(e,"Remarks")}/>   
                    </IonItem>
                    {getErrorMessage("Remarks")}

                </IonCol>
                </IonRow>
           </IonList>
           </IonGrid>
        }
        {/* <Form mode={match.params.id==="new"?"new":"update"}
              data={data}
              errors={errors}
              template={getTemplate(apipath+(match.params.id==="new"?"_create":"_update"))}
              dataChanged={setData}
        /> */}
         </IonCard>
      </IonContent>
      
{data&&
      <IonModal
        isOpen={showDebitModal}
        onDidDismiss={() => setShowDebitModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <DebitSelectForm
          cancel={cancelDebitModal}
          submit={submitDebitModal}
          currency={data.Currency}
        />
      </IonModal>
}
{data&&
      <IonModal
        isOpen={showCreditModal}
        onDidDismiss={() => setShowCreditModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <CreditSelectForm
          cancel={cancelCreditModal}
          submit={submitCreditModal}
          currency={data.Currency}
          amount={data.TotalPayment-data.TotalDebit-data.TotalCredit}
        />
      </IonModal>
}
{data&&
      <IonModal
        isOpen={showViewParnerModal}
        onDidDismiss={() => setShowViewPartnerModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <ViewPartnerForm
          close={() => setShowViewPartnerModal(false)}
          id={data.PartnerID}
          type={"customer"}

        />
      </IonModal>
}
      
{data&&
      <IonModal
        isOpen={showEditParnerModal}
        onDidDismiss={() => setShowEditPartnerModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <EditPartnerForm
          close={() => setShowEditPartnerModal(false)}
          submit={submitEditPartnerModal}
          type={"customer"}
          id={data.PartnerID}
        />
      </IonModal>
}      
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={alertHeader}
          message={alertMessage}
          buttons={alertButtons}
        />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: withRouter(ReceivablePayment)
})