import React, { useEffect, useState } from 'react';

import { getMode } from '@ionic/core';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonListHeader, IonItem, IonLabel, IonCheckbox, IonFooter, IonIcon, IonInput, IonToggle, IonSelectOption, IonSelect, IonDatetime, IonModal } from '@ionic/react';

import './Filter.scss'
import {iconMap} from '../../components/IconMap';
import { connect } from '../../data/connect';
import Selectfield from './Selectfield';
import { eyeOutline, search } from 'ionicons/icons';
import SearchPartnerForm from '../../pages/Partner/SearchPartnerForm';
import ViewPartnerForm from '../../pages/Partner/ViewPartnerForm';
// import { updateFilteredTracks } from '../data/sessions/sessions.actions';
import DateInput from '../../components/ActiveForm/DateInput';

interface OwnProps {
  submit: (filters:any[],sort:any) => void;
  cancel:()=>void;
  reset:()=>void;
  fields?:any
  filters:any[]
  sort:any
}

interface StateProps {

}

interface DispatchProps {
  // updateFilteredTracks: typeof updateFilteredTracks;
}

type SessionListFilterProps = OwnProps & StateProps & DispatchProps;

const Filter: React.FC<SessionListFilterProps> = ({ reset, submit,cancel ,fields,filters,sort}) => {
  
  const [showSearchItemModal, setShowSearchItemModal] = useState(false);
    const [showSearchParnerModal, setShowSearchPartnerModal] = useState(false);
    const [showViewParnerModal, setShowViewPartnerModal] = useState(false);
    const [showAddParnerModal, setShowAddPartnerModal] = useState(false);
    const [showEditParnerModal, setShowEditPartnerModal] = useState(false);
    const [partnerkey,setPartnerkey]=useState<any>();
    const [partnertype,setPartnertype]=useState("partner");

  useEffect(() => {

      //generatefilterfields();

  }, []);

  const openSearchPartnerModal=async(key:any,type:string)=>{
    
    setPartnerkey(key);
    setPartnertype(type);
    setShowSearchPartnerModal(true);
       

}

const openViewPartnerModal=(key:any,type:string)=>{
    setPartnerkey(key);
    setPartnertype(type);
    setShowViewPartnerModal(true);
    
}

const cancelSearchPartnerModal=()=>{

    
    setShowSearchPartnerModal(false);
   

}
const submitSearchPartnerModal=(partner:any)=>{

    // setData({...data,CustomerID:partner.id,CustomerName:partner.Name});
    
    if(fieldfilters){
      setFieldFilters({
          ...fieldfilters,
          [partnerkey]: {...fieldfilters[partnerkey],value:partner.id,label:partner.Name}
      });
    }
    setShowSearchPartnerModal(false);
    // setIschanged(true);

}

  const generatefilterfields=()=>{

    if(filters&&filters.length===0){
      if(fields){
        
        var f:any = [];
        
      Object.keys(fields).map((key, value) => {
              
          if(typeof fields[key]==="string"){
            f[key]={name:key,operand:"like",value:"",type:"string",active:false};
          }
          else if(typeof fields[key]==="boolean"){
            f[key]={name:key,operand:"eq",value:true,type:"boolean",active:false};
          }
          else if(typeof fields[key]==="number"){
            f[key]={name:key,operand:"eq",value:0,type:"number",active:false};
          }
          else{
            f[key]={name:key,operand:"eq",value:"",type:"other",active:false};
          }
        },()=>{
          // console.log("setFieldFilters",f);
          // setFieldFilters(f);
        })

          setFieldFilters(f);
      }
    }
    else{
      setFieldFilters(filters);
    }
  }

  const generateoperandoption=(type:any)=>{
    var result = [];
    switch (type) {
      case "string":
      case "description":
        result.push(<IonSelectOption key={"like"} value="like">Like</IonSelectOption>);
        result.push(<IonSelectOption key={"eq"} value="eq">=</IonSelectOption>);
        result.push(<IonSelectOption key={"neq"} value="neq">!=</IonSelectOption>);
       
        break;
      case "number":
          result.push(<IonSelectOption key={"eq"} value="eq">=</IonSelectOption>);
          result.push(<IonSelectOption key={"neq"} value="neq">!=</IonSelectOption>);
          result.push(<IonSelectOption key={"lt"} value="lt">{'<'}</IonSelectOption>);
          result.push(<IonSelectOption key={"gt"} value="gt">{'>'}</IonSelectOption>);
          result.push(<IonSelectOption key={"lte"} value="lte">{'<='}</IonSelectOption>);
          result.push(<IonSelectOption key={"gte"} value="gte">{'>='}</IonSelectOption>);
          break;
      case "boolean":
          result.push(<IonSelectOption key={"eq"} value="eq">=</IonSelectOption>);
          result.push(<IonSelectOption key={"neq"} value="neq">!=</IonSelectOption>);
          break;
      case "date":
          result.push(<IonSelectOption key={"like"} value="like">Like</IonSelectOption>);
          result.push(<IonSelectOption key={"eq"} value="eq">=</IonSelectOption>);
          result.push(<IonSelectOption key={"neq"} value="neq">!=</IonSelectOption>);
          result.push(<IonSelectOption key={"lt"} value="lt">{'<'}</IonSelectOption>);
          result.push(<IonSelectOption key={"gt"} value="gt">{'>'}</IonSelectOption>);
          result.push(<IonSelectOption key={"lte"} value="lte">{'<='}</IonSelectOption>);
          result.push(<IonSelectOption key={"gte"} value="gte">{'>='}</IonSelectOption>);
          break;
      case "datetime":
          result.push(<IonSelectOption key={"like"} value="like">Like</IonSelectOption>);
          result.push(<IonSelectOption key={"eq"} value="eq">=</IonSelectOption>);
          result.push(<IonSelectOption key={"neq"} value="neq">!=</IonSelectOption>);
          result.push(<IonSelectOption key={"lt"} value="lt">{'<'}</IonSelectOption>);
          result.push(<IonSelectOption key={"gt"} value="gt">{'>'}</IonSelectOption>);
          result.push(<IonSelectOption key={"lte"} value="lte">{'<='}</IonSelectOption>);
          result.push(<IonSelectOption key={"gte"} value="gte">{'>='}</IonSelectOption>);
            break;
      case "time":
          result.push(<IonSelectOption key={"like"} value="like">Like</IonSelectOption>);
          result.push(<IonSelectOption key={"eq"} value="eq">=</IonSelectOption>);
          result.push(<IonSelectOption key={"neq"} value="neq">!=</IonSelectOption>);
          result.push(<IonSelectOption key={"lt"} value="lt">{'<'}</IonSelectOption>);
          result.push(<IonSelectOption key={"gt"} value="gt">{'>'}</IonSelectOption>);
          result.push(<IonSelectOption key={"lte"} value="lte">{'<='}</IonSelectOption>);
          result.push(<IonSelectOption key={"gte"} value="gte">{'>='}</IonSelectOption>);
            break;
      default:
        result.push(<IonSelectOption key={"eq"} value="eq">=</IonSelectOption>);
        result.push(<IonSelectOption key={"neq"} value="neq">!=</IonSelectOption>);
        break;
    }
    return result;
  }
  const generatefields=()=>{
    var result:any = [];

    Object.keys(fieldfilters).map((key, value) => {
       result.push(generatefield(key));
            //   console.log('key',key);
            //   console.log('value',fieldfilters[key]);
              
            //   var f =  generatefield(key);
              

            //   return (f)
            });


      return result;
  }
  const generatefield=(key:any)=>{
    var result = [];
    if(fieldfilters){
      var type = fieldfilters[key].type;
      var name = fieldfilters[key].name;
      var value = fieldfilters[key].value;
      var active = fieldfilters[key].active;
      var option = fieldfilters[key].option;
      switch (type) {
        case "string":
        case "description":
        result.push(
          <IonItem key={key}>
            <IonToggle name={key} checked={fieldfilters[key].active} onIonChange={e=>handleActiveChecked(e,key)}/>

            <IonLabel position="fixed">{name}</IonLabel>
            <IonSelect value={fieldfilters[key].operand}  onIonChange={e => handleOperatorChange(e,key)}>
            {generateoperandoption(type)}
            </IonSelect>
            <IonInput name={key} value={fieldfilters[key].value} 
                  placeholder={"Please Input "+key} disabled={!fieldfilters[key].active}
                  onIonChange={e=>handleChange(e,key)}
            >   
            </IonInput>
          </IonItem>);
         
          break;
        case "number":
          result.push(
            <IonItem key={key}>
              <IonToggle name={key} checked={fieldfilters[key].active} onIonChange={e=>handleActiveChecked(e,key)}/>

              <IonLabel position="fixed">{key}</IonLabel>
              <IonSelect value={fieldfilters[key].operand}  onIonChange={e => handleOperatorChange(e,key)}>
              {generateoperandoption(type)}
              </IonSelect>
              <IonInput type='number' name={key} value={fieldfilters[key].value} 
                    placeholder={"Please Input "+key} disabled={!fieldfilters[key].active}
                    onIonChange={e=>handleChange(e,key)}
              >   
              </IonInput>
            </IonItem>);
            break;
        case "boolean":
            result.push(
              <IonItem key={key}>
                      <IonToggle name={key} checked={fieldfilters[key].active} onIonChange={e=>handleActiveChecked(e,key)}/>

                      <IonLabel position="fixed">{key}</IonLabel>
                      
                      <IonToggle name={key} checked={fieldfilters[key].value} placeholder={"Please Input "+key} 
                      disabled={!fieldfilters[key].active} onIonChange={e=>handleChangeChecked(e,key)} />
                     
                      
                  </IonItem>);

            break;
            case "option":
                        
              result.push(
              <IonItem key = {key}>
                  <IonToggle name={name} checked={active} onIonChange={e=>handleActiveChecked(e,key)}/>

                  <IonLabel position="fixed">{name}</IonLabel>
                  <IonSelect value={fieldfilters[key].operand}  onIonChange={e => handleOperatorChange(e,key)}>
                  {generateoperandoption(type)}
                  </IonSelect>
                  <Selectfield name={name} value={value} option={option} disabled={!active} dataChanged={e=>handleChange(e,key)}/>
                 
              </IonItem>
              );
              break;
          
        case "date":
         
            result.push(
                <IonItem key = {key}>
                    <IonToggle name={name} checked={active} onIonChange={e=>handleActiveChecked(e,key)}/>

                    <IonLabel position="fixed">
                    {name}
                    </IonLabel>
                    <IonSelect value={fieldfilters[key].operand}  onIonChange={e => handleOperatorChange(e,key)}>
                    {generateoperandoption(type)}
                    </IonSelect>
                    <DateInput displayFormat="DD MMM YYYY" placeholder="Select Date" value={value} onIonChange={e=>handleChange(e,key)}></DateInput>
                    </IonItem>
            );
        
            break;
        case "datetime":
              
              break;
        case "time":
              
              break;
        case "partner":
          case "customer":
            case "supplier":
          result.push(
            <IonItem key = {key}>
                      <IonToggle name={name} checked={active} onIonChange={e=>handleActiveChecked(e,key)}/>

                        <IonLabel position="fixed">
                        {name}
                        </IonLabel>
                        <IonSelect value={fieldfilters[key].operand}  onIonChange={e => handleOperatorChange(e,key)}>
                        {generateoperandoption(type)}
                        </IonSelect>
                        <IonInput name={"PartnerName"} value={fieldfilters[key].label} readonly={true}/>   
                        {active&&
                        <IonButton color="primary" onClick={() => openSearchPartnerModal(key,type)}>
                                <IonIcon icon={search} />
                                
                        </IonButton>
                        
                        }
                        {active&&fieldfilters[key].value&&
                        <IonButton color="primary" onClick={() => openViewPartnerModal(key,type)}>
                                <IonIcon icon={eyeOutline} />
                                
                            </IonButton>
                 
                        }
                    
                    </IonItem>
             
          );
      
          break;
        default:
          result.push(
            <IonItem key={key}>
              <IonToggle name={key} checked={fieldfilters[key].active} onIonChange={e=>handleActiveChecked(e,key)}/>

              <IonLabel position="fixed">{key}</IonLabel>
              <IonSelect value={fieldfilters[key].operand} onIonChange={e => handleOperatorChange(e,key)}>
              {generateoperandoption(type)}
              </IonSelect>
              <IonInput name={key} value={fieldfilters[key].value} placeholder={"Please Input "+key}  disabled={!fieldfilters[key].active}
                    onIonChange={e=>handleChange(e,key)}
              >   
              </IonInput>
            </IonItem>);
           
            
          break;
      }
    }
    return result;
  }

  const generateSortFieldoption=()=>{
    var result:any = [];

    Object.keys(fieldfilters).map((key, value) => {
      result.push(<IonSelectOption key={key} value={key}>{key}</IonSelectOption>);
    });
    
    return result;
  }
  const generatesortfields=()=>{
    var result = [];
    if(fieldsort){
     
      if(fieldfilters){ 
        
        result.push(
          <IonItem key={'sort'}>
            <IonToggle name={'sort'} checked={fieldsort.active} onIonChange={e=>handleActiveSortChecked(e)}/>
            <IonSelect value={fieldsort.field} disabled={!fieldsort.active} onIonChange={e => handleSortFieldChange(e)}>
            {generateSortFieldoption()}
            </IonSelect>
            <IonSelect value={fieldsort.direction} disabled={!fieldsort.active} onIonChange={e => handleSortDirectionChange(e)}>
            <IonSelectOption key={"asc"} value={'asc'}>{'asc'}</IonSelectOption>
            <IonSelectOption key={"desc"} value={'desc'}>{'desc'}</IonSelectOption>
            </IonSelect>
            
          </IonItem>);
        }
    }
      return result;
  }
  const ios = getMode() === 'ios';
  const [fieldfilters,setFieldFilters] = useState<any[]>(filters);
  const [fieldsort,setFieldSort] = useState<any>(sort);

  

  const submitfilter = () => {
    submit(fieldfilters,fieldsort);
  };
  const cancelfilter = () => {
    cancel();
  };
  const resetfilter = () => {
    reset();
  };
  const handleChange = (e:any,name:any) => {
    const { value } = e.detail;

    if(fieldfilters){
        setFieldFilters({
            ...fieldfilters,
            [name]: {...fieldfilters[name],value:value}
        });
      }
  };
  const handleOperatorChange = (e:any,name:any) => {
    const { value } = e.detail;

    if(fieldfilters){
        setFieldFilters({
            ...fieldfilters,
            [name]: {...fieldfilters[name],operand:value}
        });
      }
  };

  const handleActiveChecked = (e:any,name:any) => {
    e.preventDefault()
    const { checked } = e.detail;

    if(fieldfilters){
        setFieldFilters({
            ...fieldfilters,
            [name]: {...fieldfilters[name],active:checked}
        });
      }
  };
  const handleChangeChecked = (e:any,name:any) => {
    e.preventDefault()
    const { checked } = e.detail;

    if(fieldfilters){
        setFieldFilters({
            ...fieldfilters,
            [name]: {...fieldfilters[name],value:checked}
        });
      }
  };

  const handleActiveSortChecked = (e:any) => {
    e.preventDefault()
    const { checked } = e.detail;

    if(fieldsort){
        setFieldSort({
            ...fieldsort,
            active:checked
        });
      }
  };

  const handleSortFieldChange = (e:any) => {
    const { value } = e.detail;

    if(fieldsort){
      setFieldSort({
          ...fieldsort,
          field:value
      });
    }
  };

  const handleSortDirectionChange = (e:any) => {
    const { value } = e.detail;

    if(fieldsort){
      setFieldSort({
          ...fieldsort,
          direction:value
      });
    }
  };
  
  // console.log(fieldfilters);
  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="end">
            
              <IonButton onClick={cancelfilter}>Cancel</IonButton>
           
          </IonButtons>

          <IonTitle>
            Filter 
          </IonTitle>

          {/* <IonButtons slot="end">
            <IonButton  strong onClick={submitfilter}>Done</IonButton>
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList lines={ ios ? 'inset' : 'full'}>
          <IonListHeader>Fields</IonListHeader>


          {
            generatefields()
            
            
          }
        </IonList>
        <IonList lines={ ios ? 'inset' : 'full'}>
          <IonListHeader>Sort</IonListHeader>
          {
            generatesortfields()
            
            
          }
        </IonList>

        <IonModal
        isOpen={showSearchParnerModal}
        onDidDismiss={() => setShowSearchPartnerModal(false)}
        swipeToClose={true}
        // presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <SearchPartnerForm
          cancel={cancelSearchPartnerModal}
          submit={submitSearchPartnerModal}
          type={partnertype}
        />
      </IonModal>
{partnerkey&&
      <IonModal
        isOpen={showViewParnerModal}
        onDidDismiss={() => setShowViewPartnerModal(false)}
        swipeToClose={true}
        // presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <ViewPartnerForm
          close={() => setShowViewPartnerModal(false)}
          id={fieldfilters[partnerkey].value}
          type={partnertype}

        />
      </IonModal>
}

      </IonContent>

      <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={resetfilter}>Reset</IonButton>
            </IonButtons>
            <IonButtons slot="end">
                <IonButton onClick={cancelfilter}>Cancel</IonButton>
              <IonButton onClick={submitfilter}>Submit</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  // mapStateToProps: (state) => ({
  //   allTracks: state.data.allTracks,
  //   filteredTracks: state.data.filteredTracks
  // }),
  // mapDispatchToProps: {
  //   updateFilteredTracks
  // },
  component: Filter
})
