import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonLoading, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { connect } from '../../data/connect';
import React, { useEffect,  useState } from 'react';
import { eyeOffOutline, eyeOutline, printOutline } from 'ionicons/icons';
import {PDFDownloadLink,PDFViewer , Page, Text, View, Document, Image, StyleSheet ,Font} from '@react-pdf/renderer';
import {Table,TableHeader,TableCell,TableBody,DataTableCell}from '@david.kucsai/react-pdf-table'
// import * as pdfMake from 'pdfmake/build/pdfmake';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// import pdfMake from 'pdfmake/build/pdfmake';
// import vfsFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
import dateFormat from 'dateformat';
import {
    PDFDocument,
    PDFText,
    PDFTable,
    PDFTableRow,
    PDFTableColumn,
    PDFColumns,
    PDFColumn
  } from 'react-pdfmake';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import API from '../../services';

  interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
  
};

interface DispatchProps {
    
}
type StockopnamePrintProps = OwnProps & StateProps & DispatchProps;
Font.register({
    family: 'Oswald',
    // src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
    src: 'assets/font/Oswald.ttf'
});
const StockopnamePrint: React.FC<StockopnamePrintProps> = ({location,match}) => {
    const [isloading,setIsloading] = useState(false);
    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [data,setData] = useState<any>();
    const [details,setDetails] = useState<any>([]);
    const [inventoryfrom,setInventoryfrom] = useState<any>();
    const [inventoryto,setInventoryto] = useState<any>();
    const [partner,setPartner] = useState<any>();
    const [sale,setSale] = useState<any>();
    const [showPrint, setShowPrint] = useState(false);
    const [printdata,setPrintdata] = useState<any>();
    const [errors,setErrors] = useState(
        []
    );
    useEffect(() => {
        if(location){
            if(location.pathname === "/stockopname/"+match.params.id+"/print"){
           
                
                
                    loaddata();
                
                
            }
        }
       
      }, [location,match.params.id]);

      const loaddata=async()=>{
        setShowPrint(false);
        setShowLoading(true);
        var result:any = await API.load("stockopname/printtransaction",match.params.id).catch(e=>errorhandler(e));
     
        if(result){
            setToastMessage("load complete");
           
            setData(result["stockopname"]);
            if(result["stockopname"].Type ==="MOVE"){
                var details = result["details"].filter((f:any)=>f.Flow==="OUT");
                setDetails(details);
            }
            else{
                setDetails(result["details"]);
            }
            setPartner(result["partner"]);
            setInventoryfrom(result["inventoryfrom"]);
            setInventoryto(result["inventoryto"]);
            setSale(result["sale"]);

            var printdata = await TableFullDelivery(result);
            setPrintdata(printdata);
            
        }
       
        setShowCompleteToast(true);
        setShowLoading(false);
        setShowPrint(true);
      }

      const styles2 = StyleSheet.create({
        em:{
            // fontStyle: 'bold'
        }, 
        table: {
            // width: '100%',
            borderWidth: 0,
            display: 'flex',
            flexDirection: 'column',
            marginVertical: 12,
            fontFamily: 'Oswald',
            fontSize:10,
            marginTop:0,
            marginLeft:10,
            marginRight:10,
            padding: 10
        },
        tableRow:{
            display: 'flex',
            flexDirection: 'row',
        },
        header:{
            fontWeight:'bold',
            backgroundColor:'lightgrey'
        },
        cell: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            flexWrap: 'wrap',
            // height:25,
            padding:2,
        },
       
    }) ;
      const styles = StyleSheet.create({
        page: {
            fontFamily: 'Oswald',
            fontSize:12,
        //   margin:10,
        //   padding: 10,
        },
        header:{
            marginLeft:10,
            marginRight:10,
            marginTop:10,

            padding: 10,
            flexDirection: 'row'
            
        },
        image:{
            height:65,
            width:65

        },
        headerimage:{
            fontFamily: 'Oswald',
            fontSize:10,
            flexGrow: 1
        },
        headerinventory:{
            fontFamily: 'Oswald',
            fontSize:10,
            flexGrow: 1
        },
        headercustomer:{
            // margin:10,
            fontFamily: 'Oswald',
            fontSize:10,
            // textAlign:"right",
            flexGrow: 1
        },
        viewtable:{
            // fontFamily: 'Oswald',
            // fontSize:10,
            marginTop:0,
            marginLeft:10,
            marginRight:10,
            marginBottom:0,
            // padding: 10

            // flexGrow: 1
        },
        tableheadercell:{
            padding:2,
            fontSize:10,
            backgroundColor:"lightgrey"
        },
        tabledatacell:{
            padding:2,
            fontSize:8,
        },
        summary:{
            // marginLeft:10,
            padding: 10,
            fontSize:10,
            flexDirection: 'row'
        },
        summaryleft:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey"

        },
        summaryright:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey"

        },
        ttd:{
            // marginLeft:10,
            padding: 10,
            fontSize:10,
            flexDirection: 'row',
            

        },
        ttdleft:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey",justifyContent:'center'
        },
        ttdright:{
            // marginLeft:10,
            padding:5,
            margin: 5,
            fontSize:10,
            flexGrow: 1,
            backgroundColor:"lightgrey",justifyContent:'center'

        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
          
        }
      });
      
      const errorhandler = (err:any) =>{
        setToastMessage(err.message);
        if(err.response){
            
        }
        else{
            console.log("errorhandler",err.message);
        }
      }

      function numberWithCommas(x:any) {
     
        if(x!==undefined){
           
            return new Intl.NumberFormat().format(x.toString().replace(/,/g, ''));
           
        }
        else{
            return 0;
        }
    }
   

    const TableFullDelivery = (result:any)=>{
        const data =result["stockopname"];
        var details = result["details"];
        if(result["stockopname"].Type ==="MOVE"){
            details = result["details"].filter((f:any)=>f.Flow==="OUT");
            
        }
        
        const partner= result["partner"];
        const inventoryfrom = result["inventoryfrom"];
        const inventoryto = result["inventoryto"];
        const sale = result["sale"];
        return (
            
            <Document>
            <Page size="A4" style={styles.page}>
                {data.Type ==="SALE" &&
                <View style={[styles2.table,{margin:0}]}>
                    <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                        <View style={[styles2.cell
                            , {width:'65%',borderWidth:0,textAlign:'left'}]}>
                                <View style={[styles2.tableRow,{alignItems:'center'}]}>
                                    <View style={[styles2.cell
                                        , {width:'20%',textAlign:'left'}]}>
                                        <Image
                                            style={styles.image}
                                            src="assets/img/appicon.png"
                                        />
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'80%',borderWidth:0,textAlign:'left'}]}>
                                            <View style={[styles2.tableRow]}>
                                                <View style={[styles2.cell
                                                    , {width:'100%',textAlign:'left'}]}>
                                                    <Text style={{fontSize:16}}>{inventoryfrom.Title}</Text>
                                                </View>
                                            </View>
                                            <View style={[styles2.tableRow]}>
                                                <View style={[styles2.cell
                                                    , {width:'100%',textAlign:'left'}]}>
                                                    <Text>{inventoryfrom.Address}</Text>
                                                </View>
                                            </View>
                                    </View>
                                </View>
                                
                        </View>
                        
                        
                        
        
                        <View style={[styles2.cell
                            , {width:'35%',borderWidth:1,textAlign:'right'}]}>
                                <View style={[styles2.tableRow]}>
                                    <View style={[styles2.cell
                                        , {width:'100%',textAlign:'center',fontSize:16}]}>
                                        <Text>{"SURAT JALAN"}</Text>
                                    </View>
                                    
                                </View>
                                <View style={[styles2.tableRow]}>
                                    <View style={[styles2.cell
                                        , {width:'20%',textAlign:'left'}]}>
                                        <Text>{"No"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'2%',textAlign:'left'}]}>
                                        <Text>{":"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'80%',textAlign:'left'}]}>
                                        <Text>{data.TransactionCode}</Text>
                                    </View>
                                </View>
                                <View style={[styles2.tableRow]}>
                                    <View style={[styles2.cell
                                        , {width:'20%',textAlign:'left'}]}>
                                        <Text>{"Sale No"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'2%',textAlign:'left'}]}>
                                        <Text>{":"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'80%',textAlign:'left'}]}>
                                        <Text>{sale.SaleCode}</Text>
                                    </View>
                                </View>
                                <View style={[styles2.tableRow]}>
                                    <View style={[styles2.cell
                                        , {width:'20%',textAlign:'left'}]}>
                                        <Text>{"Tanggal"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'2%',textAlign:'left'}]}>
                                        <Text>{":"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'80%',textAlign:'left'}]}>
                                        <Text>{dateFormat(data.TransactionDate,'dd mmmm yyyy')}</Text>
                                    </View>
                                </View>
                                <View style={[styles2.tableRow]}>
                                    <View style={[styles2.cell
                                        , {width:'20%',textAlign:'left'}]}>
                                        <Text>{"Kepada"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'2%',textAlign:'left'}]}>
                                        <Text>{":"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'80%',textAlign:'left'}]}>
                                        <Text>{partner.Name}</Text>
                                    </View>
                                </View>
                                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                    <View style={[styles2.cell
                                        , {width:'20%',textAlign:'left'}]}>
                                        <Text>{"Alamat"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'2%',textAlign:'left'}]}>
                                        <Text>{":"}</Text>
                                    </View>
                                    <View style={[styles2.cell
                                        , {width:'80%',textAlign:'left'}]}>
                                        <Text>{partner.Address}</Text>
                                    </View>
                                </View>
                        </View>
        
                    </View>
                </View>
                }
                {data.Type ==="MOVE" &&
                <>
                <View style={[styles2.table,{marginBottom:0}]}>

                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                    <View style={[styles2.cell,{alignItems:'flex-start'}
                        , {width:'50%',borderWidth:0}]}>
                        <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                            <View style={[styles2.cell
                                , {width:'15%',textAlign:'left'}]}>
                                <Text>{"No"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'2%',textAlign:'left'}]}>
                                <Text>{":"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'85%',textAlign:'left'}]}>
                                <Text>{data.TransactionCode}</Text>
                            </View>
                        </View>    
                        <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                            <View style={[styles2.cell
                                , {width:'15%',textAlign:'left'}]}>
                                <Text>{"Tanggal"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'2%',textAlign:'left'}]}>
                                <Text>{":"}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'85%',textAlign:'left'}]}>
                                <Text>{dateFormat(data.TransactionDate,'dd mmmm yyyy')}</Text>
                            </View>
                        </View>
                        
                    </View>
                

                    <View style={[styles2.cell,{alignItems:'flex-start'}
                    , {width:'50%',borderWidth:1}]}>
                        <View style={[styles2.tableRow]}>
                                    <View style={[styles2.cell
                                        , {width:'100%',textAlign:'center',fontSize:16}]}>
                                        <Text>{"MOVE STOCK"}</Text>
                                    </View>
                                    
                                </View>
                        
                    </View>
                </View>
            </View>
                <View style={[styles2.table,{marginBottom:0}]}>

                    <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                        <View style={[styles2.cell,{alignItems:'flex-start'}
                            , {width:'50%',borderWidth:1}]}>
                            <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                <View style={[styles2.cell
                                    , {width:'15%',textAlign:'left'}]}>
                                    <Text>{"Dari"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                    <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'85%',textAlign:'left'}]}>
                                    <Text>{inventoryfrom.Name}</Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow,{alignItems:'flex-start',minHeight:90}]}>
                                <View style={[styles2.cell
                                    , {width:'15%',textAlign:'left'}]}>
                                    <Text>{"Alamat"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                    <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'85%',textAlign:'left'}]}>
                                    <Text>{inventoryfrom.Address}</Text>
                                </View>
                            </View>
                        </View>
                    
    
                        <View style={[styles2.cell,{alignItems:'flex-start'}
                        , {width:'50%',borderWidth:1}]}>
                            <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                    <Text>{"ke"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                    <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                    <Text>{inventoryto.Name}</Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow,{alignItems:'flex-start',minHeight:90}]}>
                                <View style={[styles2.cell
                                    , {width:'20%',textAlign:'left'}]}>
                                    <Text>{"Alamat"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'2%',textAlign:'left'}]}>
                                    <Text>{":"}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'80%',textAlign:'left'}]}>
                                    <Text>{inventoryto.Address}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
</>
                }
                
                {data.Type ==="ADD"&&
                 <>
                 <View style={[styles2.table,{marginBottom:0}]}>
 
                 <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                     <View style={[styles2.cell,{alignItems:'flex-start'}
                         , {width:'50%',borderWidth:0}]}>
                         <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                             <View style={[styles2.cell
                                 , {width:'15%',textAlign:'left'}]}>
                                 <Text>{"No"}</Text>
                             </View>
                             <View style={[styles2.cell
                                 , {width:'2%',textAlign:'left'}]}>
                                 <Text>{":"}</Text>
                             </View>
                             <View style={[styles2.cell
                                 , {width:'85%',textAlign:'left'}]}>
                                 <Text>{data.TransactionCode}</Text>
                             </View>
                         </View>    
                         <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                             <View style={[styles2.cell
                                 , {width:'15%',textAlign:'left'}]}>
                                 <Text>{"Tanggal"}</Text>
                             </View>
                             <View style={[styles2.cell
                                 , {width:'2%',textAlign:'left'}]}>
                                 <Text>{":"}</Text>
                             </View>
                             <View style={[styles2.cell
                                 , {width:'85%',textAlign:'left'}]}>
                                 <Text>{dateFormat(data.TransactionDate,'dd mmmm yyyy')}</Text>
                             </View>
                         </View>
                         
                     </View>
                 
 
                     <View style={[styles2.cell,{alignItems:'flex-start'}
                     , {width:'50%',borderWidth:1}]}>
                         <View style={[styles2.tableRow]}>
                                     <View style={[styles2.cell
                                         , {width:'100%',textAlign:'center',fontSize:16}]}>
                                         <Text>{"ADD STOCK"}</Text>
                                     </View>
                                     
                                 </View>
                         
                     </View>
                 </View>
             </View>
                 <View style={[styles2.table,{marginBottom:0}]}>
 
                     <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
     
                         <View style={[styles2.cell,{alignItems:'flex-start'}
                         , {width:'50%',borderWidth:1}]}>
                             <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                 <View style={[styles2.cell
                                     , {width:'20%',textAlign:'left'}]}>
                                     <Text>{"ke"}</Text>
                                 </View>
                                 <View style={[styles2.cell
                                     , {width:'2%',textAlign:'left'}]}>
                                     <Text>{":"}</Text>
                                 </View>
                                 <View style={[styles2.cell
                                     , {width:'80%',textAlign:'left'}]}>
                                     <Text>{inventoryto.Name}</Text>
                                 </View>
                             </View>
                             <View style={[styles2.tableRow,{alignItems:'flex-start',minHeight:90}]}>
                                 <View style={[styles2.cell
                                     , {width:'20%',textAlign:'left'}]}>
                                     <Text>{"Alamat"}</Text>
                                 </View>
                                 <View style={[styles2.cell
                                     , {width:'2%',textAlign:'left'}]}>
                                     <Text>{":"}</Text>
                                 </View>
                                 <View style={[styles2.cell
                                     , {width:'80%',textAlign:'left'}]}>
                                     <Text>{inventoryto.Address}</Text>
                                 </View>
                             </View>
                         </View>
                     </View>
                 </View>
 </>
                }

                {data.Type ==="LOSS"&&
                         <>
                         <View style={[styles2.table,{marginBottom:0}]}>
         
                         <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                             <View style={[styles2.cell,{alignItems:'flex-start'}
                                 , {width:'50%',borderWidth:0}]}>
                                 <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                     <View style={[styles2.cell
                                         , {width:'15%',textAlign:'left'}]}>
                                         <Text>{"No"}</Text>
                                     </View>
                                     <View style={[styles2.cell
                                         , {width:'2%',textAlign:'left'}]}>
                                         <Text>{":"}</Text>
                                     </View>
                                     <View style={[styles2.cell
                                         , {width:'85%',textAlign:'left'}]}>
                                         <Text>{data.TransactionCode}</Text>
                                     </View>
                                 </View>    
                                 <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                     <View style={[styles2.cell
                                         , {width:'15%',textAlign:'left'}]}>
                                         <Text>{"Tanggal"}</Text>
                                     </View>
                                     <View style={[styles2.cell
                                         , {width:'2%',textAlign:'left'}]}>
                                         <Text>{":"}</Text>
                                     </View>
                                     <View style={[styles2.cell
                                         , {width:'85%',textAlign:'left'}]}>
                                         <Text>{dateFormat(data.TransactionDate,'dd mmmm yyyy')}</Text>
                                     </View>
                                 </View>
                                 
                             </View>
                         
         
                             <View style={[styles2.cell,{alignItems:'flex-start'}
                             , {width:'50%',borderWidth:1}]}>
                                 <View style={[styles2.tableRow]}>
                                             <View style={[styles2.cell
                                                 , {width:'100%',textAlign:'center',fontSize:16}]}>
                                                 <Text>{"LOSS STOCK"}</Text>
                                             </View>
                                             
                                         </View>
                                 
                             </View>
                         </View>
                     </View>
                         <View style={[styles2.table,{marginBottom:0}]}>
         
                             <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                 <View style={[styles2.cell,{alignItems:'flex-start'}
                                     , {width:'50%',borderWidth:1}]}>
                                     <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                         <View style={[styles2.cell
                                             , {width:'15%',textAlign:'left'}]}>
                                             <Text>{"Dari"}</Text>
                                         </View>
                                         <View style={[styles2.cell
                                             , {width:'2%',textAlign:'left'}]}>
                                             <Text>{":"}</Text>
                                         </View>
                                         <View style={[styles2.cell
                                             , {width:'85%',textAlign:'left'}]}>
                                             <Text>{inventoryfrom.Name}</Text>
                                         </View>
                                     </View>
                                     <View style={[styles2.tableRow,{alignItems:'flex-start',minHeight:90}]}>
                                         <View style={[styles2.cell
                                             , {width:'15%',textAlign:'left'}]}>
                                             <Text>{"Alamat"}</Text>
                                         </View>
                                         <View style={[styles2.cell
                                             , {width:'2%',textAlign:'left'}]}>
                                             <Text>{":"}</Text>
                                         </View>
                                         <View style={[styles2.cell
                                             , {width:'85%',textAlign:'left'}]}>
                                             <Text>{inventoryfrom.Address}</Text>
                                         </View>
                                     </View>
                                 </View>
                             
             
                                 
                             </View>
                         </View>
         </>
                }

                {data.Type ==="CHANGE"&&
                         <>
                         <View style={[styles2.table,{marginBottom:0}]}>
         
                         <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                             <View style={[styles2.cell,{alignItems:'flex-start'}
                                 , {width:'50%',borderWidth:0}]}>
                                 <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                     <View style={[styles2.cell
                                         , {width:'15%',textAlign:'left'}]}>
                                         <Text>{"No"}</Text>
                                     </View>
                                     <View style={[styles2.cell
                                         , {width:'2%',textAlign:'left'}]}>
                                         <Text>{":"}</Text>
                                     </View>
                                     <View style={[styles2.cell
                                         , {width:'85%',textAlign:'left'}]}>
                                         <Text>{data.TransactionCode}</Text>
                                     </View>
                                 </View>    
                                 <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                                     <View style={[styles2.cell
                                         , {width:'15%',textAlign:'left'}]}>
                                         <Text>{"Tanggal"}</Text>
                                     </View>
                                     <View style={[styles2.cell
                                         , {width:'2%',textAlign:'left'}]}>
                                         <Text>{":"}</Text>
                                     </View>
                                     <View style={[styles2.cell
                                         , {width:'85%',textAlign:'left'}]}>
                                         <Text>{dateFormat(data.TransactionDate,'dd mmmm yyyy')}</Text>
                                     </View>
                                 </View>
                                 
                             </View>
                         
         
                             <View style={[styles2.cell,{alignItems:'flex-start'}
                             , {width:'50%',borderWidth:1}]}>
                                 <View style={[styles2.tableRow]}>
                                             <View style={[styles2.cell
                                                 , {width:'100%',textAlign:'center',fontSize:16}]}>
                                                 <Text>{"CHANGE STOCK"}</Text>
                                             </View>
                                             
                                         </View>
                                 
                             </View>
                         </View>
                     </View>
                         
         </>
                }

        <View style={styles2.table}>
                    
                {details.length>0 &&
                <>
                <View style={[styles2.tableRow,  styles2.header]}>
                {data.Type ==="CHANGE"&&
                <>
                 <View style={[styles2.header,styles2.cell
                        , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                        <Text>IN/OUT</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'10%',borderTopWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Inventory</Text>
                    </View>
                </>
                }
                    <View style={[styles2.header,styles2.cell
                        , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                        <Text>Code</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:data.Type ==="CHANGE"?'50%':'70%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                        <Text>Barang</Text>
                    </View>
                    <View style={[styles2.header,styles2.cell
                        , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                        <Text>Jumlah</Text>
                    </View>
                </View>
                {
                details.map((d:any,di:any)=>{
                    return(
                        <View key={di} style={[styles2.tableRow]}>
                            {data.Type ==="CHANGE"&&
                            <>
                            <View style={[styles2.cell
                                    , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                                    <Text>{d.Flow}</Text>
                                </View>
                                <View style={[styles2.cell
                                    , {width:'10%',borderTopWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                    <Text>{d.inventory.Name}</Text>
                                </View>
                            </>
                            }
                            <View style={[styles2.cell
                                , {width:'10%',borderWidth:1,textAlign:'left'}]}>
                                <Text>{d.item.Code}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:data.Type ==="CHANGE"?'50%':'70%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'left'}]}>
                                <Text>{d.item.Name}</Text>
                            </View>
                            <View style={[styles2.cell
                                , {width:'20%',borderTopWidth:1,borderRightWidth:1,borderBottomWidth:1,textAlign:'right'}]}>
                                <Text> {numberWithCommas(d.Quantity)+" "+d.item.QuantityCode}</Text>
                            </View>
                        </View>
                    )
                })
                }
                </>
                }
        </View>    
                
        <View style={[styles2.table,{marginTop:-20}]}>
                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>

                    <View style={[styles2.cell
                        , {width:'100%',borderWidth:0,textAlign:'left'}]}>
                            <View style={[styles2.tableRow,styles2.header]}>
                                <View style={[styles2.cell
                                    , {width:'100%',borderTopWidth:1,borderLeftWidth:1,borderRightWidth:1,textAlign:'left'}]}>
                                    <Text>Note : </Text>
                                </View>
                            </View>
                            <View style={[styles2.tableRow]}>
                                <View style={[styles2.cell
                                    , {width:'100%',borderWidth:1,textAlign:'left',height:40}]}>
                                        <Text>{data.Remarks}</Text>
                                    </View>
                            </View>
                            
                    </View>
                    
                </View>
                
            </View>
         
            <View style={[styles2.table,{margin:0,fontSize:12}]}>
                <View style={[styles2.tableRow,{alignItems:'flex-start',marginBottom:30}]}>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'center'}]}>
                            <Text>dikirim oleh</Text>
                            
                    </View>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'center'}]}>
                            <Text>diterima oleh</Text>
                            
                    </View>
                    
                </View>
                <View style={[styles2.tableRow,{alignItems:'flex-start'}]}>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'center'}]}>
                            <Text>(                              )</Text>
                            
                    </View>
                    <View style={[styles2.cell
                        , {width:'50%',borderWidth:0,textAlign:'center'}]}>
                            <Text>(                              )</Text>
                            
                    </View>
                    
                </View>
            </View>
        
        </Page>
    </Document>
        )
    }
    
    return (
        
        <>
         {/* <IonButton title={"print stockopname"} onClick={() => makePdf() }>
              <IonIcon icon={printOutline}></IonIcon>
            </IonButton> */}
<IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/stockopname/"+match.params.id}></IonBackButton>
          </IonButtons>
          {data?
          <IonTitle>{"PRINT "+data.Type&&data.Type==="SALE"?"SURAT JALAN":"STOCKOPNAME "+data.Type}</IonTitle>
          :
          <IonTitle>PRINT</IonTitle>
          }
          <IonButtons slot="end">
            
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        {/* <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher> */}

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

        {showPrint&&data&&details.length>0&&
        <>  
        <PDFDownloadLink document={printdata} fileName="stocknota.pdf">
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <IonButton>Download now!</IonButton>)}
    </PDFDownloadLink>
 <PDFViewer width={"100%"} height={"100%"}>
     {printdata}
  </PDFViewer>
  </>
}
      </IonContent>
      
      
    </IonPage>

        </>

    );
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state, OwnProps) => ({
     
    }),
    mapDispatchToProps: {
      
    },
    component: withRouter(StockopnamePrint)
  })