import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal, IonBackButton, IonList, IonItem, IonLabel, IonCheckbox, IonInput, IonCard, IonCardHeader, IonCardContent } from '@ionic/react';

import { connect } from '../../data/connect';
import './DebitSelectForm.scss';
import API from '../../services';
import { add, arrowBack, checkbox, filter, options, search } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';
import MasterDetailList from '../../components/ActiveForm/MasterDetailList';
import NumberFormat from 'react-number-format';

interface OwnProps{
    submit: (items:any[]) => void;
    cancel:()=>void;
    inventorycashflowaccess?:any[];
    currency:any;
 };

interface StateProps {
  mode: 'ios' | 'md';
};

interface DispatchProps {
 };

interface DebitSelectFormProps extends OwnProps, StateProps, DispatchProps { };

const DebitSelectForm: React.FC<DebitSelectFormProps> = ({mode,inventorycashflowaccess,currency,cancel,submit}) => {
    const apipath="cashflow";
    const masterapipath = "cashflow";
  const [isleave,setIsLeave] = useState(false);
  const [masters,setMasters] = useState([]);
  const [datas,setDatas] = useState<any>([]);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);
  const [usefilter,setUseFilter] = useState(false);
  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Description:''
          ,Active:true
      }
  );
  const [buttons,setButtons] = useState<any>(getTemplate(apipath+'_button'));
  const [fieldtemplate,setFieldTemplate] = useState<any>(getTemplate(apipath+"_view"));
  const [masterbuttons,setMasterButtons] = useState<any>(getTemplate(masterapipath+'_button'));
  const [masterfieldtemplate,setMasterFieldTemplate] = useState<any>(getTemplate(masterapipath+"_view"));
  
  const [sort,setSort] = useState<any>(getTemplate(apipath+'_sort'));
  const [filters,setFilters] = useState<any>(getTemplate(apipath+'_filter'));

  const ios = mode === 'ios';
  useEffect(() => {

    
      loaddatas();
    // eslint-disable-next-line
  }, [filters,sort]);

  const submitdata=(data:any)=>{
    // console.log(data);
     submit(data);

  }


  const submitfilter=(pfilters:any[],psort:any)=>{
    setFilters(pfilters);
    setSort(psort);
    setShowFilterModal(false);

  }
  const cancelfilter=()=>{
    
    setShowFilterModal(false);

  }
  const resetfilter=()=>{
    
    setFilters(getTemplate(apipath));
    setSort(getTemplate(apipath+'_sort'));
    setShowFilterModal(false);

  }
  const apifilterParameter=()=>{
    var result:any = {};

    if(filters)
    {
      Object.keys(filters).map((key:any,value:any) => {
        if(filters[key].active && filters[key].value !== ""){
          var param = "filter["+key+"]["+filters[key].operand+"]";
          var pvalue = filters[key].value;
          if(filters[key].type==="boolean"){
            pvalue = pvalue?1:0;
          }
          result[param]=pvalue;
        }
      });
    }

    if(sort){
      if(sort.active){
        var param = "sort";
        var pvalue = sort.field;
        if(sort.direction==="desc"){
          pvalue = "-"+pvalue;
        }
        result[param]=pvalue;
      }
    }

    return result;
  }


const  loaddatas=async()=>{
    setShowLoading(true);
    
    const datas:any = await API.view('user/mycashflow',null).catch(e=>errorhandler(e));
    
    if(datas){
       
        setDatas(datas);
        setToastMessage("load data complete");
    }
    
    
    ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        console.log("errorhandler response",err.response);
        
    }
    else{
        console.log("errorhandler",err.message);
    }
  }
  return (
    <IonPage id={apipath+"-list"}>
      <IonHeader translucent={true}>
        <IonToolbar>

          {!showSearchbar &&
          <IonTitle>{"SELECT DEBIT"}</IonTitle>
          }

          {showSearchbar &&
            <IonSearchbar showCancelButton="always" placeholder="Search"
              onIonCancel={() => setShowSearchbar(false)}
              >

              </IonSearchbar>
          }
          <IonButtons slot="end">
            
            <IonButton onClick={()=>cancel()}>
            {mode === 'ios' ? 'cancel' : <IonIcon icon={arrowBack} slot="icon-only" />}
            </IonButton>
            
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{apipath.toLocaleUpperCase()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddatas}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

            <IonGrid>
            
            <IonList lines={ mode==='ios' ? 'inset' : 'full'}>
          
            {datas.map((key:any, index:any) => (
                // <IonItem key={key.id}>
                
                <IonCard className="field-card">
                    <IonCardHeader>
                        <IonItem  className="field-item">
                        
                        <IonLabel color="primary">
                        {key.Name}
                        </IonLabel>
                        
                            <IonButtons slot="end">
                            <IonButton color="primary" onClick={() => submitdata(key)}>
                                select
                            </IonButton>
                            </IonButtons>
                        </IonItem>
                    </IonCardHeader>

                    <IonCardContent>
                    <IonList lines="none">
                    
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Type"}
                        </IonLabel>
                        <IonInput value={key.CashFlowType} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel color="primary">
                        {"Account Name"}
                        </IonLabel>
                        <IonInput value={key.AccountName} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel color="primary">
                        {"Account Number"}
                        </IonLabel>
                        <IonInput value={key.AccountNumber} readonly/>   
                    </IonItem>
                    </IonList>
                    </IonCardContent>
                </IonCard>

            ))}
            </IonList>
           
          </IonGrid>
         
      </IonContent>
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <Filter
          fields={filterfields}
          filters={filters}
          sort={sort}
          submit={submitfilter}
          cancel={cancelfilter}
          reset={resetfilter}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
  },
  component: React.memo(DebitSelectForm)
});