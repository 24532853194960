import { add
    ,addCircle
    ,addCircleOutline
    ,addCircleSharp
    ,addOutline
    ,addSharp
    ,airplane
    ,airplaneOutline
    ,airplaneSharp
    ,alarm
    ,alarmOutline
    ,alarmSharp
    ,albums
    ,albumsOutline
    ,albumsSharp
    ,alert
    ,alertCircle
    ,alertCircleOutline
    ,alertCircleSharp
    ,alertOutline
    ,alertSharp
    ,americanFootball
    ,americanFootballOutline
    ,americanFootballSharp
    ,analytics
    ,analyticsOutline
    ,analyticsSharp
    ,aperture
    ,apertureOutline
    ,apertureSharp
    ,apps
    ,appsOutline
    ,appsSharp
    ,archive
    ,archiveOutline
    ,archiveSharp
    ,arrowBack
    ,arrowBackCircle
    ,arrowBackCircleOutline
    ,arrowBackCircleSharp
    ,arrowBackOutline
    ,arrowBackSharp
    ,arrowDown
    ,arrowDownCircle
    ,arrowDownCircleOutline
    ,arrowDownCircleSharp
    ,arrowDownOutline
    ,arrowDownSharp
    ,arrowForward
    ,arrowForwardCircle
    ,arrowForwardCircleOutline
    ,arrowForwardCircleSharp
    ,arrowForwardOutline
    ,arrowForwardSharp
    ,arrowRedo
    ,arrowRedoCircle
    ,arrowRedoCircleOutline
    ,arrowRedoCircleSharp
    ,arrowRedoOutline
    ,arrowRedoSharp
    ,arrowUndo
    ,arrowUndoCircle
    ,arrowUndoCircleOutline
    ,arrowUndoCircleSharp
    ,arrowUndoOutline
    ,arrowUndoSharp
    ,arrowUp
    ,arrowUpCircle
    ,arrowUpCircleOutline
    ,arrowUpCircleSharp
    ,arrowUpOutline
    ,arrowUpSharp
    ,at
    ,atCircle
    ,atCircleOutline
    ,atCircleSharp
    ,atOutline
    ,atSharp
    ,attach
    ,attachOutline
    ,attachSharp
    ,backspace
    ,backspaceOutline
    ,backspaceSharp
    ,bandage
    ,bandageOutline
    ,bandageSharp
    ,barChart
    ,barChartOutline
    ,barChartSharp
    ,barbell
    ,barbellOutline
    ,barbellSharp
    ,barcode
    ,barcodeOutline
    ,barcodeSharp
    ,baseball
    ,baseballOutline
    ,baseballSharp
    ,basket
    ,basketOutline
    ,basketSharp
    ,basketball
    ,basketballOutline
    ,basketballSharp
    ,batteryCharging
    ,batteryChargingOutline
    ,batteryChargingSharp
    ,batteryDead
    ,batteryDeadOutline
    ,batteryDeadSharp
    ,batteryFull
    ,batteryFullOutline
    ,batteryFullSharp
    ,batteryHalf
    ,batteryHalfOutline
    ,batteryHalfSharp
    ,beaker
    ,beakerOutline
    ,beakerSharp
    ,bed
    ,bedOutline
    ,bedSharp
    ,beer
    ,beerOutline
    ,beerSharp
    ,bicycle
    ,bicycleOutline
    ,bicycleSharp
    ,bluetooth
    ,bluetoothOutline
    ,bluetoothSharp
    ,boat
    ,boatOutline
    ,boatSharp
    ,body
    ,bodyOutline
    ,bodySharp
    ,bonfire
    ,bonfireOutline
    ,bonfireSharp
    ,book
    ,bookOutline
    ,bookSharp
    ,bookmark
    ,bookmarkOutline
    ,bookmarkSharp
    ,bookmarks
    ,bookmarksOutline
    ,bookmarksSharp
    ,briefcase
    ,briefcaseOutline
    ,briefcaseSharp
    ,browsers
    ,browsersOutline
    ,browsersSharp
    ,brush
    ,brushOutline
    ,brushSharp
    ,bug
    ,bugOutline
    ,bugSharp
    ,build
    ,buildOutline
    ,buildSharp
    ,bulb
    ,bulbOutline
    ,bulbSharp
    ,bus
    ,busOutline
    ,busSharp
    ,business
    ,businessOutline
    ,businessSharp
    ,cafe
    ,cafeOutline
    ,cafeSharp
    ,calculator
    ,calculatorOutline
    ,calculatorSharp
    ,calendar
    ,calendarOutline
    ,calendarSharp
    ,call
    ,callOutline
    ,callSharp
    ,camera
    ,cameraOutline
    ,cameraReverse
    ,cameraReverseOutline
    ,cameraReverseSharp
    ,cameraSharp
    ,car
    ,carOutline
    ,carSharp
    ,carSport
    ,carSportOutline
    ,carSportSharp
    ,card
    ,cardOutline
    ,cardSharp
    ,caretBack
    ,caretBackCircle
    ,caretBackCircleOutline
    ,caretBackCircleSharp
    ,caretBackOutline
    ,caretBackSharp
    ,caretDown
    ,caretDownCircle
    ,caretDownCircleOutline
    ,caretDownCircleSharp
    ,caretDownOutline
    ,caretDownSharp
    ,caretForward
    ,caretForwardCircle
    ,caretForwardCircleOutline
    ,caretForwardCircleSharp
    ,caretForwardOutline
    ,caretForwardSharp
    ,caretUp
    ,caretUpCircle
    ,caretUpCircleOutline
    ,caretUpCircleSharp
    ,caretUpOutline
    ,caretUpSharp
    ,cart
    ,cartOutline
    ,cartSharp
    ,cash
    ,cashOutline
    ,cashSharp
    ,cellular
    ,cellularOutline
    ,cellularSharp
    ,chatbox
    ,chatboxEllipses
    ,chatboxEllipsesOutline
    ,chatboxEllipsesSharp
    ,chatboxOutline
    ,chatboxSharp
    ,chatbubble
    ,chatbubbleEllipses
    ,chatbubbleEllipsesOutline
    ,chatbubbleEllipsesSharp
    ,chatbubbleOutline
    ,chatbubbleSharp
    ,chatbubbles
    ,chatbubblesOutline
    ,chatbubblesSharp
    ,checkbox
    ,checkboxOutline
    ,checkboxSharp
    ,checkmark
    ,checkmarkCircle
    ,checkmarkCircleOutline
    ,checkmarkCircleSharp
    ,checkmarkDone
    ,checkmarkDoneCircle
    ,checkmarkDoneCircleOutline
    ,checkmarkDoneCircleSharp
    ,checkmarkDoneOutline
    ,checkmarkDoneSharp
    ,checkmarkOutline
    ,checkmarkSharp
    ,chevronBack
    ,chevronBackCircle
    ,chevronBackCircleOutline
    ,chevronBackCircleSharp
    ,chevronBackOutline
    ,chevronBackSharp
    ,chevronDown
    ,chevronDownCircle
    ,chevronDownCircleOutline
    ,chevronDownCircleSharp
    ,chevronDownOutline
    ,chevronDownSharp
    ,chevronForward
    ,chevronForwardCircle
    ,chevronForwardCircleOutline
    ,chevronForwardCircleSharp
    ,chevronForwardOutline
    ,chevronForwardSharp
    ,chevronUp
    ,chevronUpCircle
    ,chevronUpCircleOutline
    ,chevronUpCircleSharp
    ,chevronUpOutline
    ,chevronUpSharp
    ,clipboard
    ,clipboardOutline
    ,clipboardSharp
    ,close
    ,closeCircle
    ,closeCircleOutline
    ,closeCircleSharp
    ,closeOutline
    ,closeSharp
    ,cloud
    ,cloudCircle
    ,cloudCircleOutline
    ,cloudCircleSharp
    ,cloudDone
    ,cloudDoneOutline
    ,cloudDoneSharp
    ,cloudDownload
    ,cloudDownloadOutline
    ,cloudDownloadSharp
    ,cloudOffline
    ,cloudOfflineOutline
    ,cloudOfflineSharp
    ,cloudOutline
    ,cloudSharp
    ,cloudUpload
    ,cloudUploadOutline
    ,cloudUploadSharp
    ,cloudy
    ,cloudyNight
    ,cloudyNightOutline
    ,cloudyNightSharp
    ,cloudyOutline
    ,cloudySharp
    ,code
    ,codeDownload
    ,codeDownloadOutline
    ,codeDownloadSharp
    ,codeOutline
    ,codeSharp
    ,codeSlash
    ,codeSlashOutline
    ,codeSlashSharp
    ,codeWorking
    ,codeWorkingOutline
    ,codeWorkingSharp
    ,cog
    ,cogOutline
    ,cogSharp
    ,colorFill
    ,colorFillOutline
    ,colorFillSharp
    ,colorFilter
    ,colorFilterOutline
    ,colorFilterSharp
    ,colorPalette
    ,colorPaletteOutline
    ,colorPaletteSharp
    ,colorWand
    ,colorWandOutline
    ,colorWandSharp
    ,compass
    ,compassOutline
    ,compassSharp
    ,construct
    ,constructOutline
    ,constructSharp
    ,contract
    ,contractOutline
    ,contractSharp
    ,contrast
    ,contrastOutline
    ,contrastSharp
    ,copy
    ,copyOutline
    ,copySharp
    ,create
    ,createOutline
    ,createSharp
    ,crop
    ,cropOutline
    ,cropSharp
    ,cube
    ,cubeOutline
    ,cubeSharp
    ,cut
    ,cutOutline
    ,cutSharp
    ,desktop
    ,desktopOutline
    ,desktopSharp
    ,disc
    ,discOutline
    ,discSharp
    ,document
    ,documentAttach
    ,documentAttachOutline
    ,documentAttachSharp
    ,documentOutline
    ,documentSharp
    ,documentText
    ,documentTextOutline
    ,documentTextSharp
    ,documents
    ,documentsOutline
    ,documentsSharp
    ,download
    ,downloadOutline
    ,downloadSharp
    ,duplicate
    ,duplicateOutline
    ,duplicateSharp
    ,ear
    ,earOutline
    ,earSharp
    ,earth
    ,earthOutline
    ,earthSharp
    ,easel
    ,easelOutline
    ,easelSharp
    ,egg
    ,eggOutline
    ,eggSharp
    ,ellipse
    ,ellipseOutline
    ,ellipseSharp
    ,ellipsisHorizontal
    ,ellipsisHorizontalCircle
    ,ellipsisHorizontalCircleOutline
    ,ellipsisHorizontalCircleSharp
    ,ellipsisHorizontalOutline
    ,ellipsisHorizontalSharp
    ,ellipsisVertical
    ,ellipsisVerticalCircle
    ,ellipsisVerticalCircleOutline
    ,ellipsisVerticalCircleSharp
    ,ellipsisVerticalOutline
    ,ellipsisVerticalSharp
    ,enter
    ,enterOutline
    ,enterSharp
    ,exit
    ,exitOutline
    ,exitSharp
    ,expand
    ,expandOutline
    ,expandSharp
    ,eye
    ,eyeOff
    ,eyeOffOutline
    ,eyeOffSharp
    ,eyeOutline
    ,eyeSharp
    ,eyedrop
    ,eyedropOutline
    ,eyedropSharp
    ,fastFood
    ,fastFoodOutline
    ,fastFoodSharp
    ,female
    ,femaleOutline
    ,femaleSharp
    ,fileTray
    ,fileTrayFull
    ,fileTrayFullOutline
    ,fileTrayFullSharp
    ,fileTrayOutline
    ,fileTraySharp
    ,fileTrayStacked
    ,fileTrayStackedOutline
    ,fileTrayStackedSharp
    ,film
    ,filmOutline
    ,filmSharp
    ,filter
    ,filterOutline
    ,filterSharp
    ,fingerPrint
    ,fingerPrintOutline
    ,fingerPrintSharp
    ,fitness
    ,fitnessOutline
    ,fitnessSharp
    ,flag
    ,flagOutline
    ,flagSharp
    ,flame
    ,flameOutline
    ,flameSharp
    ,flash
    ,flashOff
    ,flashOffOutline
    ,flashOffSharp
    ,flashOutline
    ,flashSharp
    ,flashlight
    ,flashlightOutline
    ,flashlightSharp
    ,flask
    ,flaskOutline
    ,flaskSharp
    ,flower
    ,flowerOutline
    ,flowerSharp
    ,folder
    ,folderOpen
    ,folderOpenOutline
    ,folderOpenSharp
    ,folderOutline
    ,folderSharp
    ,football
    ,footballOutline
    ,footballSharp
    ,funnel
    ,funnelOutline
    ,funnelSharp
    ,gameController
    ,gameControllerOutline
    ,gameControllerSharp
    ,gift
    ,giftOutline
    ,giftSharp
    ,gitBranch
    ,gitBranchOutline
    ,gitBranchSharp
    ,gitCommit
    ,gitCommitOutline
    ,gitCommitSharp
    ,gitCompare
    ,gitCompareOutline
    ,gitCompareSharp
    ,gitMerge
    ,gitMergeOutline
    ,gitMergeSharp
    ,gitNetwork
    ,gitNetworkOutline
    ,gitNetworkSharp
    ,gitPullRequest
    ,gitPullRequestOutline
    ,gitPullRequestSharp
    ,glasses
    ,glassesOutline
    ,glassesSharp
    ,globe
    ,globeOutline
    ,globeSharp
    ,golf
    ,golfOutline
    ,golfSharp
    ,grid
    ,gridOutline
    ,gridSharp
    ,hammer
    ,hammerOutline
    ,hammerSharp
    ,handLeft
    ,handLeftOutline
    ,handLeftSharp
    ,handRight
    ,handRightOutline
    ,handRightSharp
    ,happy
    ,happyOutline
    ,happySharp
    ,hardwareChip
    ,hardwareChipOutline
    ,hardwareChipSharp
    ,headset
    ,headsetOutline
    ,headsetSharp
    ,heart
    ,heartCircle
    ,heartCircleOutline
    ,heartCircleSharp
    ,heartDislike
    ,heartDislikeCircle
    ,heartDislikeCircleOutline
    ,heartDislikeCircleSharp
    ,heartDislikeOutline
    ,heartDislikeSharp
    ,heartHalf
    ,heartHalfOutline
    ,heartHalfSharp
    ,heartOutline
    ,heartSharp
    ,help
    ,helpBuoy
    ,helpBuoyOutline
    ,helpBuoySharp
    ,helpCircle
    ,helpCircleOutline
    ,helpCircleSharp
    ,helpOutline
    ,helpSharp
    ,home
    ,homeOutline
    ,homeSharp
    ,hourglass
    ,hourglassOutline
    ,hourglassSharp
    ,iceCream
    ,iceCreamOutline
    ,iceCreamSharp
    ,image
    ,imageOutline
    ,imageSharp
    ,images
    ,imagesOutline
    ,imagesSharp
    ,infinite
    ,infiniteOutline
    ,infiniteSharp
    ,information
    ,informationCircle
    ,informationCircleOutline
    ,informationCircleSharp
    ,informationOutline
    ,informationSharp
    ,journal
    ,journalOutline
    ,journalSharp
    ,key
    ,keyOutline
    ,keySharp
    ,keypad
    ,keypadOutline
    ,keypadSharp
    ,language
    ,languageOutline
    ,languageSharp
    ,laptop
    ,laptopOutline
    ,laptopSharp
    ,layers
    ,layersOutline
    ,layersSharp
    ,leaf
    ,leafOutline
    ,leafSharp
    ,library
    ,libraryOutline
    ,librarySharp
    ,link
    ,linkOutline
    ,linkSharp
    ,list
    ,listCircle
    ,listCircleOutline
    ,listCircleSharp
    ,listOutline
    ,listSharp
    ,locate
    ,locateOutline
    ,locateSharp
    ,location
    ,locationOutline
    ,locationSharp
    ,lockClosed
    ,lockClosedOutline
    ,lockClosedSharp
    ,lockOpen
    ,lockOpenOutline
    ,lockOpenSharp
    ,logIn
    ,logInOutline
    ,logInSharp
    ,logOut
    ,logOutOutline
    ,logOutSharp
    ,logoAmazon
    ,logoAmplify
    ,logoAndroid
    ,logoAngular
    ,logoApple
    ,logoAppleAppstore
    ,logoBitbucket
    ,logoBitcoin
    ,logoBuffer
    ,logoCapacitor
    ,logoChrome
    ,logoClosedCaptioning
    ,logoCodepen
    ,logoCss3
    ,logoDesignernews
    ,logoDribbble
    ,logoDropbox
    ,logoEdge
    ,logoElectron
    ,logoEuro
    ,logoFacebook
    ,logoFirebase
    ,logoFirefox
    ,logoFlickr
    ,logoFoursquare
    ,logoGithub
    ,logoGoogle
    ,logoGooglePlaystore
    ,logoHackernews
    ,logoHtml5
    ,logoInstagram
    ,logoIonic
    ,logoIonitron
    ,logoJavascript
    ,logoLaravel
    ,logoLinkedin
    ,logoMarkdown
    ,logoNoSmoking
    ,logoNodejs
    ,logoNpm
    ,logoOctocat
    ,logoPinterest
    ,logoPlaystation
    ,logoPwa
    ,logoPython
    ,logoReact
    ,logoReddit
    ,logoRss
    ,logoSass
    ,logoSkype
    ,logoSlack
    ,logoSnapchat
    ,logoStackoverflow
    ,logoSteam
    ,logoStencil
    ,logoTumblr
    ,logoTux
    ,logoTwitch
    ,logoTwitter
    ,logoUsd
    ,logoVimeo
    ,logoVk
    ,logoVue
    ,logoWebComponent
    ,logoWhatsapp
    ,logoWindows
    ,logoWordpress
    ,logoXbox
    ,logoXing
    ,logoYahoo
    ,logoYen
    ,logoYoutube
    ,magnet
    ,magnetOutline
    ,magnetSharp
    ,mail
    ,mailOpen
    ,mailOpenOutline
    ,mailOpenSharp
    ,mailOutline
    ,mailSharp
    ,mailUnread
    ,mailUnreadOutline
    ,mailUnreadSharp
    ,male
    ,maleFemale
    ,maleFemaleOutline
    ,maleFemaleSharp
    ,maleOutline
    ,maleSharp
    ,man
    ,manOutline
    ,manSharp
    ,map
    ,mapOutline
    ,mapSharp
    ,medal
    ,medalOutline
    ,medalSharp
    ,medical
    ,medicalOutline
    ,medicalSharp
    ,medkit
    ,medkitOutline
    ,medkitSharp
    ,megaphone
    ,megaphoneOutline
    ,megaphoneSharp
    ,menu
    ,menuOutline
    ,menuSharp
    ,mic
    ,micCircle
    ,micCircleOutline
    ,micCircleSharp
    ,micOff
    ,micOffCircle
    ,micOffCircleOutline
    ,micOffCircleSharp
    ,micOffOutline
    ,micOffSharp
    ,micOutline
    ,micSharp
    ,moon
    ,moonOutline
    ,moonSharp
    ,move
    ,moveOutline
    ,moveSharp
    ,musicalNote
    ,musicalNoteOutline
    ,musicalNoteSharp
    ,musicalNotes
    ,musicalNotesOutline
    ,musicalNotesSharp
    ,navigate
    ,navigateCircle
    ,navigateCircleOutline
    ,navigateCircleSharp
    ,navigateOutline
    ,navigateSharp
    ,newspaper
    ,newspaperOutline
    ,newspaperSharp
    ,notifications
    ,notificationsCircle
    ,notificationsCircleOutline
    ,notificationsCircleSharp
    ,notificationsOff
    ,notificationsOffCircle
    ,notificationsOffCircleOutline
    ,notificationsOffCircleSharp
    ,notificationsOffOutline
    ,notificationsOffSharp
    ,notificationsOutline
    ,notificationsSharp
    ,nuclear
    ,nuclearOutline
    ,nuclearSharp
    ,nutrition
    ,nutritionOutline
    ,nutritionSharp
    ,open
    ,openOutline
    ,openSharp
    ,options
    ,optionsOutline
    ,optionsSharp
    ,paperPlane
    ,paperPlaneOutline
    ,paperPlaneSharp
    ,partlySunny
    ,partlySunnyOutline
    ,partlySunnySharp
    ,pause
    ,pauseCircle
    ,pauseCircleOutline
    ,pauseCircleSharp
    ,pauseOutline
    ,pauseSharp
    ,paw
    ,pawOutline
    ,pawSharp
    ,pencil
    ,pencilOutline
    ,pencilSharp
    ,people
    ,peopleCircle
    ,peopleCircleOutline
    ,peopleCircleSharp
    ,peopleOutline
    ,peopleSharp
    ,person
    ,personAdd
    ,personAddOutline
    ,personAddSharp
    ,personCircle
    ,personCircleOutline
    ,personCircleSharp
    ,personOutline
    ,personRemove
    ,personRemoveOutline
    ,personRemoveSharp
    ,personSharp
    ,phoneLandscape
    ,phoneLandscapeOutline
    ,phoneLandscapeSharp
    ,phonePortrait
    ,phonePortraitOutline
    ,phonePortraitSharp
    ,pieChart
    ,pieChartOutline
    ,pieChartSharp
    ,pin
    ,pinOutline
    ,pinSharp
    ,pint
    ,pintOutline
    ,pintSharp
    ,pizza
    ,pizzaOutline
    ,pizzaSharp
    ,planet
    ,planetOutline
    ,planetSharp
    ,play
    ,playBack
    ,playBackCircle
    ,playBackCircleOutline
    ,playBackCircleSharp
    ,playBackOutline
    ,playBackSharp
    ,playCircle
    ,playCircleOutline
    ,playCircleSharp
    ,playForward
    ,playForwardCircle
    ,playForwardCircleOutline
    ,playForwardCircleSharp
    ,playForwardOutline
    ,playForwardSharp
    ,playOutline
    ,playSharp
    ,playSkipBack
    ,playSkipBackCircle
    ,playSkipBackCircleOutline
    ,playSkipBackCircleSharp
    ,playSkipBackOutline
    ,playSkipBackSharp
    ,playSkipForward
    ,playSkipForwardCircle
    ,playSkipForwardCircleOutline
    ,playSkipForwardCircleSharp
    ,playSkipForwardOutline
    ,playSkipForwardSharp
    ,podium
    ,podiumOutline
    ,podiumSharp
    ,power
    ,powerOutline
    ,powerSharp
    ,pricetag
    ,pricetagOutline
    ,pricetagSharp
    ,pricetags
    ,pricetagsOutline
    ,pricetagsSharp
    ,print
    ,printOutline
    ,printSharp
    ,pulse
    ,pulseOutline
    ,pulseSharp
    ,push
    ,pushOutline
    ,pushSharp
    ,qrCode
    ,qrCodeOutline
    ,qrCodeSharp
    ,radio
    ,radioButtonOff
    ,radioButtonOffOutline
    ,radioButtonOffSharp
    ,radioButtonOn
    ,radioButtonOnOutline
    ,radioButtonOnSharp
    ,radioOutline
    ,radioSharp
    ,rainy
    ,rainyOutline
    ,rainySharp
    ,reader
    ,readerOutline
    ,readerSharp
    ,receipt
    ,receiptOutline
    ,receiptSharp
    ,recording
    ,recordingOutline
    ,recordingSharp
    ,refresh
    ,refreshCircle
    ,refreshCircleOutline
    ,refreshCircleSharp
    ,refreshOutline
    ,refreshSharp
    ,reload
    ,reloadCircle
    ,reloadCircleOutline
    ,reloadCircleSharp
    ,reloadOutline
    ,reloadSharp
    ,remove
    ,removeCircle
    ,removeCircleOutline
    ,removeCircleSharp
    ,removeOutline
    ,removeSharp
    ,reorderFour
    ,reorderFourOutline
    ,reorderFourSharp
    ,reorderThree
    ,reorderThreeOutline
    ,reorderThreeSharp
    ,reorderTwo
    ,reorderTwoOutline
    ,reorderTwoSharp
    ,repeat
    ,repeatOutline
    ,repeatSharp
    ,resize
    ,resizeOutline
    ,resizeSharp
    ,restaurant
    ,restaurantOutline
    ,restaurantSharp
    ,returnDownBack
    ,returnDownBackOutline
    ,returnDownBackSharp
    ,returnDownForward
    ,returnDownForwardOutline
    ,returnDownForwardSharp
    ,returnUpBack
    ,returnUpBackOutline
    ,returnUpBackSharp
    ,returnUpForward
    ,returnUpForwardOutline
    ,returnUpForwardSharp
    ,ribbon
    ,ribbonOutline
    ,ribbonSharp
    ,rocket
    ,rocketOutline
    ,rocketSharp
    ,rose
    ,roseOutline
    ,roseSharp
    ,sad
    ,sadOutline
    ,sadSharp
    ,save
    ,saveOutline
    ,saveSharp
    ,scan
    ,scanCircle
    ,scanCircleOutline
    ,scanCircleSharp
    ,scanOutline
    ,scanSharp
    ,school
    ,schoolOutline
    ,schoolSharp
    ,search
    ,searchCircle
    ,searchCircleOutline
    ,searchCircleSharp
    ,searchOutline
    ,searchSharp
    ,send
    ,sendOutline
    ,sendSharp
    ,server
    ,serverOutline
    ,serverSharp
    ,settings
    ,settingsOutline
    ,settingsSharp
    ,shapes
    ,shapesOutline
    ,shapesSharp
    ,share
    ,shareOutline
    ,shareSharp
    ,shareSocial
    ,shareSocialOutline
    ,shareSocialSharp
    ,shield
    ,shieldCheckmark
    ,shieldCheckmarkOutline
    ,shieldCheckmarkSharp
    ,shieldOutline
    ,shieldSharp
    ,shirt
    ,shirtOutline
    ,shirtSharp
    ,shuffle
    ,shuffleOutline
    ,shuffleSharp
    ,skull
    ,skullOutline
    ,skullSharp
    ,snow
    ,snowOutline
    ,snowSharp
    ,speedometer
    ,speedometerOutline
    ,speedometerSharp
    ,square
    ,squareOutline
    ,squareSharp
    ,star
    ,starHalf
    ,starHalfOutline
    ,starHalfSharp
    ,starOutline
    ,starSharp
    ,statsChart
    ,statsChartOutline
    ,statsChartSharp
    ,stop
    ,stopCircle
    ,stopCircleOutline
    ,stopCircleSharp
    ,stopOutline
    ,stopSharp
    ,stopwatch
    ,stopwatchOutline
    ,stopwatchSharp
    ,subway
    ,subwayOutline
    ,subwaySharp
    ,sunny
    ,sunnyOutline
    ,sunnySharp
    ,swapHorizontal
    ,swapHorizontalOutline
    ,swapHorizontalSharp
    ,swapVertical
    ,swapVerticalOutline
    ,swapVerticalSharp
    ,sync
    ,syncCircle
    ,syncCircleOutline
    ,syncCircleSharp
    ,syncOutline
    ,syncSharp
    ,tabletLandscape
    ,tabletLandscapeOutline
    ,tabletLandscapeSharp
    ,tabletPortrait
    ,tabletPortraitOutline
    ,tabletPortraitSharp
    ,tennisball
    ,tennisballOutline
    ,tennisballSharp
    ,terminal
    ,terminalOutline
    ,terminalSharp
    ,text
    ,textOutline
    ,textSharp
    ,thermometer
    ,thermometerOutline
    ,thermometerSharp
    ,thumbsDown
    ,thumbsDownOutline
    ,thumbsDownSharp
    ,thumbsUp
    ,thumbsUpOutline
    ,thumbsUpSharp
    ,thunderstorm
    ,thunderstormOutline
    ,thunderstormSharp
    ,time
    ,timeOutline
    ,timeSharp
    ,timer
    ,timerOutline
    ,timerSharp
    ,today
    ,todayOutline
    ,todaySharp
    ,toggle
    ,toggleOutline
    ,toggleSharp
    ,trailSign
    ,trailSignOutline
    ,trailSignSharp
    ,train
    ,trainOutline
    ,trainSharp
    ,transgender
    ,transgenderOutline
    ,transgenderSharp
    ,trash
    ,trashBin
    ,trashBinOutline
    ,trashBinSharp
    ,trashOutline
    ,trashSharp
    ,trendingDown
    ,trendingDownOutline
    ,trendingDownSharp
    ,trendingUp
    ,trendingUpOutline
    ,trendingUpSharp
    ,triangle
    ,triangleOutline
    ,triangleSharp
    ,trophy
    ,trophyOutline
    ,trophySharp
    ,tv
    ,tvOutline
    ,tvSharp
    ,umbrella
    ,umbrellaOutline
    ,umbrellaSharp
    ,videocam
    ,videocamOutline
    ,videocamSharp
    ,volumeHigh
    ,volumeHighOutline
    ,volumeHighSharp
    ,volumeLow
    ,volumeLowOutline
    ,volumeLowSharp
    ,volumeMedium
    ,volumeMediumOutline
    ,volumeMediumSharp
    ,volumeMute
    ,volumeMuteOutline
    ,volumeMuteSharp
    ,volumeOff
    ,volumeOffOutline
    ,volumeOffSharp
    ,walk
    ,walkOutline
    ,walkSharp
    ,wallet
    ,walletOutline
    ,walletSharp
    ,warning
    ,warningOutline
    ,warningSharp
    ,watch
    ,watchOutline
    ,watchSharp
    ,water
    ,waterOutline
    ,waterSharp
    ,wifi
    ,wifiOutline
    ,wifiSharp
    ,wine
    ,wineOutline
    ,wineSharp
    ,woman
    ,womanOutline
    ,womanSharp } from 'ionicons/icons';
import React from 'react';


export  const iconMap: { [key: string]: any } = {
    'add':add,
    'addCircle':addCircle,
    'addCircleOutline':addCircleOutline,
    'addCircleSharp':addCircleSharp,
    'addOutline':addOutline,
    'addSharp':addSharp,
    'airplane':airplane,
    'airplaneOutline':airplaneOutline,
    'airplaneSharp':airplaneSharp,
    'alarm':alarm,
    'alarmOutline':alarmOutline,
    'alarmSharp':alarmSharp,
    'albums':albums,
    'albumsOutline':albumsOutline,
    'albumsSharp':albumsSharp,
    'alert':alert,
    'alertCircle':alertCircle,
    'alertCircleOutline':alertCircleOutline,
    'alertCircleSharp':alertCircleSharp,
    'alertOutline':alertOutline,
    'alertSharp':alertSharp,
    'americanFootball':americanFootball,
    'americanFootballOutline':americanFootballOutline,
    'americanFootballSharp':americanFootballSharp,
    'analytics':analytics,
    'analyticsOutline':analyticsOutline,
    'analyticsSharp':analyticsSharp,
    'aperture':aperture,
    'apertureOutline':apertureOutline,
    'apertureSharp':apertureSharp,
    'apps':apps,
    'appsOutline':appsOutline,
    'appsSharp':appsSharp,
    'archive':archive,
    'archiveOutline':archiveOutline,
    'archiveSharp':archiveSharp,
    'arrowBack':arrowBack,
    'arrowBackCircle':arrowBackCircle,
    'arrowBackCircleOutline':arrowBackCircleOutline,
    'arrowBackCircleSharp':arrowBackCircleSharp,
    'arrowBackOutline':arrowBackOutline,
    'arrowBackSharp':arrowBackSharp,
    'arrowDown':arrowDown,
    'arrowDownCircle':arrowDownCircle,
    'arrowDownCircleOutline':arrowDownCircleOutline,
    'arrowDownCircleSharp':arrowDownCircleSharp,
    'arrowDownOutline':arrowDownOutline,
    'arrowDownSharp':arrowDownSharp,
    'arrowForward':arrowForward,
    'arrowForwardCircle':arrowForwardCircle,
    'arrowForwardCircleOutline':arrowForwardCircleOutline,
    'arrowForwardCircleSharp':arrowForwardCircleSharp,
    'arrowForwardOutline':arrowForwardOutline,
    'arrowForwardSharp':arrowForwardSharp,
    'arrowRedo':arrowRedo,
    'arrowRedoCircle':arrowRedoCircle,
    'arrowRedoCircleOutline':arrowRedoCircleOutline,
    'arrowRedoCircleSharp':arrowRedoCircleSharp,
    'arrowRedoOutline':arrowRedoOutline,
    'arrowRedoSharp':arrowRedoSharp,
    'arrowUndo':arrowUndo,
    'arrowUndoCircle':arrowUndoCircle,
    'arrowUndoCircleOutline':arrowUndoCircleOutline,
    'arrowUndoCircleSharp':arrowUndoCircleSharp,
    'arrowUndoOutline':arrowUndoOutline,
    'arrowUndoSharp':arrowUndoSharp,
    'arrowUp':arrowUp,
    'arrowUpCircle':arrowUpCircle,
    'arrowUpCircleOutline':arrowUpCircleOutline,
    'arrowUpCircleSharp':arrowUpCircleSharp,
    'arrowUpOutline':arrowUpOutline,
    'arrowUpSharp':arrowUpSharp,
    'at':at,
    'atCircle':atCircle,
    'atCircleOutline':atCircleOutline,
    'atCircleSharp':atCircleSharp,
    'atOutline':atOutline,
    'atSharp':atSharp,
    'attach':attach,
    'attachOutline':attachOutline,
    'attachSharp':attachSharp,
    'backspace':backspace,
    'backspaceOutline':backspaceOutline,
    'backspaceSharp':backspaceSharp,
    'bandage':bandage,
    'bandageOutline':bandageOutline,
    'bandageSharp':bandageSharp,
    'barChart':barChart,
    'barChartOutline':barChartOutline,
    'barChartSharp':barChartSharp,
    'barbell':barbell,
    'barbellOutline':barbellOutline,
    'barbellSharp':barbellSharp,
    'barcode':barcode,
    'barcodeOutline':barcodeOutline,
    'barcodeSharp':barcodeSharp,
    'baseball':baseball,
    'baseballOutline':baseballOutline,
    'baseballSharp':baseballSharp,
    'basket':basket,
    'basketOutline':basketOutline,
    'basketSharp':basketSharp,
    'basketball':basketball,
    'basketballOutline':basketballOutline,
    'basketballSharp':basketballSharp,
    'batteryCharging':batteryCharging,
    'batteryChargingOutline':batteryChargingOutline,
    'batteryChargingSharp':batteryChargingSharp,
    'batteryDead':batteryDead,
    'batteryDeadOutline':batteryDeadOutline,
    'batteryDeadSharp':batteryDeadSharp,
    'batteryFull':batteryFull,
    'batteryFullOutline':batteryFullOutline,
    'batteryFullSharp':batteryFullSharp,
    'batteryHalf':batteryHalf,
    'batteryHalfOutline':batteryHalfOutline,
    'batteryHalfSharp':batteryHalfSharp,
    'beaker':beaker,
    'beakerOutline':beakerOutline,
    'beakerSharp':beakerSharp,
    'bed':bed,
    'bedOutline':bedOutline,
    'bedSharp':bedSharp,
    'beer':beer,
    'beerOutline':beerOutline,
    'beerSharp':beerSharp,
    'bicycle':bicycle,
    'bicycleOutline':bicycleOutline,
    'bicycleSharp':bicycleSharp,
    'bluetooth':bluetooth,
    'bluetoothOutline':bluetoothOutline,
    'bluetoothSharp':bluetoothSharp,
    'boat':boat,
    'boatOutline':boatOutline,
    'boatSharp':boatSharp,
    'body':body,
    'bodyOutline':bodyOutline,
    'bodySharp':bodySharp,
    'bonfire':bonfire,
    'bonfireOutline':bonfireOutline,
    'bonfireSharp':bonfireSharp,
    'book':book,
    'bookOutline':bookOutline,
    'bookSharp':bookSharp,
    'bookmark':bookmark,
    'bookmarkOutline':bookmarkOutline,
    'bookmarkSharp':bookmarkSharp,
    'bookmarks':bookmarks,
    'bookmarksOutline':bookmarksOutline,
    'bookmarksSharp':bookmarksSharp,
    'briefcase':briefcase,
    'briefcaseOutline':briefcaseOutline,
    'briefcaseSharp':briefcaseSharp,
    'browsers':browsers,
    'browsersOutline':browsersOutline,
    'browsersSharp':browsersSharp,
    'brush':brush,
    'brushOutline':brushOutline,
    'brushSharp':brushSharp,
    'bug':bug,
    'bugOutline':bugOutline,
    'bugSharp':bugSharp,
    'build':build,
    'buildOutline':buildOutline,
    'buildSharp':buildSharp,
    'bulb':bulb,
    'bulbOutline':bulbOutline,
    'bulbSharp':bulbSharp,
    'bus':bus,
    'busOutline':busOutline,
    'busSharp':busSharp,
    'business':business,
    'businessOutline':businessOutline,
    'businessSharp':businessSharp,
    'cafe':cafe,
    'cafeOutline':cafeOutline,
    'cafeSharp':cafeSharp,
    'calculator':calculator,
    'calculatorOutline':calculatorOutline,
    'calculatorSharp':calculatorSharp,
    'calendar':calendar,
    'calendarOutline':calendarOutline,
    'calendarSharp':calendarSharp,
    'call':call,
    'callOutline':callOutline,
    'callSharp':callSharp,
    'camera':camera,
    'cameraOutline':cameraOutline,
    'cameraReverse':cameraReverse,
    'cameraReverseOutline':cameraReverseOutline,
    'cameraReverseSharp':cameraReverseSharp,
    'cameraSharp':cameraSharp,
    'car':car,
    'carOutline':carOutline,
    'carSharp':carSharp,
    'carSport':carSport,
    'carSportOutline':carSportOutline,
    'carSportSharp':carSportSharp,
    'card':card,
    'cardOutline':cardOutline,
    'cardSharp':cardSharp,
    'caretBack':caretBack,
    'caretBackCircle':caretBackCircle,
    'caretBackCircleOutline':caretBackCircleOutline,
    'caretBackCircleSharp':caretBackCircleSharp,
    'caretBackOutline':caretBackOutline,
    'caretBackSharp':caretBackSharp,
    'caretDown':caretDown,
    'caretDownCircle':caretDownCircle,
    'caretDownCircleOutline':caretDownCircleOutline,
    'caretDownCircleSharp':caretDownCircleSharp,
    'caretDownOutline':caretDownOutline,
    'caretDownSharp':caretDownSharp,
    'caretForward':caretForward,
    'caretForwardCircle':caretForwardCircle,
    'caretForwardCircleOutline':caretForwardCircleOutline,
    'caretForwardCircleSharp':caretForwardCircleSharp,
    'caretForwardOutline':caretForwardOutline,
    'caretForwardSharp':caretForwardSharp,
    'caretUp':caretUp,
    'caretUpCircle':caretUpCircle,
    'caretUpCircleOutline':caretUpCircleOutline,
    'caretUpCircleSharp':caretUpCircleSharp,
    'caretUpOutline':caretUpOutline,
    'caretUpSharp':caretUpSharp,
    'cart':cart,
    'cartOutline':cartOutline,
    'cartSharp':cartSharp,
    'cash':cash,
    'cashOutline':cashOutline,
    'cashSharp':cashSharp,
    'cellular':cellular,
    'cellularOutline':cellularOutline,
    'cellularSharp':cellularSharp,
    'chatbox':chatbox,
    'chatboxEllipses':chatboxEllipses,
    'chatboxEllipsesOutline':chatboxEllipsesOutline,
    'chatboxEllipsesSharp':chatboxEllipsesSharp,
    'chatboxOutline':chatboxOutline,
    'chatboxSharp':chatboxSharp,
    'chatbubble':chatbubble,
    'chatbubbleEllipses':chatbubbleEllipses,
    'chatbubbleEllipsesOutline':chatbubbleEllipsesOutline,
    'chatbubbleEllipsesSharp':chatbubbleEllipsesSharp,
    'chatbubbleOutline':chatbubbleOutline,
    'chatbubbleSharp':chatbubbleSharp,
    'chatbubbles':chatbubbles,
    'chatbubblesOutline':chatbubblesOutline,
    'chatbubblesSharp':chatbubblesSharp,
    'checkbox':checkbox,
    'checkboxOutline':checkboxOutline,
    'checkboxSharp':checkboxSharp,
    'checkmark':checkmark,
    'checkmarkCircle':checkmarkCircle,
    'checkmarkCircleOutline':checkmarkCircleOutline,
    'checkmarkCircleSharp':checkmarkCircleSharp,
    'checkmarkDone':checkmarkDone,
    'checkmarkDoneCircle':checkmarkDoneCircle,
    'checkmarkDoneCircleOutline':checkmarkDoneCircleOutline,
    'checkmarkDoneCircleSharp':checkmarkDoneCircleSharp,
    'checkmarkDoneOutline':checkmarkDoneOutline,
    'checkmarkDoneSharp':checkmarkDoneSharp,
    'checkmarkOutline':checkmarkOutline,
    'checkmarkSharp':checkmarkSharp,
    'chevronBack':chevronBack,
    'chevronBackCircle':chevronBackCircle,
    'chevronBackCircleOutline':chevronBackCircleOutline,
    'chevronBackCircleSharp':chevronBackCircleSharp,
    'chevronBackOutline':chevronBackOutline,
    'chevronBackSharp':chevronBackSharp,
    'chevronDown':chevronDown,
    'chevronDownCircle':chevronDownCircle,
    'chevronDownCircleOutline':chevronDownCircleOutline,
    'chevronDownCircleSharp':chevronDownCircleSharp,
    'chevronDownOutline':chevronDownOutline,
    'chevronDownSharp':chevronDownSharp,
    'chevronForward':chevronForward,
    'chevronForwardCircle':chevronForwardCircle,
    'chevronForwardCircleOutline':chevronForwardCircleOutline,
    'chevronForwardCircleSharp':chevronForwardCircleSharp,
    'chevronForwardOutline':chevronForwardOutline,
    'chevronForwardSharp':chevronForwardSharp,
    'chevronUp':chevronUp,
    'chevronUpCircle':chevronUpCircle,
    'chevronUpCircleOutline':chevronUpCircleOutline,
    'chevronUpCircleSharp':chevronUpCircleSharp,
    'chevronUpOutline':chevronUpOutline,
    'chevronUpSharp':chevronUpSharp,
    'clipboard':clipboard,
    'clipboardOutline':clipboardOutline,
    'clipboardSharp':clipboardSharp,
    'close':close,
    'closeCircle':closeCircle,
    'closeCircleOutline':closeCircleOutline,
    'closeCircleSharp':closeCircleSharp,
    'closeOutline':closeOutline,
    'closeSharp':closeSharp,
    'cloud':cloud,
    'cloudCircle':cloudCircle,
    'cloudCircleOutline':cloudCircleOutline,
    'cloudCircleSharp':cloudCircleSharp,
    'cloudDone':cloudDone,
    'cloudDoneOutline':cloudDoneOutline,
    'cloudDoneSharp':cloudDoneSharp,
    'cloudDownload':cloudDownload,
    'cloudDownloadOutline':cloudDownloadOutline,
    'cloudDownloadSharp':cloudDownloadSharp,
    'cloudOffline':cloudOffline,
    'cloudOfflineOutline':cloudOfflineOutline,
    'cloudOfflineSharp':cloudOfflineSharp,
    'cloudOutline':cloudOutline,
    'cloudSharp':cloudSharp,
    'cloudUpload':cloudUpload,
    'cloudUploadOutline':cloudUploadOutline,
    'cloudUploadSharp':cloudUploadSharp,
    'cloudy':cloudy,
    'cloudyNight':cloudyNight,
    'cloudyNightOutline':cloudyNightOutline,
    'cloudyNightSharp':cloudyNightSharp,
    'cloudyOutline':cloudyOutline,
    'cloudySharp':cloudySharp,
    'code':code,
    'codeDownload':codeDownload,
    'codeDownloadOutline':codeDownloadOutline,
    'codeDownloadSharp':codeDownloadSharp,
    'codeOutline':codeOutline,
    'codeSharp':codeSharp,
    'codeSlash':codeSlash,
    'codeSlashOutline':codeSlashOutline,
    'codeSlashSharp':codeSlashSharp,
    'codeWorking':codeWorking,
    'codeWorkingOutline':codeWorkingOutline,
    'codeWorkingSharp':codeWorkingSharp,
    'cog':cog,
    'cogOutline':cogOutline,
    'cogSharp':cogSharp,
    'colorFill':colorFill,
    'colorFillOutline':colorFillOutline,
    'colorFillSharp':colorFillSharp,
    'colorFilter':colorFilter,
    'colorFilterOutline':colorFilterOutline,
    'colorFilterSharp':colorFilterSharp,
    'colorPalette':colorPalette,
    'colorPaletteOutline':colorPaletteOutline,
    'colorPaletteSharp':colorPaletteSharp,
    'colorWand':colorWand,
    'colorWandOutline':colorWandOutline,
    'colorWandSharp':colorWandSharp,
    'compass':compass,
    'compassOutline':compassOutline,
    'compassSharp':compassSharp,
    'construct':construct,
    'constructOutline':constructOutline,
    'constructSharp':constructSharp,
    'contract':contract,
    'contractOutline':contractOutline,
    'contractSharp':contractSharp,
    'contrast':contrast,
    'contrastOutline':contrastOutline,
    'contrastSharp':contrastSharp,
    'copy':copy,
    'copyOutline':copyOutline,
    'copySharp':copySharp,
    'create':create,
    'createOutline':createOutline,
    'createSharp':createSharp,
    'crop':crop,
    'cropOutline':cropOutline,
    'cropSharp':cropSharp,
    'cube':cube,
    'cubeOutline':cubeOutline,
    'cubeSharp':cubeSharp,
    'cut':cut,
    'cutOutline':cutOutline,
    'cutSharp':cutSharp,
    'desktop':desktop,
    'desktopOutline':desktopOutline,
    'desktopSharp':desktopSharp,
    'disc':disc,
    'discOutline':discOutline,
    'discSharp':discSharp,
    'document':document,
    'documentAttach':documentAttach,
    'documentAttachOutline':documentAttachOutline,
    'documentAttachSharp':documentAttachSharp,
    'documentOutline':documentOutline,
    'documentSharp':documentSharp,
    'documentText':documentText,
    'documentTextOutline':documentTextOutline,
    'documentTextSharp':documentTextSharp,
    'documents':documents,
    'documentsOutline':documentsOutline,
    'documentsSharp':documentsSharp,
    'download':download,
    'downloadOutline':downloadOutline,
    'downloadSharp':downloadSharp,
    'duplicate':duplicate,
    'duplicateOutline':duplicateOutline,
    'duplicateSharp':duplicateSharp,
    'ear':ear,
    'earOutline':earOutline,
    'earSharp':earSharp,
    'earth':earth,
    'earthOutline':earthOutline,
    'earthSharp':earthSharp,
    'easel':easel,
    'easelOutline':easelOutline,
    'easelSharp':easelSharp,
    'egg':egg,
    'eggOutline':eggOutline,
    'eggSharp':eggSharp,
    'ellipse':ellipse,
    'ellipseOutline':ellipseOutline,
    'ellipseSharp':ellipseSharp,
    'ellipsisHorizontal':ellipsisHorizontal,
    'ellipsisHorizontalCircle':ellipsisHorizontalCircle,
    'ellipsisHorizontalCircleOutline':ellipsisHorizontalCircleOutline,
    'ellipsisHorizontalCircleSharp':ellipsisHorizontalCircleSharp,
    'ellipsisHorizontalOutline':ellipsisHorizontalOutline,
    'ellipsisHorizontalSharp':ellipsisHorizontalSharp,
    'ellipsisVertical':ellipsisVertical,
    'ellipsisVerticalCircle':ellipsisVerticalCircle,
    'ellipsisVerticalCircleOutline':ellipsisVerticalCircleOutline,
    'ellipsisVerticalCircleSharp':ellipsisVerticalCircleSharp,
    'ellipsisVerticalOutline':ellipsisVerticalOutline,
    'ellipsisVerticalSharp':ellipsisVerticalSharp,
    'enter':enter,
    'enterOutline':enterOutline,
    'enterSharp':enterSharp,
    'exit':exit,
    'exitOutline':exitOutline,
    'exitSharp':exitSharp,
    'expand':expand,
    'expandOutline':expandOutline,
    'expandSharp':expandSharp,
    'eye':eye,
    'eyeOff':eyeOff,
    'eyeOffOutline':eyeOffOutline,
    'eyeOffSharp':eyeOffSharp,
    'eyeOutline':eyeOutline,
    'eyeSharp':eyeSharp,
    'eyedrop':eyedrop,
    'eyedropOutline':eyedropOutline,
    'eyedropSharp':eyedropSharp,
    'fastFood':fastFood,
    'fastFoodOutline':fastFoodOutline,
    'fastFoodSharp':fastFoodSharp,
    'female':female,
    'femaleOutline':femaleOutline,
    'femaleSharp':femaleSharp,
    'fileTray':fileTray,
    'fileTrayFull':fileTrayFull,
    'fileTrayFullOutline':fileTrayFullOutline,
    'fileTrayFullSharp':fileTrayFullSharp,
    'fileTrayOutline':fileTrayOutline,
    'fileTraySharp':fileTraySharp,
    'fileTrayStacked':fileTrayStacked,
    'fileTrayStackedOutline':fileTrayStackedOutline,
    'fileTrayStackedSharp':fileTrayStackedSharp,
    'film':film,
    'filmOutline':filmOutline,
    'filmSharp':filmSharp,
    'filter':filter,
    'filterOutline':filterOutline,
    'filterSharp':filterSharp,
    'fingerPrint':fingerPrint,
    'fingerPrintOutline':fingerPrintOutline,
    'fingerPrintSharp':fingerPrintSharp,
    'fitness':fitness,
    'fitnessOutline':fitnessOutline,
    'fitnessSharp':fitnessSharp,
    'flag':flag,
    'flagOutline':flagOutline,
    'flagSharp':flagSharp,
    'flame':flame,
    'flameOutline':flameOutline,
    'flameSharp':flameSharp,
    'flash':flash,
    'flashOff':flashOff,
    'flashOffOutline':flashOffOutline,
    'flashOffSharp':flashOffSharp,
    'flashOutline':flashOutline,
    'flashSharp':flashSharp,
    'flashlight':flashlight,
    'flashlightOutline':flashlightOutline,
    'flashlightSharp':flashlightSharp,
    'flask':flask,
    'flaskOutline':flaskOutline,
    'flaskSharp':flaskSharp,
    'flower':flower,
    'flowerOutline':flowerOutline,
    'flowerSharp':flowerSharp,
    'folder':folder,
    'folderOpen':folderOpen,
    'folderOpenOutline':folderOpenOutline,
    'folderOpenSharp':folderOpenSharp,
    'folderOutline':folderOutline,
    'folderSharp':folderSharp,
    'football':football,
    'footballOutline':footballOutline,
    'footballSharp':footballSharp,
    'funnel':funnel,
    'funnelOutline':funnelOutline,
    'funnelSharp':funnelSharp,
    'gameController':gameController,
    'gameControllerOutline':gameControllerOutline,
    'gameControllerSharp':gameControllerSharp,
    'gift':gift,
    'giftOutline':giftOutline,
    'giftSharp':giftSharp,
    'gitBranch':gitBranch,
    'gitBranchOutline':gitBranchOutline,
    'gitBranchSharp':gitBranchSharp,
    'gitCommit':gitCommit,
    'gitCommitOutline':gitCommitOutline,
    'gitCommitSharp':gitCommitSharp,
    'gitCompare':gitCompare,
    'gitCompareOutline':gitCompareOutline,
    'gitCompareSharp':gitCompareSharp,
    'gitMerge':gitMerge,
    'gitMergeOutline':gitMergeOutline,
    'gitMergeSharp':gitMergeSharp,
    'gitNetwork':gitNetwork,
    'gitNetworkOutline':gitNetworkOutline,
    'gitNetworkSharp':gitNetworkSharp,
    'gitPullRequest':gitPullRequest,
    'gitPullRequestOutline':gitPullRequestOutline,
    'gitPullRequestSharp':gitPullRequestSharp,
    'glasses':glasses,
    'glassesOutline':glassesOutline,
    'glassesSharp':glassesSharp,
    'globe':globe,
    'globeOutline':globeOutline,
    'globeSharp':globeSharp,
    'golf':golf,
    'golfOutline':golfOutline,
    'golfSharp':golfSharp,
    'grid':grid,
    'gridOutline':gridOutline,
    'gridSharp':gridSharp,
    'hammer':hammer,
    'hammerOutline':hammerOutline,
    'hammerSharp':hammerSharp,
    'handLeft':handLeft,
    'handLeftOutline':handLeftOutline,
    'handLeftSharp':handLeftSharp,
    'handRight':handRight,
    'handRightOutline':handRightOutline,
    'handRightSharp':handRightSharp,
    'happy':happy,
    'happyOutline':happyOutline,
    'happySharp':happySharp,
    'hardwareChip':hardwareChip,
    'hardwareChipOutline':hardwareChipOutline,
    'hardwareChipSharp':hardwareChipSharp,
    'headset':headset,
    'headsetOutline':headsetOutline,
    'headsetSharp':headsetSharp,
    'heart':heart,
    'heartCircle':heartCircle,
    'heartCircleOutline':heartCircleOutline,
    'heartCircleSharp':heartCircleSharp,
    'heartDislike':heartDislike,
    'heartDislikeCircle':heartDislikeCircle,
    'heartDislikeCircleOutline':heartDislikeCircleOutline,
    'heartDislikeCircleSharp':heartDislikeCircleSharp,
    'heartDislikeOutline':heartDislikeOutline,
    'heartDislikeSharp':heartDislikeSharp,
    'heartHalf':heartHalf,
    'heartHalfOutline':heartHalfOutline,
    'heartHalfSharp':heartHalfSharp,
    'heartOutline':heartOutline,
    'heartSharp':heartSharp,
    'help':help,
    'helpBuoy':helpBuoy,
    'helpBuoyOutline':helpBuoyOutline,
    'helpBuoySharp':helpBuoySharp,
    'helpCircle':helpCircle,
    'helpCircleOutline':helpCircleOutline,
    'helpCircleSharp':helpCircleSharp,
    'helpOutline':helpOutline,
    'helpSharp':helpSharp,
    'home':home,
    'homeOutline':homeOutline,
    'homeSharp':homeSharp,
    'hourglass':hourglass,
    'hourglassOutline':hourglassOutline,
    'hourglassSharp':hourglassSharp,
    'iceCream':iceCream,
    'iceCreamOutline':iceCreamOutline,
    'iceCreamSharp':iceCreamSharp,
    'image':image,
    'imageOutline':imageOutline,
    'imageSharp':imageSharp,
    'images':images,
    'imagesOutline':imagesOutline,
    'imagesSharp':imagesSharp,
    'infinite':infinite,
    'infiniteOutline':infiniteOutline,
    'infiniteSharp':infiniteSharp,
    'information':information,
    'informationCircle':informationCircle,
    'informationCircleOutline':informationCircleOutline,
    'informationCircleSharp':informationCircleSharp,
    'informationOutline':informationOutline,
    'informationSharp':informationSharp,
    'journal':journal,
    'journalOutline':journalOutline,
    'journalSharp':journalSharp,
    'key':key,
    'keyOutline':keyOutline,
    'keySharp':keySharp,
    'keypad':keypad,
    'keypadOutline':keypadOutline,
    'keypadSharp':keypadSharp,
    'language':language,
    'languageOutline':languageOutline,
    'languageSharp':languageSharp,
    'laptop':laptop,
    'laptopOutline':laptopOutline,
    'laptopSharp':laptopSharp,
    'layers':layers,
    'layersOutline':layersOutline,
    'layersSharp':layersSharp,
    'leaf':leaf,
    'leafOutline':leafOutline,
    'leafSharp':leafSharp,
    'library':library,
    'libraryOutline':libraryOutline,
    'librarySharp':librarySharp,
    'link':link,
    'linkOutline':linkOutline,
    'linkSharp':linkSharp,
    'list':list,
    'listCircle':listCircle,
    'listCircleOutline':listCircleOutline,
    'listCircleSharp':listCircleSharp,
    'listOutline':listOutline,
    'listSharp':listSharp,
    'locate':locate,
    'locateOutline':locateOutline,
    'locateSharp':locateSharp,
    'location':location,
    'locationOutline':locationOutline,
    'locationSharp':locationSharp,
    'lockClosed':lockClosed,
    'lockClosedOutline':lockClosedOutline,
    'lockClosedSharp':lockClosedSharp,
    'lockOpen':lockOpen,
    'lockOpenOutline':lockOpenOutline,
    'lockOpenSharp':lockOpenSharp,
    'logIn':logIn,
    'logInOutline':logInOutline,
    'logInSharp':logInSharp,
    'logOut':logOut,
    'logOutOutline':logOutOutline,
    'logOutSharp':logOutSharp,
    'logoAmazon':logoAmazon,
    'logoAmplify':logoAmplify,
    'logoAndroid':logoAndroid,
    'logoAngular':logoAngular,
    'logoApple':logoApple,
    'logoAppleAppstore':logoAppleAppstore,
    'logoBitbucket':logoBitbucket,
    'logoBitcoin':logoBitcoin,
    'logoBuffer':logoBuffer,
    'logoCapacitor':logoCapacitor,
    'logoChrome':logoChrome,
    'logoClosedCaptioning':logoClosedCaptioning,
    'logoCodepen':logoCodepen,
    'logoCss3':logoCss3,
    'logoDesignernews':logoDesignernews,
    'logoDribbble':logoDribbble,
    'logoDropbox':logoDropbox,
    'logoEdge':logoEdge,
    'logoElectron':logoElectron,
    'logoEuro':logoEuro,
    'logoFacebook':logoFacebook,
    'logoFirebase':logoFirebase,
    'logoFirefox':logoFirefox,
    'logoFlickr':logoFlickr,
    'logoFoursquare':logoFoursquare,
    'logoGithub':logoGithub,
    'logoGoogle':logoGoogle,
    'logoGooglePlaystore':logoGooglePlaystore,
    'logoHackernews':logoHackernews,
    'logoHtml5':logoHtml5,
    'logoInstagram':logoInstagram,
    'logoIonic':logoIonic,
    'logoIonitron':logoIonitron,
    'logoJavascript':logoJavascript,
    'logoLaravel':logoLaravel,
    'logoLinkedin':logoLinkedin,
    'logoMarkdown':logoMarkdown,
    'logoNoSmoking':logoNoSmoking,
    'logoNodejs':logoNodejs,
    'logoNpm':logoNpm,
    'logoOctocat':logoOctocat,
    'logoPinterest':logoPinterest,
    'logoPlaystation':logoPlaystation,
    'logoPwa':logoPwa,
    'logoPython':logoPython,
    'logoReact':logoReact,
    'logoReddit':logoReddit,
    'logoRss':logoRss,
    'logoSass':logoSass,
    'logoSkype':logoSkype,
    'logoSlack':logoSlack,
    'logoSnapchat':logoSnapchat,
    'logoStackoverflow':logoStackoverflow,
    'logoSteam':logoSteam,
    'logoStencil':logoStencil,
    'logoTumblr':logoTumblr,
    'logoTux':logoTux,
    'logoTwitch':logoTwitch,
    'logoTwitter':logoTwitter,
    'logoUsd':logoUsd,
    'logoVimeo':logoVimeo,
    'logoVk':logoVk,
    'logoVue':logoVue,
    'logoWebComponent':logoWebComponent,
    'logoWhatsapp':logoWhatsapp,
    'logoWindows':logoWindows,
    'logoWordpress':logoWordpress,
    'logoXbox':logoXbox,
    'logoXing':logoXing,
    'logoYahoo':logoYahoo,
    'logoYen':logoYen,
    'logoYoutube':logoYoutube,
    'magnet':magnet,
    'magnetOutline':magnetOutline,
    'magnetSharp':magnetSharp,
    'mail':mail,
    'mailOpen':mailOpen,
    'mailOpenOutline':mailOpenOutline,
    'mailOpenSharp':mailOpenSharp,
    'mailOutline':mailOutline,
    'mailSharp':mailSharp,
    'mailUnread':mailUnread,
    'mailUnreadOutline':mailUnreadOutline,
    'mailUnreadSharp':mailUnreadSharp,
    'male':male,
    'maleFemale':maleFemale,
    'maleFemaleOutline':maleFemaleOutline,
    'maleFemaleSharp':maleFemaleSharp,
    'maleOutline':maleOutline,
    'maleSharp':maleSharp,
    'man':man,
    'manOutline':manOutline,
    'manSharp':manSharp,
    'map':map,
    'mapOutline':mapOutline,
    'mapSharp':mapSharp,
    'medal':medal,
    'medalOutline':medalOutline,
    'medalSharp':medalSharp,
    'medical':medical,
    'medicalOutline':medicalOutline,
    'medicalSharp':medicalSharp,
    'medkit':medkit,
    'medkitOutline':medkitOutline,
    'medkitSharp':medkitSharp,
    'megaphone':megaphone,
    'megaphoneOutline':megaphoneOutline,
    'megaphoneSharp':megaphoneSharp,
    'menu':menu,
    'menuOutline':menuOutline,
    'menuSharp':menuSharp,
    'mic':mic,
    'micCircle':micCircle,
    'micCircleOutline':micCircleOutline,
    'micCircleSharp':micCircleSharp,
    'micOff':micOff,
    'micOffCircle':micOffCircle,
    'micOffCircleOutline':micOffCircleOutline,
    'micOffCircleSharp':micOffCircleSharp,
    'micOffOutline':micOffOutline,
    'micOffSharp':micOffSharp,
    'micOutline':micOutline,
    'micSharp':micSharp,
    'moon':moon,
    'moonOutline':moonOutline,
    'moonSharp':moonSharp,
    'move':move,
    'moveOutline':moveOutline,
    'moveSharp':moveSharp,
    'musicalNote':musicalNote,
    'musicalNoteOutline':musicalNoteOutline,
    'musicalNoteSharp':musicalNoteSharp,
    'musicalNotes':musicalNotes,
    'musicalNotesOutline':musicalNotesOutline,
    'musicalNotesSharp':musicalNotesSharp,
    'navigate':navigate,
    'navigateCircle':navigateCircle,
    'navigateCircleOutline':navigateCircleOutline,
    'navigateCircleSharp':navigateCircleSharp,
    'navigateOutline':navigateOutline,
    'navigateSharp':navigateSharp,
    'newspaper':newspaper,
    'newspaperOutline':newspaperOutline,
    'newspaperSharp':newspaperSharp,
    'notifications':notifications,
    'notificationsCircle':notificationsCircle,
    'notificationsCircleOutline':notificationsCircleOutline,
    'notificationsCircleSharp':notificationsCircleSharp,
    'notificationsOff':notificationsOff,
    'notificationsOffCircle':notificationsOffCircle,
    'notificationsOffCircleOutline':notificationsOffCircleOutline,
    'notificationsOffCircleSharp':notificationsOffCircleSharp,
    'notificationsOffOutline':notificationsOffOutline,
    'notificationsOffSharp':notificationsOffSharp,
    'notificationsOutline':notificationsOutline,
    'notificationsSharp':notificationsSharp,
    'nuclear':nuclear,
    'nuclearOutline':nuclearOutline,
    'nuclearSharp':nuclearSharp,
    'nutrition':nutrition,
    'nutritionOutline':nutritionOutline,
    'nutritionSharp':nutritionSharp,
    'open':open,
    'openOutline':openOutline,
    'openSharp':openSharp,
    'options':options,
    'optionsOutline':optionsOutline,
    'optionsSharp':optionsSharp,
    'paperPlane':paperPlane,
    'paperPlaneOutline':paperPlaneOutline,
    'paperPlaneSharp':paperPlaneSharp,
    'partlySunny':partlySunny,
    'partlySunnyOutline':partlySunnyOutline,
    'partlySunnySharp':partlySunnySharp,
    'pause':pause,
    'pauseCircle':pauseCircle,
    'pauseCircleOutline':pauseCircleOutline,
    'pauseCircleSharp':pauseCircleSharp,
    'pauseOutline':pauseOutline,
    'pauseSharp':pauseSharp,
    'paw':paw,
    'pawOutline':pawOutline,
    'pawSharp':pawSharp,
    'pencil':pencil,
    'pencilOutline':pencilOutline,
    'pencilSharp':pencilSharp,
    'people':people,
    'peopleCircle':peopleCircle,
    'peopleCircleOutline':peopleCircleOutline,
    'peopleCircleSharp':peopleCircleSharp,
    'peopleOutline':peopleOutline,
    'peopleSharp':peopleSharp,
    'person':person,
    'personAdd':personAdd,
    'personAddOutline':personAddOutline,
    'personAddSharp':personAddSharp,
    'personCircle':personCircle,
    'personCircleOutline':personCircleOutline,
    'personCircleSharp':personCircleSharp,
    'personOutline':personOutline,
    'personRemove':personRemove,
    'personRemoveOutline':personRemoveOutline,
    'personRemoveSharp':personRemoveSharp,
    'personSharp':personSharp,
    'phoneLandscape':phoneLandscape,
    'phoneLandscapeOutline':phoneLandscapeOutline,
    'phoneLandscapeSharp':phoneLandscapeSharp,
    'phonePortrait':phonePortrait,
    'phonePortraitOutline':phonePortraitOutline,
    'phonePortraitSharp':phonePortraitSharp,
    'pieChart':pieChart,
    'pieChartOutline':pieChartOutline,
    'pieChartSharp':pieChartSharp,
    'pin':pin,
    'pinOutline':pinOutline,
    'pinSharp':pinSharp,
    'pint':pint,
    'pintOutline':pintOutline,
    'pintSharp':pintSharp,
    'pizza':pizza,
    'pizzaOutline':pizzaOutline,
    'pizzaSharp':pizzaSharp,
    'planet':planet,
    'planetOutline':planetOutline,
    'planetSharp':planetSharp,
    'play':play,
    'playBack':playBack,
    'playBackCircle':playBackCircle,
    'playBackCircleOutline':playBackCircleOutline,
    'playBackCircleSharp':playBackCircleSharp,
    'playBackOutline':playBackOutline,
    'playBackSharp':playBackSharp,
    'playCircle':playCircle,
    'playCircleOutline':playCircleOutline,
    'playCircleSharp':playCircleSharp,
    'playForward':playForward,
    'playForwardCircle':playForwardCircle,
    'playForwardCircleOutline':playForwardCircleOutline,
    'playForwardCircleSharp':playForwardCircleSharp,
    'playForwardOutline':playForwardOutline,
    'playForwardSharp':playForwardSharp,
    'playOutline':playOutline,
    'playSharp':playSharp,
    'playSkipBack':playSkipBack,
    'playSkipBackCircle':playSkipBackCircle,
    'playSkipBackCircleOutline':playSkipBackCircleOutline,
    'playSkipBackCircleSharp':playSkipBackCircleSharp,
    'playSkipBackOutline':playSkipBackOutline,
    'playSkipBackSharp':playSkipBackSharp,
    'playSkipForward':playSkipForward,
    'playSkipForwardCircle':playSkipForwardCircle,
    'playSkipForwardCircleOutline':playSkipForwardCircleOutline,
    'playSkipForwardCircleSharp':playSkipForwardCircleSharp,
    'playSkipForwardOutline':playSkipForwardOutline,
    'playSkipForwardSharp':playSkipForwardSharp,
    'podium':podium,
    'podiumOutline':podiumOutline,
    'podiumSharp':podiumSharp,
    'power':power,
    'powerOutline':powerOutline,
    'powerSharp':powerSharp,
    'pricetag':pricetag,
    'pricetagOutline':pricetagOutline,
    'pricetagSharp':pricetagSharp,
    'pricetags':pricetags,
    'pricetagsOutline':pricetagsOutline,
    'pricetagsSharp':pricetagsSharp,
    'print':print,
    'printOutline':printOutline,
    'printSharp':printSharp,
    'pulse':pulse,
    'pulseOutline':pulseOutline,
    'pulseSharp':pulseSharp,
    'push':push,
    'pushOutline':pushOutline,
    'pushSharp':pushSharp,
    'qrCode':qrCode,
    'qrCodeOutline':qrCodeOutline,
    'qrCodeSharp':qrCodeSharp,
    'radio':radio,
    'radioButtonOff':radioButtonOff,
    'radioButtonOffOutline':radioButtonOffOutline,
    'radioButtonOffSharp':radioButtonOffSharp,
    'radioButtonOn':radioButtonOn,
    'radioButtonOnOutline':radioButtonOnOutline,
    'radioButtonOnSharp':radioButtonOnSharp,
    'radioOutline':radioOutline,
    'radioSharp':radioSharp,
    'rainy':rainy,
    'rainyOutline':rainyOutline,
    'rainySharp':rainySharp,
    'reader':reader,
    'readerOutline':readerOutline,
    'readerSharp':readerSharp,
    'receipt':receipt,
    'receiptOutline':receiptOutline,
    'receiptSharp':receiptSharp,
    'recording':recording,
    'recordingOutline':recordingOutline,
    'recordingSharp':recordingSharp,
    'refresh':refresh,
    'refreshCircle':refreshCircle,
    'refreshCircleOutline':refreshCircleOutline,
    'refreshCircleSharp':refreshCircleSharp,
    'refreshOutline':refreshOutline,
    'refreshSharp':refreshSharp,
    'reload':reload,
    'reloadCircle':reloadCircle,
    'reloadCircleOutline':reloadCircleOutline,
    'reloadCircleSharp':reloadCircleSharp,
    'reloadOutline':reloadOutline,
    'reloadSharp':reloadSharp,
    'remove':remove,
    'removeCircle':removeCircle,
    'removeCircleOutline':removeCircleOutline,
    'removeCircleSharp':removeCircleSharp,
    'removeOutline':removeOutline,
    'removeSharp':removeSharp,
    'reorderFour':reorderFour,
    'reorderFourOutline':reorderFourOutline,
    'reorderFourSharp':reorderFourSharp,
    'reorderThree':reorderThree,
    'reorderThreeOutline':reorderThreeOutline,
    'reorderThreeSharp':reorderThreeSharp,
    'reorderTwo':reorderTwo,
    'reorderTwoOutline':reorderTwoOutline,
    'reorderTwoSharp':reorderTwoSharp,
    'repeat':repeat,
    'repeatOutline':repeatOutline,
    'repeatSharp':repeatSharp,
    'resize':resize,
    'resizeOutline':resizeOutline,
    'resizeSharp':resizeSharp,
    'restaurant':restaurant,
    'restaurantOutline':restaurantOutline,
    'restaurantSharp':restaurantSharp,
    'returnDownBack':returnDownBack,
    'returnDownBackOutline':returnDownBackOutline,
    'returnDownBackSharp':returnDownBackSharp,
    'returnDownForward':returnDownForward,
    'returnDownForwardOutline':returnDownForwardOutline,
    'returnDownForwardSharp':returnDownForwardSharp,
    'returnUpBack':returnUpBack,
    'returnUpBackOutline':returnUpBackOutline,
    'returnUpBackSharp':returnUpBackSharp,
    'returnUpForward':returnUpForward,
    'returnUpForwardOutline':returnUpForwardOutline,
    'returnUpForwardSharp':returnUpForwardSharp,
    'ribbon':ribbon,
    'ribbonOutline':ribbonOutline,
    'ribbonSharp':ribbonSharp,
    'rocket':rocket,
    'rocketOutline':rocketOutline,
    'rocketSharp':rocketSharp,
    'rose':rose,
    'roseOutline':roseOutline,
    'roseSharp':roseSharp,
    'sad':sad,
    'sadOutline':sadOutline,
    'sadSharp':sadSharp,
    'save':save,
    'saveOutline':saveOutline,
    'saveSharp':saveSharp,
    'scan':scan,
    'scanCircle':scanCircle,
    'scanCircleOutline':scanCircleOutline,
    'scanCircleSharp':scanCircleSharp,
    'scanOutline':scanOutline,
    'scanSharp':scanSharp,
    'school':school,
    'schoolOutline':schoolOutline,
    'schoolSharp':schoolSharp,
    'search':search,
    'searchCircle':searchCircle,
    'searchCircleOutline':searchCircleOutline,
    'searchCircleSharp':searchCircleSharp,
    'searchOutline':searchOutline,
    'searchSharp':searchSharp,
    'send':send,
    'sendOutline':sendOutline,
    'sendSharp':sendSharp,
    'server':server,
    'serverOutline':serverOutline,
    'serverSharp':serverSharp,
    'settings':settings,
    'settingsOutline':settingsOutline,
    'settingsSharp':settingsSharp,
    'shapes':shapes,
    'shapesOutline':shapesOutline,
    'shapesSharp':shapesSharp,
    'share':share,
    'shareOutline':shareOutline,
    'shareSharp':shareSharp,
    'shareSocial':shareSocial,
    'shareSocialOutline':shareSocialOutline,
    'shareSocialSharp':shareSocialSharp,
    'shield':shield,
    'shieldCheckmark':shieldCheckmark,
    'shieldCheckmarkOutline':shieldCheckmarkOutline,
    'shieldCheckmarkSharp':shieldCheckmarkSharp,
    'shieldOutline':shieldOutline,
    'shieldSharp':shieldSharp,
    'shirt':shirt,
    'shirtOutline':shirtOutline,
    'shirtSharp':shirtSharp,
    'shuffle':shuffle,
    'shuffleOutline':shuffleOutline,
    'shuffleSharp':shuffleSharp,
    'skull':skull,
    'skullOutline':skullOutline,
    'skullSharp':skullSharp,
    'snow':snow,
    'snowOutline':snowOutline,
    'snowSharp':snowSharp,
    'speedometer':speedometer,
    'speedometerOutline':speedometerOutline,
    'speedometerSharp':speedometerSharp,
    'square':square,
    'squareOutline':squareOutline,
    'squareSharp':squareSharp,
    'star':star,
    'starHalf':starHalf,
    'starHalfOutline':starHalfOutline,
    'starHalfSharp':starHalfSharp,
    'starOutline':starOutline,
    'starSharp':starSharp,
    'statsChart':statsChart,
    'statsChartOutline':statsChartOutline,
    'statsChartSharp':statsChartSharp,
    'stop':stop,
    'stopCircle':stopCircle,
    'stopCircleOutline':stopCircleOutline,
    'stopCircleSharp':stopCircleSharp,
    'stopOutline':stopOutline,
    'stopSharp':stopSharp,
    'stopwatch':stopwatch,
    'stopwatchOutline':stopwatchOutline,
    'stopwatchSharp':stopwatchSharp,
    'subway':subway,
    'subwayOutline':subwayOutline,
    'subwaySharp':subwaySharp,
    'sunny':sunny,
    'sunnyOutline':sunnyOutline,
    'sunnySharp':sunnySharp,
    'swapHorizontal':swapHorizontal,
    'swapHorizontalOutline':swapHorizontalOutline,
    'swapHorizontalSharp':swapHorizontalSharp,
    'swapVertical':swapVertical,
    'swapVerticalOutline':swapVerticalOutline,
    'swapVerticalSharp':swapVerticalSharp,
    'sync':sync,
    'syncCircle':syncCircle,
    'syncCircleOutline':syncCircleOutline,
    'syncCircleSharp':syncCircleSharp,
    'syncOutline':syncOutline,
    'syncSharp':syncSharp,
    'tabletLandscape':tabletLandscape,
    'tabletLandscapeOutline':tabletLandscapeOutline,
    'tabletLandscapeSharp':tabletLandscapeSharp,
    'tabletPortrait':tabletPortrait,
    'tabletPortraitOutline':tabletPortraitOutline,
    'tabletPortraitSharp':tabletPortraitSharp,
    'tennisball':tennisball,
    'tennisballOutline':tennisballOutline,
    'tennisballSharp':tennisballSharp,
    'terminal':terminal,
    'terminalOutline':terminalOutline,
    'terminalSharp':terminalSharp,
    'text':text,
    'textOutline':textOutline,
    'textSharp':textSharp,
    'thermometer':thermometer,
    'thermometerOutline':thermometerOutline,
    'thermometerSharp':thermometerSharp,
    'thumbsDown':thumbsDown,
    'thumbsDownOutline':thumbsDownOutline,
    'thumbsDownSharp':thumbsDownSharp,
    'thumbsUp':thumbsUp,
    'thumbsUpOutline':thumbsUpOutline,
    'thumbsUpSharp':thumbsUpSharp,
    'thunderstorm':thunderstorm,
    'thunderstormOutline':thunderstormOutline,
    'thunderstormSharp':thunderstormSharp,
    'time':time,
    'timeOutline':timeOutline,
    'timeSharp':timeSharp,
    'timer':timer,
    'timerOutline':timerOutline,
    'timerSharp':timerSharp,
    'today':today,
    'todayOutline':todayOutline,
    'todaySharp':todaySharp,
    'toggle':toggle,
    'toggleOutline':toggleOutline,
    'toggleSharp':toggleSharp,
    'trailSign':trailSign,
    'trailSignOutline':trailSignOutline,
    'trailSignSharp':trailSignSharp,
    'train':train,
    'trainOutline':trainOutline,
    'trainSharp':trainSharp,
    'transgender':transgender,
    'transgenderOutline':transgenderOutline,
    'transgenderSharp':transgenderSharp,
    'trash':trash,
    'trashBin':trashBin,
    'trashBinOutline':trashBinOutline,
    'trashBinSharp':trashBinSharp,
    'trashOutline':trashOutline,
    'trashSharp':trashSharp,
    'trendingDown':trendingDown,
    'trendingDownOutline':trendingDownOutline,
    'trendingDownSharp':trendingDownSharp,
    'trendingUp':trendingUp,
    'trendingUpOutline':trendingUpOutline,
    'trendingUpSharp':trendingUpSharp,
    'triangle':triangle,
    'triangleOutline':triangleOutline,
    'triangleSharp':triangleSharp,
    'trophy':trophy,
    'trophyOutline':trophyOutline,
    'trophySharp':trophySharp,
    'tv':tv,
    'tvOutline':tvOutline,
    'tvSharp':tvSharp,
    'umbrella':umbrella,
    'umbrellaOutline':umbrellaOutline,
    'umbrellaSharp':umbrellaSharp,
    'videocam':videocam,
    'videocamOutline':videocamOutline,
    'videocamSharp':videocamSharp,
    'volumeHigh':volumeHigh,
    'volumeHighOutline':volumeHighOutline,
    'volumeHighSharp':volumeHighSharp,
    'volumeLow':volumeLow,
    'volumeLowOutline':volumeLowOutline,
    'volumeLowSharp':volumeLowSharp,
    'volumeMedium':volumeMedium,
    'volumeMediumOutline':volumeMediumOutline,
    'volumeMediumSharp':volumeMediumSharp,
    'volumeMute':volumeMute,
    'volumeMuteOutline':volumeMuteOutline,
    'volumeMuteSharp':volumeMuteSharp,
    'volumeOff':volumeOff,
    'volumeOffOutline':volumeOffOutline,
    'volumeOffSharp':volumeOffSharp,
    'walk':walk,
    'walkOutline':walkOutline,
    'walkSharp':walkSharp,
    'wallet':wallet,
    'walletOutline':walletOutline,
    'walletSharp':walletSharp,
    'warning':warning,
    'warningOutline':warningOutline,
    'warningSharp':warningSharp,
    'watch':watch,
    'watchOutline':watchOutline,
    'watchSharp':watchSharp,
    'water':water,
    'waterOutline':waterOutline,
    'waterSharp':waterSharp,
    'wifi':wifi,
    'wifiOutline':wifiOutline,
    'wifiSharp':wifiSharp,
    'wine':wine,
    'wineOutline':wineOutline,
    'wineSharp':wineSharp,
    'woman':woman,
    'womanOutline':womanOutline,
    'womanSharp':womanSharp,
    
  }

