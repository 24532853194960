import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonToast, IonLoading, IonSearchbar, IonButton, IonIcon, getConfig, IonModal, IonBackButton, IonList, IonItem, IonLabel, IonCheckbox, IonInput, IonCard, IonCardHeader, IonCardContent, IonTextarea } from '@ionic/react';

import { connect } from '../../data/connect';
import './SearchSaleItemForm.scss';
import API from '../../services';
import { add, arrowBack, checkbox, filter, options, search } from 'ionicons/icons';
import Filter  from '../../components/ActiveForm/Filter';
import { RouteProps } from 'react-router';
import Card from '../../components/ActiveForm/Card';
import { getTemplate } from '../../data/form/template';
import MasterDetailList from '../../components/ActiveForm/MasterDetailList';
import NumberFormat from 'react-number-format';
import NumericField from '../../components/ActiveForm/NumericField';

interface OwnProps{
    submit: (items:any[]) => void;
    cancel:()=>void;
    saleid:any;
 };

interface StateProps {
  mode: 'ios' | 'md';
};

interface DispatchProps {
 };

interface SearchSaleItemFormProps extends OwnProps, StateProps, DispatchProps { };

const SearchSaleItemForm: React.FC<SearchSaleItemFormProps> = ({mode,saleid,cancel,submit}) => {
    const apipath="item";
    const masterapipath = "itemgroup";
  const [isleave,setIsLeave] = useState(false);
  const [masters,setMasters] = useState([]);
  const [datas,setDatas] = useState<any>([]);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Complete");
  const [showLoading, setShowLoading] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const pageRef = useRef<HTMLElement>(null);
  const [usefilter,setUseFilter] = useState(false);
  const [filterfields,setFilterfields] = useState<any>(
      {  Name:'' 
          ,Description:''
          ,Active:true
      }
  );
  const [buttons,setButtons] = useState<any>(getTemplate(apipath+'_button'));
  const [fieldtemplate,setFieldTemplate] = useState<any>(getTemplate(apipath+"_view"));
  const [masterbuttons,setMasterButtons] = useState<any>(getTemplate(masterapipath+'_button'));
  const [masterfieldtemplate,setMasterFieldTemplate] = useState<any>(getTemplate(masterapipath+"_view"));
  
  const [sort,setSort] = useState<any>(getTemplate(apipath+'_sort'));
  const [filters,setFilters] = useState<any>(getTemplate(apipath+'_filter'));

  const ios = mode === 'ios';
  useEffect(() => {

    
      loaddatas();
    // eslint-disable-next-line
  }, [filters,sort]);

  const submitdata=()=>{
    var selecteddata = datas.filter((d:any)=>{
        return d.selected;
    });

    // console.log(selecteddata);
    submit(selecteddata);

  }
  const submitalldata=()=>{
    var selecteddata = datas;

    // console.log(selecteddata);
    submit(selecteddata);

  }
  const selecteditem = (item: any) => {
    var items:any =  datas.map((i:any)=>{
      if(i.id===item.id){
        return {...i,selected:!i.selected}
      }
      else{
        return i;
      }
      
    })
    setDatas(items);
  };
  const handleChangeNumber = (e:any,item:any,name:any) => {
    const { value } = e;
    console.log(e);
    var items:any =  datas.map((i:any)=>{
        if(i.id===item.id){
          return {...i,Quantity:value}
        }
        else{
          return i;
        }
        
      })
      setDatas(items);
  };
  const handleChange = (e:any,item:any,name:any) => {
    const { value } = e.detail;
    var items:any =  datas.map((i:any)=>{
        if(i.id===item.id){
          return {...i,Quantity:value}
        }
        else{
          return i;
        }
        
      })
      setDatas(items);
  };

  const submitfilter=(pfilters:any[],psort:any)=>{
    setFilters(pfilters);
    setSort(psort);
    setShowFilterModal(false);

  }
  const cancelfilter=()=>{
    
    setShowFilterModal(false);

  }
  const resetfilter=()=>{
    
    setFilters(getTemplate(apipath));
    setSort(getTemplate(apipath+'_sort'));
    setShowFilterModal(false);

  }
  const apifilterParameter=()=>{
    var result:any = {};

    if(filters)
    {
      Object.keys(filters).map((key:any,value:any) => {
        if(filters[key].active && filters[key].value !== ""){
          var param = "filter["+key+"]["+filters[key].operand+"]";
          var pvalue = filters[key].value;
          if(filters[key].type==="boolean"){
            pvalue = pvalue?1:0;
          }
          result[param]=pvalue;
        }
      });
    }

    if(sort){
      if(sort.active){
        var param = "sort";
        var pvalue = sort.field;
        if(sort.direction==="desc"){
          pvalue = "-"+pvalue;
        }
        result[param]=pvalue;
      }
    }

    return result;
  }


const  loaddatas=async()=>{
    setShowLoading(true);
    const param:any = apifilterParameter();
    const datas:any = await API.view('sale/deliveryitems/'+saleid).catch(e=>errorhandler(e));
    
    var nmasters:any = [];
    var ndatas:any=[];
    if(datas){
        var items = datas["items"];
        var itemdelivered = datas["itemdelivered"];
        console.log(itemdelivered);
        ndatas =await items.map((d:any)=>{
            var item= {...d.item,Quantity:d.Quantity,MaxQuantity:d.Quantity};
            
            if(itemdelivered[d.ItemID]!==undefined){
                var q = d.Quantity - itemdelivered[d.ItemID];
                item = {...item,Quantity:q,MaxQuantity:q};
            }
            
            return item;
        })
        // console.log(datas,nmasters);
        setMasters(nmasters);
        setDatas(ndatas);
        setToastMessage("load data complete");
    }
    
    
    ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setShowLoading(false);
    
  }
  const errorhandler = (err:any) =>{
    setToastMessage(err.message);
    if(err.response){
        console.log("errorhandler response",err.response);
        
    }
    else{
        console.log("errorhandler",err.message);
    }
  }
  return (
    <IonPage id={apipath+"-list"}>
      <IonHeader translucent={true}>
        <IonToolbar>
        <IonButtons slot="start">
            
            <IonButton onClick={()=>cancel()}>
            {mode === 'ios' ? 'cancel' : <IonIcon icon={arrowBack} slot="icon-only" />}
            </IonButton>
         
        </IonButtons>

      
          {!showSearchbar &&
          <IonTitle>{apipath.toLocaleUpperCase()}</IonTitle>
          }

          {showSearchbar &&
            <IonSearchbar showCancelButton="always" cancelButtonIcon="close" placeholder="Search"
              onIonCancel={() => setShowSearchbar(false)}
              >

              </IonSearchbar>
          }
          <IonButtons slot="end">
           
                
                <IonButton color="primary" onClick={() => submitdata()}>
                {mode === 'ios' ? 'submit' : 'submit'}
                </IonButton>
            
          
            
          </IonButtons>
          <IonButtons slot="end">
            {!showSearchbar &&
                
                <IonButton color="primary" onClick={() => submitalldata()}>
                {mode === 'ios' ? 'submit all' : 'submit all'}
                </IonButton>
            }
          
            
          </IonButtons>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{apipath.toLocaleUpperCase()}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddatas}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

            <IonGrid>
            
            <IonList lines={ mode==='ios' ? 'inset' : 'full'}>
          
            {datas.map((key:any, index:any) => (
                // <IonItem key={key.id}>
                
                <IonCard className="field-card">
                    <IonCardHeader>
                        <IonItem  className="field-item">
                        
                        <IonLabel color="primary">
                        {key.Code}
                        </IonLabel>
                        
                            <IonButtons slot="end">
                                <IonCheckbox
                                onClick={() => selecteditem(key)}
                                checked={key.selected}
                                color="primary"
                                // value={track}
                                />
                            </IonButtons>
                        </IonItem>
                    </IonCardHeader>

                    <IonCardContent>
                    <IonList lines="none">
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Item Code"}
                        </IonLabel>
                        <IonInput value={key.Code} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Item Name"}
                        </IonLabel>
                        <IonInput value={key.Name} readonly/>   
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Quantity ("+key.QuantityCode+")"}
                        </IonLabel>
                        <NumericField type='tel' name={"Quantity"} value={key.Quantity} 
                                placeholder={"please input quantity"} 
                                onIonChange={e=>handleChange(e,key,"Quantity")}
                                min={"0"}
                                inputmode="tel"
                                
                            />  

                        {/* <IonLabel color="primary">
                            {key.QuantityCode}
                        </IonLabel> */}
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Price"}
                        </IonLabel>
                        <NumericField type='tel' name={"ItemSellPrice"} value={key.ItemSellPrice} 
                                placeholder={"please input quantity"} 
                                onIonChange={e=>handleChange(e,key,"Quantity")}
                                min={"0"}
                                inputmode="tel"
                                
                            />  
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Description"}
                        </IonLabel>
                        <IonTextarea value={key.Description} readonly/>   
                    </IonItem>
                    </IonList>
                    </IonCardContent>
                </IonCard>

                
            ))}
            </IonList>
               
           
          </IonGrid>
         
      </IonContent>
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <Filter
          fields={filterfields}
          filters={filters}
          sort={sort}
          submit={submitfilter}
          cancel={cancelfilter}
          reset={resetfilter}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    mode: getConfig()!.get('mode')
  }),
  mapDispatchToProps: {
  },
  component: React.memo(SearchSaleItemForm)
});