import { IonSelectOption } from "@ionic/react";
import React from "react";
import API from "../../services";

const data:any = {
    inventory:
    {
        Name:{name:"Name",type:"string",view:true,create:true,edit:true} 
        ,Address:{name:"Address",type:"description",view:true,create:true,edit:true}
        // ,Description:{name:"Description",type:"description",view:false,create:true,edit:true}
        ,Active:{name:"Active",type:"boolean",view:true,create:true,edit:true}
    }
    ,inventory_view:
    {
        Name:{name:"Name",type:"string"} 
        ,Title:{name:"Title",type:"string"}
        ,Address:{name:"Address",type:"description"}
        // ,Title2:{name:"Title2",type:"string"}
        // ,Address2:{name:"Address2",type:"description"}
        // ,Description:{name:"Description",type:"description"}
        ,Active:{name:"Active",type:"boolean"}
    }
    ,inventory_create:
    {
        Name:{name:"Name",type:"string"} 
        ,Title:{name:"Title",type:"string"}
        ,Address:{name:"Address",type:"description"}
        ,Title2:{name:"Title2",type:"string"}
        ,Address2:{name:"Address2",type:"description"}
        // ,Description:{name:"Description",type:"description"}
        ,Active:{name:"Active",type:"boolean"}
    }
    ,inventory_update:
    {
        Name:{name:"Name",type:"string"} 
        ,Title:{name:"Title",type:"string"}
        ,Address:{name:"Address",type:"description"}
        // ,Description:{name:"Description",type:"description"}
        ,Title2:{name:"Title2",type:"string"}
        ,Address2:{name:"Address2",type:"description"}
        ,Active:{name:"Active",type:"boolean"}
    }
    ,inventory_filter:
    {
        Name:{name:"Name",operand:"like",value:"",type:"string",active:false}
        ,Title:{name:"Title",operand:"like",value:"",type:"string",active:false}
        ,Address:{name:"Address",operand:"like",value:"",type:"string",active:false}
        ,Title2:{name:"Title2",operand:"like",value:"",type:"string",active:false}
        ,Address2:{name:"Address2",operand:"like",value:"",type:"string",active:false}
        ,Description:{name:"Description",operand:"like",value:"",type:"string",active:false}
        ,Active:{name:"Active",operand:"eq",value:true,type:"boolean",active:false}
    }
    ,inventory_sort:
    {   field:"Name"
        ,direction:"asc"
        ,active:false
    }
    ,inventory_page:
    {   limit:20
        ,active:false
    }
    ,inventory_button:
    [
        {icon:"cashOutline",route:"/inventorycashflow/:id",label:"cashflow"},
        {icon:"peopleOutline",route:"/inventoryuser/:id",label:"user"},
        {icon:"createOutline",route:"/inventory/:id",label:"edit"}
    
    ]
    

    ,user:
    {
        usertype:{name:"User Type",type:"option",option:{api:"user/role",param:null,label:"name",value:"id"},view:false,create:true,edit:false}
        ,username:{name:"User Name",type:"string",view:false,create:true,edit:false}
        ,password:{name:"Password",type:"password",view:false,create:true,edit:false}
        ,name:{name:"Name",type:"string",view:false,create:true,edit:false}
        ,email:{name:"Email",type:"email",view:false,create:true,edit:false}
        ,active:{name:"Active",type:"boolean",view:false,create:true,edit:false}

        ,UserName:{name:"User Name",type:"string",view:true,create:false,edit:true,readonly:true}
        ,UserType:{name:"User Type",type:"option",option:{api:"user/role",param:null,label:"name",value:"id"},view:true,create:false,edit:true}
        ,Name:{name:"Name",type:"string",view:true,create:false,edit:true} 
        ,Email:{name:"Email",type:"email",view:true,create:false,edit:true} 
        ,MobilePhone:{name:"Mobile Phone",type:"tel",view:true,create:false,edit:true} 
        ,Active:{name:"Active",type:"boolean",view:true,create:false,edit:true}
    }
    ,user_view:
    {
        UserName:{name:"User Name",type:"string"}
        ,UserType:{name:"User Type",type:"option",option:{api:"user/role",param:null,label:"name",value:"id"}}
        ,Name:{name:"Name",type:"string"} 
        // ,Email:{name:"Email",type:"email"} 
        // ,MobilePhone:{name:"Mobile Phone",type:"tel"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,user_create:
    {
        usertype:{name:"User Type",type:"option",option:{api:"user/role",param:null,label:"name",value:"id"}}
        ,username:{name:"User Name",type:"string"}
        ,password:{name:"Password",type:"password"}
        ,name:{name:"Name",type:"string"}
        ,email:{name:"Email",type:"email"}
        ,active:{name:"Active",type:"boolean"}

    }
    ,user_update:
    {

        UserName:{name:"User Name",type:"string",disabled:true}
        ,UserType:{name:"User Type",type:"option",option:{api:"user/role",param:null,label:"name",value:"id"}}
        ,Name:{name:"Name",type:"string"} 
        ,Email:{name:"Email",type:"email"} 
        ,MobilePhone:{name:"Mobile Phone",type:"tel"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,user_filter:
    {
        UserName:{name:"User Name",operand:"like",value:"",type:"string",active:false}
        ,UserType:{name:"User Type",operand:"like",value:"",type:"option",option:{api:"user/role",param:null,label:"name",value:"id"},active:false}
        ,Name:{name:"Name",operand:"like",value:"",type:"string",active:false}
        ,Active:{name:"Active",operand:"eq",value:true,type:"boolean",active:false}
    }
    ,user_sort:
    {   field:"Name"
        ,direction:"asc"
        ,active:false
    }
    ,user_page:
    {   limit:20
        ,active:false
    }
    ,user_button:
    [
        
        {icon:"homeOutline",route:"/userinventory/:id",label:"inventory"},
        {icon:"cashOutline",route:"/usercashflow/:id",label:"cashflow"},

        {icon:"keyOutline",route:"/changepassword/:id",label:"password"},
        {icon:"createOutline",route:"/user/:id",label:"edit"},
    ]

    ,cashflow:
    {
        Name:{name:"Name",type:"string",view:true,create:true,edit:true} 
        ,Currency:{name:"Currency",type:"option",option:{api:"cashflow/currency",param:null,label:"name",value:"id"},view:true,create:true,edit:true}
        ,CashFlowType:{name:"Type",type:"option",option:{api:"cashflow/type",param:null,label:"name",value:"id"},view:true,create:true,edit:true}
        ,AccountNumber:{name:"Account Number",type:"string",view:true,create:true,edit:true} 
        ,AccountName:{name:"Account Name",type:"string",view:true,create:true,edit:true} 
        ,Description:{name:"Description",type:"description",view:false,create:true,edit:true} 
        ,Active:{name:"Active",type:"boolean",view:true,create:true,edit:true}
    }
    ,cashflow_view:
    {
        Name:{name:"Name",type:"string"} 
        ,Currency:{name:"Currency",type:"option",option:{api:"cashflow/currency",param:null,label:"name",value:"id"}}
        ,CashFlowType:{name:"Type",type:"option",option:{api:"cashflow/type",param:null,label:"name",value:"id"}}
        // ,AccountNumber:{name:"Account Number",type:"string"} 
        // ,AccountName:{name:"Account Name",type:"string"} 
        // ,Description:{name:"Description",type:"description"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,cashflow_create:
    {
        Name:{name:"Name",type:"string"} 
        ,Currency:{name:"Currency",type:"option",option:{api:"cashflow/currency",param:null,label:"name",value:"id"}}
        ,CashFlowType:{name:"Type",type:"option",option:{api:"cashflow/type",param:null,label:"name",value:"id"}}
        ,AccountNumber:{name:"Account Number",type:"string"} 
        ,AccountName:{name:"Account Name",type:"string"} 
        ,Description:{name:"Description",type:"description"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,cashflow_update:
    {
        Name:{name:"Name",type:"string"} 
        ,Currency:{name:"Currency",type:"option",option:{api:"cashflow/currency",param:null,label:"name",value:"id"},readonly:true}
        ,CashFlowType:{name:"Type",type:"option",option:{api:"cashflow/type",param:null,label:"name",value:"id"}}
        ,AccountNumber:{name:"Account Number",type:"string"} 
        ,AccountName:{name:"Account Name",type:"string"} 
        ,Description:{name:"Description",type:"description"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,cashflow_filter:
    {
        Name:{name:"Name",operand:"like",value:"",type:"string",active:false}
        ,Currency:{name:"Currency",operand:"like",value:"",type:"option",option:{api:"cashflow/currency",param:null,label:"name",value:"id"},active:false}
        ,CashFlowType:{name:"Type",operand:"like",value:"",type:"option",option:{api:"cashflow/type",param:null,label:"name",value:"id"},active:false}
        ,AccountNumber:{name:"Account Name",operand:"like",value:"",type:"string",active:false}
        ,Description:{name:"Description",operand:"like",value:"",type:"string",active:false}
        ,Active:{name:"Active",operand:"eq",value:true,type:"boolean",active:false}
    }
    ,cashflow_sort:
    {   field:"Name"
        ,direction:"asc"
        ,active:false
    }
    ,cashflow_page:
    {   limit:20
        ,active:false
    }
    ,cashflow_button:
    [
        
        {icon:"homeOutline",route:"/cashflowinventory/:id",label:"inventory"},
        {icon:"peopleOutline",route:"/cashflowuser/:id",label:"user"},
        {icon:"createOutline",route:"/cashflow/:id",label:"edit"},
    ]

   
    ,partner_view:
    {
        Code:{name:"Code",type:"string"}
        ,Name:{name:"Name",type:"string"}
        ,LocationCode:{name:"LocationCode",type:"string"}
        ,Location:{name:"Location",type:"string"} 
        ,Email:{name:"Email",type:"email"} 
        ,HomePhone:{name:"Home Phone",type:"tel"} 
        ,OfficePhone:{name:"Office Phone",type:"tel"} 
        ,MobilePhone:{name:"Mobile Phone",type:"tel"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,partner_create:
    {
        Code:{name:"Code",type:"string"}
        ,Name:{name:"Name",type:"string"}
        ,LocationCode:{name:"LocationCode",type:"string"}
        ,Location:{name:"Location",type:"string"}  
        ,Email:{name:"Email",type:"email"} 
        ,DOB:{name:"DOB",type:"date"}
        ,HomePhone:{name:"Home Phone",type:"tel"} 
        ,OfficePhone:{name:"Office Phone",type:"tel"} 
        ,MobilePhone:{name:"Mobile Phone",type:"tel"} 
        ,Address:{name:"Address",type:"description"}
        ,isCustomer:{name:"isCustomer",type:"boolean"}
        ,isSupplier:{name:"isSupplier",type:"boolean"}
        ,Active:{name:"Active",type:"boolean"}

    }
    ,partner_update:
    {
        Code:{name:"Code",type:"string"}
        ,Name:{name:"Name",type:"string"}
        ,LocationCode:{name:"LocationCode",type:"string"}
        ,Location:{name:"Location",type:"string"} 
        ,Email:{name:"Email",type:"email"} 
        ,DOB:{name:"DOB",type:"date"}
        ,HomePhone:{name:"Home Phone",type:"tel"} 
        ,OfficePhone:{name:"Office Phone",type:"tel"} 
        ,MobilePhone:{name:"Mobile Phone",type:"tel"} 
        ,Address:{name:"Address",type:"description"}
        ,isCustomer:{name:"isCustomer",type:"boolean"}
        ,isSupplier:{name:"isSupplier",type:"boolean"}
        ,Active:{name:"Active",type:"boolean"}
    }
    ,partner_filter:
    {
        
        Code:{name:"Code",type:"string",operand:"like",value:"",active:false} 
        ,Name:{name:"Name",type:"string",operand:"like",value:"",active:false} 
        ,LocationCode:{name:"Location Code",type:"string",operand:"like",value:"",active:false} 
        ,Location:{name:"Location",type:"string",operand:"like",value:"",active:false} 
        ,Email:{name:"Email",type:"email",operand:"like",value:"",active:false} 
        ,DOB:{name:"DOB",type:"date",operand:"eq",value:"",active:false}
        ,HomePhone:{name:"Home Phone",type:"tel",operand:"like",value:"",active:false} 
        ,OfficePhone:{name:"Office Phone",type:"tel",operand:"like",value:"",active:false} 
        ,MobilePhone:{name:"Mobile Phone",type:"tel",operand:"like",value:"",active:false} 
        ,Address:{name:"Address",type:"description",operand:"like",value:"",active:false}
        ,isCustomer:{name:"isCustomer",type:"boolean",operand:"eq",value:true,active:false}
        ,isSupplier:{name:"isSupplier",type:"boolean",operand:"eq",value:true,active:false}
        ,Active:{name:"Active",type:"boolean",operand:"eq",value:true,active:false}
    }
    ,partner_sort:
    {   field:"Name"
        ,direction:"asc"
        ,active:false
    }
    ,partner_page:
    {   limit:20
        ,active:false
    }
    ,partner_button:
    [
        {icon:"createOutline",route:"/partner/:id",label:"edit"},
    ]

    
    ,quantity_view:
    {

       Name:{name:"Name",type:"string"} 
        // ,Code:{name:"Code",type:"string"} 
        ,Quantity:{name:"Quantity",type:"number"} 
        // ,Active:{name:"Active",type:"boolean"}
        ,BasicQuantity:{name:"BasicQuantity",type:"string"}
    }
    ,quantity_create:
    {
        QuantityGroupID:{name:"Group",type:"option",option:{api:"quantity/group",param:null,label:"Name",value:"id"}}
        ,Name:{name:"Name",type:"string"} 
        ,Code:{name:"Code",type:"string"} 
        ,Quantity:{name:"Quantity",type:"number"} 
        ,Active:{name:"Active",type:"boolean"}

    }
    ,quantity_update:
    {
        QuantityGroupID:{name:"Group",type:"option",option:{api:"quantity/group",param:null,label:"Name",value:"id"},readonly:true}
        ,Name:{name:"Name",type:"string"} 
        ,Code:{name:"Code",type:"string",readonly:true} 
        ,Quantity:{name:"Quantity",type:"number"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,quantity_filter:
    {
        QuantityGroupID:{name:"Group",type:"option",option:{api:"quantity/group",param:null,label:"Name",value:"id"},operand:"eq",value:"",active:false} 
        ,Name:{name:"Name",type:"string",operand:"like",value:"",active:false} 
        ,Code:{name:"Code",type:"string",operand:"like",value:"",active:false} 
        ,Quantity:{name:"Quantity",type:"number",operand:"eq",value:"",active:false} 
        ,Active:{name:"Active",type:"boolean",operand:"eq",value:true,active:false}

    }
    ,quantity_sort:
    {   field:"Name"
        ,direction:"asc"
        ,active:false
    }
    ,quantity_page:
    {   limit:20
        ,active:false
    }
    ,quantity_button:
    [
        {icon:"createOutline",route:"/quantity/:id",label:"edit"},
    ]

    ,quantitygroup_view:
    {

       Name:{name:"Group",type:"string"} 
        // ,BasicQuantityCode:{name:"Basic Quantity",type:"string"} 
        // ,Active:{name:"Active",type:"boolean"}
    }
    ,quantitygroup_create:
    {
        Group:{name:"Group Name",type:"string"} 
        ,Name:{name:"Quantity Name",type:"string"} 
        ,Code:{name:"Quantity Code",type:"string"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,quantitygroup_update:
    {
        Name:{name:"Name",type:"string"} 
        ,Description:{name:"Description",type:"description"}
        ,BasicQuantityCode:{name:"Basic Quantity",type:"option",option:{api:"quantity?filter[QuantityGroupID]=:id",param:null,label:"Name",value:"Code"}} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,quantitygroup_filter:
    {
        Name:{name:"Name",type:"string",operand:"like",value:"",active:false} 
        ,Description:{name:"Description",type:"description",operand:"like",value:"",active:false}
        ,Active:{name:"Active",type:"boolean",operand:"eq",value:true,active:false}

       
    }
    ,quantitygroup_sort:
    {   field:"Name"
        ,direction:"asc"
        ,active:false
    }
    ,quantitygroup_page:
    {   limit:20
        ,active:false
    }
    ,quantitygroup_button:
    [
        {icon:"createOutline",route:"/quantitygroup/:id",label:"edit group"},
        {icon:"addOutline",route:"/quantitygroup/:id/add",label:"add variant"},
    ]

    
    ,item_view:
    {
         Code:{name:"Code",type:"string"} 
        ,Name:{name:"Name",type:"string"} 
        ,QuantityCode:{name:"Quantity",type:"string"}
        ,Currency:{name:"Currency",type:"string"} 
        ,StockPrice:{name:"Stock Price",type:"number"} 
        ,SellPrice:{name:"Sell Price",type:"number"} 
        ,DiscountPercent:{name:"Discount(%)",type:"number"}

    }
    ,item_create:
    {
        ItemGroupID:{name:"Group",type:"option",option:{api:"item/group",param:null,label:"Name",value:"id"}}
        ,Code:{name:"Code",type:"string"} 
        ,Name:{name:"Name",type:"string"} 
        ,QuantityCode:{name:"Quantity",type:"option",option:{api:"item/quantity/:ItemGroupID",param:null,label:"Name",value:"Code"}}
        ,Currency:{name:"Currency",type:"option",option:{api:"currency",param:null,label:"Name",value:"Currency"}} 
        ,StockPrice:{name:"Stock Price",type:"number"} 
        ,SellPrice:{name:"Sell Price",type:"number"} 
        ,DiscountPercent:{name:"Discount(%)",type:"number"}
        ,Barcode:{name:"Barcode",type:"string"} 
        ,Remarks:{name:"Remarks",type:"description"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,item_update:
    {
        ItemGroupID:{name:"Group",type:"option",option:{api:"item/group",param:null,label:"Name",value:"id"},readonly:true}
        ,Code:{name:"Code",type:"string"} 
        ,Name:{name:"Name",type:"string"} 
        ,QuantityCode:{name:"Quantity",type:"option",option:{api:"quantity",param:null,label:"Name",value:"Code"},readonly:false}
        ,Currency:{name:"Currency",type:"option",option:{api:"currency",param:null,label:"Name",value:"Currency"},readonly:false} 
        ,StockPrice:{name:"Stock Price",type:"number"} 
        ,SellPrice:{name:"Sell Price",type:"number"} 
        ,DiscountPercent:{name:"Discount(%)",type:"number"}
        ,Barcode:{name:"Barcode",type:"string"} 
        ,Remarks:{name:"Remarks",type:"description"} 
        ,Active:{name:"Active",type:"boolean"}

    }
    ,item_filter:
    {
        ItemGroupID:{name:"Group",type:"option",option:{api:"item/group",param:null,label:"Name",value:"id"},operand:"eq",value:"",active:false}
        ,Code:{name:"Code",type:"string",operand:"like",value:"",active:false} 
        ,Name:{name:"Name",type:"string",operand:"like",value:"",active:false} 
        ,QuantityCode:{name:"Quantity",type:"option",option:{api:"quantity",param:null,label:"Name",value:"Code"},operand:"eq",value:"",active:false}
        ,Currency:{name:"Currency",type:"option",option:{api:"currency",param:null,label:"Name",value:"Currency"},operand:"eq",value:"",active:false} 
        ,StockPrice:{name:"Stock Price",type:"number",operand:"eq",value:"",active:false} 
        ,SellPrice:{name:"Sell Price",type:"number",operand:"eq",value:"",active:false} 
        ,DiscountPercent:{name:"Discount(%)",type:"number",operand:"eq",value:"",active:false} 
        ,Barcode:{name:"Barcode",type:"string",operand:"like",value:"",active:false} 
        ,Remarks:{name:"Remarks",type:"description",operand:"like",value:"",active:false} 
        ,Active:{name:"Active",type:"boolean",operand:"eq",value:true,active:false}

    }
    ,item_sort:
    {   field:"Name"
        ,direction:"asc"
        ,active:false
    }
    ,item_page:
    {   limit:20
        ,active:false
    }
    ,item_button:
    [
        {icon:"createOutline",route:"/item/:id",label:"edit"},
    ]

    ,itemgroup_view:
    {
        Code:{name:"Code",type:"string"} 
       ,Name:{name:"Group",type:"string"} 
        // ,BasicitemCode:{name:"Basic item",type:"string"} 
        // ,Active:{name:"Active",type:"boolean"}
    }
    ,itemgroup_create:
    {
        GroupCode:{name:"Group Code",type:"string"} 
        ,GroupName:{name:"Group Name",type:"string"} 
        ,QuantityGroupID:{name:"Quantity Group",type:"option",option:{api:"quantity/group",param:null,label:"Name",value:"id"}}
        ,ItemCode:{name:"Item Code",type:"string"} 
        ,ItemName:{name:"Item Name",type:"string"} 
        ,QuantityCode:{name:"Quantity",type:"option",option:{api:"quantity?filter[QuantityGroupID]=:QuantityGroupID",param:null,label:"Name",value:"Code"}}
        ,Currency:{name:"Currency",type:"option",option:{api:"cashflow/currency",param:null,label:"name",value:"id"}} 
        ,StockPrice:{name:"Stock Price",type:"number"} 
        ,SellPrice:{name:"Sell Price",type:"number"} 
        ,DiscountPercent:{name:"Discount(%)",type:"number"}
        ,Remarks:{name:"Remarks",type:"description"} 
        ,Active:{name:"Active",type:"boolean"}
    }
    ,itemgroup_update:
    {
        Code:{name:"Group Code",type:"string"} 
        ,Name:{name:"Group Name",type:"string"} 
        ,QuantityGroupID:{name:"Quantity Group",type:"option",option:{api:"quantity/group",param:null,label:"Name",value:"id"},readonly:true}
        ,Active:{name:"Active",type:"boolean"}
    }
    ,itemgroup_filter:
    {
        Code:{name:"Group Code",type:"string",operand:"like",value:"",active:false} 
        ,Name:{name:"Group Name",type:"string",operand:"like",value:"",active:false} 
        ,QuantityGroupID:{name:"Quantity Group",type:"option",option:{api:"quantity/group",param:null,label:"Name",value:"id"},operand:"eq",value:"",active:false}
        ,Active:{name:"Active",type:"boolean",operand:"eq",value:"",active:false}

    }
    ,itemgroup_sort:
    {   field:"Name"
        ,direction:"asc"
        ,active:false
    }
    ,itemgroup_page:
    {   limit:20
        ,active:false
    }
    ,itemgroup_button:
    [
        {icon:"createOutline",route:"/itemgroup/:id",label:"edit group"},
        {icon:"addOutline",route:"/itemgroup/:id/add",label:"add item"},
    ]

    ,stockopname_view:
    {
        TransactionDate:{name:"Date",type:"date"} 
        ,TransactionCode:{name:"Code",type:"string"} 
        ,Type:{name:"Type",type:"string"} 
        ,SendBy:{name:"SendBy",type:"string"}
        ,ReceivedBy:{name:"ReceivedBy",type:"string"} 
        ,Remarks:{name:"Remarks",type:"description"} 
        ,Status:{name:"Status",type:"string"}
    }
    ,stockopname_create:
    {
        TransactionDate:{name:"Date",type:"date"} 
        ,TransactionCode:{name:"Code",type:"string"} 
        ,Type:{name:"Type",type:"option",option:{api:"stockopname/type",param:null,label:"label",value:"value"}} 
        ,SendBy:{name:"SendBy",type:"string"}
        ,ReceivedBy:{name:"ReceivedBy",type:"string"} 
        ,Remarks:{name:"Remarks",type:"description"} 

    }
    ,stockopname_update:
    {
        TransactionDate:{name:"TransactionDate",type:"date"} 
        ,TransactionCode:{name:"TransactionCode",type:"string"} 
        ,Type:{name:"Type",type:"option",option:{api:"stockopname/type",param:null,label:"label",value:"value"}} 
        ,SendBy:{name:"SendBy",type:"string"}
        ,ReceivedBy:{name:"ReceivedBy",type:"string"} 
        ,Remarks:{name:"Remarks",type:"description"} 

    }
    ,stockopname_filter:
    {
        TransactionDate:{name:"Date",type:"date",operand:"eq",value:"",active:false} 
        ,TransactionCode:{name:"Code",type:"string",operand:"like",value:"",active:false} 
        ,Type:{name:"Type",type:"option",option:{api:"stockopname/type",param:null,label:"label",value:"value"},operand:"eq",value:"",active:false} 
        ,SendBy:{name:"SendBy",type:"string",operand:"like",value:"",active:false}
        ,ReceivedBy:{name:"ReceivedBy",type:"string",operand:"like",value:"",active:false} 
        ,Remarks:{name:"Remarks",type:"description",operand:"like",value:"",active:false} 

    }
    ,stockopname_sort:
    {   field:"TransactionDate"
        ,direction:"desc"
        ,active:true
    }
    ,stockopname_page:
    {   limit:20
        ,active:false
    }
    ,stockopname_button:
    [
        {icon:"printOutline",route:"/stockopname/:id/print",label:"print"},
        {icon:"eyeOutline",route:"/stockopname/:id",label:"view"},
    ]

    ,stockopnamedetail_view:
    {
        Flow:{name:"Flow",type:"string"}
        ,InventoryID:{name:"Inventory",type:"option",option:{api:"inventory",param:null,label:"Name",value:"id"}}
        ,SubGroup:{name:"Sub Group",type:"string"}
        ,ItemID:{name:"Item",type:"string"}
        ,QuantityID:{name:"Quantity",type:"string"}
        ,Status:{name:"Status",type:"string"}
    }
    ,stockopnamedetail_create:
    {
        Flow:{name:"Flow",type:"string"}
        ,InventoryID:{name:"Inventory",type:"option",option:{api:"inventory",param:null,label:"Name",value:"id"}}
        ,SubGroup:{name:"Sub Group",type:"string"}
        ,ItemID:{name:"Item",type:"string"}
        ,QuantityID:{name:"Quantity",type:"string"}
        ,Status:{name:"Status",type:"string"}
    }
    ,stockopnamedetail_update:
    {
        Flow:{name:"Flow",type:"string"}
        ,InventoryID:{name:"Inventory",type:"option",option:{api:"inventory",param:null,label:"Name",value:"id"}}
        ,SubGroup:{name:"Sub Group",type:"string"}
        ,ItemID:{name:"Item",type:"string"}
        ,QuantityID:{name:"Quantity",type:"string"}
        ,Status:{name:"Status",type:"string"}
    }
    ,stockopnamedetail_filter:
    {
        Flow:{name:"Flow",type:"string",operand:"like",value:"",active:false}
        ,InventoryID:{name:"Inventory",type:"option",option:{api:"inventory",param:null,label:"Name",value:"id"},operand:"eq",value:"",active:false}
        ,SubGroup:{name:"Sub Group",type:"string",operand:"like",value:"",active:false}
        ,ItemID:{name:"Item",type:"string",operand:"like",value:"",active:false}
        ,QuantityID:{name:"Quantity",type:"string",operand:"like",value:"",active:false}
        ,Status:{name:"Status",type:"string",operand:"like",value:"",active:false}
    }
    ,stockopnamedetail_sort:
    {   field:"Flow"
        ,direction:"asc"
        ,active:false
    }
    ,stockopnamedetail_page:
    {   limit:20
        ,active:false
    }
    ,stockopnamedetail_button:
    [
        // {icon:"createOutline",route:"stockopnamedetail/:id",label:"edit"},
        // {icon:"addOutline",route:"stockopnamedetail/:id/add",label:"add"},
    ]


    
    ,sale_view:
    {
        SaleDate:{name:"Date",type:"date"} 
        ,SaleCode:{name:"Code",type:"string"} 
        ,InventoryName:{name:"Inventory",type:"string"}
        ,CustomerName:{name:"Customer", type:"string"}
        // ,InventoryID:{name:"Inventory",type:"string"} 
        // ,CustomerID:{name:"Customer",type:"string"}
        ,Currency:{name:"Currency",type:"string"}
        // ,TotalItemPrice:{name:"TotalItemPrice",type:"string"} 
        // ,TotalItemDiscount:{name:"TotalItemDiscount",type:"string"} 
        // ,TotalItemBonus:{name:"TotalItemBonus",type:"string"} 
        // ,TotalSale:{name:"TotalItemBonus",type:"string"} 
        // ,Discount:{name:"Discount",type:"string"} 
        // ,TotalDelivery:{name:"TotalDelivery",type:"string"} 
        ,TotalPayment:{name:"TotalInvoice",type:"number"} 
        ,Status:{name:"Status",type:"string"} 
        ,Remarks:{name:"Remarks",type:"description"} 
    }
    ,sale_create:
    {
        SaleDate:{name:"Date",type:"date"} 
        ,SaleCode:{name:"Code",type:"string"} 
        // ,InventoryName:{name:"Inventory",type:"string"}
        // ,CustomerName:{name:"Customer", type:"string"}
        ,InventoryID:{name:"Inventory",type:"string"} 
        ,CustomerID:{name:"Customer",type:"string"}
        ,Currency:{name:"Currency",type:"string"}
        ,TotalItemPrice:{name:"TotalItemPrice",type:"string"} 
        ,TotalItemDiscount:{name:"TotalItemDiscount",type:"string"} 
        ,TotalItemBonus:{name:"TotalItemBonus",type:"string"} 
        ,TotalSale:{name:"TotalItemBonus",type:"string"} 
        ,Discount:{name:"Discount",type:"string"} 
        ,TotalDelivery:{name:"TotalDelivery",type:"string"} 
        ,TotalPayment:{name:"TotalInvoice",type:"string"} 
        ,Status:{name:"Status",type:"string"} 
        ,Remarks:{name:"Remarks",type:"description"} 

    }
    ,sale_update:
    {
        SaleDate:{name:"Date",type:"date"} 
        ,SaleCode:{name:"Code",type:"string"} 
        ,InventoryID:{name:"Inventory",type:"string"} 
        ,CustomerID:{name:"Customer",type:"string"}
        ,Currency:{name:"Currency",type:"string"}
        ,TotalItemPrice:{name:"TotalItemPrice",type:"string"} 
        ,TotalItemDiscount:{name:"TotalItemDiscount",type:"string"} 
        ,TotalItemBonus:{name:"TotalItemBonus",type:"string"} 
        ,TotalSale:{name:"TotalItemBonus",type:"string"} 
        ,Discount:{name:"Discount",type:"string"} 
        ,TotalDelivery:{name:"TotalDelivery",type:"string"} 
        ,TotalPayment:{name:"TotalPayment",type:"string"} 
        ,Status:{name:"Status",type:"string"} 
        ,Remarks:{name:"Remarks",type:"description"} 

    }
    ,sale_filter:
    {
        SaleDate:{name:"Date",type:"date",operand:"eq",value:"",active:false} 
        ,SaleCode:{name:"Code",type:"string",operand:"like",value:"",active:false} 
        ,InventoryID:{name:"Inventory",type:"option",option:{api:"inventory",param:null,label:"name",value:"id"},operand:"eq",value:"",active:false} 
        ,CustomerID:{name:"Customer",type:"customer",operand:"eq",value:"",active:false}
        ,Currency:{name:"Currency",type:"option",option:{api:"currency",param:null,label:"Currency",value:"Currency"},operand:"like",value:"",active:false}
        // ,TotalItemPrice:{name:"TotalItemPrice",type:"string",operand:"like",value:"",active:false} 
        // ,TotalItemDiscount:{name:"TotalItemDiscount",type:"string",operand:"like",value:"",active:false} 
        // ,TotalItemBonus:{name:"TotalItemBonus",type:"string",operand:"like",value:"",active:false} 
        // ,TotalSale:{name:"TotalItemBonus",type:"string",operand:"like",value:"",active:false} 
        // ,Discount:{name:"Discount",type:"string",operand:"like",value:"",active:false} 
        // ,TotalDelivery:{name:"TotalDelivery",type:"string",operand:"like",value:"",active:false} 
        // ,TotalPayment:{name:"TotalPayment",type:"string",operand:"like",value:"",active:false} 
        ,Status:{name:"Status",type:"string",operand:"like",value:"",active:false} 
        ,Remarks:{name:"Remarks",type:"string",operand:"like",value:"",active:false} 

    }
    ,sale_sort:
    {   field:"SaleDate"
        ,direction:"desc"
        ,active:true
    }
    ,sale_page:
    {   limit:20
        ,active:false
    }
    ,sale_button:
    [
        {icon:"printOutline",route:"/sale/:id/print",label:"print"},
        {icon:"eyeOutline",route:"/sale/:id",label:"view"},
    ]

    ,saledetail_view:
    {
       
        ItemID:{name:"Item",type:"string"}
        ,Quantity:{name:"Quantity",type:"string"}
        ,QuantityCode:{name:"Qty",type:"string"}
        ,Currency:{name:"Currency",type:"string"}
        ,ItemSellPrice:{name:"Price",type:"string"}
        ,ItemBuyPrice:{name:"Stock Price",type:"string"}
        ,TotalPrice:{name:"Total Price",type:"string"}
        ,Discount:{name:"Discount",type:"string"}
        ,isBonus:{name:"Free",type:"string"}
        ,Status:{name:"Status",type:"string"}
    }
    ,saledetail_create:
    {
        ItemID:{name:"Item",type:"string"}
        ,Quantity:{name:"Quantity",type:"string"}
        ,QuantityCode:{name:"Qty",type:"string"}
        ,Currency:{name:"Currency",type:"string"}
        ,ItemSellPrice:{name:"Price",type:"string"}
        ,ItemBuyPrice:{name:"Stock Price",type:"string"}
        ,TotalPrice:{name:"Total Price",type:"string"}
        ,Discount:{name:"Discount",type:"string"}
        ,isBonus:{name:"Free",type:"string"}
        ,Status:{name:"Status",type:"string"}
    }
    ,saledetail_update:
    {
        ItemID:{name:"Item",type:"string"}
        ,Quantity:{name:"Quantity",type:"string"}
        ,QuantityCode:{name:"Qty",type:"string"}
        ,Currency:{name:"Currency",type:"string"}
        ,ItemSellPrice:{name:"Price",type:"string"}
        ,ItemBuyPrice:{name:"Stock Price",type:"string"}
        ,TotalPrice:{name:"Total Price",type:"string"}
        ,Discount:{name:"Discount",type:"string"}
        ,isBonus:{name:"Free",type:"string"}
        ,Status:{name:"Status",type:"string"}
    }
    ,saledetail_filter:
    {
        ItemID:{name:"Item",type:"string",operand:"like",value:"",active:false}
        ,Quantity:{name:"Quantity",type:"string",operand:"like",value:"",active:false}
        ,QuantityCode:{name:"Qty",type:"string",operand:"like",value:"",active:false}
        ,Currency:{name:"Currency",type:"string",operand:"like",value:"",active:false}
        ,ItemSellPrice:{name:"Price",type:"string",operand:"like",value:"",active:false}
        ,ItemBuyPrice:{name:"Stock Price",type:"string",operand:"like",value:"",active:false}
        ,TotalPrice:{name:"Total Price",type:"string",operand:"like",value:"",active:false}
        ,Discount:{name:"Discount",type:"string",operand:"like",value:"",active:false}
        ,isBonus:{name:"Free",type:"string",operand:"like",value:"",active:false}
        ,Status:{name:"Status",type:"string",operand:"like",value:"",active:false}

       
    }
    ,saledetail_sort:
    {   field:"ItemID"
        ,direction:"asc"
        ,active:false
    }
    ,saledetail_page:
    {   limit:20
        ,active:false
    }
    ,saledetail_button:
    [
        // {icon:"createOutline",route:"stockopnamedetail/:id",label:"edit"},
        // {icon:"addOutline",route:"stockopnamedetail/:id/add",label:"add"},
    ]

    ,payment_view:
    {
        PaymentType:{name:"Type",type:"string"}
        ,PaymentDate:{name:"Date",type:"date"} 
        ,PaymentCode:{name:"Code",type:"string"} 
        ,InventoryName:{name:"Inventory",type:"string"}
        ,PartnerName:{name:"Partner", type:"string"}
        
        ,Currency:{name:"Currency",type:"string"}
        ,Debit:{name:"Debit",type:"number"}
        ,Credit:{name:"Credit",type:"number"}
       
        ,Status:{name:"Status",type:"string"} 
        
    }
    ,payment_create:
    {
        PaymentType:{name:"Type",type:"string"}
        ,PaymentDate:{name:"Date",type:"date"} 
        ,PaymentCode:{name:"Code",type:"string"} 
        ,InventoryName:{name:"Inventory",type:"string"}
        ,PartnerName:{name:"Partner", type:"string"}
        
        ,Currency:{name:"Currency",type:"string"}
        ,Debit:{name:"Debit",type:"string"}
        ,Credit:{name:"Credit",type:"number"}
       
        ,Status:{name:"Status",type:"number"} 

    }
    ,payment_update:
    {
        PaymentType:{name:"Type",type:"string"}
        ,PaymentDate:{name:"Date",type:"date"} 
        ,PaymentCode:{name:"Code",type:"string"} 
        ,InventoryName:{name:"Inventory",type:"string"}
        ,PartnerName:{name:"Partner", type:"string"}
        
        ,Currency:{name:"Currency",type:"string"}
        ,Debit:{name:"Debit",type:"number"}
        ,Credit:{name:"Credit",type:"number"}
        ,Status:{name:"Status",type:"string"} 

    }
    ,payment_filter:
    {
        PaymentType:{name:"Type",type:"string",operand:"like",value:"",active:false}
        ,PaymentDate:{name:"Date",type:"date",operand:"like",value:"",active:false} 
        ,PaymentCode:{name:"Code",type:"string",operand:"like",value:"",active:false} 
        ,InventoryID:{name:"Inventory",type:"option",option:{api:"inventory",param:null,label:"name",value:"id"},operand:"eq",value:"",active:false} 
        ,PartnerID:{name:"Partner", type:"partner",operand:"eq",value:"",active:false}
        ,Currency:{name:"Currency",type:"string",operand:"like",value:"",active:false}
        ,Debit:{name:"Debit",type:"number",operand:"eq",value:"",active:false}
        ,Credit:{name:"Credit",type:"number",operand:"eq",value:"",active:false}
        ,Status:{name:"Status",type:"string",operand:"like",value:"",active:false} 

    }
    ,payment_sort:
    {   field:"PaymentDate"
        ,direction:"desc"
        ,active:true
    }
    ,payment_page:
    {   limit:20
        ,active:false
    }
    ,payment_button:
    [
        {icon:"printOutline",route:"/payment/:id/print",label:"print"},
        {icon:"eyeOutline",route:"/payment/:id",label:"view"},
    ]
    ,receivable_view:
    {
        ReceivableType:{name:"Type",type:"string"}
        ,TransactionDate:{name:"Date",type:"date"} 
        ,TransactionNumber:{name:"Code",type:"string"} 
        ,InventoryName:{name:"Inventory",type:"string"}
        ,PartnerName:{name:"Partner", type:"string"}
        ,WithDrawalDate:{name:"Due Date",type:"date"}
        ,ChequeNo:{name:"Cheque/Giro No",type:"string"}
        ,Currency:{name:"Currency",type:"string"}
        ,Amount:{name:"Amount",type:"number"}
        ,LeftPayment:{name:"LeftPayment",type:"number"}
        
        ,Status:{name:"Status",type:"string"} 
        
    }
    ,receivable_create:
    {
        ReceivableType:{name:"Type",type:"string"}
        ,TransactionDate:{name:"Date",type:"date"} 
        ,TransactionNumber:{name:"Code",type:"string"} 
        ,InventoryName:{name:"Inventory",type:"string"}
        ,PartnerName:{name:"Partner", type:"string"}
        ,WithDrawalDate:{name:"Due Date",type:"date"}
        ,ChequeNo:{name:"Cheque/Giro No",type:"string"}
        ,Currency:{name:"Currency",type:"string"}
        ,Amount:{name:"Amount",type:"number"}
        ,LeftPayment:{name:"LeftPayment",type:"number"}
        
        ,Status:{name:"Status",type:"string"} 

    }
    ,receivable_update:
    {
        ReceivableType:{name:"Type",type:"string",readonly:true}
        ,TransactionDate:{name:"Date",type:"date",readonly:true} 
        ,TransactionNumber:{name:"Code",type:"string",readonly:true} 
        ,InventoryName:{name:"Inventory",type:"string",readonly:true}
        ,PartnerName:{name:"Partner", type:"string",readonly:true}
        ,WithDrawalDate:{name:"Due Date",type:"date",readonly:true}
        ,ChequeNo:{name:"Cheque/Giro No",type:"string",readonly:true}
        ,Currency:{name:"Currency",type:"string",readonly:true}
        ,Amount:{name:"Amount",type:"number",readonly:true}
        ,LeftPayment:{name:"LeftPayment",type:"number",readonly:true}
        
        ,Status:{name:"Status",type:"string",readonly:true} 

    }
    ,receivable_filter:
    {
        ReceivableType:{name:"Type",type:"string",operand:"like",value:"",active:false}
        ,TransactionDate:{name:"Date",type:"date",operand:"eq",value:"",active:false} 
        ,TransactionNumber:{name:"Code",type:"string",operand:"like",value:"",active:false} 
        ,InventoryName:{name:"Inventory",type:"string",operand:"like",value:"",active:false}
        ,PartnerName:{name:"Partner", type:"string",operand:"like",value:"",active:false}
        ,WithDrawalDate:{name:"Due Date",type:"date",operand:"eq",value:"",active:false}

        ,Currency:{name:"Currency",type:"string",operand:"like",value:"",active:false}
        ,Amount:{name:"Amount",type:"number",operand:"eq",value:"",active:false}
        ,LeftPayment:{name:"LeftPayment",type:"number",operand:"eq",value:"",active:false}
        
        ,Status:{name:"Status",type:"string",operand:"like",value:"WAIT_PAYMENT",active:true} 
       

    }
    ,receivable_sort:
    {   field:"TransactionDate"
        ,direction:"desc"
        ,active:true
    }
    ,receivable_page:
    {   limit:20
        ,active:false
    }
    ,receivable_button:
    [
        {icon:"printOutline",route:"/receivable/:id/print",label:"print"},

        {icon:"eyeOutline",route:"/receivable/:id",label:"view"},
    ]

    ,changemypassword:
    {
        oldpassword:{name:"Old Password",type:"password"}
        ,newpassword:{name:"New Password",type:"password"} 
        ,reconfirmpassword:{name:"Reconfirm Password",type:"password"} 

    }
    ,changepassword:
    {
       newpassword:{name:"New Password",type:"password"} 
        ,reconfirmpassword:{name:"Reconfirm Password",type:"password"} 

    }
}

export const getTemplate=(template:any)=>{
    return data[template];
}

export const getOptionsdata=(template:any)=>{
    var result:any =[];
    const t = data[template];
    // console.log("t",t);
     Object.keys(t).map((key:any, value) => {
        var type:any = t[key].type;
        var option:any = t[key].option;
        var optiondata:any = [];
        if(type==="option"){
            
            optiondata =  getOption(option.api,option.param,option.label,option.value).then((value)=>{result.push({key:optiondata});});
            
        }
    }
    );
    return result;
}

const errorhandler = (err:any) =>{
    // setToastMessage(err.message);
    // if(err.response){
    //     if(err.response.status==422){
    //         setErrors(err.response.data);
    //     }
    // }
    // else{
        console.log("errorhandler",err.message);
    // }
  }
export const getOption=async(api:any,param:any,label:string,value:string)=>{
    
    const data:any = await API.view(api,param).catch(e=>errorhandler(e));;
    var result:any =[];
    // console.log(label);
    // console.log(value);
    // console.log(data);

    if(data!==undefined){
        data.forEach((d:any) => {
            result.push({"label":d[label],"value":d[value]});
            // result.push( <IonSelectOption value={d[value]}>{d[label]}</IonSelectOption>);

        });
    }

    // console.log(result);
    return result;
    
}
