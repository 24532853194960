import React from 'react';

import { IonCard, IonCardHeader, IonItem, IonLabel, IonAvatar, IonCardContent, IonList, IonButtons, IonButton, IonInput, IonToggle, IonTextarea, IonIcon, IonDatetime } from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import { iconMap } from '../IconMap';
import NumericField from './NumericField';
import DateInput from '../../components/ActiveForm/DateInput';


interface FieldProps {
 data : any;
 id:any;
 name: string;
 template: any[];
 buttons:any[];
}

const Card: React.FC<FieldProps> = ({ data,name,id,template,buttons }) => {

  
    const generatebuttons=()=>{
      var result:any= [];
      
      
      Object.keys(buttons).map((key:any, value) => {
        var route:any = buttons[key].route;
        var icon:any = buttons[key].icon;
        var label:any = buttons[key].label;
        result.push(
                <IonButton title={label} key={key} routerLink={route.replace(":id",id)}>
                  <IonIcon icon={iconMap[icon]} slot="icon-only" />
                  
                </IonButton>
          );
      });

      return result;
    }
    const generatefields=()=>{
        var result:any= [];
        if(data&&template){
          Object.keys(template).map((key:any, value) => {
              var type:any = template[key].type;
              // var view:any = template[key].view;
              // if(view){
                if(type==="string"){
                    result.push(
                    <IonItem key = {key} detail={false} >
                        <IonLabel position="floating" color="primary">
                        {template[key].name}
                        </IonLabel>
                        <IonInput name={key} value={data[key]} readonly/>   
                    </IonItem>
                    );
                }
                else if(type==="boolean"){
                    result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <IonInput name={key} value={data[key]?"True":"False"} readonly/>   
                        </IonItem>

                        );
                }
                else if(type==="number"){
                    result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <NumericField type="tel" name={key} value={data[key]} readonly={true}/>   
                        </IonItem>
                        );
                }
                else if(type==="date"){
                  result.push(
                      <IonItem key = {key} detail={false} >
                          <IonLabel position="floating" color="primary">
                          {template[key].name}
                          </IonLabel>
                          <DateInput readonly displayFormat="DD MMM YYYY" placeholder="Select Date" value={data[key]} ></DateInput>
                      </IonItem>
                      );
              }
                else if(type==="description"){
                    result.push(
                    <IonItem key = {key} detail={false} >
                        <IonLabel position="floating" color="primary">
                        {template[key].name}
                        </IonLabel>
                        <IonTextarea name={key} value={data[key]} readonly/>  
                    </IonItem>
                    );
                }
                else{
                    result.push(
                        <IonItem key = {key} detail={false} >
                            <IonLabel position="floating" color="primary">
                            {template[key].name}
                            </IonLabel>
                            <IonInput name={key} value={data[key]} readonly/>  
                        </IonItem>
                        );
                }
                // }
            },()=>{
              
            })
    
              
          }
          return result;
        }
        
      
  return (
    <>
      <IonCard className="field-card">
        <IonCardHeader>
          <IonItem  className="field-item">
         
            <IonButtons slot="end">
                {/* <IonButton routerLink={`/${name}/${id}`} placeholder={"edit"}>
                  <IonIcon icon={createOutline} slot="icon-only" />
                </IonButton>
                <IonButton routerLink={`/${name}/${id}`} >
                  <IonIcon icon={createOutline} slot="icon-only" />
                </IonButton> */}
                {generatebuttons()}
            </IonButtons>
            
          </IonItem>
        </IonCardHeader>

        <IonCardContent>
          <IonList lines="none">

            {generatefields()}
          </IonList>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default Card;