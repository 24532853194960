import React, { Component } from 'react';
import { Route, withRouter, RouteComponentProps, Redirect, RouteChildrenProps, RouteProps } from "react-router";
import { connect } from '../data/connect';
import * as H from 'history';
import RedirectToLogin from './RedirectToLogin';
import { setIsLoggedIn, setUsername } from '../data/user/user.actions';
import Account from '../pages/Account';
import { restaurant } from 'ionicons/icons';
interface DispatchProps {

    setIsLoggedIn: typeof setIsLoggedIn;
    setUsername: typeof setUsername;
  }

interface StateProps {
    isAuthenticated: boolean;

}

interface OwnProps extends RouteProps{
  // setCurrentPath?: (path:any) => void;
}
interface PrivateRouteProps extends  StateProps,OwnProps,OwnProps {
    
 }

const PrivateRoute : React.FC<PrivateRouteProps> = ({isAuthenticated,...rest }) => {
    // console.log("isauthenticated p",isAuthenticated);
    // return (
    //     <Route {...rest} render={props => (
    //       isAuthenticated === true?
    //           <Component {...props} />
    //       : <Redirect to="/login" />
    //   )} />
    // );
    // return isAuthenticated === true?
    // (<Route {...rest} />)
    // :
    // (<Route path={rest.path} render={() => {
    //     return <RedirectToLogin
    //       setIsLoggedIn={setIsLoggedIn}
    //       setUsername={setUsername}
    //     />;
    //   }} />)
    // <Route path='/' exact render={() => <Redirect to={this.props.isAuthenticated ? '/dashboard' : '/login'}/>}/>

    return(isAuthenticated?<Route {...rest} />
      :<Route path={rest.path} exact={rest.exact} render={() => <Redirect to={'/logout'}/>}/>);
    // return (
    // <Route path={path} render={(props ) =>{
    //     console.log("component",component);
    //     console.log("props",props);
    //     console.log("rest",rest);
    //     return (
    //     isAuthenticated === true
    //         ?  React.createElement(component, props)
    //         : <RedirectToLogin
    //                 setIsLoggedIn={setIsLoggedIn}
    //                 setUsername={setUsername}
    //             />
    //         )
    //     }
    // } 
        
    // />
    // )
}
  

  export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
      
      isAuthenticated: state.user.isLoggedin,
      
    }),
    mapDispatchToProps: {setIsLoggedIn, setUsername},

    component: PrivateRoute
  })
  