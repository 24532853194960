import { IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import { connect } from '../../data/connect';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getOption } from '../../data/form/template';
import { setIsLoggedIn } from '../../data/user/user.actions';

interface OwnProps {
    name:any,
    value:any,
    option:any,
    disabled?:boolean
    dataChanged: (data:any) => void;
}
interface StateProps {
  
};

interface DispatchProps {
    
}
type SelectfieldProps = OwnProps & StateProps & DispatchProps;

const Selectfield: React.FC<SelectfieldProps> = ({name,value,option,disabled,dataChanged}) => {
    const [optiondatas,setOptiondatas] = useState<any>([]);
    const [isloading,setIsloading] = useState(false);
    useEffect(() => {

       
        loadOptiondata();
        // eslint-disable-next-line
      }, [option.api,option.param,option.datas]);


    const  loadOptiondata =async()=>{
        setIsloading(true);
        // console.log(option.datas);
        if(option.datas){

            const optiondatas:any =
                option.datas.map((d:any) => {
                   return {"label":d[option.label],"value":d[option.value]};
        
                });
            
            await setOptiondatas(optiondatas);

        }
        else{
        const optiondatas:any = await getOption(option.api,option.param,option.label,option.value);
        setOptiondatas(optiondatas);
        }
        setIsloading(false);
    }

    
    const generateOption=()=>{
        var result:any = [];
        // console.log(optiondatas);
        optiondatas.forEach((od:any) => {
            // console.log(od.value);
            // console.log(od.data);
            result.push( <IonSelectOption key={od.value} value={od.value}>{od.label}</IonSelectOption>);

        });
        

        return result;
    }

    return (
        
        <>
        {!isloading&&optiondatas.length>0?
        <IonSelect key={value} value={value}  placeholder={"Please Select "+name} onIonChange={e => dataChanged(e)} disabled={disabled}>
            {generateOption()}
                             
        </IonSelect>
        :  <IonInput value={value}/>   

        }
        </>

    );
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state, OwnProps) => ({
     
    }),
    mapDispatchToProps: {
      
    },
    component: (Selectfield)
  })