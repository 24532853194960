import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard, IonDatetime, IonCardHeader, IonCardContent, IonModal, IonSelect, IonSelectOption, IonAlert, IonItemDivider, useIonViewWillEnter } from '@ionic/react';

import { connect } from '../../data/connect';
import './SaleDelivery.scss';
import API from '../../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { add, arrowBack, grid, informationCircleOutline, remove, save as saveicon, saveOutline } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
import Selectfield from '../../components/ActiveForm/Selectfield';
import SearchSaleItemForm from './SearchSaleItemForm';
import NumberFormat from 'react-number-format';
import NumericField from '../../components/ActiveForm/NumericField';
import DateInput from '../../components/ActiveForm/DateInput';

interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
 
};

interface DispatchProps {

}

type SaleDeliveryProps = OwnProps & StateProps & DispatchProps;

const SaleDelivery: React.FC<SaleDeliveryProps> = ({location,match}) => {
  const ionRouterContext = useContext(IonRouterContext);
  var today = new Date();

  const initdate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    const apipath = 'stockopname';
   
    const [errors,setErrors] = useState(
        []
    );

    const [errordetails,setErrordetails] = useState<any>(
        []
    );
    const [data,setData] = useState<any>();
    const [sender,setSender] = useState<any>();
    const [details,setDetails] = useState<any>([]);
    const [sale,setSale] = useState<any>();
    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showSearchItemModal, setShowSearchItemModal] = useState(false);
    const [myInventory, setMyInventory] = useState<any>([]);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeader, setAlertHeader] = useState("Warning");
    const [alertMessage, setAlertMessage] = useState("Warning");
    const [alertButtons, setAlertButtons] = useState<any>(['Ok']);

    const pageRef = useRef<HTMLElement>(null);

    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
    const handleDetailChange = (e:any,key:any,name:string)=>{
        const { value } = e.detail;
        var change = details;
        change[key]={...change[key],[name]:value};
        // console.log(change);
        setDetails(change);
    }
    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
        // console.log("before",data,value);

        if(name==="Type"&&data.Type){
            // console.log("process",data,value);
            const oldType = data.Type;
            setAlertButtons([
                {
                  text: 'No',
                  role: 'cancel',
                  handler: () => {
                    // console.log(data,value);
                    setData({
                        ...data,
                        Type: oldType
                    });
                    }
                },
                {
                  text: 'Yes',
                  handler: () => {
                    //   console.log(data,value);
                    setDetails([]);
                    
                  }
                }
              ]);
            setAlertHeader("Warning");
            setAlertMessage("Change Type Will reset item, do you want to change?");
            setShowAlert(true);
        }
        
            setData({
                ...data,
                [name]: value
            });
        
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        setData({
            ...data,
            [name]: checked
        });
        // setChecked(checked);
    };

    useEffect(() => {
        if(location){
            if(location.pathname === "/sale/"+match.params.id+"/delivery"){
             
                loaddata();
            }
        }
        // loaddata();
        // eslint-disable-next-line
      }, [location,match.params.id]);
    //   useIonViewWillEnter(async () => {
    //     console.log("useIonViewWillEnter");
    //     loaddata();
    //   });
      const openSearchItemModal=()=>{
    
        if(data.Type!==undefined&&data.Type!==null&&data.Type!==""){

            if(data.Type==="ADD"
                &&(data.InventoryTo===undefined||data.InventoryTo===null||data.InventoryTo==="")
                ){
                    // console.log(data.InventoryTo);
                setAlertButtons(['Ok']);
                setAlertHeader("Warning");
                setAlertMessage("Please Input Inventory To");
                setShowAlert(true);
            }
            else if(data.Type==="LOSS"
                &&(data.InventoryFrom===undefined||data.InventoryFrom===null||data.InventoryFrom==="")
                ){
                setAlertButtons(['Ok']);
                setAlertHeader("Warning");
                setAlertMessage("Please Input Inventory From");
                setShowAlert(true);
            }
            else if(data.Type==="MOVE"
                    &&
                       ((data.InventoryFrom===undefined||data.InventoryFrom===null||data.InventoryFrom==="")
                        ||(data.InventoryTo===undefined||data.InventoryTo===null||data.InventoryTo==="")
                        )){
                setAlertButtons(['Ok']);
                setAlertHeader("Warning");
                setAlertMessage("Please Input Inventory From and Inventory To");
                setShowAlert(true);
            }
            else if(data.Type==="MOVE"&&data.InventoryFrom===data.InventoryTo){
                setAlertButtons(['Ok']);
                setAlertHeader("Warning");
                setAlertMessage("Inventory From and To Is Same");
                setShowAlert(true);
            }
            else{
                setShowSearchItemModal(true);
            }
        }
        else{
            setAlertButtons(['Ok']);
            setAlertHeader("Warning");
            setAlertMessage("Please Input Type");
            setShowAlert(true);
        }
        
    
      }
      const cancelSearchItemModal=()=>{
    
        setShowSearchItemModal(false);
    
      }

      const submitSearchItemModal=(items:any)=>{
        var SubGroup =new Date().getTime().toString(); 
        var InventoryID = "";
        var newinput = items.map((item:any)=>{
            var Flow = "";
            switch(data.Type){
                case "ADD": Flow="IN";
                            InventoryID=data.InventoryTo;
                            break;
                case "LOSS": Flow="OUT";
                            InventoryID=data.InventoryFrom;
                            break;
                case "SALE": Flow="OUT";
                            InventoryID=data.InventoryFrom;
                            break;
                default:break;
            }
            return {
                Flow:Flow,
                InventoryID:InventoryID,
                SubGroup:SubGroup,
                ItemID:item.id,
                Quantity:item.Quantity,
                Status:"COMPLETE",
                ItemName:item.Name,
                ItemCode:item.Code,
                QuantityCode:item.QuantityCode
                
            }
        })

        // console.log(newinput);
        if(newinput!==undefined && newinput.length>0){
            if(data.Type=="MOVE"){
                
                var inputin = newinput.map((i:any)=>{
                    return {...i,InventoryID:data.InventoryTo,Flow:"IN"}
                });

                var inputout = newinput.map((i:any)=>{
                    return {...i,InventoryID:data.InventoryFrom,Flow:"OUT"}

                })

                newinput = [...inputout,...inputin];
            }
            var i = 0;
            newinput=newinput.map((input:any)=>{
               
                return {...input,key:i++} 
            })
        }
        changeItemDetails(newinput);

        setShowSearchItemModal(false);
    
      }

      const changeItemDetails=(inputs:any)=>{
        if(details.length>0){
            var currentdetails = details;
  
            inputs= inputs.map((d:any)=>{
                var find = currentdetails.findIndex((f:any)=>{
                    
                    return f.Flow === d.Flow && f.InventoryID ===d.InventoryID && f.ItemID === d.ItemID;
                });

                
                if(find>=0){
                    // console.log("currentdetails[find]",currentdetails[find]);
                    var quantity = parseFloat(currentdetails[find].Quantity.toString().replace(",","")) + parseFloat(d.Quantity.toString().replace(",",""));
                    currentdetails[find].Quantity=quantity;
                }
                else{
                    
                    currentdetails.push(d);
                }
                return d;
            });

            setDetails(currentdetails);

        }
        else{
            setDetails(inputs);
        }
      }
      const loadinventory = async()=>{
        var inventories:any = await API.view("user/myinventory").catch(e=>errorhandler(e));
       
        if(inventories){
            setMyInventory(inventories);
        }
      }
    const loaddata=async()=>{
      setShowLoading(true);
      var result:any = await API.load('sale',match.params.id).catch(e=>errorhandler(e));
      var sender:any = await API.getUserme().catch(e=>errorhandler(e));
      var inventories:any = await API.view("user/myinventory").catch(e=>errorhandler(e));
      if(result){
          setToastMessage("load complete");
          setSale(result);
          setData({id:0,Type:"SALE",InventoryFrom:result["InventoryID"],TransactionID:result["id"],TransactionDate:initdate,SendBy:sender.Name});
          // setChecked(result.Active);
      }
      if(inventories){
          setMyInventory(inventories);
      }
      if(sender){
        setSender(sender);
        console.log(sender);
 
        }
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              setErrors(err.response.data["stockopname"]);
              setErrordetails(err.response.data["details"]);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }
    const removeitem=(item:any)=>{
        // console.log(item);
        var items = details.filter((d:any)=>{
            return d.key!==item.key;
        })

        setDetails(items);
    }
    const back=()=>{
        ionRouterContext.push('/sale/'+match.params.id,"root");

    }
    const save = async ()=>{
      
      setShowLoading(true);
      setErrors([]);
      setErrordetails([]);
      let result:any=null;
      
        result = await API.insert(apipath+"/createtransaction",{"stockopname":data,"details":details}).catch(e=>errorhandler(e));
      
      if(result){
          setToastMessage("save complete");
          setData(result["stockopname"]);
          ionRouterContext.push('/sale/'+match.params.id,'root');
      }
      setShowCompleteToast(true);
      setShowLoading(false);

    }

    function getDetailErrorMessage(field:string,index:any) {
        if(errordetails.length>0){
            console.log("errordetails",errordetails);
            console.log("index",index);

            console.log("errordetails[index]",errordetails[index]);
            if(!Array.isArray(errordetails[index])){
                return ;
            }
            else{
            return (
                <div className="validation-errors"> 
                    {errordetails[index].filter((error:any) => error.field === field).map((filterederror:any) => (
                    <div key={field} className="error-message"> 
                    <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                        {filterederror.message}
                        
                    </div>
                    
                    ))}
                </div>
                
                );
            }
        }
    }
    function getErrorMessage(field:string) {
        if(!Array.isArray(errors)){
            return ;
        }
        else{
        return (
            <div className="validation-errors"> 
                {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
                <div key={field} className="error-message"> 
                <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                    {filterederror.message}
                    
                </div>
                
                ))}
            </div>
            
            );
        }
    }
    const generateOption=()=>{
        var result:any = [];
        // console.log(myInventory);
        myInventory.forEach((od:any) => {
            
            result.push( <IonSelectOption key={"Inventory"+od.id} value={od.id}>{od.Name}</IonSelectOption>);

        });
        

        return result;
    }
    function getDetailsempty(){
        return (
            <IonList>
            <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"IN/OUT"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Inventory"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Item"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Quantity"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="fixed" color="primary">
                            {"Qty"}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonList>
        );
    }

    function getDetail(detail:any,key:any){
        // console.log(detail);
        return (
        <IonList key = {"list"+key} >
            <IonRow>
                <IonCol>
                    <IonItem detail={false} >
                        <IonLabel position="floating" color="primary">
                        {"IN/OUT"}
                        </IonLabel>
                        {data.Type=="CHANGE"?
                        <IonSelect key={"Flow"} value={detail["Flow"]} onIonChange={e => handleDetailChange(e,key,"Flow")}>
                            <IonSelectOption value={"IN"}>{"IN"}</IonSelectOption>
                            <IonSelectOption value={"OUT"}>{"OUT"}</IonSelectOption>
             
                        </IonSelect>
                        :
                        <IonInput readonly={true} name={"Flow"} value={detail["Flow"]} />   
                        }   
                    </IonItem>
                    {getDetailErrorMessage("Flow",key)}
                   
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Inventory"}
                        </IonLabel>
                        {/* <IonInput readonly={true} name={"InventoryID"} value={detail["InventoryID"]} />    */}
                        <IonSelect disabled key={"InventoryID"} value={detail["InventoryID"]}  placeholder={"Please Select Inventory"} onIonChange={e => handleDetailChange(e,key,"InventoryID")}>
                            {generateOption()}
                                            
                        </IonSelect>
                    </IonItem>
                    {getDetailErrorMessage("InventoryID",key)}
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Code"}
                        </IonLabel>
                        <IonInput readonly={true} name={"ItemCode"} value={detail["ItemCode"]}  />   

                    </IonItem>
                    {/* {getDetailErrorMessage("ItemID",key-1)} */}
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Item"}
                        </IonLabel>
                        <IonInput readonly={true} name={"Item"} value={detail["ItemName"]}  />   

                    </IonItem>
                    {getDetailErrorMessage("ItemID",key)}
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Quantity"}
                        </IonLabel>
                        <NumericField type={"tel"} readonly={false} name={"Quantity"} value={detail["Quantity"]} placeholder={"Please Input "+"Quantity"} onIonChange={e => handleDetailChange(e,key,"Quantity")} />   
                        {/* <NumberFormat customInput={IonInput} 
                                        thousandSeparator={true}
                                        pattern="[0-9]*"
                                        min="0"
                                        inputmode="numeric"
                                        name={"Quantity"} 
                                        value={detail["Quantity"]} 
                                        placeholder={"Please Input "+"Quantity"} 
                                        onIonChange={(e:any) => handleDetailChange(e,key,"Quantity")}/> */}
                    </IonItem>
                    {getDetailErrorMessage("Quantity",key)}

                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating" color="primary">
                        {"Qty"}
                        </IonLabel>
                        <IonInput readonly={true} name={"qty"} value={detail["QuantityCode"]} />   

                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonButton onClick={() => removeitem(detail) }>
                        <IonIcon slot="icon-only" icon={remove}></IonIcon>
                        </IonButton>  

                    </IonItem>
                </IonCol>
            </IonRow>
            <IonItemDivider color="primary"></IonItemDivider>
        </IonList>
        );
    }
    console.log(data);
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {/* <IonBackButton defaultHref={"/"+apipath}></IonBackButton> */}
            <IonButton title="Back to Sale" onClick={() => back() }>
              <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>{"SALE DELIVERY"}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => save() }>
              <IonIcon slot="icon-only" icon={saveicon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
         <IonCard className="field-card">
           {data&&
           <IonGrid>
               <IonRow>
               <IonCol>
                    <IonItem key = {"TransactionDate"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Date"}
                        </IonLabel>
                        <DateInput displayFormat="DD MMM YYYY" placeholder="Select Date" value={data["TransactionDate"]} onIonChange={e=>handleChange(e,"TransactionDate")}></DateInput>
                    </IonItem>
                    {getErrorMessage("TransactionDate")}
                </IonCol>
                <IonCol>
                    <IonItem key = {"TransactionCode"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Code"}
                        </IonLabel>
                        <IonInput name={"TransactionCode"} value={data["TransactionCode"]} placeholder={"Please Input "+"Code"} onIonChange={e=>handleChange(e,"TransactionCode")}/>   
                    </IonItem>
                    {getErrorMessage("TransactionCode")}
                </IonCol>
            </IonRow>
           
            <IonRow>
               <IonCol>
                    <IonItem key = {"Type"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Type"}
                        </IonLabel>
                        <IonInput readonly name={"Type"} value={data["Type"]} placeholder={"Please Input "+"Type"} onIonChange={e=>handleChange(e,"Type")}/>   

                    </IonItem>
                    {getErrorMessage("Type")}
                    </IonCol>
               
                    <IonCol>
                        
                        <IonItem key = {"Inventory From"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                            {"Inventory From"}
                        </IonLabel>
                        <IonSelect disabled key={"inventoryfrom"} value={data["InventoryFrom"]}  placeholder={"Please Select Inventory"} onIonChange={e => handleChange(e,"InventoryFrom")}>
                            {generateOption()}
                                            
                        </IonSelect>
                        </IonItem>
                        {getErrorMessage("InventoryFrom")}

                    </IonCol>
                
                </IonRow>
           <IonRow>
                <IonCard >
                    <IonList>
                        <IonRow>
                        {/* <IonCol>
                            <IonItem key = {"itemdetail"} detail={false} disabled={false}>
                                <IonLabel position="fixed" color="primary">
                                {"Item Detail"}
                                </IonLabel>
                            </IonItem>
                        </IonCol> */}
                        <IonCol>
                        {/* <IonButtons > */}
                            <IonButton color="primary" onClick={() => openSearchItemModal()}>
                                <IonIcon icon={add} />
                                Add
                            </IonButton>
                            {/* </IonButtons> */}
                        </IonCol>
                        
                        </IonRow>
                    </IonList>
                    <IonCardContent>
                    {details.length==0? 
                        ""//getDetailsempty()
                            :
                        Object.keys(details).map((key:any)=>{
                            var detail = details[key];
                            // console.log(detail);
                            return getDetail(detail,key);
                        })
                    }
                    </IonCardContent>
                </IonCard>
           </IonRow>
          
           <IonList>
           <IonRow>
                <IonCol>
                    <IonItem key = {"SendBy"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Send By"}
                        </IonLabel>
                        <IonInput name={"SendBy"} value={data["SendBy"]} placeholder={"Please Input "+"Send By"} onIonChange={e=>handleChange(e,"SendBy")}/>   
                    </IonItem>
                    {getErrorMessage("SendBy")}
                </IonCol>
                <IonCol>
                    <IonItem key = {"ReceivedBy"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Received By"}
                        </IonLabel>
                        <IonInput name={"ReceivedBy"} value={data["ReceivedBy"]} placeholder={"Please Input "+"Received By"} onIonChange={e=>handleChange(e,"ReceivedBy")}/>   
                    </IonItem>
                    {getErrorMessage("ReceivedBy")}

                </IonCol>
            </IonRow>
           </IonList>
           <IonList>
            <IonRow>
               <IonCol>
                    <IonItem key = {"Remarks"} detail={false} disabled={false}>
                        <IonLabel position="floating" color="primary">
                        {"Remarks"}
                        </IonLabel>
                        <IonTextarea name={"Remarks"} value={data["Remarks"]} placeholder={"Please Input "+"Remarks"} onIonChange={e=>handleChange(e,"Remarks")}/>   
                    </IonItem>
                    {getErrorMessage("Remarks")}

                </IonCol>
                </IonRow>
           </IonList>
           </IonGrid>
        }
        {/* <Form mode={match.params.id==="new"?"new":"update"}
              data={data}
              errors={errors}
              template={getTemplate(apipath+(match.params.id==="new"?"_create":"_update"))}
              dataChanged={setData}
        /> */}
         </IonCard>
      </IonContent>
      <IonModal
        isOpen={showSearchItemModal}
        onDidDismiss={() => setShowSearchItemModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
        cssClass="list-filter"
      >
        <SearchSaleItemForm
          cancel={cancelSearchItemModal}
          submit={submitSearchItemModal}
          saleid={match.params.id}
        />
      </IonModal>
      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={alertHeader}
          message={alertMessage}
          buttons={alertButtons}
        />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: withRouter(SaleDelivery)
})