import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard } from '@ionic/react';

import { connect } from '../../data/connect';
import './GQuantityForm.scss';
import API from '../../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { informationCircleOutline, save as saveicon, saveOutline } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
 
};

interface DispatchProps {

}

type GQuantityFormProps = OwnProps & StateProps & DispatchProps;

const GQuantityForm: React.FC<GQuantityFormProps> = ({location,match}) => {
  const ionRouterContext = useContext(IonRouterContext);

    const apipath = 'quantity';
    var initdata =  {   
      id:0
        ,Name:''
        ,Active:true
    }
    const [errors,setErrors] = useState(
        []
    );
    const [data,setData] = useState<any>(initdata);
    const [ischanged,setIschanged]=useState(false);

    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        setData({
            ...data,
            [name]: checked
        });
        // setChecked(checked);
    };

    useEffect(() => {
      if(location){
        if(location.pathname === "/quantitygroup/"+match.params.id+"/add"){
            var id = parseInt(match.params.id);
          if(id){
            setData({...initdata,QuantityGroupID:id});
            // loaddata();
          }
          else{
            setData(initdata);
          }
        }
      }
        // loaddata();
        // eslint-disable-next-line
      }, [location,match.params.id]);
    
    const loaddata=async()=>{
      setShowLoading(true);
      var result:any = await API.load(apipath,match.params.id).catch(e=>errorhandler(e));
      if(result){
          setToastMessage("load complete");
          setData(result);
          // setChecked(result.Active);
      }
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              setErrors(err.response.data);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }

    const save = async ()=>{
      
      setShowLoading(true);
      setErrors([]);
  
      let result:any=null;
    //   if(match.params.id==="new"){
        result = await API.insert(apipath,data).catch(e=>errorhandler(e));
    //   }
    //   else{
    //      result = await API.update(apipath,match.params.id,data).catch(e=>errorhandler(e));
    //   }
      if(result){
          setToastMessage("save complete");
          setData(result);
          ionRouterContext.push('/'+apipath);
      }
      setShowCompleteToast(true);
      setShowLoading(false);

    }
    const dataChanged=(data:any)=>{
      setData(data);
      setIschanged(true);
    }

    function getErrorMessage(field:string) {
      return (
        <div className="validation-errors"> 
            {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
              <div key={field} className="error-message"> 
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                {filterederror.message}
                
              </div>
              
            ))}
        </div>
          
        );
    }
    console.log(data);
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/"+apipath}></IonBackButton>
          </IonButtons>
          <IonTitle>{"NEW QUANTITY"}</IonTitle>
          <IonButtons slot="end">
            <IonButton title={"save"} disabled={!ischanged} onClick={() => save() }>
              <IonIcon slot="icon-only" icon={saveicon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
         <IonCard className="field-card">
           
        <Form mode={"new"}
              data={data}
              errors={errors}
              template={getTemplate(apipath+"_create")}
              dataChanged={dataChanged}
        />
         </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: withRouter(GQuantityForm)
})