import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonGrid, IonRow, IonCol, IonBackButton, IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonTextarea, IonToggle, IonCheckbox, IonLoading, IonRefresher, IonRefresherContent, IonToast, IonRouterContext, IonCard } from '@ionic/react';

import { connect } from '../../data/connect';
// import './ChangePassword.scss';
import API from '../../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { informationCircleOutline, save as saveicon, saveOutline } from 'ionicons/icons';
import Form from '../../components/ActiveForm/Form';
import { getOptionsdata, getTemplate } from '../../data/form/template';
interface OwnProps extends RouteComponentProps<{
    id: string;
  }> {}

interface StateProps {
 
};

interface DispatchProps {

}

type ChangePasswordProps = OwnProps & StateProps & DispatchProps;

const ChangePassword: React.FC<ChangePasswordProps> = ({location,match}) => {
  const ionRouterContext = useContext(IonRouterContext);

    const apipath = 'user';
    var initdata =  {   
        userid:match.params.id
     ,newpassword:''
     ,reconfirmpassword:''
    }
    const [errors,setErrors] = useState(
        []
    );
    const [data,setData] = useState<any>(initdata);
    const [ischanged,setIschanged]=useState(false);

    const [showCompleteToast, setShowCompleteToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Complete");
    const [showLoading, setShowLoading] = useState(false);
    const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

    const handleChange = (e:any,name:string) => {
        const { value } = e.detail;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeChecked = (e:any,name:string) => {
        const { checked } = e.detail;
        setData({
            ...data,
            [name]: checked
        });
        // setChecked(checked);
    };

    useEffect(() => {
      if(location){
        if(location.pathname === "/changepassword/"+match.params.id){

        }
      }
        // loaddata();
        // eslint-disable-next-line
      }, [location,match.params.id]);
    
    const loaddata=async()=>{
      setShowLoading(true);
      var result:any = await API.load(apipath,match.params.id).catch(e=>errorhandler(e));
      if(result){
          setToastMessage("load complete");
          setData(result);
          // setChecked(result.Active);
      }
      setShowCompleteToast(true);
      setShowLoading(false);
    }

    const errorhandler = (err:any) =>{
      setToastMessage(err.message);
      if(err.response){
          if(err.response.status==422){
              setErrors(err.response.data);
          }
      }
      else{
          console.log("errorhandler",err.message);
      }
    }

    const save = async ()=>{
      
      setShowLoading(true);
      setErrors([]);
      let result:any=null;
     
        result = await API.insert(apipath+"/changepassword",data).catch(e=>errorhandler(e));
    
      if(result){
          setToastMessage("change password complete");
          setData(result);
          ionRouterContext.push('/user/'+match.params.id,'root');
      }
      setShowCompleteToast(true);
      setShowLoading(false);

    }

    const dataChanged=(data:any)=>{
      setData(data);
      setIschanged(true);
    }
    function getErrorMessage(field:string) {
      return (
        <div className="validation-errors"> 
            {errors.filter((error:any) => error.field === field).map((filterederror:any) => (
              <div key={field} className="error-message"> 
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
                {filterederror.message}
                
              </div>
              
            ))}
        </div>
          
        );
    }
  return (
    <IonPage id="form-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={"/user/"+match.params.id}></IonBackButton>
          </IonButtons>
          <IonTitle>Change Password</IonTitle>
          <IonButtons slot="end">
            <IonButton disabled ={!ischanged} title={"save"} onClick={() => save() }>
              <IonIcon slot="icon-only" icon={saveicon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      <IonLoading
          // cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          // duration={5000}
        />
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={loaddata}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message={toastMessage}
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
         <IonCard className="field-card">
           
         <Form mode={"new"}
              data={data}
              errors={errors}
              template={getTemplate("changepassword")}
              dataChanged={dataChanged}
        />
         </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
  }),
  mapDispatchToProps: {
  },
  component: withRouter(ChangePassword)
})